import { AppBar, Divider, Grid, TextField, Typography, FormHelperText, Select, MenuItem } from "@mui/material";
import { PrimaryButton, SecondaryButton, MobilePhoneInput } from "./styles";
import {createCaseSchema, createI9CaseSchema, createI9AltCaseSchema, createRtwCaseSchema} from "./CreateCaseFormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import { JourneyInfo } from "../../../models/responses/JourneyInfo";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { matchIsValidTel } from "mui-tel-input";
import { useCreateCase } from "../../../hooks/Cases/useCreateCase";
import { StartEndDateField } from "../../../components/CaseDetailsForm/BasicDetailsPanel/styles";
import {useContext} from "react";
import JourneyContext from "../../../context/JourneyContext";
import { useBrand } from "../../../hooks/Brand/useBrand";

type CreateCaseFormFieldProps = {
  name: string;
  label?: string;
  placeholder?: string;
  sx?: number;
  type?: string;
};

const CreateCaseForm = ({
  fields,
  journey,
}: {
  fields: CreateCaseFormFieldProps[];
  journey: JourneyInfo;
}) => {
  const navigate = useNavigate();
  const { currentJourney } = useContext(JourneyContext);
  const isI9 = currentJourney?.journeyType.startsWith("I9");

  const { data: brand } = useBrand();
  console.log(brand)

  const getYupResolver = () => {
    switch (currentJourney?.journeyType) {
      case 'I9':
        return createI9CaseSchema;
      case 'I9_ALT':
        return createI9AltCaseSchema;
      case 'UK_RTW':
      case 'IRL_RTW':
        return createRtwCaseSchema;
      default:
       return createCaseSchema;
    }
  }

  const  defaultLocation = currentJourney?.locations?.length === 1 ? currentJourney.locations[0].id as unknown as string : '';
  // eslint-disable-next-line
  const defaultValues: any = {
    locationId: defaultLocation,
  }

  if (isI9) {
    defaultValues.hiringManagerName = brand?.hiringManagers && brand?.hiringManagers?.length > 0 ? brand?.hiringManagers[0].name : '';
    defaultValues.hiringManagerLastName = brand?.hiringManagers && brand?.hiringManagers?.length > 0 ? brand?.hiringManagers[0].lastName : '';
    defaultValues.hiringManagerEmail = brand?.hiringManagers && brand?.hiringManagers?.length > 0 ? brand?.hiringManagers[0].email : '';
    defaultValues.hiringManagerPhone = brand?.hiringManagers && brand?.hiringManagers?.length > 0 ? brand?.hiringManagers[0].phone : '';
    defaultValues.hiringManagerTitle = brand?.hiringManagers && brand?.hiringManagers?.length > 0 ? brand?.hiringManagers[0].title : '';
  }

  const { handleSubmit, control, formState: { isValid } } = useForm({
    resolver: yupResolver(getYupResolver()),
    mode: 'all',
    defaultValues
  });

  const {
    mutate,
    isLoading,
    isSuccess
  } = useCreateCase({
    onSuccess: () => {
      toast.success("Successfully created case");
      setTimeout(() => navigate("/cases"), 3000);
    },
    onError: () => {
      toast.error("Could not create Case");
    }
  });

  const isDisabled = isLoading || isSuccess

  const onSubmit = (data: { [key: string]: string }) => {
      mutate({
        brandId: journey.brandId,
        journeyId: journey.uid,
        country: journey.country,
        consumerReference: data.candidateReference,
        givenName: data.firstName,
        familyName: data.lastName,
        emailAddress: data.email,
        mobileNumber: data.mobileNumber,
        locationId: Number.parseInt(data.locationId),
        location: data.location,
        startDate: data.startDate ? new Date(data.startDate).toLocaleDateString("sv-SE") + "T00:00:00.000Z" : undefined,
        endDate: data.endDate ? (new Date(data.endDate).toLocaleDateString("sv-SE") + "T00:00:00.000Z") : undefined,
        hiringManagerName: data.hiringManagerName,
        hiringManagerLastName: data.hiringManagerLastName,
        hiringManagerEmail: data.hiringManagerEmail,
        hiringManagerPhone: data.hiringManagerPhone,
        hiringManagerTitle: data.hiringManagerTitle,
        additionalData: Object.fromEntries(Object.entries(data).filter(itm => currentJourney?.caseSpecifications.additionalFields.map(field => field.name).includes(itm[0])))  
      });
  };

  const DateField = (params: { field: CreateCaseFormFieldProps }) => {
    return <Controller
      // eslint-disable-next-line
      //@ts-ignore
      name={params.field.name}
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (<>
        <StartEndDateField
          {...field}
          format={isI9? undefined : "YYYY/MM/DD"}
          onChange={(value) => {
            field.onChange(value);
          }}
          slotProps={params.field.name === "endDate" ? {
            actionBar: {
              actions: ['clear'],
              sx: {
                background: "#000",
                color: '#fff',
                button: {
                  color: '#fff'
                }
              }
            }
          } : undefined}
        />
        {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
      </>)}
    />
  }

  const MobileField = (params: { field: CreateCaseFormFieldProps }) => {
    return <Controller
      // eslint-disable-next-line
      //@ts-ignore
      name={params.field.name} control={control} rules={{ required: true, validate: matchIsValidTel }}
      render={({ field, fieldState }) => (
        // eslint-disable-next-line
        //@ts-ignore
        <MobilePhoneInput
          {...field}
          fullWidth
          size={"small"}
          onChange={(value) => {
            field.onChange(value);
          }}
          helperText={
            fieldState.invalid ? "Cell / Mobile Number is invalid" : ""
          }
          error={fieldState.invalid}
        />
      )}
    />
  }

  const TextInputField = (params: { field: CreateCaseFormFieldProps }) => {
    return <Controller
      // eslint-disable-next-line
      //@ts-ignore
      name={params.field.name}
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          onChange={(value) => {
            field.onChange(value);
          }}
          fullWidth
          size={"small"}
          placeholder={params.field.placeholder}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
    />
  }

  const LocationField = (params: { field: CreateCaseFormFieldProps }) => {
    return <Controller
      // eslint-disable-next-line
      //@ts-ignore
      name={params.field.name}
      control={control}
      rules={{ required: true }}
      render={({
        field,
        fieldState: { error },
      }) => (
        <>
        <Select
          {...field}
          size={"small"}
          fullWidth
          error={!!error}
        >
          {currentJourney?.locations.map((location, index) =>
            <MenuItem key={index} value={location.id}>{location.name}</MenuItem>
          )}
        </Select>
        {error?.message && <FormHelperText style={{color: 'red'}}>{error?.message}</FormHelperText>}
         </>
      )}
    />
  }

  const getInputField = (field: CreateCaseFormFieldProps) => {
    if (["startDate", "endDate"].includes(field.name)) {
      return <DateField field={field} />
    }
    if (field.name === "mobileNumber") {
      return <MobileField field={field} />
    }
    if (field.name === 'locationId') {
      return <LocationField field={field} />
    }
    return <TextInputField field={field} />
  }

  return (
    <>
      <Grid item xs={8}>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: "18px" }}>
          Create a case
        </Typography>
        <p>Provide the following details to create a case. Clicking ‘SUBMIT’ generates a candidate invitation.<br/>The candidate will receive the invitation as an SMS/email.</p> 
      </Grid>
      <form style={{ width: "100%" }}>
        <Grid container alignItems={"center"} item xs={8} sx={{ marginTop: 2 }}>
          {fields.map((field, index) => {
            if (field.type === "divider") {
              return (
                <Grid item xs={12} key={index}>
                  <Divider sx={{ margin: "20px 0px" }} />
                </Grid>
              );
            }
            else if (field.type === "subtitle") {
              return (
                <Grid item xs={8} key={index}>
                  <Typography paragraph sx={{ fontWeight: 600, fontSize: "18px" }}>
                    {field.label}
                  </Typography>
                </Grid>
              );
            } else {
              return (
                <Grid container alignItems={"center"} item xs={12} key={index}>
                  <Grid item xs={6}>
                    {field.label}
                  </Grid>
                  <Grid item xs={field.sx || 6} sx={{ marginTop: 2 }}>
                    {getInputField(field)}
                  </Grid>
                </Grid>
              );
            }
          })}
          {
            currentJourney && currentJourney.caseSpecifications.additionalFields.map(field => <Grid container alignItems={"center"} item xs={12} key={`${field}-field`}>
                <Grid item xs={6}>
                  {field.name.toUpperCase()}
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  {getInputField({
                    name: field.name,
                    label: field.name.toUpperCase(),
                  })}
                </Grid>
              </Grid>)
          }
        </Grid>
        <AppBar
          position="fixed"
          sx={{
            borderTop: 1,
            borderColor: "divider",
            top: "auto",
            bottom: 0,
            background: "white",
          }}
        >
          <Grid container item xs={12} style={{ flexGrow: 1, padding: 20 }}>
            <Grid item xs={8}></Grid>
            <Grid
              container
              item
              xs={4}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <SecondaryButton variant="contained" href="/">
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled || !isValid}
              >
                Submit
              </PrimaryButton>
            </Grid>
          </Grid>
        </AppBar>
      </form>
    </>
  );
};

export default CreateCaseForm;