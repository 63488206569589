import { ListItemButton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SectionsDataListButton = styled(ListItemButton)(() => ({
  width: "100%",
  display: "block",
  padding: "0px",
  borderRadius: "16px",
}));

export const SectionsDataStack = styled(Stack)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #D7D7D7",
  padding: "16px 5px",
  "&:hover": {
    borderColor: "#0000",
  },
  p: {
    fontSize: "12px",
    margin: 0,
  },
  ".MuiSvgIcon-fontSizeSmall": {
    fontSize: "12px",
    margin: "13px 0px 0px 10px",
  },
  ".MuiSvgIcon-fontSizeMedium": {
    fontSize: "22px",
    margin: "7px 5px 0px 0px",
  },
  ".MuiChip-outlined": {
    height: "auto",
    border: "2px solid rgba(0,0,0,0.1)",
    padding: "5px 10px",
    borderRadius: "50px",
    span: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    "&.Section_1": {
      background: "#90EBBF",
    },
    "&.Section_2_In_Progress": {
      background: "#EBE790",
    },
    "&.Section_3_In_Progress": {
      background: "#C6B7F2",
    },
    "&.E_Verify": {
      background: "#98DBFA",
    },
  },
}));
