import {Button, DialogActions, DialogTitle, Grid} from "@mui/material";
import {NotificationModalDialog} from "./styles";
import * as React from "react";
import {ReactElement} from "react";

export type NotificationModalProps = {
    text: string;
    subtext?: string;
    icon?: ReactElement;
    isOpen: boolean;
    handleOk?: () => void;
};

const NotificationModal = ({
                               text,
                               subtext,
                               isOpen,
                               icon,
                               handleOk
                           }: NotificationModalProps) => {
    return (
        <NotificationModalDialog
            open={isOpen}
            aria-labelledby="notification-dialog-text">
            {icon && <Grid container item xs={12} justifyContent={"center"}>
                {icon}
            </Grid>
            }
            <DialogTitle id="notification-dialog-text">{text}</DialogTitle>
            {subtext && <p>{subtext}</p>}
            {handleOk && <DialogActions>
                <Button onClick={handleOk} variant="contained" style={{width: "50%"}}>
                    Ok
                </Button>
            </DialogActions>
            }
        </NotificationModalDialog>
    );
};

export default NotificationModal;
