import React, { useCallback, useState } from "react";
import { Box, CssBaseline, Toolbar, Link, Grid } from "@mui/material";
import { AppBar, DrawerHeader, OpenSidebarIcon } from "./styles";
import { ReactComponent as SidebarControlLeft } from "../../img/sidebar_control_left.svg";
import { ReactComponent as SidebarControlRight } from "../../img/sidebar_control_right.svg";
import Sidebar from "../SideBar";
import CountrySelector from "./CountrySelector";

export type HeaderBreadcrumb = {
  label: string;
  path?: string;
};

const BreadCrumbComponent = ({
  breadcrumb,
}: {
  breadcrumb: HeaderBreadcrumb;
}) => {
  if (breadcrumb.path) {
    return (
      <Link
        color={"grey"}
        href={breadcrumb.path}
        underline={"hover"}
        sx={{ marginRight: "10px", fontSize: 24, fontWeight: "bold" }}
      >
        {breadcrumb.label}
      </Link>
    );
  } else {
    return (
      <span
        style={{
          marginRight: "10px",
          fontSize: 24,
          fontWeight: "bold",
          color: "#1A1A1A",
        }}
      >
        {breadcrumb.label}
      </span>
    );
  }
};

const HeaderBar = ({
  breadcrumbs,
  children,
}: {
  breadcrumbs: HeaderBreadcrumb[];
  children: React.ReactNode;
}) => {
  const [openSideBar, setOpenSideBar] = useState<boolean>(true);

  const toggleSideBar = useCallback(() => {
    setOpenSideBar(!openSideBar);
  }, [openSideBar, setOpenSideBar]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={openSideBar}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Toolbar>
          <OpenSidebarIcon
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSideBar}
            edge="start"
            sx={{
              ...(!openSideBar && { left: "56px" }),
            }}
          >
            {openSideBar ? <SidebarControlLeft /> : <SidebarControlRight />}
          </OpenSidebarIcon>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={11} sx={{display: 'flex'}} >
              {breadcrumbs.map((breadcrumb, index) => {
                return (
                  <div key={`breadcrumb-${breadcrumb.label}`} >
                    {index !== 0 && (
                      <BreadCrumbComponent breadcrumb={{ label: "/" }} />
                    )}
                    <BreadCrumbComponent breadcrumb={breadcrumb} />
                  </div>
                );
              })}
            </Grid>
            <Grid container item xs={1} justifyContent={"flex-end"} alignContent={"center"}>
              <CountrySelector/>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={openSideBar} />
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
        <div style={{ height: "calc(100% - 64px)" }}>{children}</div>
      </Box>
    </Box>
  );
};

export default HeaderBar;
