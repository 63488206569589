import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const JoinCallButton = styled(Button)(() => ({
  border: '1px solid #3572E7',
  color: '#3572E7',
  textTransform: 'capitalize',
  borderRadius: '8px',
  svg: {
    marginRight: '10px',
    width: '23px',
    height: '20px'
  },
  '&:hover': {
    background: '#3572E7',
    border: '1px solid #3572E7',
    color: '#fff'
  }
}));