import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { TableCell } from "@mui/material";

export const AuditHeadTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#666666",
    fontSize: 12,
    borderRight: "1px solid #0000000d",
    borderBottom: 0,
    padding: "0px 16px",
  },
}));
