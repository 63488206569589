import { Button } from "@mui/material";
import { styled, CSSObject } from "@mui/material/styles";

export const TopTabLeftButton = styled(Button)(
  ({ theme }): CSSObject => ({
    background: "#fff",
    border: `2px solid ${theme.palette.primary.main}`,
    textTransform: "capitalize",
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    padding: "5px 20px",
    position: "relative",
    zIndex: "3",
    "&:after": {
      width: "30px",
      height: "29px",
      borderRight: `2px solid ${theme.palette.primary.main}`,
      background: "#fff",
      position: "absolute",
      content: '""',
      top: "2px",
      right: "-15px",
      zIndex: "2",
      transform: "rotate(45deg)",
      borderTopLeftRadius: "8px",
      borderTop: `2px solid ${theme.palette.primary.main}`,
      borderTopRightRadius: "0px",
    },
    "&:hover": {
        background: "#fff",
        border: `2px solid ${theme.palette.primary.main}`,
    },
    "&.TopTabSelected": {
        background: theme.palette.secondary.light,
        border: `2px solid ${theme.palette.secondary.main}`,
        "&:hover": {
            border: `2px solid ${theme.palette.secondary.main}`,
            background: theme.palette.secondary.light,
        },
        "&:after": {
            background: theme.palette.secondary.light,
            border: `2px solid ${theme.palette.secondary.main}`,
            borderLeft: `2px solid ${theme.palette.secondary.light}`,
            borderBottom: `2px solid ${theme.palette.secondary.light}`
        }
    }
  })
);

export const TopTabButtonLeftArrow = styled("div")(
  ({ theme }): CSSObject => ({
    background: "#fff",
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0px',
    textTransform: "capitalize",
    padding: "5px 18px",
    position: "relative",
    zIndex: "2",
    "&:after": {
      width: "0",
      height: "0",
      borderTop: "19px inset transparent",
      borderBottom: "17px inset transparent",
      borderLeft: `20px solid #fff`,
      position: "absolute",
      content: '""',
      top: "0",
      left: "-2px",
      zIndex: "2"
    },
    "&:before": {
      width: "0",
      height: "0",
      borderTop: "22px inset transparent",
      borderBottom: "19px inset transparent",
      borderLeft: `22px solid ${theme.palette.primary.main}`,
      position: "absolute",
      content: '""',
      top: "-2px",
      left: "-1px",
      zIndex: "2",
      borderRadius: "6px",
    },
    "&.TopTabSelected": {
       background: theme.palette.secondary.light,
       borderTop: `2px solid ${theme.palette.secondary.main}`,
       borderBottom: `2px solid ${theme.palette.secondary.main}`,
       "&:hover": {
            background: theme.palette.secondary.light,
        },
       "&:before": {
           borderLeft: `22px solid ${theme.palette.secondary.main}`
       }
    }
  })
);

export const TopTabButtonRightArrow = styled("div")(
  ({ theme }): CSSObject => ({
    background: "#fff",
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0px',
    textTransform: "capitalize",
    padding: "5px 10px",
    position: "relative",
    zIndex: "3",
    "&:after": {
      width: "30px",
      height: "29px",
      borderRight: `2px solid ${theme.palette.primary.main}`,
      background: "#fff",
      position: "absolute",
      content: '""',
      top: "2px",
      right: "-15px",
      zIndex: "2",
      transform: "rotate(45deg)",
      borderTopLeftRadius: "8px",
      borderTop: `2px solid ${theme.palette.primary.main}`,
      borderTopRightRadius: "0px",
    },
    "&:hover": {
        background: "#fff",
    },
    "&.TopTabSelected": {
        background: theme.palette.secondary.light,
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        "&:hover": {
            background: theme.palette.secondary.light,
        },
        "&:after": {
            background: theme.palette.secondary.light,
            border: `2px solid ${theme.palette.secondary.main}`,
            borderLeft: `2px solid ${theme.palette.secondary.light}`,
            borderBottom: `2px solid ${theme.palette.secondary.light}`
        }
    }
  })
);

export const TopTabButtonMiddlePart = styled(Button)(
  ({ theme }): CSSObject => ({
    background: "#fff",
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0px',
    textTransform: "capitalize",

    padding: "5px 0px",
    position: "relative",
    zIndex: "3",    
    "&:hover": {
        background: "#fff",
    },
    "&.TopTabSelected": {
        background: theme.palette.secondary.light,
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        "&:hover": {
            background: theme.palette.secondary.light,
        },
    }
  })
);

export const TopTabRightButton = styled(Button)(
  ({ theme }): CSSObject => ({
    background: "#fff",
    border: `2px solid ${theme.palette.primary.main}`,
    textTransform: "capitalize",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    padding: "5px 40px",
    zIndex: "2",
    "&:after": {
      width: "0",
      height: "0",
      borderTop: "19px inset transparent",
      borderBottom: "17px inset transparent",
      borderLeft: `20px solid #fff`,
      position: "absolute",
      content: '""',
      top: "0",
      left: "-2px",
      zIndex: "2"
    },
    "&:before": {
      width: "0",
      height: "0",
      borderTop: "22px inset transparent",
      borderBottom: "19px inset transparent",
      borderLeft: `22px solid ${theme.palette.primary.main}`,
      position: "absolute",
      content: '""',
      top: "-2px",
      left: "-1px",
      zIndex: "2",
      borderRadius: "6px",
    },
    "&.TopTabSelected": {
       background: theme.palette.secondary.light,
       border: `2px solid ${theme.palette.secondary.main}`,
       "&:hover": {
            border: `2px solid ${theme.palette.secondary.main}`,
            background: theme.palette.secondary.light,
        },
       "&:before": {
           borderLeft: `22px solid ${theme.palette.secondary.main}`
       }
    }
  })
);
