import {useAxiosParams} from "../common/useAxiosParams";
import {useQuery} from "@tanstack/react-query";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import axios, {AxiosError} from "axios";
import {CaseAuditLogResponse} from "../../models/responses/CaseAuditLogResponse";

export const useAuditLogList = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()

    return useQuery<unknown, GenericJsonObject, CaseAuditLogResponse[]>({
        queryKey: ["case-audit-logs", caseUid],
        queryFn: async () => {
            try {
                const response = await axios.get<CaseAuditLogResponse[]>(basePath + `/${caseUid}/auditLogs`, config);
                return response.data;
            }
            catch (error) {
                const axiosError = error as AxiosError;
                throw axiosError.response?.data || {"message": axiosError.message};
            }
        }
    })
}