import { Button } from "@mui/material";
import { styled, CSSObject } from "@mui/material/styles";

export const DashboardMapContainer = styled("div")(() => ({
  background: "#fff",
  border: "1px solid #D7D7D7",
  borderRadius: "16px",
  "p, h3": {
    padding: 0,
    margin: 0,
  },
  p: {
    display: "inline-block",
    marginRight: "20px",
  },
}));

export const LocationList = styled("div")(() => ({
  padding: "20px 20px 10px 20px",
  borderTop: "1px solid #D7D7D7",
  p: {
    display: "block",
    marginBottom: "15px",
  },
}));

export const MoreLocationsButton = styled(Button)(
  ({ theme }): CSSObject => ({
    display: "inline-block",
    color: "#fff",
    borderRadius: 0,
    background: theme.palette.primary.main,
    textTransform: "none",
    padding: "10px 20px",
    fontSize: "14px",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  })
);
