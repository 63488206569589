import React, {useEffect, useRef, useState} from 'react';
import {Box, CircularProgress, Grid} from "@mui/material";
import {ReactComponent as EnlargeIcon} from "../../../img/enlarge.svg";
import I9FormModal from "../../I9FormModal";
import {Document, Page, pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const i9Form = ({i9PdfFile, height, showDocName}:
                    {
                        i9PdfFile: Blob | undefined,
                        height?: string,
                        showDocName: boolean
                    }) => {

    const [open, setOpen] = useState<boolean>(false);

    const handleEnlarge = () => {
        setOpen(true);
    }

    const [pdfWidth, setPdfWidth] = React.useState(0);

    const pdfContainerRef = useRef<HTMLDivElement>(null);

    const calculatePdfWidth = () => {
        const width = pdfContainerRef?.current?.clientWidth;
        return width ? width - 28 : 0
    }

    const updatePdfWidth = () => {
        setPdfWidth(calculatePdfWidth());
    }

    const resizeHandler = () => {
        updatePdfWidth();
    }

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    return (
        <>
            <Grid container>
                {!i9PdfFile &&
                    <div>
                        <label>I-9 Form</label>
                        <Box sx={{display: 'flex'}}>
                            <CircularProgress/>
                        </Box>
                    </div>

                }
                {
                    i9PdfFile && <Grid item container width="100%">
                        <div style={{
                            width: "100%",
                        }}>
                            {showDocName && <p>I-9 Form</p>}
                            <div ref={pdfContainerRef}
                                 style={{
                                     padding: "16.5px 14px",
                                     borderRadius: 4,
                                     position: "relative",
                                     background: "#F4F4F4",
                                     width: "100%",
                                 }}>
                                <EnlargeIcon
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        right: "0px",
                                        margin: "23px",
                                        zIndex: "1000"
                                    }}
                                    onClick={() => handleEnlarge()}
                                />
                                <div style={{
                                    width: "100%",
                                    height: height ? height : "55vh",
                                    overflow: "scroll"
                                }}>
                                    <Document onLoadSuccess={resizeHandler}
                                              file={i9PdfFile}>
                                        <Page pageNumber={1}
                                              width={pdfWidth}
                                              renderTextLayer={false}
                                              renderAnnotationLayer={false}
                                        />
                                        <Page pageNumber={2}
                                              width={pdfWidth}
                                              renderTextLayer={false}
                                              renderAnnotationLayer={false}
                                        />
                                        <Page pageNumber={3}
                                              width={pdfWidth}
                                              renderTextLayer={false}
                                              renderAnnotationLayer={false}
                                        />
                                        <Page pageNumber={4}
                                              width={pdfWidth}
                                              renderTextLayer={false}
                                              renderAnnotationLayer={false}
                                        />
                                    </Document>
                                </div>
                            </div>
                        </div>
                    </Grid>
                }
            </Grid>
            <I9FormModal
                open={open}
                setOpen={setOpen}
                i9PdfFile={i9PdfFile}
            />
        </>
    );
}

i9Form.defaultProps = {
    showDocName: true
};


export default i9Form;
