import { styled, CSSObject } from "@mui/material/styles";
import { Box } from "@mui/material";

export const CreateCaseButtonSwitch = styled(Box)(
  ({ theme }): CSSObject => ({
    borderRadius: "20px",
    position: "relative",
    background: theme.palette.secondary.light,
    marginBottom: "40px",
    "& .mask": {
      width: "120px",
      height: "40px",
      borderRadius: "20px",
      background: "#ffffff",
      border: `1px solid ${theme.palette.primary.main}`,
      position: "absolute",
      transition: "all 0.5s ease",
    },
    "& .MuiButton-root": {
      borderRadius: "20px",
      width: "120px",
      height: "40px",
      transition: "all 0.2s 0.1s ease",
      textTransform: "none",
    },
    "& .MuiButton-root:hover": {
      opacity: "0.8",
    },
  })
);
