import {Button, Grid, Link, SvgIcon} from "@mui/material";
import {ReactComponent as UploadErrorIcon} from "../../../img/upload-error.svg";
import {CenteredTextGrid, renderCsvValidationErrorRows, UploadCsvErrorGrid} from "../common";
import {UploadCsvResponse} from "../../../models/responses/UploadCsvResponse";
import {GenericJsonObject} from "../../../models/responses/Cases/CaseResponse";

type ErrorScreenProps = {
    toggleGuidelineDrawer : (open: boolean) => () => void;
    error: UploadCsvResponse | GenericJsonObject;
    handleCloseError : () => void;
}

const ErrorScreen = ({toggleGuidelineDrawer, error, handleCloseError} : ErrorScreenProps) => {
    const validationErrors = error.issues && (error as UploadCsvResponse).issues;
    const httpError = !error.issues && (error as GenericJsonObject);

    return <Grid container item alignContent={"flex-start"}>
        <Grid container item xs={12} justifyContent={"center"}>
            <SvgIcon style={{fontSize: 88, marginTop: 80, marginBottom: 20,}}><UploadErrorIcon/></SvgIcon>
        </Grid>
        <CenteredTextGrid sx={{fontSize: 26, fontWeight: 500, color: "#333"}}>
            File upload failed
        </CenteredTextGrid>
        <CenteredTextGrid sx={{fontSize: 16, fontWeight: 400, color: "#1A1A1A"}}>
            We found the following issue(s) with your CSV file:
        </CenteredTextGrid>
        <Grid container item xs={12} justifyContent={"center"}>
            <Grid container item sx={{width: 464, border:"1px solid #D7D7D7", borderRadius: "16px", padding: 2, margin: 3}} spacing={1}>
                {
                    httpError && <UploadCsvErrorGrid key={"http-error"} message={`${httpError.message}`} />
                }
                {
                    validationErrors && validationErrors.headerIssues
                        .map((header)=><UploadCsvErrorGrid
                            key={`header-error-${header}`}
                            message={`The ‘${header}’ is missing in the ‘Header’ of the CSV.`}
                        />)
                }
                {
                    validationErrors && validationErrors.validationIssues
                        .map((issue)=><UploadCsvErrorGrid
                            key={`validation-error-${issue.field}`}
                            message={`The ‘${issue.field}’ is missing or invalid in ${renderCsvValidationErrorRows(issue.rows)}. `}
                        />)
                }
                {
                    validationErrors && validationErrors.otherIssues.map((issue) => <UploadCsvErrorGrid
                        key={`other-error-${issue.field}`}
                        message={`The ‘${issue.field}’ issue is in ${issue.rows.length} case(s).`}
                    />)
                }
            </Grid>
        </Grid>

        <Grid container item xs={12} justifyContent={"center"}>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={handleCloseError}
            > Try again </Button>
        </Grid>

        <CenteredTextGrid sx={{fontSize: 14, fontWeight: 400, color: "#666", marginTop: 3}}>
            {`Need help? Check our submission guidelines `}
            <Link
                href={undefined}
                sx={{cursor: "pointer"}}
                underline={"hover"}
                color={"#2253FF"}
                onClick={toggleGuidelineDrawer(true)}
            >here</Link>
        </CenteredTextGrid>
    </Grid>
}

export default ErrorScreen;
