import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useAxiosParams } from "../common/useAxiosParams";
import { Brand } from "../../models/responses/Brand";
import AuthenticationContext from "../../context/AuthenticationContext";
import JourneyContext from "../../context/JourneyContext";

export const useBrand = () => {
  const { basePath, config } = useAxiosParams();
  const { defaultBrandId } = useContext(AuthenticationContext);
  const { currentJourney } = useContext(JourneyContext);

  const brandId = currentJourney?.brandId || defaultBrandId;

  return useQuery<unknown, AxiosError, Brand>({
    queryKey: ["brand", brandId],
    queryFn: async () => {
      const response = await axios.get<Brand>(
        basePath + `/brands/${brandId}`,
        config
      );
      return response.data;
    },
    staleTime: 5 * 60 * 1000, // the brand available to a user wouldn't change on a high frequency, and shouldn't require frequent re-loading
  });
};
