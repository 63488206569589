import {useContext, useState} from "react";
import { InputAdornment, MenuItem } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { CaseFinderInput, CaseFinderDateInput, SearchButton } from "./styles";
import { Link } from "react-router-dom";
import {CaseListFilterContext} from "../../../context/CaseListFilters";
import JourneyContext from "../../../context/JourneyContext";

type ProgressFilterType = "ALL_STATUSES" | "IN_PROGRESS" | "COMPLETE";

const CaseFinderWidget = () => {
  const [nameFilter, setNameFilter] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<string>("");
  const [sectionFilter, setSectionFilter] = useState<string[]>([]);
  const [progressFilter, setProgressFilter] = useState<ProgressFilterType>("ALL_STATUSES");

  const {
      setFilterStatuses,
      setFilterDate,
      setFilterText,
      setDateType
  } = useContext(CaseListFilterContext);

  const { currentJourney } = useContext(JourneyContext);
  const isI9 = currentJourney?.journeyType.startsWith("I9");

  const handleSearch = () => {
      setDateType(isI9 ? "dueDate" : "startDate");
      setFilterText(nameFilter);
      setFilterDate(dateFilter);
      if (sectionFilter[0].startsWith("Section_1")) {
          if (progressFilter === "IN_PROGRESS") {
              setFilterStatuses(["Section_1_In_Progress"])
          } else if (progressFilter === "COMPLETE") {
              setFilterStatuses(["Section_1_Complete"])
          } else {
              setFilterStatuses(["Section_1_In_Progress", "Section_1_Complete"])
          }
      } else if (sectionFilter[0].startsWith("Section_2")) {
          if (progressFilter === "IN_PROGRESS") {
              setFilterStatuses(["Section_2_In_Progress"])
          } else if (progressFilter === "COMPLETE") {
              setFilterStatuses(["Section_2_Complete"])
          } else {
              setFilterStatuses(["Section_2_In_Progress", "Section_2_Complete"])
          }
      } else if (sectionFilter[0].startsWith("Section_3")) {
          if (progressFilter === "IN_PROGRESS") {
              setFilterStatuses(["Section_3_In_Progress"])
          } else if (progressFilter === "COMPLETE") {
              setFilterStatuses(["Section_3_Complete"])
          } else {
              setFilterStatuses(["Section_3_In_Progress", "Section_3_Complete"])
          }
      } else {
        setFilterStatuses(sectionFilter);
      }
  }

  return (
    <>
      <CaseFinderInput
        variant="outlined"
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          setNameFilter(event.target.value);
        }}
      />

      <CaseFinderDateInput
        format={isI9 ? undefined : "YYYY/MM/DD"}
        // eslint-disable-next-line
        onChange={(searchDate: any) =>
          setDateFilter(searchDate.toISOString())
        }
      />

      <CaseFinderInput
        variant="outlined"
        select
        value={sectionFilter}
        label="All Statuses"
        onChange={(event) => {
          // eslint-disable-next-line
          // @ts-ignore
          setSectionFilter(event.target.value);
        }}
        >
          {(currentJourney?.uiConfig ? currentJourney.uiConfig.summary : []).map(({ label, filter }, index) => (
            <MenuItem value={filter} key={index}>{label}</MenuItem>
          ))}
      </CaseFinderInput>

      {isI9 &&
        <>
          <CaseFinderInput
            variant="outlined"
            select
            value={progressFilter}
            onChange={(event) => {
              setProgressFilter(event.target.value as ProgressFilterType);
            }}
            >
            <MenuItem value="ALL_STATUSES">All Progress</MenuItem>
            <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
            <MenuItem value="COMPLETE">Completed</MenuItem>
          </CaseFinderInput>

        </>
      }

      <Link to="/cases" onClick={handleSearch}>
        <SearchButton>Search</SearchButton>
      </Link>
    </>
  );
};

export default CaseFinderWidget;
