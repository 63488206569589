import { Chip, ListItem, Stack } from "@mui/material";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SectionsDataListButton, SectionsDataStack } from "./styles";

import { useContext } from "react";
import AuthenticationContext from "../../../../context/AuthenticationContext";
import { useCaseList } from "../../../../hooks/Cases/useCaseList";
import {CaseListFilterContext} from "../../../../context/CaseListFilters";
import JourneyContext from "../../../../context/JourneyContext";

type SectionCorrectionListItemProps = {
  section: string;
  text: string;
};

const SectionCorrectionListItem = ({
  section,
  text,
}: SectionCorrectionListItemProps) => {
  const { defaultBrandId } = useContext(AuthenticationContext);
  const { currentJourney } = useContext(JourneyContext);
  const { correctionPending } = useCaseList(currentJourney?.brandId || defaultBrandId);

  const { setFilterStatuses, setDateType, setFilterDate, setFilterDateEnd, setFilterText } = useContext(
      CaseListFilterContext
  );

  const handleCaseLink = (filters: string[]) => () => {
    setFilterStatuses(filters);
    setDateType("dueDate");
    setFilterText("");
    setFilterDate("");
    setFilterDateEnd("");
  };

  return (
    <ListItem disablePadding dense sx={{ display: "block" }}>
      <Link
        to={`/cases`}
        onClick={handleCaseLink(["Section_1_Correction"])}
        style={{ color: "#000", width: "100%", textDecoration: "none" }}
      >
        <SectionsDataListButton>
          <SectionsDataStack direction="row" useFlexGap flexWrap="wrap">
            <p>{text}</p>
            <Stack direction="row" useFlexGap flexWrap="wrap">
              <Chip
                label={correctionPending()}
                variant="outlined"
                className={section}
              />
              <ArrowForwardIosOutlined fontSize="small" />
            </Stack>
          </SectionsDataStack>
        </SectionsDataListButton>
      </Link>
    </ListItem>
  );
};

export default SectionCorrectionListItem;
