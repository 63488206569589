import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import { CssBaseline } from "@mui/material";
import "./styles/global.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./fonts/TuesdayNight/TuesdayNight-Regular.otf";

import { FeatureFlagsProvider } from "./context/FeatureFlags";

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(
  <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FeatureFlagsProvider>
          <CssBaseline />
          <Router>
            <Routes />
          </Router>
        </FeatureFlagsProvider>
      </LocalizationProvider>
  </React.StrictMode>
);
