import {createRef} from "react";
import {HeaderType, useAxiosParams} from "../../hooks/common/useAxiosParams";

type AuthenticatedLinkProps = {
    url: string;
    headerType: HeaderType;
    // eslint-disable-next-line
    postBody?: any;
    fileName?: string;
    disabled?: boolean;
    onSuccess?: ()=>void;
    children: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
}

const AuthenticatedLink = ({url, postBody, headerType, fileName, disabled, onSuccess, children}: AuthenticatedLinkProps) => {
    const link = createRef<HTMLAnchorElement>();

    const {basePath, config} = useAxiosParams(headerType);

    const handleAction = async () => {
        if (disabled) return;
        if (!link.current) return;
        if (link.current.href) return;

        const result = await fetch(basePath + url, {
            // eslint-disable-next-line
            // @ts-ignore
            headers: {
                ...config.headers,
                "Content-Type":  postBody? "application/json" : undefined
            }, 
            method: postBody? "POST" : "GET",
            body: postBody? JSON.stringify(postBody) : undefined,
        });

        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        if (fileName) {
            link.current.download = fileName;
        }
        link.current.href = href
        link.current.click();
        onSuccess && onSuccess();
    }

    return (
        <>
            <a role='button' ref={link} onClick={handleAction} download>{children}</a>
        </>
    )
}

export default AuthenticatedLink;
