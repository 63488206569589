import React from 'react';

const Text = ({label, value}: {
    label: string | undefined,
    value: string | undefined,
}) => {
    return (
        <div style={{
            width: "100%",
            marginBottom: "10px"
        }}>
            <strong>{label}:</strong>
            <br/>
            <span>{value}</span>
        </div>
    );
}

export default Text;
