import {useContext, useState} from "react";
import { List } from "@mui/material";
import SectionDataListItem from "./SectionDataListItem";
import SectionCorrectionListItem from "./SectionCorrectionListItem";
import { SectionTabs, SectionTab } from "./styles";
import AuthenticationContext from "../../../context/AuthenticationContext";
import {useCaseList} from "../../../hooks/Cases/useCaseList";
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import {DAY_MILLISECONDS} from "../../../utils/DateUtils/dateUtils";
import JourneyContext from "../../../context/JourneyContext";

type SectionsDataWidgetProps = {
  section: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const SectionsDataWidget = ({ section }: SectionsDataWidgetProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleSectionTabs = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };

    const { defaultBrandId } = useContext(AuthenticationContext);
    const { currentJourney } = useContext(JourneyContext);
    const { dueDateSection, everifyCaseCount } = useCaseList(currentJourney?.brandId || defaultBrandId);

  if (section === "Section_1_In_Progress") {
      const dueInWeek = dueDateSection(7, section);
      const dueInMonth = dueDateSection(30, section) - dueInWeek;
      return (
          <List>
              <SectionDataListItem
                  section={section}
                  count={dueInWeek}
                  dueDateEnd={new Date(Date.now() + 7 * DAY_MILLISECONDS).toISOString()}
                  text="Due within 7 days"
                  icon
              />
              <SectionDataListItem
                  section={section}
                  count={dueInMonth}
                  dueDateStart={new Date(Date.now() + 8 * DAY_MILLISECONDS).toISOString()}
                  dueDateEnd={new Date(Date.now() + 30 * DAY_MILLISECONDS).toISOString()}
                  text="Due within 30 days"
                  icon={false}
              />
              <SectionCorrectionListItem
                  section={section}
                  text="Correction pending"
              />
          </List>
      );
  }


  if (section === "Section_2_In_Progress") {
      const dueToday = dueDateSection(0, section);
      const dueTomorrow = dueDateSection(1, section) - dueToday;
      const dueInThreeDays = dueDateSection(3, section) - dueTomorrow - dueToday;

      return (
          <List>
              <SectionDataListItem
                  section={section}
                  count={dueToday}
                  dueDateEnd={new Date().toISOString()}
                  text="Due today"
                  icon
              />
              <SectionDataListItem
                  section={section}
                  count={dueTomorrow}
                  dueDateStart={new Date(Date.now() + 1 * DAY_MILLISECONDS).toISOString()}
                  dueDateEnd={new Date(Date.now() + 1 * DAY_MILLISECONDS).toISOString()}
                  text="Due tomorrow"
                  icon={false}
              />
              <SectionDataListItem
                  section={section}
                  count={dueInThreeDays}
                  dueDateStart={new Date(Date.now() + 2 * DAY_MILLISECONDS).toISOString()}
                  dueDateEnd={new Date(Date.now() + 3 * DAY_MILLISECONDS).toISOString()}
                  text="Due within 3 days"
                  icon={false}
              />
          </List>
      );
  }

  if (section === "Section_3_In_Progress") {
      const dueInWeek = dueDateSection(7, section, "SECTION_3_INVITED");
      const dueInMonth = dueDateSection(30, section, "SECTION_3_INVITED") - dueInWeek;
      return (
          <>
              <SectionTabs value={activeTab} onChange={handleSectionTabs}>
                  <SectionTab label="Reverification" id="0"/>
                  {/* <SectionTab label="Rehires" id="1"/> */}
              </SectionTabs>
              <TabPanel value={activeTab} index={0}>
                  <List>
                      <SectionDataListItem
                          section={section}
                          count={dueInWeek}
                          dueDateEnd={new Date(Date.now() + 7 * DAY_MILLISECONDS).toISOString()}
                          text="Due within 7 days"
                          icon
                      />
                      <SectionDataListItem
                          section={section}
                          count={dueInMonth}
                          dueDateStart={new Date(Date.now() + 8 * DAY_MILLISECONDS).toISOString()}
                          dueDateEnd={new Date(Date.now() + 30 * DAY_MILLISECONDS).toISOString()}
                          text="Due within 30 days"
                          icon={false}
                      />
                  </List>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                  <List>
                      <SectionDataListItem
                          section={section}
                          count={0}
                          text="Due within 15 days"
                          icon
                      />
                      <SectionDataListItem
                          section={section}
                          count={0}
                          text="Due within 30 days"
                          icon={false}
                      />
                  </List>
              </TabPanel>
          </>
      );
  }

    if (section === "E_Verify") {
        const count = everifyCaseCount();

        return (
            <List>
                <SectionDataListItem
                    section={section}
                    count={count.finalNonConfirmation || 0}
                    text="Final Non-Confirmation"
                    icon={false}
                    beforeIcon={()=> <FlagIcon sx={{color: "#000", transform:"translateY(-5px)"}}/>}
                />
                <SectionDataListItem
                    section={section}
                    count={count.tentativeNonConfirmation || 0}
                    text="Tentative Non-Confirmation"
                    icon={false}
                    beforeIcon={()=> <FlagIcon sx={{color: "#aaa", transform:"translateY(-5px)"}}/>}
                />
                <SectionDataListItem
                    section={section}
                    count={count.employmentAuthorized || 0}
                    text="Employment Authorized"
                    icon={false}
                    beforeIcon={()=> <FlagOutlinedIcon sx={{color: "#000", transform:"translateY(-5px)"}}/>}
                />
            </List>
        );
    }

  return (
    <List>
        <SectionDataListItem
            section={section}
            count={0}
            dueDateEnd={new Date(Date.now() + 7 * DAY_MILLISECONDS).toISOString()}
            text="Due within 7 days"
            icon
        />
    </List>
  );
};

export default SectionsDataWidget;
