import {useCallback, useContext, useMemo, useState} from "react";
import { Grid } from "@mui/material";
import {
  CaseResponse,
  getRawDueDate,
} from "../../../models/responses/Cases/CaseResponse";
import { formatDate } from "../../../utils/DateUtils/dateUtils";
import { Location } from "../../../models/responses/Location";
import VirtualizedTable, {
  VirtualizedTableSort,
} from "../../../components/common/VirtualizedTable";
import CaseDetailsIceAuditorModal from "../CaseDetailsIceAuditorModal";
import CaseDetailsInternalAuditorModal from "../CaseDetailsInternalAuditorModal";
import { RowMouseEventHandlerParams } from "react-virtualized/dist/es/Table";
import JourneyContext from "../../../context/JourneyContext";

type AuditorCaseListProps = {
  cases: CaseResponse[];
  auditorType?: string;
};

const AuditorCaseList = ({ cases, auditorType }: AuditorCaseListProps) => {
  const [isIceModalOpen, setIceModalOpen] = useState<boolean>(false);
  const [isInternalModalOpen, setInternalModalOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const { currentJourney } = useContext(JourneyContext);

  const [sort, setSort] = useState<VirtualizedTableSort>({
    key: "createdAt",
    direction: "DESC",
  });

  const getLocationForId = (locationId: number): Location | null => {
    if (locationId === null || locationId === undefined) {
      return null;
    }
    return (
      (currentJourney?.locations || []).find(
        (location) => location.id === locationId
      ) || null
    );
  };

  const handleSort = useCallback(
    (header: string) => {
      if (header === sort.key) {
        setSort({
          key: header,
          direction: sort.direction === "ASC" ? "DESC" : "ASC",
        });
      } else {
        setSort({
          key: header,
          direction: "ASC",
        });
      }
    },
    [sort]
  );

  const casesSorted = useMemo(() => {
    const direction = sort.direction === "ASC" ? 1 : -1;

    if (sort.key === "dueDate") {
      return cases.sort(
        (a, b) => (getRawDueDate(a) > getRawDueDate(b) ? 1 : -1) * direction
      );
    }

    if (sort.key === "location") {
      return cases.sort(
        (a, b) => (a.locationId > b.locationId ? 1 : -1) * direction
      );
    }

    return cases.sort((a, b) => {
      // eslint-disable-next-line
      // @ts-ignore
      return (a[sort.key] > b[sort.key] ? 1 : -1) * direction;
    });
  }, [cases, sort]);

  const onRowClick = (info: RowMouseEventHandlerParams) => {
    setIndex(info.index);
    {
      auditorType === "internal_auditor"
        ? setInternalModalOpen(true)
        : setIceModalOpen(true);
    }
  };

  return (
    <Grid sx={{ minHeight: "500px", padding: 3 }}>
      <VirtualizedTable
        handleSort={handleSort}
        sorting={sort}
        rowHeight={34}
        rowCount={casesSorted.length}
        rowGetter={({ index }) => casesSorted[index]}
        onRowClick={onRowClick}
        columns={[
          {
            width: 300,
            label: "Candidate Ref",
            dataKey: "consumerReference",
          },
          {
            width: 280,
            label: "First Name",
            dataKey: "givenName",
          },
          {
            width: 280,
            label: "Last Name",
            dataKey: "familyName",
          },
          {
            width: 250,
            label: "Location",
            dataKey: "location",
            dataGetter: ({ rowData: caseItem }) =>
              getLocationForId(caseItem.locationId)?.name || caseItem.country,
          },
          {
            width: 250,
            label: "Start Date",
            dataKey: "startDate",
            dataGetter: ({ rowData: caseItem }) =>
              formatDate(caseItem.startDate || ""),
          },
        ]}
      />
      <CaseDetailsIceAuditorModal
        open={isIceModalOpen}
        setOpen={setIceModalOpen}
        index={index}
        setIndex={setIndex}
        getCase={(index) => casesSorted[index]}
        casesCount={casesSorted.length}
      />
      <CaseDetailsInternalAuditorModal
        open={isInternalModalOpen}
        setOpen={setInternalModalOpen}
        index={index}
        setIndex={setIndex}
        getCase={(index) => casesSorted[index]}
        casesCount={casesSorted.length}
      />
    </Grid>
  );
};

export default AuditorCaseList;
