import {Chip} from "@mui/material";
import {useContext} from "react";
import JourneyContext from "../../../context/JourneyContext";

type CaseListStatusBadgeProps = {
    status: string;
    section2Invited?: boolean;
    isWorkAuthExpirable?: boolean
};

export type CaseStatusBadgeProps = {
    label: string;
    color: string;
};

export const getCaseStatusRender = (
    status: string,
    section2Invited?: boolean
): CaseStatusBadgeProps => {
    const {currentJourney} = useContext(JourneyContext);
    const defaultStatusStyle = {label: status, color: "#90ebbf4d"};
    const statusList = currentJourney?.uiConfig.statusBadge;
    return statusList?
        statusList[status === "SECTION_1_COMPLETE" && section2Invited ? "SECTION_2_INVITED" : status] || defaultStatusStyle
        : defaultStatusStyle;
}

const CaseListStatusBadge = ({status, section2Invited, isWorkAuthExpirable}: CaseListStatusBadgeProps) => {
    const {label, color} = getCaseStatusRender(status, section2Invited);
    const caseStatusLabel = (status === 'SECTION_2_COMPLETE' && isWorkAuthExpirable) ? 'Section 2: Complete' : label;
    return (
        <Chip
            sx={{
                padding: "2px 0px",
                fontSize: "10px",
                height: "auto",
                background: color,
                border: "1px solid rgba(0,0,0,0.1)",
            }}
            label={caseStatusLabel}
            color="secondary"
        />
    );
};

export default CaseListStatusBadge;
