import {Section2CorrectionRequest} from "../../../models/requests/Section2CorrectionRequest";
import {useState} from "react";

export const useSection2CorrectionForm = (defaultValues: Section2CorrectionRequest) => {

    const [data, setData] = useState<Section2CorrectionRequest>(defaultValues);

    const resetFormData = (data: Section2CorrectionRequest) => setData(data);

    const handleChange = (key: string) => (value: string) => {
        setData({
            ...data,
            [key]: value,
        });
    }

    const handleDocumentChange = (index: number, key: string) => (value: string) => {
        setData({
            ...data,
            documents: data.documents.map((doc, i) => {
                if (i === index) {
                    return {
                        ...doc,
                        [key]: value,
                    }
                } else {
                    return doc;
                }
            })
        })
    }

    return {
        data,
        resetFormData,
        handleChange,
        handleDocumentChange,
    }
}
