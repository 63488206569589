import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { GenericJsonObject } from "../../../models/responses/Cases/CaseResponse";
import PdfForm from "../../PdfForm";

const ImportedFormPanel: FC<{
  data: GenericJsonObject;
}> = ({data}) => {

  const [formI9PdfFile, setFormI9PdfFile] = useState<Blob | undefined>(undefined);

  useEffect(() => {
    if (data?.importedUrl) {
      fetch(data?.importedUrl as string, {
        headers: { Accept: "application/pdf" },
      })
        .then((response) => {
          if (response.ok) {
            return response.arrayBuffer();
          }
        })
        .then((arrayBuffer) => {
          if (arrayBuffer) {
            setFormI9PdfFile(new Blob([arrayBuffer], { type: "application/pdf" }));
          }
        });
    } else {
      setFormI9PdfFile(undefined);
    }
  }, [data]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PdfForm pdfFile={formI9PdfFile} height={"100vh"}/>
        </Grid>
      </Grid>
    </>
  )
}

export default ImportedFormPanel;