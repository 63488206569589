declare global {
    interface Window {
        __ENV__: {[key: string] : string};
    }
}

const ConfigService = {
    getConfigValue : (key: string) => {
        if (window && window.__ENV__ && window.__ENV__[key] !== undefined) {
            return window.__ENV__[key]
        } else {
            console.warn(`ERROR: could not find value for key ${key}`);
            throw "env.js not properly configured"
        }
    }
}

export default ConfigService;
