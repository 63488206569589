import {useNavigate, useParams} from "react-router-dom";
import {useCaseDetails} from "../../hooks/Cases/useCaseDetails";
import {
    AppBar,
    Button,
    Checkbox,
    FormHelperText,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DocumentImage from "../../components/DocumentImage";
import React, {useRef, useState} from "react";
import {
    getDisplayDocumentTitleAboveImage,
    getDocumentImageMap
} from "../../utils/CaseUtils/documentsDisplayUtil";
import {DetailsBlock, DetailsFields} from "../../components/CaseDetailsForm/common";
import {PrimaryButton} from "../CreateCase/CreateCaseForm/styles";
import {ReactComponent as NoButtonIcon} from "../../img/no-button.svg";
import {ReactComponent as YesButtonIcon} from "../../img/yes-button.svg";
import html2canvas from "html2canvas";
import NotificationModal from "../../components/NotificationModal";
import {ReactComponent as ConfirmationIcon} from "../../img/confirmation-icon.svg";
import {useQueryClient} from "@tanstack/react-query";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import {ConsentCheckbox, PrePopulateDataBlock} from "./styles";
import {
    sectionThreeDataSchema,
    sectionThreeRehireDataSchema,
    sectionThreeRenameDataSchema
} from "./sectionThreeDataSchema";
import {useCompleteSupplementB} from "../../hooks/Cases/useMutateCase";

const CompleteSectionThree = ({
                                  onExit,
                              }: {
    onExit: () => void;
}) => {
    const {caseId}: { caseId: string } = useParams();
    const {data} = useCaseDetails(caseId);
    const cache = useQueryClient();
    const [submitSuccessModalOpen, setSubmitSuccessModalOpen] = useState<boolean>(false);

    const  isRename = data?.status === "SECTION_3_RENAME_SUBMITTED";
    const  isRehire = data?.status === "SECTION_3_REHIRE_SUBMITTED";

    const navigate = useNavigate();
    const onSubmitSuccess = () => {
        setSubmitSuccessModalOpen(true);
        setTimeout(function () {
            setSubmitSuccessModalOpen(false);
            cache.invalidateQueries(["cases-details", caseId])
                .then(() => {
                    navigate(`/cases/${caseId}`);
                });
        }, 1000);
    }
    const onSubmitError = () => {
        toast.error("Could not complete Supplement B");
    }
    const {mutate, isLoading} = useCompleteSupplementB(caseId, onSubmitSuccess, onSubmitError);
    const [isPrePopulateDataVisible, setPrePopulateDataVisible] = useState(true);
    const signatureRef = useRef(null);

    // eslint-disable-next-line
    const sectionOneData: any = data?.additionalData.Section_1 ?
        JSON.parse(JSON.stringify(data.additionalData?.Section_1[data.additionalData.Section_1.length - 1])) : undefined;

    // eslint-disable-next-line
    const standaloneTransactionsSectionThree: any =
        // eslint-disable-next-line
        (data?.additionalData?.standaloneTransactions as any)
            ?.Section_3_Documents || [];
    const submittedDocuments = data?.additionalData?.Section_3_Documents ?
        data?.additionalData?.Section_3_Documents[data?.additionalData?.Section_3_Documents.length - 1] : undefined;
    // eslint-disable-next-line
    const documents: { [key: string]: string }[] | undefined = submittedDocuments?.documents;

    const initialDocs: {
        document_title: string;
        document_number: string;
        issuing_authority: string;
        date_of_expiry?: Date | null,
        document_type: string,
        list_type: string
    }[] | undefined = documents?.map(doc => {
            return {
                document_title: '',
                document_number: '',
                issuing_authority: '',
                date_of_expiry: undefined,
                document_type: doc.document_type,
                list_type: doc.list_type
            }
        }
    );

    const getYupResolver = () => {
        if (isRename) {
            return yupResolver(sectionThreeRenameDataSchema);
        } else if (isRehire) {
            return yupResolver(sectionThreeRehireDataSchema);
        } else {
            return yupResolver(sectionThreeDataSchema);
        }
    }

    const getDefaultValues = () => {
        if (isRename) {
            return {
                employee_last_name: "",
                employee_first_name: "",
                employee_middle_initial: "",
                employer_full_name: "",
                signature: {
                    image: ""
                },
                documents: initialDocs,
                consent: undefined
            };
        } else if (isRehire) {
            return  {
                rehire_date: data?.additionalData.rehireDate,
                employer_full_name: "",
                signature: {
                    image: ""
                },
                documents: initialDocs,
                consent: undefined
            };
        } else {
            return {
                employer_full_name: "",
                signature: {
                    image: ""
                },
                documents: initialDocs,
                consent: undefined
            };
        }
    }

    // eslint-disable-next-line
    const {handleSubmit, control, setValue} = useForm<any, any>({
            resolver: getYupResolver(),
            mode: 'all',
            defaultValues: getDefaultValues()
        }
    );

    const sectionThreeDocumentImages = documents ? getDocumentImageMap(documents, standaloneTransactionsSectionThree) : new Map<string, string[]>();

    const initialSelectedImages = Array.from(sectionThreeDocumentImages?.keys()).map(docKey => sectionThreeDocumentImages?.get(docKey)?.[0]);
    const [selectedImages, setSelectedImages] = useState(initialSelectedImages);

    const generateBase64Image = () => {
        if (signatureRef && signatureRef.current) {
            return html2canvas(signatureRef.current, {scale: 3}).then((canvas) => {
                return canvas.toDataURL("image/jpeg").split(';base64,')[1];
            });
        }
    }

    // eslint-disable-next-line
    const onSubmit = async (data: any) => {
        const imageBase64 = await generateBase64Image();
        const documents = data.documents?.map((doc:  {
                document_title: string;
                document_number: string;
                issuing_authority: string;
                date_of_expiry?: Date | null;
                list_type: string,
                document_type: string
            }) => {
                if (doc.date_of_expiry) {
                    return {
                        ...doc,
                        date_of_expiry: new Date(doc.date_of_expiry).toLocaleDateString("sv-SE") + "T00:00:00.000Z"
                    }
                } else {
                    return {
                        ...doc,
                        date_of_expiry: "N/A"
                    }
                }
            }
        );
        const reverificationData = {
            employee: {
                last_name: data.employee_last_name,
                first_name: data.employee_first_name,
                middle_initial: data.employee_middle_initial
            },
            reviewer: {
                full_name: data.employer_full_name,
                signature: {
                    image: imageBase64 ? imageBase64 : data.signature.image,
                    date: new Date().toLocaleDateString("sv-SE") + "T00:00:00.000Z"
                }
            },
            documents: documents ? documents : []
        };
        const submittedData = data.rehire_date ?
            {
                reverification: {
                    ...reverificationData,
                    rehire_date: new Date(data.rehire_date).toLocaleDateString("sv-SE") + "T00:00:00.000Z"
                }
            }
            : {reverification: reverificationData};

        mutate(submittedData);
    }

    return (
        <>
            {isPrePopulateDataVisible &&
                <PrePopulateDataBlock>
                    <Grid container item xs={12} style={{flexGrow: 1}}>
                        <Grid item xs={10}>
                            <Typography sx={{fontSize: "1.1rem !important", fontWeight: "bold"}}>
                                Would you like system gathered ID data to pre-populate Document Details?
                            </Typography>
                            <Typography sx={{fontSize: "0.8rem !important"}}>
                                It’s important that you check each field for accuracy before submitting the Form
                                I-9
                            </Typography>
                        </Grid>
                        <Grid container item xs={2}
                              alignItems={"center"}
                              justifyContent={"flex-end"}>
                            <IconButton onClick={() => setPrePopulateDataVisible(false)}>
                                <NoButtonIcon/>
                            </IconButton>
                            <IconButton onClick={() => {
                                documents?.forEach((d, index) => {
                                    setValue(`documents.${index}.document_title`, d.document_title, {shouldValidate: true});
                                    setValue(`documents.${index}.document_number`, d.document_number, {shouldValidate: true});
                                    setValue(`documents.${index}.issuing_authority`, d.issuing_authority, {shouldValidate: true});
                                    setValue(`documents.${index}.date_of_expiry`,
                                        (!d.date_of_expiry || ["", "n/a", "N/A"].includes(d.date_of_expiry)) ? null : new Date(d.date_of_expiry),
                                        {shouldValidate: true});
                                    setValue(`documents.${index}.document_type`, d.document_type, {shouldValidate: true});
                                    setValue(`documents.${index}.list_type`, d.list_type, {shouldValidate: true});
                                });
                                setPrePopulateDataVisible(false);
                            }
                            }>
                                <YesButtonIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </PrePopulateDataBlock>

            }
            <Grid container paddingRight="10px" paddingLeft="10px">
                <DetailsBlock xs={10} title={"Section 1 Details"} borderBottom>
                    <DetailsFields
                        keyPrefix={`section1-details-`}
                        items={[
                            {
                                label: "Last name (family name)",
                                value: sectionOneData?.employee?.last_name
                            },
                            {
                                label: "First name (given name)",
                                value: sectionOneData?.employee?.first_name
                            },
                            {
                                label: "Middle initial (if any)",
                                value: sectionOneData?.employee?.middle_initial?sectionOneData?.employee?.middle_initial : "N/A"
                            }
                        ]}
                    />
                </DetailsBlock>
                <DetailsBlock xs={10} title={"Employee Details (if applicable)"} borderBottom>
                    <DetailsFields
                        control={control}
                        keyPrefix={"employee-"}
                        items={[
                            {
                                label: "Date of Rehire (if applicable)",
                                field: "rehire_date",
                                editableProps: {type: "date"},
                                required: isRehire
                            },
                            {
                                label: "Last name (family name)",
                                field: "employee_last_name",
                                editableProps: {type: "text"},
                                required: isRename
                            },
                            {
                                label: "First name (given name)",
                                field: "employee_first_name",
                                editableProps: {type: "text"},
                                required: isRename
                            },
                            {
                                label: "Middle initial (if any)",
                                field: "employee_middle_initial",
                                editableProps: {type: "text"},
                                required: isRename
                            }
                        ]}
                    />
                </DetailsBlock>
                <DetailsBlock xs={12} title={"Employee Document Details"}>
                    {Array.from(sectionThreeDocumentImages.entries()).map(
                        (entry, docIndex) => {
                            const [documentType, images] = entry;
                            return (<Grid container item xs={10} key={docIndex} sx={{marginTop: "15px"}}>
                                <Grid item xs={6}>
                                    <Typography>{getDisplayDocumentTitleAboveImage(documents, documentType)}</Typography>
                                    <Stack direction="row" spacing={2}>
                                        {images.map(
                                            (imageUrl: string, index: number) => (
                                                <img
                                                    src={imageUrl}
                                                    key={index}
                                                    aria-label={documentType}
                                                    style={{
                                                        display: "block",
                                                        width: "100px",
                                                        cursor: "pointer",
                                                        borderRadius: "4px",
                                                        padding: "4px",
                                                        border:
                                                            selectedImages[docIndex] === imageUrl ? "2px solid #1CDBBC" : "none",
                                                    }}
                                                    onClick={() => {
                                                        const newSelectedImages = [...selectedImages];
                                                        newSelectedImages [docIndex] = imageUrl;
                                                        setSelectedImages(newSelectedImages);
                                                    }}
                                                />
                                            )
                                        )}
                                    </Stack>
                                    <DocumentImage imageScr={selectedImages[docIndex] || images[0]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailsFields
                                        control={control}
                                        keyPrefix={`document-${docIndex}-`}
                                        compactView={true}
                                        items={[
                                            {
                                                label: "Document title",
                                                field: `documents.${docIndex}.document_title`,
                                                required: true,
                                                editableProps: {type: "text"},
                                            },
                                            {
                                                label: "Issuing authority",
                                                field: `documents.${docIndex}.issuing_authority`,
                                                required: true,
                                                editableProps: {type: "text"},
                                            },
                                            {
                                                label: "Document number",
                                                field: `documents.${docIndex}.document_number`,
                                                required: true,
                                                editableProps: {type: "text"},
                                            },
                                            {
                                                label: "Expiration date",
                                                field: `documents.${docIndex}.date_of_expiry`,
                                                editableProps: {type: "date"},
                                            }
                                        ]}
                                    />
                                </Grid>
                            </Grid>);
                        }
                    )}
                </DetailsBlock>
                <DetailsBlock xs={10} title={"Employer of Authorized Representative’s Details"} borderBottom>
                    <Typography>If you are completing Supplement B, it must be your name that is input here</Typography>
                    <DetailsFields
                        control={control}
                        keyPrefix={"rep-"}
                        items={[
                            {
                                label: "Name of Employer or Authorised Representative",
                                required: true,
                                field: "employer_full_name",
                                editableProps: {type: "text"}
                            }
                        ]}
                    />
                </DetailsBlock>
                <DetailsBlock xs={10} title={"Signature of Employer or Authorized Representative"}>
                    <Typography sx={{marginBottom: "10px"}}>
                        I attest, under penalty of perjury, that (1) I have examined the documentation presented
                        by the above-named employee, (2) the above-listed documentation appears to be genuine and to
                        relate to the employee named, and (3) to the best of my knowledge, the employee is authorized to
                        work in the United States.
                    </Typography>
                    <Controller
                        // eslint-disable-next-line
                        //@ts-ignore
                        name="signature.image"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field,
                                     fieldState: {error},
                                 }) => (
                            <>
                                <TextField
                                    {...field}
                                    sx={{width: "350px"}}
                                    size={"small"}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                                <Grid item xs={10}>
                                    <div
                                        aria-label="signature"
                                        style={{
                                            display: "inline-block",
                                            marginTop: "15px",
                                            borderRadius: "4px",
                                            border: "1px solid #D7D7D7",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div
                                            ref={signatureRef}
                                            style={{
                                                display: "inline-block",
                                                fontFamily: "TuesdayNight",
                                                fontSize: "52px",
                                                marginTop: "15px",
                                                width: "350px",
                                                height: "100px",
                                                textAlign: "center",
                                                lineHeight: "100px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden"
                                            }}
                                        > {field.value}
                                        </div>
                                    </div>
                                </Grid></>
                        )}
                    />
                    <Controller
                        // eslint-disable-next-line
                        //@ts-ignore
                        name="consent"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field,
                                     fieldState: {error},
                                 }) => (
                            <ConsentCheckbox
                                control={<><Checkbox {...field}/>{!!error &&
                                    <FormHelperText error>{error.message}</FormHelperText>}</>}
                                label="I agree to sign electronically and acknowledge and agree that your electronic signature is
                        legally equivalent to a handwritten signature and that it binds you to the same extent as a
                        handwritten signature would."/>
                        )}
                    />
                </DetailsBlock>
            </Grid>
            <AppBar
                position="fixed"
                sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    top: "auto",
                    bottom: 0,
                    background: "white",
                }}
            >
                <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                    <Grid item xs={8}></Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Button sx={{textDecoration: "underline", textTransform: "none"}}
                                onClick={() => onExit()}>
                            Exit, no decision
                        </Button>
                        <PrimaryButton
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading}
                        >
                            Submit
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </AppBar>
            <NotificationModal
                isOpen={submitSuccessModalOpen}
                text="Supplement B is now complete"
                icon={<ConfirmationIcon/>}
            />
        </>
    )
};

export default CompleteSectionThree;
