import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";
import AuditQueryForm from "./AuditQueryForm";
import { AuditQueryFormFields } from "./AuditQueryForm/AuditQueryFormFields";
import {
  PrimaryButton,
  SecondaryButton,
} from "../CreateCase/CreateCaseForm/styles";
import AuthenticationContext from "../../context/AuthenticationContext";
import { useCaseList } from "../../hooks/Cases/useCaseList";
import { CaseResponse } from "../../models/responses/Cases/CaseResponse";

import AuditCredentialForm from "./AuditCredentialForm";
import AuditCaseList from "./AuditCaseList";
import HeaderBar from "../../components/HeaderBar";
import { ResultText } from "./styles";
import JourneyContext from "../../context/JourneyContext";

export type AuditCriteriaProps = {
  auditReference: string;
  dateFrom: string;
  dateTo: string;
  locationIds: string[];
};

const AuditPage = () => {
  const { defaultBrandId, isAdminUser } = useContext(AuthenticationContext);
  const { currentJourney } = useContext(JourneyContext);
  const { auditCriteriaCases } = useCaseList(currentJourney?.brandId || defaultBrandId);

  const [auditCriteria, setAuditCriteria] = useState<AuditCriteriaProps>({
    auditReference: "",
    dateFrom: "",
    dateTo: "",
    locationIds: [],
  });

  const [isAuditCredentialFormOpen, setAuditCredentialFormOpen] =
    useState(false);
  const [auditCaseList, setAuditCaseList] = useState<CaseResponse[]>([]);

  const { auditType } = useParams();

  useEffect(() => {
    const auditCaseList = auditCriteriaCases(auditCriteria);
    setAuditCaseList(auditCaseList);
  }, [auditCriteria]);
  

  return (
    <HeaderBar
      breadcrumbs={[
        {
          label: `${auditType === "internal" ? "Internal" : "ICE"} Audit`,
        },
      ]}
    >
      {
        !isAdminUser ? 
        <Typography color={"error"} padding={1}>You are not authorized to access this page.</Typography>
        :
        <div style={{ position: "relative", height: "100%" }}>
        <Grid sx={{ flexGrow: 1, p: 3 }}>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <AuditQueryForm
                fields={AuditQueryFormFields}
                setAuditCriteria={(e) => setAuditCriteria(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ margin: "20px 0px" }} />
            </Grid>
            <Grid item xs={12}>
              <AuditCaseList cases={auditCaseList} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ flexGrow: 1, padding: 20 }}
          sx={{
            borderTop: 1,
            borderColor: "divider",
            top: "auto",
            bottom: 0,
            background: "white",
            width: "fill-available",
            position: "fixed",
          }}
        >
          <Grid item xs={8}>
            <ResultText>
              <span>Results:</span>
              {auditCaseList.length} Case{auditCaseList.length > 1 ? "s" : ""}
            </ResultText>
          </Grid>
          <Grid
            container
            item
            xs={4}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <SecondaryButton variant="contained" href="/cases">
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={!auditCriteria.auditReference || !auditCriteria.dateFrom || !auditCriteria.dateTo || auditCriteria.locationIds.length === 0}
              onClick={() =>
                setAuditCredentialFormOpen(!isAuditCredentialFormOpen)
              }
            >
              Enter Auditor Contact Details
            </PrimaryButton>
          </Grid>
        </Grid>
        <AuditCredentialForm
          auditType={auditType}
          auditCriteria={auditCriteria}
          auditCasesLength={auditCaseList.length}
          isOpen={isAuditCredentialFormOpen}
          onClose={() => setAuditCredentialFormOpen(!isAuditCredentialFormOpen)}
        />
      </div>
      }

    </HeaderBar>
  );
};

export default AuditPage;
