import {Grid, Typography} from "@mui/material";
import {ReactComponent as GMeetIcon} from "../../../img/GMeetIcon.svg";
import {ReactComponent as ZoomIcon} from "../../../img/ZoomIcon.svg";
import {ReactComponent as MTeamsIcon} from "../../../img/MTeamsIcon.svg";
import {useOAuthStatus} from "../../../hooks/Meetings/useOAuthStatus";
import {JoinCallButton} from './styles'
import { useContext } from "react";
import JourneyContext from "../../../context/JourneyContext";

type VideoCallDataProps = {
    meetingUrl: string | undefined;
};

const VideoCallData = ({meetingUrl}: VideoCallDataProps) => {

    const { currentJourney } = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");

    const {data : oauthStatus} = useOAuthStatus();

    const getProviderDisplayIcon = (provider?: "GOOGLE" | "MICROSOFT" | "ZOOM") => {
        switch (provider) {
            case "GOOGLE": return <GMeetIcon/>;
            case "MICROSOFT": return <MTeamsIcon/>;
            case "ZOOM": return <ZoomIcon/>;
        }
        return "";
    }

    const openInNewTab = (url: string | undefined) => {
        if (url) {
            window.open(url, '_blank', 'noreferrer');
        }
    };

    return (
        <Grid
            item
            xs={12}
            sx={{
                padding: 2,
                borderBottom: 1,
                borderColor: "divider",
                display: "block",
            }}
        >
            <Typography sx={{fontSize: "16px", fontWeight: "bold"}}>
                {isI9 ? 'Alternative Procedure – remote examination of employee’s documentation' : 'Remote Verification - First Day Check'}
            </Typography>
            <p>
                Please be sure to review the instructions by clicking on the tab below before joining the meeting.<br/>
                <strong>Note:</strong> Be aware that repeated clicking of &quot;Join Meeting&quot; may open multiple browser windows.
            </p>
            {meetingUrl && <JoinCallButton variant="outlined" onClick={() => openInNewTab(meetingUrl)}>{getProviderDisplayIcon(oauthStatus?.provider)} Join Call</JoinCallButton>}
        </Grid>
    );
};

export default VideoCallData;
