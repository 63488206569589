import {useAxiosParams} from "../common/useAxiosParams";
import {useQuery} from "@tanstack/react-query";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import axios, {AxiosError} from "axios";
import {OAuthStatus} from "../../models/responses/Meetings/OAuthStatus";

export const useOAuthStatus = () => {
    const {orchestratorPath, config} = useAxiosParams()

    return useQuery<unknown, GenericJsonObject, OAuthStatus>({
        queryKey: ["oauth-status/me"],
        queryFn: async () => {
            try {
                const response = await axios.get<OAuthStatus>(orchestratorPath + `/meetings/status`, config);
                return response.data;
            }
            catch (error) {
                const axiosError = error as AxiosError;
                throw axiosError.response?.data || {"message": axiosError.message};
            }
        },
        cacheTime: 15 * 60 * 1000,
        staleTime: 15 * 60 * 1000,
    })
}
