import { styled, CSSObject } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";

export const FaqItem = styled(Accordion)(
  ({ theme }): CSSObject => ({
    marginBottom: "20px",
    borderRadius: "10px !important",
    border: "1px solid #D7D7D7",
    transition: "all ease 0.2",
    "&:before": {
      display: "none",
    },
    ".Mui-expanded": {
      background: theme.palette.secondary.light,
    },
  })
);

export const FaqQuestion = styled(AccordionSummary)(() => ({
  ".MuiAccordionSummary-content.Mui-expanded": {
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "20px",
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    background: "#1cdbbc80",
    borderRadius: "20px",
  },
}));

export const FaqAnswer = styled(AccordionDetails)(
  ({ theme }): CSSObject => ({
    background: theme.palette.secondary.light,
  })
);

export const MoreQuestionsBox = styled(Grid)(
  ({ theme }): CSSObject => ({
    padding: "30px",
    borderRadius: "10px",
    background: theme.palette.secondary.light,
    "p,h3": {
      margin: 0,
    },
    a: {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      textTransform: "none",
      borderRadius: "10px",
      padding: "10px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
  })
);
