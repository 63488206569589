import {useState, useContext} from "react";
import {AdditionalData, GenericJsonObject} from "../../../models/responses/Cases/CaseResponse";
import {
  AppBar,
  Chip,
  Grid, Link, Paper,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { ReactComponent as AccountCircleOutlinedIcon } from "../../../img/profile-icon.svg";
import { ReactComponent as AlarmIcon } from "../../../img/alarm.svg";
import { DetailsBlock } from "../common";
import {formatDate, formatNonUSDate, formatNonUSDateTime} from "../../../utils/DateUtils/dateUtils";
import {
  CaseProfileBox,
  CaseDetailsBox,
  StartEndDateField,
  VerifyDocumentsButton, ScheduleAppointmentButton, ConsumerReferenceTextField,
} from "./styles";
import {
  CaseTabContext, CaseTabType,
} from "../../../pages/CaseDetails/useCaseControl.hook";
import dayjs from "dayjs";
import {
  useConfirmationModal,
  ConfirmationModelContainer,
} from "../../ConfirmationModal";
import {
  useSubmitCaseEvent, useUpdateCaseConsumerReference,
  useUpdateCaseEndDate,
  useUpdateCaseStartDate,
} from "../../../hooks/Cases/useMutateCase";

import { Location } from "../../../models/responses/Location";
import AuthenticationContext from "../../../context/AuthenticationContext";
import JourneyContext from "../../../context/JourneyContext";
import {CSSObject, styled} from "@mui/material/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ColorUtils} from "../../../utils/ColorUtils";
import {CaseSpecifications} from "../../../models/responses/Cases/CaseSpecifications/CaseSpecifications";
import {DashboardTileProps} from "../../../models/responses/JourneyInfo";
import { TemplatingUtils } from "../../../utils/TemplatingUtils";
import ScheduleRtwAppointmentModal from "../ScheduleRtwAppointmentModal";
import {useOAuthStatus} from "../../../hooks/Meetings/useOAuthStatus";
import {Meeting} from "../../../models/responses/Cases/Meeting";
import {PrimaryButton, SecondaryButton} from "../../../pages/CreateCase/CreateCaseForm/styles";

// eslint-disable-next-line
export const BasicDetailsGenericPanel: FC<{
  data: GenericJsonObject;
  context?: CaseTabContext;
  handleSubmitSuccess?: (caseId: string) => void;
}> = ({ data, context }) => {
  const [isScheduleRtwAppointmentModalOpen, setScheduleRtwAppointmentModalOpen] = useState(false)

  const [isConsumerReferenceChanged, setConsumerReferenceChanged] = useState<boolean>(false);
  const [consumerReference, setConsumerReference] = useState<string>(data.consumerReference as string);

  const [startDate, setStartDate] = useState<unknown>(
    data.startDate && dayjs(`${data.startDate}`)
  );
  const [endDate, setEndDate] = useState(
    data.endDate && dayjs(`${data.endDate}`)
  );

  const { currentJourney } = useContext(JourneyContext);

  const getLocationForId = (locationId: string | string[] | number | GenericJsonObject | GenericJsonObject[] | undefined | boolean):Location | null => {
    if(locationId === null || locationId === undefined || locationId === '') {
      return null;
    }
    return (currentJourney?.locations || []).find(location => location.id === locationId) || null;
  }

  const modal = useConfirmationModal();
  const { mutate: updateCaseStartDate } = useUpdateCaseStartDate(`${data.uid}`);
  const { mutate: updateCaseEndDate } = useUpdateCaseEndDate(`${data.uid}`);
  const { mutate: updateCaseConsumerReference } = useUpdateCaseConsumerReference(
      { caseUid: `${data.uid}`,
        onSuccess: () => {
          toast.success("Saved");
        },
        onError: () => {
          toast.error("Could not update");
        }
      });
  const { auditRole, isAdminUser } = useContext(AuthenticationContext);

  const specs = data.specifications as CaseSpecifications;
  const interactiveEvents = specs.interactiveEvents?.find(evtGroup => evtGroup.entryState === data.status);

  const startFirstDayCheckAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "START_FIRST_DAY_CHECK");
  const scheduleAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "SCHEDULE_MEETING");
  const rescheduleAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "RESCHEDULE_MEETING");

  const approveAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "APPROVE");
  const confirmationScreen = approveAction?.screens.find(screen => screen.type === "confirmation");
  const successMessage = approveAction?.screens.find(screen => screen.type === "success")?.title || "Event submitted";
  const failMessage = approveAction?.screens.find(screen => screen.type === "fail")?.title || "Event submission failed";

  const {data : oauthStatus} = useOAuthStatus();
  const isCalendarAuthorised = !!oauthStatus?.authorised;

  const {mutate: submitCaseEvent} = useSubmitCaseEvent(
      `${data.uid}`,
      () => { toast.success(successMessage); },
      () => { toast.error(failMessage); },
      () => null,
  );

  const handleExpeditedVerify = () => {
    if (confirmationScreen) {
      let content = confirmationScreen.description;
      let checkList = undefined;
      if (content.includes("|")){
          const parts = content.split("|");
          content = parts[0];
          checkList = parts.slice(1);
      }

      modal.openConfirmationModal({
          message: confirmationScreen.title,
          content: content,
          checkList: checkList,
          confirmButtonLabel: "Confirm",
          onConfirm: () => {
              if (approveAction?.caseEventType) {
                submitCaseEvent(approveAction.caseEventType)
              }
          },
          onCancel: () => null,
      })
    } else {
        if (approveAction?.caseEventType) {
          submitCaseEvent(approveAction.caseEventType)
        }
    }
  }

  // eslint-disable-next-line
  const formatToString = (date: any) => {
    return  new Date(date).toLocaleDateString("sv-SE") + "T00:00:00.000Z";
  }

  // eslint-disable-next-line
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    modal.openConfirmationModal({
      message: "Are you sure you want to update the candidate’s start date?",
      confirmButtonLabel: "Apply",
      onConfirm: () => {
        updateCaseStartDate({
          date: formatToString(date),
        });
      },
      onCancel: () => {
        setStartDate(data.startDate && dayjs(`${data.startDate}`));
      },
    });
  };

  // eslint-disable-next-line
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    modal.openConfirmationModal({
      message: "Are you sure you want to update the candidate’s end date?",
      confirmButtonLabel: "Apply",
      onConfirm: () => {
        updateCaseEndDate({
          date: date && formatToString(date),
        });
      },
      onCancel: () => {
        setEndDate(data.endDate && dayjs(`${data.endDate}`));
      },
    });
  };

  const handleUpdateConsumerReference = () => {
    updateCaseConsumerReference({
      consumerReference: consumerReference,
    });
  }

  const handleUpdateConsumerReferenceExit = () => {
    setConsumerReference(data.consumerReference as string);
    setConsumerReferenceChanged(!isConsumerReferenceChanged)
  }

  const onChangeChangeConsumerReference = (ref: string) => {
    setConsumerReferenceChanged(consumerReference !== ref);
    setConsumerReference(ref);
  }

  const  statusBadgeColor  =  currentJourney?.uiConfig.statusBadge[data.status as string]?.color;
  const StatusExplanationBox = styled(Paper)(
      (): CSSObject => ({
        border: '2px solid ' + (statusBadgeColor? statusBadgeColor : '#81EAB2'),
        borderRadius: '14px',
        background: statusBadgeColor ?  ColorUtils.hexToHsl(statusBadgeColor, 93) : '#D7F9E8',
        padding: '20px 30px',
        '.MuiTypography-body1': {
          fontSize: '18px'
        },
        'p': {
          fontSize: '14px',
          lineHeight: '24px'
        }
      })
  )

  const StatusBadge = styled(Chip)(() => ({
        padding: "20px 10px",
        borderRadius: "80px",
        border: '2px solid ' + (statusBadgeColor? statusBadgeColor : '#81EAB2'),
        backgroundColor: statusBadgeColor? ColorUtils.hexToHsl(statusBadgeColor, 93) : '#D7F9E8',
        width: "90%",
        justifyContent: "normal",
        ".MuiChip-label": {
           marginLeft: "auto",
           marginRight: "auto",
        }
  }));

  const displayStatus = ((data.statusDisplayText || data.status) as string).replace("_", " ");
  const statusExplanationTitle = (data.status === "FIRST_DAY_CHECK_REQUIRED" || data.status === "MANUAL_FIRST_DAY_CHECK_REQUIRED") && !isCalendarAuthorised?
      specs?.statusDisplayValues?.["NO_CALENDAR_AUTH"] :
      ((data.statusExplanationTitle || data.status) as string).replace("_", " ");
  const additionalData = data.additionalData as AdditionalData;

  const tile: DashboardTileProps| undefined = currentJourney?.uiConfig.dashboard?.flatMap(item=>item.tiles)
      .find(tile=> tile.filter.find(filter => currentJourney?.uiConfig.filter[filter].statuses.includes(data.status as string)));
  const dueDate = tile && tile.type === "DUE_IN" && additionalData[tile.timestamp] as string;

  let statusDisplayText : string | null = null;
  const selectedDocuments = (data.selectedDocuments || []) as string[];
  const shareCode = data.shareCode as string | undefined;
  if (data.statusExplanationText) {
    statusDisplayText = (data.statusExplanationText || "") as string;
    statusDisplayText = TemplatingUtils.templateSelectedDocuments(statusDisplayText, selectedDocuments);
    statusDisplayText = TemplatingUtils.templateShareCodeUploadMessage(statusDisplayText, shareCode);
    statusDisplayText = TemplatingUtils.templateShareCodeCheckMessage(statusDisplayText, shareCode);
  }

  const formatMeetingDate = (date: Date | string) =>
      date === ""
          ? date
          : formatDate(date, {
            weekday: "long",
            day: "2-digit",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
          });

  // eslint-disable-next-line
  const isGeolocationValues = (obj: any) => {
    return Object.keys(obj).length != 0;
  };

  const isNewCase = () => {
    const dateString = '2024-12-01';
    const caseCreatedDate = data.createdAt as string;
    const caseCreatedDateString = caseCreatedDate && caseCreatedDate.length && new Date(caseCreatedDate).toLocaleString("en-CA").substring(0,10);
    return  caseCreatedDateString && dateString <= caseCreatedDateString;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatusExplanationBox>
            <Typography sx={{fontSize: "18px", fontWeight: "bold"}}>
              {statusExplanationTitle}
            </Typography>
            {
              statusDisplayText && data?.status !== "REJECTED" && <p dangerouslySetInnerHTML={{__html: statusDisplayText as string}}/>
            }
            {
              data?.status === "REJECTED" && data?.selectedReasons && <p>Rejection Reason: {data?.selectedReasons as string}</p>
            }
            {
              data?.status === "EXCEPTION_HANDLING_FOR_SHARECODE" && data?.shareCode &&  <p><b>Share Code: </b>{data?.shareCode as string}</p>
            }
            {
                (data?.status as string)?.includes("MEETING_SCHEDULED") && data.meeting  && <p style={{fontWeight: "bold"}}> {`${formatMeetingDate((data.meeting as Meeting).startTime)}`}  </p>
            }
            {
              ((data?.status as string)?.includes("FIRST_DAY_CHECK_REQUIRED") && !isCalendarAuthorised
                || !(data?.status as string)?.includes("FIRST_DAY_CHECK_REQUIRED"))
                && approveAction && (context?.hasNoDocument?
                <VerifyDocumentsButton onClick={handleExpeditedVerify}>{approveAction.buttonLabel}</VerifyDocumentsButton>
                : <VerifyDocumentsButton href={`/cases/${data.uid}/verify`}>{approveAction.buttonLabel}</VerifyDocumentsButton>)
            }
            {startFirstDayCheckAction && <VerifyDocumentsButton href={`/cases/${data.uid}/first-day-check`}>{startFirstDayCheckAction.buttonLabel}</VerifyDocumentsButton> }
            {
                isCalendarAuthorised && scheduleAction &&
                <ScheduleAppointmentButton
                    disabled={!isCalendarAuthorised}
                    onClick={() => setScheduleRtwAppointmentModalOpen(!isScheduleRtwAppointmentModalOpen)}>{scheduleAction.buttonLabel}</ScheduleAppointmentButton>
            }
            {
                isCalendarAuthorised && rescheduleAction &&
                <Link
                    component="button"
                    variant="body2"
                    underline="always"
                    color="primary"
                    style={{marginLeft: "20px"}}
                    onClick={() => setScheduleRtwAppointmentModalOpen(!isScheduleRtwAppointmentModalOpen)}>
                    {rescheduleAction.buttonLabel}
                </Link>
            }
            {
              data?.status === "EXCEPTION_HANDLING_FOR_SHARECODE" && <VerifyDocumentsButton
                onClick={()=>{
                  if (context?.selectTab) {
                    context.selectTab(CaseTabType.SHARE_CODE);
                  }
                }}
              >NEXT</VerifyDocumentsButton>
            }
          </StatusExplanationBox>
        </Grid>
        <Grid item xs={5}>
          <CaseProfileBox>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                      <StatusBadge
                          icon={dueDate? <AlarmIcon/> : <></>}
                          label={dueDate? <div><b>{displayStatus}:</b> Due {formatNonUSDate(dueDate)}</div> :
                              <div><b>{displayStatus}</b></div>}/>
              </Grid>
              <Grid item xs={12}>
                <AccountCircleOutlinedIcon
                  style={{ margin: "20px 0px 10px 0px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "bold" }}
                >{`${data.givenName} ${data.familyName}`}</Typography>
                {isAdminUser && isNewCase() ? <ConsumerReferenceTextField
                        onChange={(e) => onChangeChangeConsumerReference(e.target.value)}
                        size={"small"}
                        value={consumerReference}/>
                    : <Typography
                        sx={{fontSize: "20px", color: "#666666"}}
                    >{`${data.consumerReference}`}</Typography>
                }
              </Grid>
            </Grid>
          </CaseProfileBox>
        </Grid>
        <Grid item xs={7}>
          <CaseDetailsBox>
            <DetailsBlock xs={12} title={"Case Details"} borderBottom>
              <Grid item xs={12}>
                <p>
                  Created: <span>{formatNonUSDateTime(`${data.createdAt}`)}</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Created By: <span>{`${data.createdBy || ""}`}</span>
                </p>
              </Grid>
            </DetailsBlock>
            <DetailsBlock xs={12} title={"Employment Details"} borderBottom>
              <Grid container alignItems={"center"}>
                <Grid item xs={5}>
                  Start Date:
                </Grid>
                <Grid item xs={7}>
                  <StartEndDateField
                    format="YYYY/MM/DD"
                    value={startDate}
                    onAccept={handleStartDateChange}
                    disabled={!!auditRole}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"center"}>
                <Grid item xs={5}>
                  End Date:
                </Grid>
                <Grid item xs={7}>
                  <StartEndDateField
                    format="YYYY/MM/DD"
                    value={endDate}
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                        sx: {
                          background: "#00255440",
                        },
                      },
                    }}
                    onAccept={handleEndDateChange}
                    disabled={!!auditRole}
                  />
                </Grid>
              </Grid>
            </DetailsBlock>
            <DetailsBlock xs={12} title={"Contact Details"} borderBottom>
              <Grid item xs={12}>
                <p>
                  Email: <span>{`${data.emailAddress}`}</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Mobile: <span>{`${data.mobileNumber}`}</span>
                </p>
              </Grid>
            </DetailsBlock>
            <DetailsBlock xs={12} title={"Location Details"} borderBottom={additionalData?.geolocation ? isGeolocationValues(additionalData?.geolocation) : false}>
              <Grid item xs={12}>
                <p>
                  Country: <span>{`${data.country}`}</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Location: <span>{`${getLocationForId(data.locationId as number)?.name || ""}`}</span>
                </p>
              </Grid>
            </DetailsBlock>
            {additionalData && additionalData?.geolocation && isGeolocationValues(additionalData?.geolocation) && <DetailsBlock xs={12} title={"GeoLocation Details"}>
              <Grid item xs={12}>
                <p>
                  Latitude: <span>{`${additionalData?.geolocation?.latitude}`}</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Longitude: <span>{`${additionalData?.geolocation?.longitude}`}</span>
                </p>
              </Grid>
            </DetailsBlock>}
          </CaseDetailsBox>
        </Grid>
        <ConfirmationModelContainer {...modal} />
        <ScheduleRtwAppointmentModal isOpen={isScheduleRtwAppointmentModalOpen}
                                  onClose={() => setScheduleRtwAppointmentModalOpen(!isScheduleRtwAppointmentModalOpen)}
                                  caseId={data.uid as string}
                                  caseStatus={data.status as string}
                                  startDate={formatToString(startDate)}
                                  isReschedule={!!rescheduleAction}
        />
      </Grid>
      {isConsumerReferenceChanged && <AppBar
          position="fixed"
          sx={{
            borderTop: 1,
            borderColor: "divider",
            top: "auto",
            bottom: 0,
            background: "white",
          }}
      >
       <Grid container item xs={12} style={{ flexGrow: 1, padding: 20 }}>
          <Grid item xs={8}></Grid>
          <Grid
              container
              item
              xs={4}
              alignItems={"center"}
              justifyContent={"flex-end"}
          >
            <SecondaryButton variant="contained" onClick={handleUpdateConsumerReferenceExit}>
              Exit, no decision
            </SecondaryButton>
            <PrimaryButton
                onClick={handleUpdateConsumerReference}
                disabled={!isConsumerReferenceChanged}
            >
              Apply
            </PrimaryButton>
          </Grid>
        </Grid>
      </AppBar>}
    </>
  );
};
