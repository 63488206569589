import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Dialog, Grid, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Document, Page, pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfFormModal = ({pdfFile, open, setOpen}:
                         {
                            pdfFile: Blob | string | undefined,
                             open: boolean,
                             setOpen: Dispatch<SetStateAction<boolean>>
                         }) => {

    const closeButtonHandler = () => {
        setOpen(false);
    }

    const [pageCount, setPageCount] = useState(1);
    const [pdfWidth, setPdfWidth] = useState(0);

    const pdfContainerRef = useRef<HTMLDivElement>(null);
    const calculatePdfWidth = () => {
        const width = pdfContainerRef?.current?.clientWidth;
        return width ? width - 60 : 0
    }

    const updatePdfWidth = () => {
        setPdfWidth(calculatePdfWidth());
    }

    const resizeHandler = () => {
        updatePdfWidth();
    }

    const onPdfLoaded = ({ numPages }: { numPages? : number}) => {
        if (numPages && numPages !== pageCount) {
            setPageCount(numPages);
        }        
        resizeHandler();
    }

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    return (
        <Dialog maxWidth={'lg'}
                open={open}
                fullWidth>
            <div ref={pdfContainerRef}
                 style={{
                     padding: 30,
                     width: "100%",
                 }}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{
                        height: 0,
                        paddingRight: 15,
                        paddingBottom: 5
                    }}>
                        <IconButton
                            aria-label="close"
                            onClick={closeButtonHandler}
                            sx={{
                                height: 35,
                                width: 35,
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
                <Grid container>
                    {
                        pdfFile && <Grid item xs={24}>
                            <div style={{
                                padding: "16.5px 0px",
                                borderRadius: 6,
                                position: "relative",
                                border: "1px solid rgba(0, 0, 0, 0.25)",
                                marginTop: "10px",
                                width: "100%",
                            }}>
                                <div style={{
                                    width: "100%",
                                    height: "70vh",
                                    overflow: "scroll"
                                }}>
                                    <Document file={pdfFile}
                                              onLoadSuccess={onPdfLoaded}>
                                        {
                                            Array.from(Array(pageCount).keys()).map(idx => <Page 
                                                key={`page-${idx}`}
                                                pageNumber={idx + 1}
                                                width={pdfWidth}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                          />)
                                        }
                                    </Document>
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
        </Dialog>
    );
}

export default PdfFormModal;
