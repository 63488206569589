import { FC } from "react"
import { GenericJsonObject } from "../../../models/responses/Cases/CaseResponse"
import { Grid, Typography } from "@mui/material"
import { DetailsBlock, ImageBlock, ImageQuery } from "../common"
import { CaseDetailsBox } from "./styles"
import { StringUtils } from "../../../utils/StringUtils"

export const FaceImagesPanel: FC<{
    data: GenericJsonObject
}>
    = ({data}) => {
        try {
            return <Grid container p={0} spacing={2} alignItems={"flex-start"}>
                {
                    Object.entries(data).map(([key, val]) => {
                        const face = val as {image: string, imageQuery?: (()=>ImageQuery)};
                        return <Grid item xs={6} key={"face_" + key}>
                            <p>{StringUtils.keyToLabel(key)}</p>
                            <CaseDetailsBox>
                                <DetailsBlock xs={12}>
                                {
                                    face.imageQuery ? <ImageBlock
                                        useImageQuery={face.imageQuery}
                                        path={face.image}
                                        alt={"face image for " + key}
                                    /> : <></>
                                }
                                </DetailsBlock>
                            </CaseDetailsBox>
                        </Grid>
                    })
                }
            </Grid>
        } catch (e) {
            console.error(e)
            return <Typography color={"error"} padding={1}> Unexpected data received for face images </Typography>
        }
}