import { styled, CSSObject } from "@mui/material/styles";
import { DialogContent } from "@mui/material";

export const ReinviteModalContent = styled(DialogContent)(
  (): CSSObject => ({
    "p": {
      fontSize: "14px",
      "&.divider": {
        borderBottom: "1px solid #cecece",
        padding: "0 0 20px 0",
        marginBottom: "20px",
      }
    },
    "h2": {
      fontSize: '18px',
      fontWeight: 'bold'
    }
  })
)