import { styled, CSSObject } from "@mui/material/styles";
import { Tabs, Tab, TextField } from "@mui/material";

export const CompareField = styled(TextField)(() => ({
  borderRadius: "8px",
  borderBottom: 0,
  marginBottom: "14px",
  width: "100%",
  "& .MuiFilledInput-root": {
    background: "#fcfdfe",
    borderRadius: "8px",
    border: "1px solid #A4A4A4",
    "&.Mui-error": {
      borderColor: "#d32f2f",
      color: "#d32f2f"
    },
    "& .MuiInputBase-input": {
      padding: "20px 14px 0px 14px",
      height: "35px",
    },
    "&: before": {
      display: "none",
    },
  },
}));

export const CompareTabs = styled(Tabs)(() => ({
  minHeight: 0,
  marginBottom: "20px",
  "& .MuiTabs-scroller": {
    padding: "5px 0px",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

export const CompareTab = styled(Tab)(({theme}): CSSObject => ({
  opacity: 1,
  textTransform: "none",
  minHeight: 0,
  minWidth: 0,
  margin: "0px 5px",
  borderRadius: "100px",
  background: "#F4FDFC",
  padding: "8px",
  color: theme.palette.secondary.main,
  border: "1px solid #fff",
  boxShadow: "0px 0px 0px 3px #F4FDFC",
  marginRight: "20px",
  "&.Mui-selected": {
    background: theme.palette.secondary.main,
    boxShadow: `0px 0px 0px 3px ${theme.palette.secondary.main}`,
    color: "#fff",
  },
}));
