export type OAuthStatus = {
    user: string;
    provider?: "GOOGLE" | "MICROSOFT" | "ZOOM";
    authorised: boolean;
}

export const getProviderDisplayName = (provider?: "GOOGLE" | "MICROSOFT" | "ZOOM") => {
    switch (provider) {
        case "GOOGLE": return "Google Calendar";
        case "MICROSOFT": return "Microsoft Outlook";
        case "ZOOM": return "Zoom";
    }
    return "";
}
