import React, { useCallback, useContext, useState } from "react";
import JourneyContext from "../../../context/JourneyContext";
import { CircleFlag } from "react-circle-flags";
import { Box, Grid, Modal } from "@mui/material";
import { JourneyInfo } from "../../../models/responses/JourneyInfo";
import { useNavigate } from "react-router-dom";

type CircleFlagStyledProps = {
    journey? : JourneyInfo;
    color: string;
    onClick?: ()=>void;
}

const CircleFlagStyled = ({journey, color, ...props} : CircleFlagStyledProps) => {
    const countryCode = journey?.country.substring(0,2).toLowerCase() || "";

    return <CircleFlag countryCode={countryCode === "ir" ? 'ie' : countryCode} height="35" style={{border: `3px solid ${color}`, borderRadius: 18, padding: 2, cursor: "pointer"}} {...props}/>;
}

const CountrySelector = () => {
    const [open, setOpen] = useState<boolean>(false);
    const { currentJourney, journeys, selectJourney } = useContext(JourneyContext);
    const navigate = useNavigate();

    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const getFlagColor = useCallback((journey: JourneyInfo) => {
        return (journey === currentJourney)? "#1CDBBC" : "#fff";
    }, [currentJourney]);

    const getLabelColor = useCallback((journey: JourneyInfo) => {
        return (journey === currentJourney)? "#000" : "#777";
    }, [currentJourney]);

    const getLabelText = useCallback((journey: JourneyInfo) => {
        const journeysWithSameCountry = journeys?.filter(j => j.country === journey.country) || [];
        return journeysWithSameCountry.length > 1 ? journey.journeyName : journey.country;
    }, [journeys]);

    const handleSelectCountry = useCallback((journey: JourneyInfo) => {
        navigate("/");
        selectJourney(journey.uid);
        handleClose();
    }, [handleClose, selectJourney]);


    return <Grid container item justifyContent={"flex-end"} alignContent={"center"}>
        <CircleFlagStyled
            journey={currentJourney}
            color="#777"
            onClick={()=>{setOpen(true)}}
        />
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={{marginTop: 2, marginRight: 2, borderRadius: 5, background: "white", float: "right"}}>
                <Grid container padding={2} spacing={1}>
                    <Grid item xs={12}>
                        <h3 style={{marginTop: '0px'}}>Select Journey</h3>
                    </Grid>
                    {
                        journeys && journeys
                        // eslint-disable-next-line
                        .sort((a, b) => (a.flowType === "I9_ALT" ? -1 : 1))
                        .map(journey => <Grid key={journey.uid} container item xs={4} flexDirection={"column"} alignContent={"center"}>
                            <Grid container item justifyContent={"center"}>
                            <CircleFlagStyled
                                journey={journey}
                                color={getFlagColor(journey)}
                                onClick={()=>{handleSelectCountry(journey)}}
                            />
                            </Grid>
                            <Grid item textAlign={"center"} sx={{color: getLabelColor(journey)}}>
                                {getLabelText(journey)}
                            </Grid>
                        </Grid>)
                    }
                </Grid>
            </Box>
        </Modal>
    </Grid>
}

export default CountrySelector;