export const AuditQueryFormFields = [
  {
    name: "auditReference",
    label: "Audit reference*",
    placeholder: "0001/04-07-23",
  },
  {
    name: "dateFrom",
    label: "Start Date*",
    placeholder: "",
  },
  {
    name: "dateTo",
    label: "End Date*",
    placeholder: "",
  },
  {
    name: "locationIds",
    label: "Locations*",
  },
];
