import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

export const ConfirmationModalDialog = styled(Dialog)(() => ({
  ".MuiModal-backdrop": {
    background: "rgba(39, 95, 166, 0.3)",
  },
  ".MuiDialog-container": {
    ".MuiPaper-root": {
      borderRadius: "16px",
      padding: "20px 30px",
      maxWidth: "620px",
      h2: {
        fontSize: "18px",
        fontWeight: "bold",
        textAlign: "center",
      },
      ".MuiDialogActions-root": {
        button: {
          border: "1px solid #002554",
          width: "100%",
          borderRadius: "8px",
          textTransform: "none",
          color: "#1A1A1A",
          "&.MuiButton-outlined": {
            background: "rgba(39, 95, 166, 0.1)",
          },
          "&.MuiButton-contained": {
            background: "#1A1A1A",
            color: "#fff",
          },
        },
      },
    },
  },
}));
