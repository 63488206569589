import {FC, useContext, useEffect, useState} from "react";
import HeaderBar from "../../components/HeaderBar";
import {useParams, useNavigate} from "react-router-dom";
import {useCaseDetails} from "../../hooks/Cases/useCaseDetails";
import {AppBar, Button, Grid, LinearProgress, Stack} from "@mui/material";
import {
    PrimaryButton, SecondaryButton
} from "../CreateCase/CreateCaseForm/styles";
import {
    TopTabLeftButton,
    TopTabButtonMiddlePart,
    TopTabRightButton,
    TopTabButtonLeftArrow,
    TopTabButtonRightArrow
} from "./styles";
import {CaseSpecifications} from "../../models/responses/Cases/CaseSpecifications/CaseSpecifications";
import {CaseTabType, useCaseControl} from "../CaseDetails/useCaseControl.hook";
import {GenericDocumentPanel} from "../../components/CaseDetailsForm/GenericDocumentPanel";
import {FaceImagesPanel} from "../../components/CaseDetailsForm/FaceImagesPanel";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import {ConfirmationModelContainer, useConfirmationModal} from "../../components/ConfirmationModal";
import {useSubmitCaseEvent} from "../../hooks/Cases/useMutateCase";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ShareCodePanel} from "../../components/CaseDetailsForm/ShareCodePanel";
import JourneyContext from "../../context/JourneyContext";
import RejectCaseModal from "../../components/CaseDetailsForm/RejectCaseModal";
import InstructionsBottomBar from "../../components/InstructionsBottomBar";

const getTabPanel = (type: CaseTabType) => {
    switch (type) {
        case CaseTabType.GENERIC_DOCUMENT:
            return GenericDocumentPanel;
        case CaseTabType.FACE_IMAGES:
            return FaceImagesPanel;
        case CaseTabType.SHARE_CODE:
            return ShareCodePanel;
        default:
            return GenericDocumentPanel;
    }
}

const VerifyCase = () => {
    const {caseId}: { caseId: string } = useParams();
    const {data, isLoading} = useCaseDetails(caseId);
    const {tabs} = useCaseControl(data);
    const modal = useConfirmationModal();
    const navigate = useNavigate();

    const [isRejectModalOpen, setRejectModalOpen] = useState(false);

    const {currentJourney} = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");
    const isPrescient = currentJourney?.journeyType === "PRESCIENT";

    const specs = data?.specifications as CaseSpecifications;
    const interactiveEvents = specs?.interactiveEvents?.find(evtGroup => evtGroup.entryState === data?.status);
    const instructions = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "INSTRUCTIONS");
    const approveAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "APPROVE");
    const referredAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "REFERRED");
    const confirmationReferredAction = referredAction?.screens.find(screen => screen.type === "confirmation");
    const successReferredMessage = referredAction?.screens.find(screen => screen.type === "success")?.title || "This case has been referred.";
    const confirmationScreen = approveAction?.screens.find(screen => screen.type === "confirmation");
    const itemsToVerify = tabs.filter(tab => [CaseTabType.GENERIC_DOCUMENT, CaseTabType.FACE_IMAGES, CaseTabType.SHARE_CODE].includes(tab.type));
    const successMessage = approveAction?.screens.find(screen => screen.type === "success")?.title || "Event submitted";
    const failMessage = approveAction?.screens.find(screen => screen.type === "fail")?.title || "Event submission failed";
    const rejectAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "MANUAL_REJECT");

    const {mutate} = useSubmitCaseEvent(
        caseId,
        () => {
            toast.success(successMessage);
        },
        () => {
            toast.error(failMessage);
        },
        () => {
            navigate(`/cases/${caseId}`)
        },
    );

    const {mutate: mutateReferred} = useSubmitCaseEvent(
        caseId,
        () => {
            toast.success(successReferredMessage);
        },
        () => {
            toast.error("Referring this case failed.");
        },
        () => {
            navigate(`/cases/${caseId}`)
        },
    );

    const [activeTabIdx, setActiveTabIdx] = useState(0);
    const [approvedTabs, setApprovedTabs] = useState<number[]>([]);
    const [shareCodePdfFile, setShareCodePdfFile] = useState<Blob | undefined>(undefined);

    useEffect(() => {
        if (data?.additionalData["shareCodePdf"]) {
            fetch(data?.additionalData["shareCodePdf"] as string, {
                headers: {'Accept': 'application/pdf'}
            })
                .then(response => {
                    if (response.ok) {
                        return response.arrayBuffer();
                    }
                })
                .then(arrayBuffer => {
                    if (arrayBuffer) {
                        setShareCodePdfFile(new Blob([arrayBuffer], {type: 'application/pdf'}));
                    }
                })
        } else {
            setShareCodePdfFile(undefined);
        }
    }, [data])

    const readyToApprove = (approvedTabs.length === itemsToVerify.length)
        || (approvedTabs.length + 1 === itemsToVerify.length && !approvedTabs.includes(activeTabIdx));


    const handleTabButton = (idx: number) => {
        setActiveTabIdx(idx);
    }

    const handleExitNoDecisionButton = () => {
        modal.openConfirmationModal({
            message: "Exit, no decision",
            content: "Are you sure you want to exit the verification process for this case and restart again later?",
            confirmButtonLabel: "Yes, restart later",
            cancelButtonLabel: "No, continue verification process",
            confirmIsSecondaryButton: true,
            onConfirm: () => {
                navigate(`/cases/${caseId}`)
            },
            onCancel: () => null,
        });
    }

    const handleReferredButton = () => {
        if (confirmationReferredAction) {
            modal.openConfirmationModal({
                message: confirmationReferredAction.title,
                content: confirmationReferredAction.description,
                confirmButtonLabel: confirmationReferredAction.confirmButtonLabel ? confirmationReferredAction.confirmButtonLabel : "Confirm",
                cancelButtonLabel: confirmationReferredAction.cancelButtonLabel,
                confirmIsSecondaryButton: confirmationReferredAction.confirmIsSecondaryButton,
                onConfirm: () => {
                    if (referredAction?.caseEventType) {
                        mutateReferred(referredAction.caseEventType)
                    }
                },
                onCancel: () => null,
            })
        } else {
            if (referredAction?.caseEventType) {
                mutateReferred(referredAction.caseEventType)
            }
        }
    }

    const handleConfirmButton = () => {
        if (readyToApprove) {
            if (confirmationScreen) {
                let content = confirmationScreen.description;
                let checkList = undefined;
                if (content.includes("|")) {
                    const parts = content.split("|");
                    content = parts[0];
                    checkList = parts.slice(1);
                }

                modal.openConfirmationModal({
                    message: confirmationScreen.title,
                    content: content,
                    checkList: checkList,
                    confirmButtonLabel: confirmationScreen.confirmButtonLabel ? confirmationScreen.confirmButtonLabel : "Confirm",
                    cancelButtonLabel: confirmationScreen.cancelButtonLabel ? confirmationScreen.cancelButtonLabel : "Cancel",
                    onConfirm: () => {
                        if (approveAction?.caseEventType) {
                            mutate(approveAction.caseEventType)
                        }
                    },
                    onCancel: () => null,
                })
            } else {
                if (approveAction?.caseEventType) {
                    mutate(approveAction.caseEventType)
                }
            }
        } else {
            if (!approvedTabs.includes(activeTabIdx)) {
                setApprovedTabs([...approvedTabs, activeTabIdx]);
            }
            if (activeTabIdx + 1 < itemsToVerify.length) {
                setActiveTabIdx(activeTabIdx + 1);
            }
        }
    }

    if (isLoading) {
        return <LinearProgress/>;
    }

    if (itemsToVerify.length === 0) {
        return <HeaderBar
            breadcrumbs={[
                {
                    label: isI9 ? "I-9 Cases" : (isPrescient ? "myKYC Cases" : "myRTW Cases"),
                    path: "/cases",
                },
                ...(data ? [{label: `${data.givenName} ${data.familyName}`}] : []),
                {
                    label: caseId,
                },
            ]}
        >
            <div>Unexpected Case Data received</div>
        </HeaderBar>
    }

    const TabPanel: FC<{ data: GenericJsonObject, shareCodePdfFile?: Blob, canReplaceShareCodePdf?: boolean }> = getTabPanel(itemsToVerify[activeTabIdx].type);

    return (
        <HeaderBar
            breadcrumbs={[
                {
                    label: isI9 ? "I-9 Cases" : (isPrescient ? "myKYC Cases" : "myRTW Cases"),
                    path: "/cases",
                },
                ...(data ? [{label: `${data.givenName} ${data.familyName}`}] : []),
                {
                    label: caseId,
                },
            ]}
        >
            <Grid container sx={{paddingBottom: "100px"}}>
                <Grid
                    alignItems={"center"}
                    item
                    xs={12}
                    sx={{borderBottom: 1, borderColor: "divider", padding: 2}}
                >
                    <Stack direction="row" spacing={2}>
                        {
                            itemsToVerify.map((tab, idx) => {
                                if (idx === 0) {
                                    return <TopTabLeftButton key={`tab-${idx}`}
                                                             className={approvedTabs.includes(idx) ? "TopTabSelected" : ""}
                                                             onClick={() => {
                                                                 if (approvedTabs.includes(idx)) {
                                                                     handleTabButton(idx);
                                                                 }
                                                             }}
                                    >
                                        {tab.label}</TopTabLeftButton>
                                }

                                if (idx === itemsToVerify.length - 1) {
                                    return <TopTabRightButton key={`tab-${idx}`}
                                                              className={approvedTabs.includes(idx) ? "TopTabSelected" : ""}
                                                              onClick={() => {
                                                                  if (approvedTabs.includes(idx)) {
                                                                      handleTabButton(idx);
                                                                  }
                                                              }}
                                    >
                                        {tab.label}</TopTabRightButton>
                                }
                                return <div key={`tab-${idx}`} style={{flexDirection: "row", display: "flex"}}>
                                    <TopTabButtonLeftArrow
                                        className={approvedTabs.includes(idx) ? "TopTabSelected" : ""}/>
                                    <TopTabButtonMiddlePart
                                        className={approvedTabs.includes(idx) ? "TopTabSelected" : ""}
                                        onClick={() => {
                                            if (approvedTabs.includes(idx)) {
                                                handleTabButton(idx);
                                            }
                                        }}
                                    >
                                        {tab.label}</TopTabButtonMiddlePart>
                                    <TopTabButtonRightArrow
                                        className={approvedTabs.includes(idx) ? "TopTabSelected" : ""}/>
                                </div>

                            })
                        }
                    </Stack>
                </Grid>
                <Grid container item xs={12} sx={{padding: 3}}>
                    <TabPanel data={itemsToVerify[activeTabIdx].data} shareCodePdfFile={shareCodePdfFile}
                              canReplaceShareCodePdf={false}/>
                </Grid>
                <AppBar
                    position="fixed"
                    sx={{
                        borderTop: 1,
                        borderColor: "divider",
                        top: "auto",
                        bottom: 0,
                        background: "white",
                    }}
                >
                    <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                        <Grid item xs={6}></Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <Button sx={{textDecoration: "underline", textTransform: "none"}}
                                    onClick={handleExitNoDecisionButton}>
                                Exit, no decision
                            </Button>
                            <SecondaryButton onClick={() => setRejectModalOpen(!isRejectModalOpen)}>
                                Reject
                            </SecondaryButton>
                            {referredAction && <SecondaryButton onClick={handleReferredButton}>
                                Referred
                            </SecondaryButton>
                            }
                            <PrimaryButton
                                disabled={!shareCodePdfFile && itemsToVerify[activeTabIdx].type === CaseTabType.SHARE_CODE}
                                onClick={handleConfirmButton}>
                                {
                                    readyToApprove ? ('Approve' || "Next") : "Next"
                                }
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </AppBar>
            </Grid>
            {instructions && <InstructionsBottomBar content={instructions}/>}
            <ConfirmationModelContainer {...modal} />
            <RejectCaseModal caseId={caseId}
                             isOpen={isRejectModalOpen}
                             onClose={() => setRejectModalOpen(!isRejectModalOpen)}
                             interactiveEvent={rejectAction}/>
        </HeaderBar>
    );
};

export default VerifyCase;
