import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const ResultText = styled(Typography)(() => ({
  fontSize: "24px",
  fontWeight: "bold",
  "& span": {
    fontSize: "14px",
    fontWeight: "normal",
    display: "block",
  },
}));
