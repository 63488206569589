import {AxiosRequestConfig} from "axios";
import ConfigService from "../../services/ConfigService/ConfigService";
import {useContext} from "react";
import AuthenticationContext from "../../context/AuthenticationContext";

type AxiosParams = {
    basePath: string;
    orchestratorPath: string;
    config: AxiosRequestConfig;
}

export enum HeaderType {
    NORMAL,
    DOWNLOAD_PDF,
    DOWNLOAD_CSV,
}

const getConfig = (headerType: HeaderType, token: string) : AxiosRequestConfig => {
    switch (headerType) {
        case HeaderType.NORMAL:
            return {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            };
        case HeaderType.DOWNLOAD_CSV:
            return {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'text/csv'
                }
            };
        case HeaderType.DOWNLOAD_PDF:
            return {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/octet-stream'
                }
            };
        default:
            throw `Unsupported header type for AxiosParams generation: ${headerType}`;
    }
}

export const useAxiosParams = (headerType : HeaderType = HeaderType.NORMAL): AxiosParams => {
    const basePath: string = ConfigService.getConfigValue('REACT_APP_CASES_ENDPOINT');
    const orchestratorPath: string = ConfigService.getConfigValue('ORCHESTRATOR_ENDPOINT');
    const {token} = useContext(AuthenticationContext);

    if (!token) {
        throw "No auth token provided for AxiosParams generation"
    }

    return {
        basePath: basePath,
        orchestratorPath: orchestratorPath,
        config: getConfig(headerType, token)
    }
}
