import { Grid, Link, AppBar, Typography } from "@mui/material";
import CsvDropzone, { CsvDropzoneProps } from "./CsvDropzone";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";

type UploadScreenProps = CsvDropzoneProps & {
  toggleGuidelineDrawer: (open: boolean) => () => void;
  handleSubmitCsvCases: () => void;
};

const UploadScreen = ({
  getRootProps,
  getInputProps,
  isDragActive,
  selectedFile,
  isLoading,
  toggleGuidelineDrawer,
  handleSubmitCsvCases,
}: UploadScreenProps) => {

  return (
    <>
      <Grid item xs={12}>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: "18px" }}>
          Batch cases
        </Typography>
        <p>CSV is the required file format.</p>
        <p>Click on the ‘BROWSE’ to select your batch case file.</p>
        <p>Clicking ‘SUBMIT’ generates candidate invitations. Candidates receive invitations as an SMS and email.</p>
        <p>NOTE: Batch case files cannot contain duplicate Candidate References. Duplicates will be rejected.</p>
        
      </Grid>
      <Grid item xs={12}>
        <CsvDropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          selectedFile={selectedFile}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12} sx={{ fontSize: 14 }}>
        {`Need help? Check our submission guidelines `}
        <Link
          href={undefined}
          sx={{ cursor: "pointer" }}
          underline={"hover"}
          color={"#2253FF"}
          onClick={toggleGuidelineDrawer(true)}
        >
          here
        </Link>
      </Grid>
      <AppBar
        position="fixed"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          top: "auto",
          bottom: 0,
          background: "white",
        }}
      >
        <Grid container item xs={12} style={{ flexGrow: 1, padding: 20 }}>
          <Grid item xs={8}></Grid>
          <Grid
            container
            item
            xs={4}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <SecondaryButton variant="contained" href="/">
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={handleSubmitCsvCases}
              disabled={!selectedFile}
            >
              Submit
            </PrimaryButton>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};

export default UploadScreen;
