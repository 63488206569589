import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box, CircularProgress, Grid} from "@mui/material";
import {ReactComponent as EnlargeIcon} from "../../img/enlarge.svg";
// import I9FormModal from "../../I9FormModal";
import {Document, Page, pdfjs} from 'react-pdf';
import PdfFormModal from './PdfFormModal';
import JourneyContext from '../../context/JourneyContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfForm = ({pdfFile, height}:
                    {
                        pdfFile: Blob | string | undefined,
                        height?: string,
                        showDocName: boolean
                    }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState(1);

    const { currentJourney } = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");

    const handleEnlarge = () => {
        setOpen(true);
    }

    const [pdfWidth, setPdfWidth] = React.useState(0);

    const pdfContainerRef = useRef<HTMLDivElement>(null);

    const calculatePdfWidth = () => {
        const width = pdfContainerRef?.current?.clientWidth;
        return width ? width - 45 : 0
    }

    const updatePdfWidth = () => {
        setPdfWidth(calculatePdfWidth());
    }

    const resizeHandler = () => {
        updatePdfWidth();
    }

    const onPdfLoaded = ({ numPages }: { numPages? : number}) => {
        if (numPages && numPages !== pageCount) {
            setPageCount(numPages);
        }        
        resizeHandler();
    }

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    return (
        <>
            <Grid container>
                {!pdfFile &&
                    <div>
                        <label>{isI9 ? 'I-9 Form' : 'PDF File'}</label>
                        <Box sx={{display: 'flex'}}>
                            <CircularProgress/>
                        </Box>
                    </div>

                }
                {
                    pdfFile && <Grid item container width="100%">
                        <div style={{
                            width: "100%",
                        }}>
                            <div ref={pdfContainerRef}
                                 style={{
                                     padding: "16.5px 14px",
                                     borderRadius: 4,
                                     position: "relative",
                                     background: "#F4F4F4",
                                     width: "100%",
                                 }}>
                                <EnlargeIcon
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        right: "0px",
                                        margin: "23px",
                                        zIndex: "1000"
                                    }}
                                    onClick={() => handleEnlarge()}
                                />
                                <div style={{
                                    width: "100%",
                                    height: height ? height : "55vh",
                                    overflow: "scroll"
                                }}>
                                    <Document onLoadSuccess={onPdfLoaded}
                                              file={pdfFile}
                                              options={{
                                                standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
                                            }}>
                                        {
                                            Array.from(Array(pageCount).keys()).map(idx => <Page 
                                                key={`page-${idx}`}
                                                pageNumber={idx + 1}
                                                width={pdfWidth}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                          />)
                                        }
                                    </Document>
                                </div>
                            </div>
                        </div>
                    </Grid>
                }
            </Grid>
            <PdfFormModal
                open={open}
                setOpen={setOpen}
                pdfFile={pdfFile}
            />
        </>
    );
}

PdfForm.defaultProps = {
    showDocName: true
};


export default PdfForm;
