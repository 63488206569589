import { AppBar, Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import HeaderBar from "../../components/HeaderBar";
import { PrimaryButton } from "../CreateCase/CreateCaseForm/styles";
import { useBrand } from "../../hooks/Brand/useBrand";
import { useAuditLogForm } from "./useAuditLogForm";
import { AuditFormDateInput } from "../Audit/AuditQueryForm/styles";
import dayjs from "dayjs";
import AuthenticatedLink from "../../components/common/AuthenticatedLink";
import { HeaderType } from "../../hooks/common/useAxiosParams";
import { DownloadAuditLogRequest } from "../../models/requests/DownloadAuditLogRequest";
import { useContext, useMemo } from "react";
import { useUsers } from "../../hooks/Users/useUsers";
import AuthenticationContext from "../../context/AuthenticationContext";
import JourneyContext from "../../context/JourneyContext";

const AuditInputGrid = ({label, children} : {label: string, children?: React.ReactNode}) => <Grid container alignItems={"center"} item xs={12} sx={{ marginTop: 2 }}>
    <Grid item xs={4}>
        {label}
    </Grid>
    <Grid item container xs={8}>
        { children }
    </Grid>
</Grid>

const AuditLog = () => {

    const { isAdminUser } = useContext(AuthenticationContext);

    const { data: brand } = useBrand();
    const { currentJourney } = useContext(JourneyContext);

    const { data: users } = useUsers();

    const { data, handleChange } = useAuditLogForm({
        dateFrom: "",
        dateTo: "",
        user: "",
        locationIds: [],
        consumerReference: "",
    });

    const postData : DownloadAuditLogRequest = useMemo(()=>{
        return {
            dateFrom: data.dateFrom,
            dateTo: data.dateTo,
            user: data.user?.length ? data.user : undefined,
            locationIds: data.locationIds?.length ? data.locationIds : undefined,
            consumerReference: data.consumerReference?.length ? data.consumerReference : undefined,
        }
    }, [data])
    
    return <HeaderBar
        breadcrumbs={[
        {
            label: "Audit Log",
        },
        ]}
    >
        {
        !isAdminUser ? 
        <Typography color={"error"} padding={1}>You are not authorized to access this page.</Typography>
        :
        <Grid sx={{ flexGrow: 1, p: 3 }}>
            <Grid item xs={8}>
                <Typography paragraph sx={{ fontWeight: 600, fontSize: "18px" }}>
                Select your log criteria
                </Typography>
                <p>
                Select the parameters of your Audit Log from the inputs below, to then download as a CSV file.
                </p>
            </Grid>
            <form style={{ width: "100%" }}>
                <Grid container alignItems={"center"} item xs={8} sx={{ marginTop: 2 }}>
                   <AuditInputGrid label="Start Date*">
                        <AuditFormDateInput
                            value={data.dateFrom.length > 0 ? dayjs(data.dateFrom) : null}
                            // eslint-disable-next-line                            
                            onChange={(date: any) => {
                                handleChange("dateFrom")(date.toISOString())
                            }}
                        />
                   </AuditInputGrid>
                   <AuditInputGrid label="End Date*">
                        <AuditFormDateInput
                            value={data.dateTo.length > 0 ? dayjs(data.dateTo) : null}
                            // eslint-disable-next-line
                            onChange={(date: any) => {
                                handleChange("dateTo")(date.toISOString())
                            }}
                            minDate={data.dateFrom.length > 0 ? dayjs(data.dateFrom) : null}
                        />
                   </AuditInputGrid>
                   <AuditInputGrid label="Locations*">
                        <Select
                            multiple
                            value={data.locationIds || []}
                            onChange={event => {
                                const {
                                    target: { value },
                                } = event;
                                handleChange("locationIds")(
                                    // On autofill we get a stringified value.
                                    typeof value === 'string' ? value.split(',').map(str => Number(str)) : value,
                                );
                            }}
                            input={<OutlinedInput />}
                            renderValue={(selected) => selected.map(locationId => currentJourney?.locations.find(loc => loc.id === locationId)?.name || "").join(", ")}
                            fullWidth
                            sx={{
                                "& .MuiSelect-select": {
                                padding: "10px",
                                },
                            }}
                            >
                                {currentJourney?.locations.map((location) => (
                                    <MenuItem key={location.id} value={location.id}>
                                    <Checkbox
                                        checked={
                                            data.locationIds && data.locationIds.indexOf(location.id) > -1
                                        }
                                    />
                                    <ListItemText primary={location.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                   </AuditInputGrid>
                   <AuditInputGrid label="User">
                        <Select
                            value={data.user || ""}
                            onChange={event => handleChange("user")(event.target.value)}
                            input={<OutlinedInput />}
                            fullWidth
                            sx={{
                                "& .MuiSelect-select": {
                                padding: "10px",
                                },
                            }}
                            >
                                <MenuItem value={""}>
                                    All
                                </MenuItem>
                                {
                                    users && users.map(user => <MenuItem key={`user-${user.emailAddress}`} value={user.emailAddress}>{`${user.givenName} ${user.familyName}`}</MenuItem>)
                                }
                        </Select>
                   </AuditInputGrid>
                   <AuditInputGrid label="Candidate Reference">
                        <TextField
                            fullWidth
                            value={data.consumerReference || ""}
                            size={"small"}
                            onChange={event => handleChange("consumerReference")(event.target.value) }
                        />
                   </AuditInputGrid>
                </Grid>
                <AppBar
                position="fixed"
                sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    top: "auto",
                    bottom: 0,
                    background: "white",
                }}
                >
                <Grid container item xs={12} style={{ flexGrow: 1, padding: 20 }}>
                    <Grid item xs={8}></Grid>
                    <Grid
                    container
                    item
                    xs={4}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    >
                    <AuthenticatedLink
                        url={`/brands/${brand?.id}/auditLogs`}
                        headerType={HeaderType.DOWNLOAD_CSV}
                        fileName={"audit-log.csv"}
                        disabled={ !data.dateFrom?.length || !data.dateTo?.length }
                        onSuccess={()=>window.location.reload()}
                        postBody={postData}
                    >                    
                        <PrimaryButton
                            disabled={ !data.dateFrom?.length || !data.dateTo?.length || data.locationIds?.length === 0 }
                        >
                            Download
                        </PrimaryButton>
                    </AuthenticatedLink>
                    </Grid>
                </Grid>
                </AppBar>
            </form>
        </Grid>
        }
    </HeaderBar>
}

export default AuditLog;
