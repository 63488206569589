import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ReinviteModalContent } from "./styles";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";
import { useReinvite } from "../../../hooks/Cases/useMutateCase";
import { useState } from "react";

type ReinviteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
  screen?: {
    description: string,
    icon: string,
    title: string,
    type: string
  }
};

const ReinviteCaseModal = ({ isOpen, onClose, caseId, screen }: ReinviteModalProps) => {
  
  const [reviewReason, setReviewReasons] = useState("");
  const { mutate, isLoading } = useReinvite(caseId);

  const handleRenvite = () => {
    mutate(reviewReason);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>{screen?.title}</DialogTitle>
      <ReinviteModalContent>
        <p>
          If you wish to supplement the Re-invitation reason, please use the
          free text box below.
        </p>
        <Typography component={"h2"}>
          Reason message states the following:
        </Typography>
        <p className="divider">
          Unfortunately, we were unable to validate your documents. Please
          resubmit, using this Link and make sure that in the image(s), your
          document data is clear and readable.
        </p>
        <Typography component={"h2"}>Additional reason to user:</Typography>
        <Grid
          container
          alignItems={"center"}
          sx={{ marginTop: "10px", padding: 0 }}
        >
          <Grid container item xs={12} style={{ flexGrow: 1, padding: 0 }}>
            <Grid container item xs={12} alignItems={"center"}>
              <TextField
                multiline
                rows={4}
                fullWidth
                onChange={(e) => setReviewReasons(e.target.value)}
              />
              {screen?.description && <p dangerouslySetInnerHTML={{__html: screen?.description}}></p>}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ flexGrow: 1, padding: 1, marginTop: "30px" }}
          >
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <SecondaryButton variant="contained" onClick={onClose}>
                Back to case
              </SecondaryButton>
              <PrimaryButton disabled={isLoading} onClick={handleRenvite}>
                Confirm
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </ReinviteModalContent>
    </Dialog>
  );
};

export default ReinviteCaseModal;
