import { FC, useEffect, useState } from "react"
import { GenericJsonObject } from "../../../models/responses/Cases/CaseResponse"
import { Grid, Link, Typography } from "@mui/material"
import PdfForm from "../../PdfForm";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { ConfirmButton } from "./style";
import { useDropzone } from "react-dropzone";
import CsvDropzone from "../../UploadCasesForm/UploadScreen/CsvDropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteShareCodePdf, useUploadShareCodePdf } from "../../../hooks/Cases/useMutateCase";

export const ShareCodePanel: FC<{
    data: GenericJsonObject,
    shareCodePdfFile?: Blob,
    canReplaceShareCodePdf?: boolean,
    handleRefresh?: () => void,
}>
    = ({data, handleRefresh, shareCodePdfFile, canReplaceShareCodePdf}) => {

        const [copiedStyle, setCopiedStyle] = useState({opacity: 0, textDecoration: "none", transition: "none"});

        const [files, setFiles] = useState([]);

        const {mutate, isLoading} = useUploadShareCodePdf(
            data.uid as string,
            () => { toast.success("Share Code PDF Uploaded"); },
            () => { toast.error("Share Code PDF Upload Failed"); },
            handleRefresh
        )

        const {mutate: deleteShareCodePdf} = useDeleteShareCodePdf(
            data.uid as string,
            () => { toast.success("Share Code PDF Deleted"); },
            () => { toast.error("Share Code PDF Deletion Failed"); },
        );

        // eslint-disable-next-line
        //@ts-ignore
        const onDrop = acceptedFiles => {
            setFiles(acceptedFiles);
        };

        const {getRootProps, getInputProps, isDragActive} = useDropzone({
            accept: {
                "application/pdf": [".pdf"],
            },
            maxFiles: 1,
            maxSize: 10 * 1024 * 1024,
            onDrop
        });

        const selectedFile = files && files.length > 0 && files[0];

        const shareCodePdfPresent = data.shareCodePdfPresent as boolean;
        const shareCodePdfLoaded = !!shareCodePdfFile;

        const formatShareCode = (code: string) => {
            if (code.length === 9) {
                return `${code.substring(0,3)} ${code.substring(3,6)} ${code.substring(6,9)}`;
            }
            return code;
        }

        const onCopyShareCode = () => {
            navigator.clipboard.writeText(data.shareCode as string);
            setCopiedStyle({opacity: 1, textDecoration: "underline", transition: "none"})
        }

        useEffect(()=>{
            if (copiedStyle.opacity === 1) {
                setCopiedStyle({opacity: 0, textDecoration: "underline", transition: "opacity 1500ms"})
            }
        }, [copiedStyle, setCopiedStyle]);

        useEffect(()=>{
            if (selectedFile) {
                mutate(selectedFile as File);
            }
        }, [selectedFile])


        try {
            return <Grid container p={0} spacing={2} alignItems={"flex-start"}>
                <Grid container flexDirection={"row"} item xs={12}>
                    {
                        shareCodePdfPresent ? <Grid container spacing={1} item xs={12} sx={{paddingBottom: 3, borderBottom: "1px solid #D7D7D7",}}>
                            <Grid item xs={12}><b>Important Details</b></Grid>
                            <Grid item xs={12}>{`Confirm that the photo included in the Share Code verification report below matches the candidate presenting themselves for work. This can be done in person or by a video call. Once you have confirmed this, click 'Approve' to validate the candidate’s right to work evidence.`}</Grid>
                        </Grid> 
                        : <Grid container spacing={1} item xs={12} sx={{paddingBottom: 3, borderBottom: "1px solid #D7D7D7",}}>
                            <Grid item xs={12}><b>Share Code verification report - Retrieve and Upload</b></Grid>
                            <Grid item xs={12}>{`To access the Home Office Checking Service and generate the report, press ‘Retrieve Share Code verification report’. After doing so, click 'Download PDF' and save the file to your desktop.`} <br/><br/> {`The PDF report can be uploaded by dragging and dropping it onto the screen below. Alternatively, you can click on 'BROWSE' to locate the file on your desktop to upload it.`}</Grid>
                            <Grid item>
                                <Link target="_blank" href="https://www.gov.uk/view-right-to-work">
                                    <ConfirmButton>Retrieve Share Code verification report</ConfirmButton>
                                </Link>                                
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={1} p={1} item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
                        <Grid container item sx={{background: (theme) => theme.palette.secondary.light, borderRadius: "10px"}} justifyContent={"space-around"}>
                            <Grid item xs={0} sx={{color: "#777", fontSize: 15, width: "100px"}}>Share Code:</Grid>
                            <Grid item xs={6} sx={{fontWeight: 700}}>{ formatShareCode(data.shareCode as string) }</Grid>
                            <Grid container item spacing={1} xs={0} sx={{color: "#777", fontSize: 15, width: "250px", cursor: "pointer"}} onClick={onCopyShareCode}>
                                <Grid item>Copy to clipboard</Grid>
                                <Grid item><ContentCopyOutlinedIcon/></Grid>
                                <Grid item sx={copiedStyle}>Copied!</Grid>
                            </Grid>                        
                        </Grid>
                        
                    </Grid>
                    {
                        shareCodePdfPresent && shareCodePdfLoaded && 
                        <Grid container item xs={6} sx={{marginBottom: 2}}>
                            <Grid item xs={12}>SHARE CODE VERIFICATION REPORT</Grid>
                            <PdfForm pdfFile={shareCodePdfFile} height={"55vh"}/>
                            {
                                shareCodePdfPresent && canReplaceShareCodePdf && <Grid container item xs={12} sx={{marginTop: 1}}>
                                    <ConfirmButton onClick={()=>{deleteShareCodePdf()}}>Replace uploaded file</ConfirmButton>
                                </Grid>
                            }
                        </Grid>
                    }
                    {
                        !shareCodePdfPresent && canReplaceShareCodePdf &&  <Grid container item xs={12}>
                        <CsvDropzone
                          getRootProps={getRootProps}
                          getInputProps={getInputProps}
                          isDragActive={isDragActive}
                          selectedFile={selectedFile}
                          isLoading={isLoading}
                          fileTypeLabel={"PDF"}
                        />
                      </Grid>
                    }
                </Grid>                
            </Grid>
        } catch (e) {
            console.error(e)
            return <Typography color={"error"} padding={1}> Unexpected data received for document: {data?.documentName as string} </Typography>
        }
}