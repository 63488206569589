import { styled, CSSObject } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

export const MobilePhoneInput = styled(MuiTelInput)(() => ({
  "& .MuiTelInput-Flag img": {
    display: "none"
  },
  "& .MuiTelInput-Flag picture img":{
      display: 'block'
  }
}))

export const PrimaryButton = styled(Button)<ButtonProps>(
  ({ theme }): CSSObject => ({
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    fontSize: "14px",
    padding: "8px 30px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      background: "#cecece",
      border: `1px solid #cecece`,
    },
  })
);

export const SecondaryButton = styled(Button)<ButtonProps>(
  ({ theme }): CSSObject => ({
    color: "#1A1A1A",
    backgroundColor: theme.palette.secondary.light,
    textTransform: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    fontSize: "14px",
    padding: "8px 30px",
    marginLeft: 15,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  })
);
