import { Chip, ListItem, Stack } from "@mui/material";
import {
  AccessAlarmTwoTone,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SectionsDataListButton, SectionsDataStack } from "./styles";
import React, { useContext } from "react";
import {
  CaseListFilterContext,
} from "../../../../context/CaseListFilters";

type SectionDataListItemProps = {
  section: string;
  count: number;
  text: string;
  icon: boolean;
  dueDateStart?: string;
  dueDateEnd?: string;
  beforeIcon?: () => React.ReactNode;
  color?: string;
};

const SectionDataListItem = ({
  section,
  count,
  text,
  icon,
  dueDateStart,
  dueDateEnd,
  beforeIcon,
  color
}: SectionDataListItemProps) => {
  const { setFilterStatuses, setDateType, setFilterDate, setFilterDateEnd, setFilterText } = useContext(
    CaseListFilterContext
  );

  const handleCaseLink = (filters: string[]) => () => {
    setFilterStatuses(filters);
    setDateType("dueDate");
    setFilterText("");
    setFilterDate(dueDateStart || "");
    setFilterDateEnd(dueDateEnd || "");
  };

  return (
    <ListItem disablePadding dense sx={{ display: "block" }}>
      <Link
        to={`/cases`}
        onClick={handleCaseLink([section])}
        style={{ color: "#000", width: "100%", textDecoration: "none" }}
      >
        <SectionsDataListButton>
          <SectionsDataStack direction="row" useFlexGap flexWrap="wrap">
            <Stack
              direction="row"
              alignItems={"center"}
              useFlexGap
              flexWrap="wrap"
            >
              {beforeIcon && beforeIcon()}
              <p>{text}</p>
            </Stack>
            <Stack direction="row" useFlexGap flexWrap="wrap">
              {icon && <AccessAlarmTwoTone fontSize="medium" />}
              <Chip
                label={count ? count.toString() : 0}
                variant="outlined"
                className={section}
                sx={{
                  background: color || '#D7D7D7',
                }}
              />
              <ArrowForwardIosOutlined fontSize="small" />
            </Stack>
          </SectionsDataStack>
        </SectionsDataListButton>
      </Link>
    </ListItem>
  );
};

export default SectionDataListItem;
