import { styled, CSSObject } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";

export const EditButton = styled(Button)(
  ({ theme }): CSSObject => ({
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    textTransform: "uppercase",
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      svg: {
        path: {
          fill: theme.palette.primary.main,
        },
      },
    },
  })
);

export const CompareButton = styled(Button)(
  ({ theme }): CSSObject => ({
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      svg: {
        path: {
          fill: theme.palette.primary.main,
        },
      },
    },
  })
);

export const TopBarActionButton = styled(Button)<ButtonProps>(
  ({ theme }): CSSObject => ({
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    fontSize: "12px",
    padding: "5px 15px",
    margin: "5px 0px 5px 10px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      background: "#cecece",
      border: `1px solid #cecece`,
    },
  })
);

export const TopBarActionButtonSecondary = styled(Button)<ButtonProps>(
  ({ theme }): CSSObject => ({
    color: "#000",
    backgroundColor: "#C9F8EF",
    textTransform: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    fontSize: "12px",
    padding: "5px 15px",
    margin: "5px 0px 5px 10px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      background: "#cecece",
      border: `1px solid #cecece`,
    },
  })
);