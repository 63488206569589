import {createRef} from "react";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import {StringUtils} from "../../utils/StringUtils";

type ExportCsvButtonProps = {
    fileName: string,
    data: GenericJsonObject[];
    children: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
}

const ExportCsvButton = ({fileName, data, children}: ExportCsvButtonProps) => {
    const link = createRef<HTMLAnchorElement>();

    const convertToCsv = (data: GenericJsonObject[]): string => {
        const headers = Object.keys(data[0]);
        const rows = data.map((obj) => headers.map((header)=>obj[header]));
        const headerRows = headers.map((header) => StringUtils.camelCaseToLabel(header)).join(',');
        const  csvRows = [headerRows, ...rows.map( (row) => row.join(','))];
        return csvRows.join('\n');
    }
    const handleAction = () => {
        if (!link.current) return;
        const csvContent = convertToCsv(data);
        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
        const href = window.URL.createObjectURL(blob);
        link.current.download = fileName;
        link.current.href = href
    }

    return (
        <>
            <a role='button' ref={link} onClick={handleAction}>{children}</a>
        </>
    )
}

export default ExportCsvButton;
