import { useState } from "react";
import { Container, Typography } from "@mui/material";
import {
  InstructionsBarBox,
  InstructionBarItem,
  InstructionsBarToggle,
} from "./styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { ReactComponent as InstructionIconOne } from "../../../img/instructions-icon-1.svg";
import { ReactComponent as InstructionIconTwo } from "../../../img/instructions-icon-2.svg";
import { ReactComponent as InstructionIconThree } from "../../../img/instructions-icon-3.svg";
import { ReactComponent as InstructionIconFour } from "../../../img/instructions-icon-4.svg";

const InstructionsBar = () => {
  const [isInstructionsOpen, setInstructionsOpen] = useState(false);

  return (
    <div>
      <InstructionsBarBox disableRipple>
        <InstructionsBarToggle
          onClick={() => setInstructionsOpen(!isInstructionsOpen)}
        >
          Instructions{" "}
          {isInstructionsOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </InstructionsBarToggle>
        <Container
          sx={{ display: isInstructionsOpen ? "block" : "none !important" }}
        >
          <InstructionBarItem direction="row" spacing={1} alignItems="center">
            <Typography
              variant="h3"
              sx={{ fontSize: "34px", fontWeight: "bold" }}
            >
              1
            </Typography>
            <InstructionIconOne />
            <p>Check employee has documentation with them</p>
          </InstructionBarItem>
          <InstructionBarItem direction="row" spacing={1} alignItems="center">
            <Typography
              variant="h3"
              sx={{ fontSize: "34px", fontWeight: "bold" }}
            >
              2
            </Typography>
            <InstructionIconTwo />
            <p>
              Employee should hold documentation so that you can see them
              clearly (front & back)
            </p>
          </InstructionBarItem>
          <InstructionBarItem direction="row" spacing={1} alignItems="center">
            <Typography
              variant="h3"
              sx={{ fontSize: "34px", fontWeight: "bold" }}
            >
              3
            </Typography>
            <InstructionIconThree />
            <p>
              Satisfy yourself documents appear genuine and relate to the named
              employee on the call
            </p>
          </InstructionBarItem>
          <InstructionBarItem direction="row" spacing={1} alignItems="center">
            <Typography
              variant="h3"
              sx={{ fontSize: "34px", fontWeight: "bold" }}
            >
              4
            </Typography>
            <InstructionIconFour />
            <p>
              If satisfied employee is authorized to work in the US, click
              Verify Documentation, thank employee and end call
            </p>
          </InstructionBarItem>
        </Container>
      </InstructionsBarBox>
    </div>
  );
};

export default InstructionsBar;
