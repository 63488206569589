import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const AuditFormDateInput = styled(DatePicker)(() => ({
  background: "#F4FDFC",
  width: "100%",
  ".MuiOutlinedInput-root": {
    paddingLeft: "8px",
    flexDirection: "row-reverse",
    ".MuiButtonBase-root": {
      padding: 0,
      margin: "0px 7px 0px -7px",
    },
    ".MuiSvgIcon-root": {
      fontSize: "16px",
    },
    input: {
      padding: "10px",
      fontSize: "14px",
      borderRadius: "8px",
    },
  },
}));
