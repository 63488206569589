import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCaseDetails } from "../../../hooks/Cases/useCaseDetails";
import { CaseResponse } from "../../../models/responses/Cases/CaseResponse";
import CaseDetailsForm from "../../../components/CaseDetailsForm";

const CaseDetailsInternalAuditorModal = ({
  index,
  getCase,
  open,
  setOpen,
}: {
  index: number;
  casesCount: number;
  getCase: (index: number) => CaseResponse;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setIndex: Dispatch<SetStateAction<number>>;
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(index);
  const [currentCaseId, setCurrentCaseId] = useState<string>(
    getCase(index).uid
  );

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);

  useEffect(() => {
    setCurrentCaseId(getCase(index).uid);
  }, [currentIndex]);

  const { data: emsCase } = useCaseDetails(currentCaseId);

  const [i9PdfFile, setI9PdfFile] = useState<Blob | undefined>(undefined);

  useEffect(() => {
    if (emsCase?.additionalData["i9Pdf"]) {
      fetch(emsCase?.additionalData["i9Pdf"] as string, {
        headers: { Accept: "application/pdf" },
      })
        .then((response) => {
          if (response.ok) {
            return response.arrayBuffer();
          }
        })
        .then((arrayBuffer) => {
          if (arrayBuffer) {
            setI9PdfFile(new Blob([arrayBuffer], { type: "application/pdf" }));
          }
        });
    } else {
      setI9PdfFile(undefined);
    }
  }, [emsCase]);

  const closeButtonHandler = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth={"xl"} open={open} fullWidth>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              height: 0,
              paddingRight: 15,
              paddingTop: 5,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={closeButtonHandler}
              sx={{
                height: 35,
                width: 35,
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div
            style={{
              marginTop: 10,
              marginBottom: 15,
              marginLeft: 30,
              fontSize: 16,
              textTransform: "uppercase",
            }}
          >
            {emsCase?.givenName && emsCase?.familyName && (
              <strong>{`${emsCase?.givenName} ${emsCase?.familyName}`}</strong>
            )}
            {emsCase?.consumerReference && (
              <span
                style={{ color: "#666666" }}
              >{` (${emsCase?.consumerReference})`}</span>
            )}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {emsCase && <div style={{height: "800px", width: "100%"}}>
            <CaseDetailsForm emsCase={emsCase} i9PdfFile={i9PdfFile} isAuditorView={true} />
        </div>}
      </DialogContent>
    </Dialog>
  );
};

export default CaseDetailsInternalAuditorModal;
