import { Typography, Paper } from "@mui/material";
import { DashboardGridBox } from "./styles";

type DashboardBoxWidgetProps = {
  children: JSX.Element;
  title: string;
  size?: number;
  height?: string;
  icon?: string;
  color?: string;
};
const DashboardBox = ({ children, title, size, height, icon, color }: DashboardBoxWidgetProps) => {
  return (
    <DashboardGridBox item xs={size || 4} sx={{height: height}}>
      <Paper>
        <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center'}}>{icon ? <div style={{ borderRadius: '100px', padding: 6, backgroundColor: color, width: '30px', height: '30px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center',marginRight: '10px'}}>
          <img
                      style={{
                        minWidth: 0,
                        justifyContent: "center",
                      }}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
                    />
        </div> : ''}{title}</Typography>
        {children}
      </Paper>
    </DashboardGridBox>
  );
};

export default DashboardBox;
