import {JourneyInfo} from "../../models/responses/JourneyInfo";
import {Drawer, Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {DrawerHeader} from "../HeaderBar/styles";
import SubmissionGuidelines from "./SubmissionGuidelines";
import {useDropzone} from "react-dropzone";
import {useUploadCases} from "../../hooks/Cases/useUploadCases";
import UploadScreen from "./UploadScreen";
import ErrorScreen from "./ErrorScreen";
import SuccessScreen from "./SuccessScreen";

const UploadCasesForm = ({journey} : {journey: JourneyInfo}) => {
    const [showGuideline, setShowGuideline] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    
    const [files, setFiles] = useState([]);

    // eslint-disable-next-line
    //@ts-ignore
    const onDrop = acceptedFiles => {
        setFiles(acceptedFiles);
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "text/csv": [".csv"],
        },
        maxFiles: 1,
        maxSize: 10 * 1024 * 1024,
        onDrop
    });

    const {data, error, isSuccess, isError, isLoading, mutate} = useUploadCases();

    const selectedFile = files && files.length > 0 && files[0];

    const toggleGuidelineDrawer = (open : boolean) => () => {
        setShowGuideline(open);
    };

    const handleSubmitCsvCases = useCallback(() => {
        selectedFile && mutate({
            brandId: journey.brandId,
            journeyId: journey.uid,
            csvFile: selectedFile,
        })
    }, [selectedFile, journey, mutate])

    const handleCloseError = useCallback(() => {
        setFiles([]);
        setShowError(false);
    }, [setShowError])

    const handleCloseSuccess = useCallback(() => {
        setShowSuccess(false);
    }, [setShowSuccess])

    useEffect(() => setShowSuccess(isSuccess), [isSuccess, setShowSuccess]);
    useEffect(() => setShowError(isError), [isError, setShowError]);

    return <Grid container flexDirection={"row"} justifyContent={"space-around"} sx={{height: '100%'}}>
        {
            showSuccess && data && data.created && <SuccessScreen
                caseCount={data.created.length}
                handleCloseSuccess={handleCloseSuccess}
            />
        }
        {
            showError && error && <ErrorScreen
                toggleGuidelineDrawer={toggleGuidelineDrawer}
                handleCloseError={handleCloseError}
                error={error}
            />
        }
        {
            !showSuccess && !showError && <UploadScreen
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                selectedFile={selectedFile}
                isLoading={isLoading}
                toggleGuidelineDrawer={toggleGuidelineDrawer}
                handleSubmitCsvCases={handleSubmitCsvCases}
            />
        }

        <Drawer
            anchor={"right"}
            open={showGuideline}
            onClose={toggleGuidelineDrawer(false)}
        >
            <DrawerHeader/>
            <SubmissionGuidelines caseSpecifications={journey.caseSpecifications} country={journey.country} locationNames={journey.locations.map(location => location.name)}/>
        </Drawer>
    </Grid>
}

export default UploadCasesForm;
