import {useNavigate, useParams} from "react-router-dom";
import {useCaseDetails} from "../../hooks/Cases/useCaseDetails";
import {
    AppBar,
    Button,
    Checkbox,
    FormHelperText,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DocumentImage from "../../components/DocumentImage";
import React, {useContext, useRef, useState} from "react";
import {
    getDisplayDocumentTitleAboveImage,
    getDocumentImageMap
} from "../../utils/CaseUtils/documentsDisplayUtil";
import {DetailsBlock, DetailsFields} from "../../components/CaseDetailsForm/common";
import {formatFullDate} from "../../utils/DateUtils/dateUtils";
import AuthenticationContext from "../../context/AuthenticationContext";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import {PrimaryButton} from "../CreateCase/CreateCaseForm/styles";
import {useCompleteSectionTwo} from "../../hooks/Cases/useMutateCase";
import {ReactComponent as NoButtonIcon} from "../../img/no-button.svg";
import {ReactComponent as YesButtonIcon} from "../../img/yes-button.svg";
import html2canvas from "html2canvas";
import NotificationModal from "../../components/NotificationModal";
import {ReactComponent as ConfirmationIcon} from "../../img/confirmation-icon.svg";
import {useQueryClient} from "@tanstack/react-query";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import {ConsentCheckbox, PrePopulateDataBlock} from "./styles";
import {sectionTwoDataSchema} from "./sectionTwoDataSchema";

const CompleteSectionTwo = ({
                                onExit,
                            }: {
    onExit: () => void;
}) => {
    const {firstname, familyname} = useContext(AuthenticationContext);
    const {caseId}: { caseId: string } = useParams();
    const {data} = useCaseDetails(caseId);
    const cache = useQueryClient();
    const [submitSuccessModalOpen, setSubmitSuccessModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const onSubmitSuccess = () => {
        setSubmitSuccessModalOpen(true);
        setTimeout(function () {
            setSubmitSuccessModalOpen(false);
            cache.invalidateQueries(["cases-details", caseId])
                .then(() => {
                    navigate(`/cases/${caseId}`);
                });
        }, 1000);
    }
    const onSubmitError = () => {
        toast.error("Could not complete section 2");
    }
    const {mutate, isLoading} = useCompleteSectionTwo(caseId, onSubmitSuccess, onSubmitError);
    const [isPrePopulateDataVisible, setPrePopulateDataVisible] = useState(true);
    const signatureRef = useRef(null);

    // eslint-disable-next-line
    const standaloneTransactionsSectionTwo: any =
        // eslint-disable-next-line
        (data?.additionalData?.standaloneTransactions as any)
            ?.Section_2_Documents || [];
    const submittedDocuments = data?.additionalData?.Section_2_Documents ?
        data?.additionalData?.Section_2_Documents[data?.additionalData?.Section_2_Documents.length - 1] : undefined;
    // eslint-disable-next-line
    const documents: { [key: string]: string }[] | undefined = submittedDocuments?.documents;

    const initialDocs: {
        document_title: string;
        document_number: string;
        issuing_authority: string;
        date_of_expiry?: Date | null,
        document_type: string,
        list_type: string
    }[] | undefined = documents?.map(doc => {
            return {
                document_title: '',
                document_number: '',
                issuing_authority: '',
                date_of_expiry: undefined,
                document_type: doc.document_type,
                list_type: doc.list_type
            }
        }
    );
    const {handleSubmit, control, setValue} = useForm({
        resolver: yupResolver(sectionTwoDataSchema),
        mode: 'all',
        defaultValues: {
            title: 'Hiring Manager',
            last_name: familyname,
            first_name: firstname,
            signature: {
                image: ""
            },
            documents: initialDocs,
            consent: undefined
        }
    });

    const  sectionTwoDocumentImages = documents? getDocumentImageMap(documents, standaloneTransactionsSectionTwo) : new Map<string, string[]>();

    const initialSelectedImages = Array.from(sectionTwoDocumentImages?.keys()).map(docKey => sectionTwoDocumentImages?.get(docKey)?.[0]);
    const [selectedImages, setSelectedImages] = useState(initialSelectedImages);

    const generateBase64Image = () => {
        if (signatureRef && signatureRef.current) {
            return html2canvas(signatureRef.current, {scale: 3}).then((canvas) => {
                return canvas.toDataURL("image/jpeg").split(';base64,')[1];
            });
        }
    }

    const onSubmit = async (data: {
        documents?: {
            document_title: string;
            document_number: string;
            issuing_authority: string;
            date_of_expiry?: Date | null;
            list_type: string,
            document_type: string

        }[] | undefined;
        title: string;
        last_name: string;
        first_name: string;
        signature: {
            image: string
        },
    }) => {
        const imageBase64 = await generateBase64Image();
        const documents = data.documents?.map(doc => {
                if (doc.date_of_expiry) {
                    return {
                        ...doc,
                        date_of_expiry: new Date(doc.date_of_expiry).toLocaleDateString("sv-SE") + "T00:00:00.000Z"
                    }
                } else {
                    return {
                        ...doc,
                        date_of_expiry: "N/A"
                    }
                }
            }
        );

        const submittedData = {
            employer_or_authorized_representatives: {
                title: data.title,
                last_name: data.last_name,
                first_name: data.last_name,
                signature: {
                    image: imageBase64 ? imageBase64 : data.signature.image,
                    date: new Date().toLocaleDateString("sv-SE") + "T00:00:00.000Z"
                },
                documents: documents ? documents : []
            }
        };
        mutate(submittedData);
    }

    // eslint-disable-next-line
    const employerData: any = (data?.additionalData.brand_i9_config as GenericJsonObject)?.employer || {
        business_or_org_address: "",
        business_or_org_name: "",
        city_or_town: "",
        state: "",
        title: "",
        zip_code: "",
    };

    return (
        <>
            {isPrePopulateDataVisible &&
                <PrePopulateDataBlock>
                    <Grid container item xs={12} style={{flexGrow: 1}}>
                        <Grid item xs={10}>
                            <Typography sx={{fontSize: "1.1rem !important", fontWeight: "bold"}}>
                                Would you like system gathered ID data to pre-populate Document Details?
                            </Typography>
                            <Typography sx={{fontSize: "0.8rem !important"}}>
                                It’s important that you check each field for accuracy before submitting the Form
                                I-9
                            </Typography>
                        </Grid>
                        <Grid container item xs={2}
                              alignItems={"center"}
                              justifyContent={"flex-end"}>
                            <IconButton onClick={() => setPrePopulateDataVisible(false)}>
                                <NoButtonIcon/>
                            </IconButton>
                            <IconButton onClick={() => {
                                documents?.forEach((d, index) => {
                                    setValue(`documents.${index}.document_title`, d.document_title, {shouldValidate: true});
                                    setValue(`documents.${index}.document_number`, d.document_number, {shouldValidate: true});
                                    setValue(`documents.${index}.issuing_authority`, d.issuing_authority, {shouldValidate: true});
                                    setValue(`documents.${index}.date_of_expiry`,
                                        (!d.date_of_expiry || ["", "n/a", "N/A"].includes(d.date_of_expiry)) ? null : new Date(d.date_of_expiry),
                                        {shouldValidate: true});
                                    setValue(`documents.${index}.document_type`, d.document_type, {shouldValidate: true});
                                    setValue(`documents.${index}.list_type`, d.list_type, {shouldValidate: true});
                                });
                                setPrePopulateDataVisible(false);
                            }
                            }>
                                <YesButtonIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </PrePopulateDataBlock>

            }
            <Grid container paddingRight="10px" paddingLeft="10px">
                <DetailsBlock xs={12} title={"Document Details"}>
                    {Array.from(sectionTwoDocumentImages.entries()).map(
                        (entry, docIndex) => {
                            const [documentType, images] = entry;
                            return (<Grid container item xs={10} key={docIndex} sx={{marginTop: "15px"}}>
                                <Grid item xs={6}>
                                    <Typography>{getDisplayDocumentTitleAboveImage(documents, documentType)}</Typography>
                                    <Stack direction="row" spacing={2} sx={{alignItems: 'flex-start'}}>
                                        {images.map(
                                            (imageUrl: string, index: number) => (
                                                <img
                                                    src={imageUrl}
                                                    key={index}
                                                    aria-label={documentType}
                                                    style={{
                                                        display: "block",
                                                        width: "100px",
                                                        cursor: "pointer",
                                                        borderRadius: "4px",
                                                        padding: "4px",
                                                        border:
                                                            selectedImages[docIndex] === imageUrl ? "2px solid #1CDBBC" : "none",
                                                    }}
                                                    onClick={() => {
                                                        const newSelectedImages = [...selectedImages];
                                                        newSelectedImages [docIndex] = imageUrl;
                                                        setSelectedImages(newSelectedImages);
                                                    }}
                                                />
                                            )
                                        )}
                                    </Stack>
                                    <DocumentImage imageScr={selectedImages[docIndex] || images[0]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DetailsFields
                                        control={control}
                                        keyPrefix={`document-${docIndex}-`}
                                        compactView={true}
                                        items={[
                                            {
                                                label: "Document title",
                                                field: `documents.${docIndex}.document_title`,
                                                editableProps: {type: "text"},
                                            },
                                            {
                                                label: "Issuing authority",
                                                field: `documents.${docIndex}.issuing_authority`,
                                                editableProps: {type: "text"},
                                            },
                                            {
                                                label: "Document number",
                                                field: `documents.${docIndex}.document_number`,
                                                editableProps: {type: "text"},
                                            },
                                            {
                                                label: "Expiration date",
                                                field: `documents.${docIndex}.date_of_expiry`,
                                                editableProps: {type: "date"},
                                            }
                                        ]}
                                    />
                                </Grid>
                            </Grid>);
                        }
                    )}
                </DetailsBlock>
                <DetailsBlock xs={10} title={"Employment Date"} borderBottom>
                    <DetailsFields
                        keyPrefix={`emp-${0}-`}
                        items={[
                            {
                                label: "First Date of Employment",
                                value: formatFullDate(`${data?.startDate}`)
                            }
                        ]}
                    />
                </DetailsBlock>
                <DetailsBlock xs={10} title={"Employer of Authorized Representative’s Details"} borderBottom>
                    <Typography>If you are completing Section 2, it must be your name & title that is input
                        here</Typography>
                    <DetailsFields
                        control={control}
                        keyPrefix={"rep-"}
                        items={[
                            {
                                label: "Title of employer",
                                field: "title",
                                editableProps: {type: "text"}
                            },
                            {
                                label: "Given name",
                                field: "first_name",
                                editableProps: {type: "text"}
                            },
                            {
                                label: "Family name",
                                field: "last_name",
                                editableProps: {type: "text"}
                            },
                            {
                                label: "Employer's Business or Organization Name",
                                value: `${employerData.business_or_org_name}`
                            },
                            {
                                label: "Employer's Business or Organization Address",
                                value: `${employerData.business_or_org_address}`
                            },
                            {
                                label: "City or Town",
                                value: `${employerData.city_or_town}`
                            },
                            {
                                label: "State",
                                value: `${employerData.state}`,
                            },
                            {
                                label: "Zip Code",
                                value: `${employerData.zip_code}`,
                            },
                        ]}
                    />
                </DetailsBlock>
                <DetailsBlock xs={10} title={"Signature of Employer or Authorized Representative"}>
                    <Typography sx={{marginBottom: "10px"}}>
                        I attest, under penalty of perjury, that (1) I have examined the documentation presented
                        by the
                        above-named employee, (2) the above-listed documentation appears to be genuine and to
                        relate to
                        the employee named, and (3) to the best of my knowledge, the employee is authorized to
                        work in
                        the United States.
                    </Typography>
                    <Controller
                        // eslint-disable-next-line
                        //@ts-ignore
                        name="signature.image"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field,
                                     fieldState: {error},
                                 }) => (
                            <>
                                <TextField
                                    {...field}
                                    sx={{width: "350px"}}
                                    size={"small"}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                                <Grid item xs={10}>
                                    <div
                                        aria-label="signature"
                                        style={{
                                            display: "inline-block",
                                            marginTop: "15px",
                                            borderRadius: "4px",
                                            border: "1px solid #D7D7D7",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <div
                                            ref={signatureRef}
                                            style={{
                                                display: "inline-block",
                                                fontFamily: "TuesdayNight",
                                                fontSize: "52px",
                                                marginTop: "15px",
                                                width: "350px",
                                                height: "100px",
                                                textAlign: "center",
                                                lineHeight: "100px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden"
                                            }}
                                        > {field.value}
                                        </div>
                                    </div>
                                </Grid></>
                        )}
                    />
                    <Controller
                        // eslint-disable-next-line
                        //@ts-ignore
                        name="consent"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field,
                                     fieldState: {error},
                                 }) => (
                            <ConsentCheckbox
                                control={<><Checkbox {...field}/>{!!error &&
                                    <FormHelperText error>{error.message}</FormHelperText>}</>}
                                label="I agree to sign electronically and acknowledge and agree that your electronic signature is
                        legally equivalent to a handwritten signature and that it binds you to the same extent as a
                        handwritten signature would."/>
                        )}
                    />
                </DetailsBlock>
            </Grid>
            <AppBar
                position="fixed"
                sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    top: "auto",
                    bottom: 0,
                    background: "white",
                }}
            >
                <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                    <Grid item xs={8}></Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Button sx={{textDecoration: "underline", textTransform: "none"}}
                                onClick={() => onExit()}>
                            Exit, no decision
                        </Button>
                        <PrimaryButton
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading}
                        >
                            Submit
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </AppBar>
            <NotificationModal
                isOpen={submitSuccessModalOpen}
                text="Form I-9 is now complete"
                icon={<ConfirmationIcon/>}
            />

        </>
    )
};

export default CompleteSectionTwo;
