export class TemplatingUtils {
    static SELECTED_DOCUMENTS_PATH = "${additionalData.selectedDocuments}";
    static SHARECODE_UPLOAD_MESSAGE_PATH = "${sharecodeUploadPdfMessage}";
    static SHARECODE_UPLOAD_MESSAGE = " Validate the provided SHARE CODE on gov.uk (www.gov.uk/view-right-to-work), save and upload the report. ";
    static SHARECODE_UPLOAD_MESSAGE_ALT = " The documents will require approval via an in-person inspection. ";
    static SHARECODE_CHECK_MESSAGE_PATH = "${sharecodeCheckPdfMessage}";
    static SHARECODE_CHECK_MESSAGE = "approved the Share Code PDF Report and reviewed";
    static SHARECODE_CHECK_MESSAGE_ALT = "physically seen";

    static removeFrontBackInDocumentName(selectedDocuments: string[]): string[] {
        const uniqueDocuments: string[] = [];
        selectedDocuments.map(doc =>
            doc.replace("/FRONT", "").replace("/BACK", "")
                .replace("/front", "").replace("/back", ""))
            .forEach((element) => {
                if (!uniqueDocuments.includes(element)) {
                    uniqueDocuments.push(element);
                }
            });
        return uniqueDocuments;
    }

    static templateSelectedDocuments(textToTemplate: string, selectedDocuments: string[]): string {
        const documents = TemplatingUtils.removeFrontBackInDocumentName(selectedDocuments);
        return textToTemplate.replaceAll(
            this.SELECTED_DOCUMENTS_PATH,
            documents.length < 3 ?
                documents.join(" and ") :
                (documents.slice(0, -1).join(", ") + ", and " + documents.slice(-1)))
    }

    static templateShareCodeUploadMessage(textToTemplate: string, shareCode: string | undefined): string {
        return shareCode ? textToTemplate.replaceAll(
            this.SHARECODE_UPLOAD_MESSAGE_PATH,
            this.SHARECODE_UPLOAD_MESSAGE
        ) : textToTemplate.replaceAll(
            this.SHARECODE_UPLOAD_MESSAGE_PATH,
            this.SHARECODE_UPLOAD_MESSAGE_ALT
        );
    }

    static templateShareCodeCheckMessage(textToTemplate: string, shareCode: string | undefined): string {
        return shareCode ? textToTemplate.replaceAll(
            this.SHARECODE_CHECK_MESSAGE_PATH,
            this.SHARECODE_CHECK_MESSAGE
        ) : textToTemplate.replaceAll(
            this.SHARECODE_CHECK_MESSAGE_PATH,
            this.SHARECODE_CHECK_MESSAGE_ALT
        );
    }
}


