import { useState, useContext } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { FeatureFlags } from "../../context/FeatureFlags";

import { FaqItem, FaqQuestion, FaqAnswer, MoreQuestionsBox } from "./styles";
import HeaderBar from "../../components/HeaderBar";
import { FaqQuestions } from "./FaqQuestions";

const FAQ = () => {
  const { features } = useContext(FeatureFlags);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  if (!features.isI9v2)
    return (
      <HeaderBar
        breadcrumbs={[
          {
            label: "SUPPORT / FAQs",
          },
        ]}
      >
        <Typography color={"error"} padding={1}>
          You are not authorized to access this page.
        </Typography>
      </HeaderBar>
    );

  return (
    <HeaderBar
      breadcrumbs={[
        {
          label: "SUPPORT / FAQs",
        },
      ]}
    >
      <Grid sx={{ flexGrow: 1, p: 3 }}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <h1 style={{ margin: 0 }}>Frequently Asked Questions</h1>
            <p style={{ margin: 0 }}>Have a question? See our FAQs</p>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ marginTop: 5 }}>
          <Grid item xs={12}>
            {FaqQuestions.map((question, index) => (
              <FaqItem
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <FaqQuestion
                  expandIcon={
                    expanded === `panel${index}` ? <Remove /> : <Add />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{question.question}</Typography>
                </FaqQuestion>
                <FaqAnswer>
                  <Typography>{question.answer}</Typography>
                </FaqAnswer>
              </FaqItem>
            ))}
          </Grid>
        </Grid>
        <MoreQuestionsBox container item xs={12}>
          <Grid item xs={9}>
            <h3>Still have questions?</h3>
            <p>
              Can’t find the answer your looking for? Contact our team for help?
            </p>
          </Grid>
          <Grid item xs={3}>
            <Button href="mailto:support@paycasso.com">Support Request</Button>
          </Grid>
        </MoreQuestionsBox>
      </Grid>
    </HeaderBar>
  );
};

export default FAQ;
