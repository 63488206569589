export type CaseActivityLogResponse = {
    caseId: string;
    type: string;
    displayTime: string;
    timestamp: string;
    text: string;
};

export const sortCaseActivityLogResponse = (a: CaseActivityLogResponse, b: CaseActivityLogResponse) => {
    if (!a.timestamp && !b.timestamp) {
        return 0;
    } else if (!a.timestamp) {
        return -1;
    } else if (!b.timestamp) {
        return 1;
    } else {
        return a.timestamp ? 1 : b.timestamp ? -1 : 0;
    }
}
