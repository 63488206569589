import {useContext, useState} from "react";
import { useLocation } from "../../../hooks/Brand/useLocation";
import { LinearProgress } from "@mui/material";

import { Stack } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import {
  DashboardMapContainer,
  LocationList,
  MoreLocationsButton,
} from "./styles";

import MapIcon from "../../../img/map-pin.png";
import JourneyContext from "../../../context/JourneyContext";
const iconSize = [32, 32];

const LocationMap = () => {
  const { currentJourney } = useContext(JourneyContext);
  const { locationCoordinates: coordinates, middleCoordinate } = useLocation(
      currentJourney?.locations
  );

  const [isLocationListActive, setLocationListActive] = useState(false);
  const locations = currentJourney?.locations;

  const customIcon = L.icon({
    iconUrl: MapIcon,
    iconAnchor: [iconSize[0] / 2, iconSize[1]],
  });

  if (!coordinates || coordinates.length === 0) return <LinearProgress />;

  return (
    <DashboardMapContainer>
      {!isLocationListActive && (
        <MapContainer
          center={
            coordinates.length > 1
              ? middleCoordinate
                ? [middleCoordinate.lat, middleCoordinate.lon]
                : [0, 0]
              : [coordinates[0].lat, coordinates[0].lon]
          }
          zoom={coordinates.length === 1 ? 8 : 2}
          style={{
            height: "255px",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {coordinates &&
            coordinates.map((coordinate, index) => (
              <Marker
                key={index}
                position={[coordinate.lat, coordinate.lon]}
                icon={customIcon}
              >
                <Popup>{coordinate.name}</Popup>
              </Marker>
            ))}
        </MapContainer>
      )}
      <div style={{ padding: "0 0 0 20px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h3>Locations</h3>
          <div>
            <p>
              {locations && locations?.length > 0
                ? `${locations?.length} locations`
                : `${locations && locations[0]?.street}, ${
                      locations && locations[0]?.cityTown
                  }, ${locations && locations[0]?.state}`}
            </p>
            {locations && (
              <MoreLocationsButton
                onClick={() => setLocationListActive(!isLocationListActive)}
                style={
                  isLocationListActive
                    ? { borderTopRightRadius: "16px" }
                    : { borderBottomRightRadius: "16px" }
                }
              >
                {isLocationListActive ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </MoreLocationsButton>
            )}
          </div>
        </Stack>
      </div>
      {isLocationListActive && (
        <LocationList>
          {locations &&
            locations.map((location, index) => (
              <p key={index}>{`${index + 1}. ${location.street}, ${
                location.cityTown
              }, ${location.state}`}</p>
            ))}
        </LocationList>
      )}
    </DashboardMapContainer>
  );
};

export default LocationMap;
