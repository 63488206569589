import { useMutation } from "@tanstack/react-query";
import { CreateAuditorRequest } from "../../models/requests/CreateAuditorRequest";
import axios from "axios";
import { useAxiosParams } from "../common/useAxiosParams";
import { useBrand } from "../Brand/useBrand";

type useCreateAuditorProps = {
  onSuccess: () => void;
  onError: () => void;
};

export const useCreateAuditor = ({
  onSuccess,
  onError,
}: useCreateAuditorProps) => {
  const { data: brand } = useBrand();
  const { orchestratorPath, config } = useAxiosParams();

  const createAuditorUrl = `${orchestratorPath}/brands/${brand?.id}/auditor`;

  return useMutation({
    mutationFn: async (createAuditorRequest: CreateAuditorRequest) => {
      const response = await axios.post(
        createAuditorUrl,
        createAuditorRequest,
        config
      );
      return response.data;
    },
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      console.error(error);
      onError();
    },
  });
};
