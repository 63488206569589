import { useContext, useState } from "react";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  IconButton,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import {
  ArrowForwardIos,
  LogoutOutlined,
  UnfoldMore,
  SettingsOutlined,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { AccountPopover } from "../styles";

import { FeatureFlags } from "../../../context/FeatureFlags";
import AuthenticationContext from "../../../context/AuthenticationContext";
import AuthenticationService from "../../../services/AuthenticationService/AuthenticationService";
import AccountSettingsModal from "../AccountSettingsModal";

type UserAccountProps = {
  isSidebarOpen: boolean;
  isPopoverOpen: boolean;
  onClose: () => void;
};

const UserAccount = ({
  isSidebarOpen,
  isPopoverOpen,
  onClose,
}: UserAccountProps) => {
  const [isAccountSettinsModalOpen, setAccountSettingsModalOpen] =
    useState(false);

  const { features } = useContext(FeatureFlags);
  const {
    username,
    initials,
    auditRole,
    firstname,
    familyname,
    email,
  } = useContext(AuthenticationContext);
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        item
        flexDirection={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          position: "relative",
          maxWidth: "260px",
        }}
      >
        <AccountPopover open={isPopoverOpen} onClose={onClose}>
          <List>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="settings">
                  <ArrowForwardIos />
                </IconButton>
              }
              onClick={() =>
                setAccountSettingsModalOpen(!isAccountSettinsModalOpen)
              }
            >
              <ListItemAvatar>
                <SettingsOutlined />
              </ListItemAvatar>
              <ListItemText primary="Account settings" />
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <ArrowForwardIos />
                </IconButton>
              }
              onClick={() => AuthenticationService.logout(auditRole)}
            >
              <ListItemAvatar>
                <LogoutOutlined />
              </ListItemAvatar>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </AccountPopover>
        <Grid item xs={isSidebarOpen ? 2.5 : 12}>
          <Avatar
            sx={{
              width: 30,
              height: 30,
              fontSize: "14px",
              bgcolor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
            }}
          >
            {initials}
          </Avatar>
        </Grid>
        {isSidebarOpen && (
          <Grid container item xs={7} flexDirection={"column"}>
            <Grid item sx={{ fontSize: 14, whiteSpace: 'break-spaces' }}>
              {username}
            </Grid>
            {features.isI9v2 && (
              <Grid item sx={{ fontSize: 10 }}>
                General User
              </Grid>
            )}
          </Grid>
        )}
        {isSidebarOpen && (
          <Grid item xs={2.5}>
            <IconButton onClick={onClose}>
              <UnfoldMore fontSize={"medium"} sx={{ color: "#ffffff" }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <AccountSettingsModal
        isOpen={isAccountSettinsModalOpen}
        onClose={() => setAccountSettingsModalOpen(!isAccountSettinsModalOpen)}
        userData={{ firstname, familyname, email }}
        onRequestPasswordChange={() => AuthenticationService.changePassword()}
      />
    </>
  );
};

export default UserAccount;
