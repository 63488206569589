import React, {useCallback, useContext, useMemo, useState} from "react";
import {Grid} from "@mui/material";
import {formatDate, formatNonUSDate, formatTime} from "../../utils/DateUtils/dateUtils";
import VirtualizedTable, {VirtualizedTableSort,} from "../../components/common/VirtualizedTable";
import {useAuditLogList} from "../../hooks/Audit/useAuditLogList";
import JourneyContext from "../../context/JourneyContext";

const AuditLogList = ({caseId}: { caseId: string}) => {
    const {data: auditLogs} = useAuditLogList(caseId);
    const [auditLogsSort, setAuditLogsSort] = useState<VirtualizedTableSort>({
        key: "createdAt",
        direction: "DESC"
    });

    const { currentJourney } = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");
    
    const handleAuditLogsSort = useCallback((header: string) => {
        if (header === auditLogsSort.key) {
            setAuditLogsSort({
                key: header,
                direction: auditLogsSort.direction === "ASC" ? "DESC" : "ASC"
            })
        } else {
            setAuditLogsSort({
                key: header,
                direction: "ASC"
            })
        }
    }, [auditLogsSort]);

    const auditLogsSorted = useMemo(() => {
        const direction = (auditLogsSort.direction === "ASC") ? 1 : -1;

        return auditLogs?.sort(
            (a, b) => {
                // eslint-disable-next-line
                // @ts-ignore
                return (a[auditLogsSort.key] > b[auditLogsSort.key] ? 1 : -1) * direction
            }
        );
    }, [auditLogs, auditLogsSort])

    return (<Grid container sx={{height: "100%", width: "100%", padding: 3}}>
            {auditLogsSorted && <VirtualizedTable
                handleSort={handleAuditLogsSort}
                sorting={auditLogsSort}
                rowCount={auditLogsSorted.length}
                rowGetter={({index}) => auditLogsSorted[index]}
                columns={[
                    {
                        width: 150,
                        label: 'Date',
                        dataKey: 'timestamp',
                        dataGetter: ({rowData: auditLog}) =>  isI9 ? formatDate(auditLog.timestamp || "") : formatNonUSDate(auditLog.timestamp || "")
                    },
                    {
                        width: 100,
                        label: 'Time',
                        dataKey: 'timestamp',
                        dataGetter: ({rowData: auditLog}) => formatTime(auditLog.timestamp || "")
                    },
                    {
                        width: 150,
                        label: 'Action Type',
                        dataKey: 'title',
                    },
                    {
                        width: 380,
                        label: 'Description',
                        dataKey: 'description',
                    },
                    {
                        width: 160,
                        label: 'Performed by',
                        dataKey: 'performedBy',
                    },
                    {
                        width: 250,
                        label: 'Name',
                        dataKey: 'performedByName',
                    }
                ]}
            />
            }
        </Grid>
    )
};

export default AuditLogList;
