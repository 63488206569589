type documentListTypeProps = {
    text: string;
};

const documentListTypes: Record<string, documentListTypeProps> = {
    LIST_A: {
        text: 'List A'
    },
    LIST_B: {
        text: 'List B'
    },
    LIST_C: {
        text: 'List C'
    }
}

export const getDisplayDocumentListType = (type: string): string | undefined => {
    return documentListTypes[type]?.text;
}