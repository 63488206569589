import {
    Dialog,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import {RejectModalContent} from "./styles";
import {
    PrimaryButton,
    SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";
import {useReject} from "../../../hooks/Cases/useMutateCase";
import {useState} from "react";
import {
    InteractiveEvent
} from "../../../models/responses/Cases/CaseSpecifications/CaseInteractiveEvents/InteractiveEvent";
import {toast} from "react-toastify";

type ReinviteModalProps = {
    isOpen: boolean;
    onClose: () => void;
    caseId: string;
    interactiveEvent?: InteractiveEvent
};

const RejectCaseModal = ({isOpen, onClose, caseId, interactiveEvent}: ReinviteModalProps) => {

    const [rejectReason, setRejectReasons] = useState("");
    const successRejectedMessage = interactiveEvent?.screens.find(screen => screen.type === "success")?.title || "This case has been rejected.";
    const {mutate, isLoading} = useReject(caseId, (callback: () => void) => {
        toast.success(successRejectedMessage);
        callback();
    });

    const handleRejection = () => {
        mutate(rejectReason);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"} fullWidth>
            <DialogTitle sx={{fontWeight: "bold"}}>Rejection Reason</DialogTitle>
            <RejectModalContent>
                <p className="divider">
                    Rejecting this case will move the candidate’s file into ‘Archived: Rejected’ status. Use the free
                    text box below to outline the rejection reason.
                </p>
                <Typography component={"h2"}>Reason:*</Typography>
                <Grid
                    container
                    alignItems={"center"}
                    sx={{marginTop: "10px", padding: 0}}
                >
                    <Grid container item xs={12} style={{flexGrow: 1, padding: 0}}>
                        <Grid container item xs={12} alignItems={"center"}>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                onChange={(e) => setRejectReasons(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        style={{flexGrow: 1, padding: 1, marginTop: "30px"}}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <SecondaryButton variant="contained" onClick={onClose}>
                                Exit, no decision
                            </SecondaryButton>
                            <PrimaryButton disabled={isLoading || !rejectReason} onClick={handleRejection}>
                                Confirm Rejection
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </RejectModalContent>
        </Dialog>
    );
};

export default RejectCaseModal;
