import { ListItemButton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SummaryListButton = styled(ListItemButton)(() => ({
  width: "100%",
  display: "block",
  padding: "0px",
  borderRadius: "8px",
}));

export const SummaryStack = styled(Stack)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #D7D7D7",
  padding: "7px 5px",
  "&:last-child": {
    borderBottom: "none",
  },
  p: {
    fontSize: "14px",
    margin: 0,
  },
  ".MuiSvgIcon-root": {
    fontSize: "12px",
    margin: "4px 0px 0px 10px",
  },
  ".MuiChip-outlined": {
    height: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    span: {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "&.Section_1": {
      background: "#90EBBF",
    },
    "&.Section_2": {
      background: "#EBE790",
    },
    "&.SECTION_2_COMPLETE": {
      background: "#C6B7F2",
    },
    "&.Section_3": {
      background: "#C6B7F2",
    },
    "&.E_Verify": {
      background: "#98DBFA",
    },
    "&.Failed": {
      background: "#E58181",
    },
    "&.Active": {
      background: "#D7D7D7",
    },
    "&.ARCHIVED": {
      background: "#E58181",
    },
    "&.FAILED": {
      background: "#E58181",
    },
  },
}));
