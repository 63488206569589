import HeaderBar from "../../components/HeaderBar";
import {useParams} from "react-router-dom";
import {useCaseDetails} from "../../hooks/Cases/useCaseDetails";
import {LinearProgress, Typography} from "@mui/material";
import CaseDetailsForm from "../../components/CaseDetailsForm";
import {useContext, useEffect, useState} from "react";
import JourneyContext from "../../context/JourneyContext";

const CaseDetails = () => {
    const {caseId}: { caseId: string } = useParams();
    const {data, error, isSuccess, isLoading, isError} = useCaseDetails(caseId);
    const [i9PdfFile, setI9PdfFile] = useState<Blob | undefined>(undefined);
    const [shareCodePdfFile, setShareCodePdfFile] = useState<Blob | undefined>(undefined);

    const { currentJourney } = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");
    const isPrescient = currentJourney?.journeyType === "PRESCIENT";

    useEffect(() => {
        if (data?.additionalData["i9Pdf"]) {
            fetch(data?.additionalData["i9Pdf"] as string, {
                headers: {'Accept': 'application/pdf'}
            })
                .then(response => {
                    if (response.ok) {
                        return response.arrayBuffer();
                    }
                })
                .then(arrayBuffer => {
                    if (arrayBuffer) {
                        setI9PdfFile(new Blob([arrayBuffer], {type: 'application/pdf'}));
                    }
                })
        } else {
            setI9PdfFile(undefined);
        }
        if (data?.additionalData["shareCodePdf"]) {
            fetch(data?.additionalData["shareCodePdf"] as string, {
                headers: {'Accept': 'application/pdf'}
            })
                .then(response => {
                    if (response.ok) {
                        return response.arrayBuffer();
                    }
                })
                .then(arrayBuffer => {
                    if (arrayBuffer) {
                        setShareCodePdfFile(new Blob([arrayBuffer], {type: 'application/pdf'}));
                    }
                })
        } else {
            setShareCodePdfFile(undefined);
        }
    }, [data])

    return (
        <HeaderBar
            breadcrumbs={[
                {
                    label: isI9 ? "I-9 Cases" : (isPrescient? "myKYC Cases" : "myRTW Cases"),
                    path: "/cases"
                },
                ...(data ? [{label: `${data.givenName} ${data.familyName}`}] : []),
                {
                    label: caseId,
                },
            ]}
        >
            {
                isLoading && <LinearProgress/>
            }
            {
                isError && <Typography color={"error"} padding={1} > { error?.message as string } </Typography>
            }
            {
                isSuccess && data && <CaseDetailsForm emsCase={data}
                                                      i9PdfFile={i9PdfFile}
                                                      shareCodePdfFile={shareCodePdfFile}
                />
            }
        </HeaderBar>
    );
};

export default CaseDetails;
