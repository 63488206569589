import {useMutation, useQueryClient} from "@tanstack/react-query";
import {CreateCaseRequest} from "../../models/requests/CreateCaseRequest";
import axios from "axios";
import {useAxiosParams} from "../common/useAxiosParams";

type useCreateCaseProps = {
    onSuccess: ()=>void;
    onError: ()=>void;
}

export const useCreateCase = ({onSuccess, onError} : useCreateCaseProps) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (createCaseRequest : CreateCaseRequest) => {
            const response = await axios.post(basePath, createCaseRequest, config);
            await cache.invalidateQueries(["cases", createCaseRequest.brandId]);
            return response.data;
        },
        onSuccess: () => {
            onSuccess();
        },
        onError: (error) => {
            console.error(error);
            onError();
        }
    });
}
