import {useContext, useEffect, useState} from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import HeaderBar from "../../components/HeaderBar";
import CreateCaseForm from "./CreateCaseForm";
import UploadCasesForm from "../../components/UploadCasesForm";
import {
  CreateCaseFormFields,
  CreateRtwCaseFormFields,
  CreateI9CaseFormFields,
  CreateI9AltCaseFormFields
} from "./CreateCaseForm/CreateCaseFormFields";
import { CreateCaseButtonSwitch } from "./styles";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import JourneyContext from "../../context/JourneyContext";

const CreateCase = () => {
  const location = useLocation();
  const theme = useTheme();

  const [caseCreateType, setCaseCreateType] = useState<"single" | "batch">(
    location?.state?.caseCreateType || "single"
  );

  const { currentJourney : journey } = useContext(JourneyContext);

  const journeyError: string | false = !journey &&
    "No journey configured for your account. Please contact the system administrator.";

  useEffect(() => {
    location?.state?.caseCreateType &&
      setCaseCreateType(location.state.caseCreateType);
  }, [location]);

  if (journeyError)
    return (
      <Typography color={"error"} padding={1}>
        {" "}
        {journeyError}{" "}
      </Typography>
    );

  const getFields = () => {
    switch (journey?.journeyType) {
      case 'I9':
      case 'I9_ALT':
        return CreateI9CaseFormFields;
      case 'I9_AUTH_REP':
        return CreateI9AltCaseFormFields;
      case 'UK_RTW':
      case 'IRL_RTW':
        return CreateRtwCaseFormFields;
      default:
        return CreateCaseFormFields;
    }
  }

  return (
    <HeaderBar
      breadcrumbs={[
        {
          label: "Create a Case / Upload Cases",
        },
      ]}
    >
      <Grid sx={{ flexGrow: 1, p: 3 }}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex" }}>
              <CreateCaseButtonSwitch>
                <Box
                  className="mask"
                  style={{
                    transform: `translateX(${
                      caseCreateType === "single" ? 0 : "120px"
                    })`,
                  }}
                />
                <Button
                  disableRipple
                  variant="text"
                  sx={{
                    color:
                      caseCreateType === "single"
                        ? "#1A1A1A"
                        : theme.palette.primary.main,
                  }}
                  onClick={() => setCaseCreateType("single")}
                >
                  Individual
                </Button>
                <Button
                  disableRipple
                  variant="text"
                  sx={{
                    color:
                      caseCreateType === "batch"
                        ? "#1A1A1A"
                        : theme.palette.primary.main,
                  }}
                  onClick={() => setCaseCreateType("batch")}
                >
                  Batch
                </Button>
              </CreateCaseButtonSwitch>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ marginBottom: "100px" }}>
          {caseCreateType === "single" && journey && (
            <CreateCaseForm fields={getFields()} journey={journey} />
          )}
          {caseCreateType === "batch" && journey && (
            <UploadCasesForm journey={journey} />
          )}
        </Grid>
      </Grid>
    </HeaderBar>
  );
};

export default CreateCase;
