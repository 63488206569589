import { Typography } from "@mui/material";
import { SectionRTWDataWidgetBox } from "./styles";
import SectionDataListItem from "../SectionsDataWidget/SectionDataListItem";
import { useContext } from "react";
import AuthenticationContext from "../../../context/AuthenticationContext";
import JourneyContext from "../../../context/JourneyContext";
import { useCaseList } from "../../../hooks/Cases/useCaseList";

type tileProps = {
  label: string;
  icon?: string;
  color?: string;
  filter: string[];
  type: string;
  daysNumber: number[];
  timestamp: string;
};

type SectionRTWDataWidgetProps = {
  tile: tileProps;
  size?: number;
  height?: string;
}

const SectionRTWDataWidget = ({ height, size, tile }: SectionRTWDataWidgetProps) => {

  const { defaultBrandId } = useContext(AuthenticationContext);
  const { currentJourney } = useContext(JourneyContext);
  const { rtwCaseCount } = useCaseList(currentJourney?.brandId || defaultBrandId);

  return (
    <SectionRTWDataWidgetBox item xs={size || 12} sx={{height: height}}>
      <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center'}}>{tile.icon ? <div style={{ borderRadius: '100px', padding: 6, backgroundColor: tile.color, width: '30px', height: '30px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center',marginRight: '10px'}}>
        <img
                    style={{
                      minWidth: 0,
                      justifyContent: "center",
                    }}
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(tile.icon)}`}
                  />
      </div> : ''}{tile.label}</Typography>
      {tile.daysNumber.map((day, index) => (
        <SectionDataListItem key={index} section={tile.filter[0] as string} count={rtwCaseCount(day, tile.filter[0] as string, tile.timestamp, tile.type)} text={tile.type === "DUE_IN" ? `Due in ${day} days` : `Last ${day} days`} icon={index === 0 && !['Failed', 'Cancelled'].includes(tile.label)} color={tile.color} />
      ))}
    </SectionRTWDataWidgetBox>
  );
};

export default SectionRTWDataWidget;
