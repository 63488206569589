import { styled, CSSObject } from "@mui/material/styles";
import { Button, ButtonProps } from "@mui/material";

export const ConfirmButton = styled(Button)<ButtonProps>(
    ({ theme }): CSSObject => ({
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main,
      textTransform: "none",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "10px",
      fontSize: "12px",
      padding: "5px 15px",
      margin: "5px 0px 5px 0px",
      'svg': {
        fontSize: '10px',
        marginLeft: '10px',
      },
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
      },
      "&.Mui-disabled": {
        background: "#cecece",
        border: `1px solid #cecece`,
      },
    })
  );
  