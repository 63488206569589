import React, {FC} from "react";
import {GenericJsonObject} from "../../../models/responses/Cases/CaseResponse";
import {CaseTabContext} from "../../../pages/CaseDetails/useCaseControl.hook";
import {Grid, Typography} from "@mui/material";
import {DetailsBlock, DetailsFields, StatusLabel} from "../common";
import I9Form from "../I9Form";
import {getDisplayDocumentTitleAboveImage, getDocumentImages} from "../../../utils/CaseUtils/documentsDisplayUtil";
import DocumentImage from "../../DocumentImage";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const SectionThreePanel: FC<{
    data: GenericJsonObject,
    context?: CaseTabContext,
    i9PdfFile?: Blob | undefined,
    images?: GenericJsonObject,
    handleSubmitSuccess?: (caseId: string) => void,
    handleCompare?: (e?: string) => void
}> =
    ({data, context, i9PdfFile, images, handleCompare}) => {
        // eslint-disable-next-line
        const employeeData: any = (data.reverification as any).employee || {
            "last_name": "",
            "first_name": "",
            "middle_initial": "",
        };
        // eslint-disable-next-line
        const rehireDate = (data.reverification as any).rehire_date;
        // eslint-disable-next-line
        const reviewerData: any = (data.reverification as any).reviewer;
        // eslint-disable-next-line
        const documents: { [key: string]: string }[] = (data.reverification as any).documents;

        // eslint-disable-next-line
        const standaloneTransactionsSectionThree: any = (images?.standaloneTransactions as any)?.Section_3 || [];

        const sectionThreeDocumentImages: { title: string, image: string }[]  = getDocumentImages(documents, standaloneTransactionsSectionThree);

        const handleCompareButtonClick = () => {
            handleCompare && handleCompare(context?.compareSection)
        }

        try {
            return <Grid container p={2} alignItems={"flex-start"}>
                <Grid container flexDirection={"row"} item xs={12}>
                    <DetailsBlock xs={12} borderBottom>
                        <Grid container item xs={12} style={{fontWeight: 600, marginBottom: 10,}}>
                            <Grid item xs={8} style={{fontWeight: 600}}>Employee Details</Grid>
                            <Grid container item xs={4} alignItems={"center"} justifyContent={"flex-end"}>
                                <StatusLabel
                                    backgroundColor={"rgba(133, 232, 139, 0.3)"}
                                    borderColor={"#85E88B"}
                                    label={"Completed"}
                                />
                            </Grid>
                        </Grid>

                        <DetailsFields
                            keyPrefix={"candidate-"}
                            items={[
                                {
                                    label: "Last name (Family name)",
                                    value: `${employeeData.last_name}`
                                },
                                {
                                    label: "First name (Given name)",
                                    value: `${employeeData.first_name}`
                                },
                                {
                                    label: "Middle Initial",
                                    value: `${employeeData.middle_initial}`,
                                },
                                {
                                    label: "Date of Rehire (if applicable)",
                                    value: rehireDate ? dayjs.utc(rehireDate).format('MM/DD/YYYY') : '',
                                }
                            ]}
                        />
                    </DetailsBlock>

                    {
                        documents.map((document, key) => <DetailsBlock xs={12}
                                                                       title={"Employee Document Details" + ((documents.length > 1) && `: Item ${key + 1}`)}
                                                                       borderBottom
                                                                       key={`document-details-${key}`}>
                            <DetailsFields
                                keyPrefix={`document-${key}-`}
                                items={[
                                    {
                                        label: "Document Title",
                                        value: `${document.document_title}`,
                                    },
                                    {
                                        label: "Document Number",
                                        value: `${document.document_number}`,
                                    },
                                    {
                                        label: "Expiration Date (if any)",
                                        value: document.date_of_expiry && document.date_of_expiry !== '' ? dayjs.utc(`${document.date_of_expiry}`).format('MM/DD/YYYY') : '',
                                    },
                                ]}
                            />
                        </DetailsBlock>)
                    }

                    <DetailsBlock xs={12} title={"Employer or Authorized Representative Details"} borderBottom>
                        <DetailsFields
                            keyPrefix={"rep-"}
                            items={[
                                {
                                    label: "Signature of Employer or Authorized Rep",
                                    value: `${reviewerData.signature.image}`,
                                    image: reviewerData.signature.imageQuery,
                                },
                                {
                                    label: "Date",
                                    value: reviewerData.signature.date ? dayjs.utc(`${reviewerData.signature.date}`).format('MM/DD/YYYY') : ''
                                },
                                {
                                    label: "Name of Employer or Authorized Rep",
                                    value: `${reviewerData.full_name}`,
                                }
                            ]}
                        />
                    </DetailsBlock>
                    <DetailsBlock xs={12} borderBottom title="Documents" compareButton
                                  compareButtonClick={handleCompareButtonClick}>
                        <Grid container spacing={1}>
                            <Grid item container xs={5}>
                                {sectionThreeDocumentImages.map((item: { title: string, image: string }, index: number) => (
                                    <DocumentImage key={index}
                                                   imageName={getDisplayDocumentTitleAboveImage(documents, item.title)}
                                                   imageScr={item.image}/>
                                ))}
                            </Grid>
                            <Grid item container xs={7}>
                                <I9Form i9PdfFile={i9PdfFile}/>
                            </Grid>
                        </Grid>
                    </DetailsBlock>
                </Grid>
            </Grid>
        } catch (e) {
            console.error(e)
            return <Typography color={"error"} padding={1}> Unexpected data received for Section 2 </Typography>
        }
    }
