import { useContext } from "react";
import { Avatar, Box, Grid, IconButton, Toolbar } from "@mui/material";
import { AppBar } from "./styles";
import { ReactComponent as HeaderLogo } from "../../img/xydus_logo.svg";
import LogoutIcon from "@mui/icons-material/Logout";

import AuthenticationContext from "../../context/AuthenticationContext";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";

const AuditorHeaderBar = ({ children }: { children: React.ReactNode }) => {
  const { username, initials, auditRole, auditReference } = useContext(
    AuthenticationContext
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
          background: '#000 url("https://app-icons-fonts-pdf.s3.eu-west-2.amazonaws.com/Xydus/background_image_vialto.png")',
          color: "#fff",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <p>{auditRole === "internal_auditor" ? "INTERNAL" : "ICE"} AUDIT: {auditReference}</p>
          <HeaderLogo
            style={{
              minWidth: 40,
              minHeight: 40,
              background: "#fff",
              padding: "5px",
              borderRadius: "100px",
              margin: "0px auto",
            }}
          />
          <Grid
            container
            item
            flexDirection={"row"}
            alignItems={"center"}
            spacing={1}
            sx={{
              marginLeft: 1,
              marginRight: 1,
              position: "relative",
              maxWidth: "260px",
            }}
          >
            <Grid item xs={2.5}>
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  fontSize: "14px",
                  bgcolor: "#fff",
                  color: '#000'
                }}
              >
                {initials}
              </Avatar>
            </Grid>
            <Grid container item xs={5} flexDirection={"column"}>
              <Grid item sx={{ fontSize: 14 }}>
                {username}
              </Grid>
            </Grid>
            <Grid item xs={1.5}>
              <IconButton
                onClick={() => AuthenticationService.logout(auditRole)}
              >
                <LogoutIcon fontSize={"small"} sx={{ color: "#ffffff" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <div style={{ height: "calc(100% - 64px)" }}>{children}</div>
      </Box>
    </Box>
  );
};

export default AuditorHeaderBar;
