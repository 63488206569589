import {useAxiosParams} from "../common/useAxiosParams";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {UploadCasesRequest} from "../../models/requests/UploadCasesRequest";
import {UploadCsvResponse} from "../../models/responses/UploadCsvResponse";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";

export const useUploadCases = () => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation<UploadCsvResponse, UploadCsvResponse | GenericJsonObject, UploadCasesRequest>({
        mutationFn: async ({brandId, journeyId, csvFile} : UploadCasesRequest) => {
            try {
                const req = new FormData();
                req.append('file', csvFile);
                req.append('brandId', brandId);
                req.append('journeyId', journeyId);

                const response = await axios.post<UploadCsvResponse>(basePath + "/bulk", req, config)
                await cache.invalidateQueries(["cases", brandId]);
                return response.data;
            }
            catch (e) {
                const error = e as AxiosError;
                if (error.response && error.response.data && (error.response.data as GenericJsonObject).issues) {
                    throw (e as AxiosError).response?.data as UploadCsvResponse;
                }
                else {
                    console.error(`Unexpected error: ${error.message}`)
                    throw {message: error.message}
                }
            }
        }
    });
}
