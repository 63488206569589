import React, { useContext, useState } from "react";
import {
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Add, HomeOutlined, HelpOutline } from "@mui/icons-material";
import { CreateCaseButton, Drawer, DrawerHeader } from "./styles";
import { useTheme } from "@mui/material/styles";

import { Link } from "react-router-dom";

import { ReactComponent as HeaderLogo } from "../../img/vialto_logo.svg";
import { ReactComponent as InternalAuditIcon } from "../../img/audit-internal.svg";
import { ReactComponent as AuditLogIcon } from "../../img/audit-log.svg";
import { ReactComponent as IceAuditIcon } from "../../img/audit-ice.svg";
import { CaseListFilterContext } from "../../context/CaseListFilters";
import UserAccount from "./UserAccount";
import AuthenticationContext from "../../context/AuthenticationContext";
import { FeatureFlags } from "../../context/FeatureFlags";
import JourneyContext from "../../context/JourneyContext";

type SideBarProps = {
  isOpen: boolean;
};

const DrawerListHeader = ({
  visible,
  label,
}: {
  visible: boolean;
  label: string;
}) => {
  return (
    <div
      style={{
        marginLeft: 20,
        marginTop: 10,
        color: "#eeeeee",
        fontWeight: 600,
        fontSize: "12px",
        opacity: visible ? 0.6 : 0,
      }}
    >
      {label}
    </div>
  );
};

type PageProps = {
  title: string;
  link: string;
  filter?: string[];
  icon: React.ReactNode;
};

const AuditPageList: PageProps[] = [
  {
    title: "Internal Audit",
    link: "/audit/internal",
    icon: <InternalAuditIcon style={{ color: "#ffffff" }} />,
  },
  {
    title: "Audit Log",
    link: "/audit/log",
    icon: <AuditLogIcon style={{ color: "#ffffff" }} />,
  },
  {
    title: "ICE Audit",
    link: "/audit/ice",
    icon: <IceAuditIcon style={{ color: "#ffffff" }} />,
  },
];

const Sidebar = ({ isOpen }: SideBarProps) => {
  const { isAdminUser } = useContext(AuthenticationContext);
  const { features } = useContext(FeatureFlags);
  const { currentJourney } = useContext(JourneyContext);

  const { setFilterStatuses, setFilterDate, setFilterDateEnd, setFilterText } = useContext(
    CaseListFilterContext
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const theme = useTheme();

  const isI9 = currentJourney?.journeyType.startsWith("I9");

  return (
    <Drawer variant="permanent" open={isOpen}>
      <DrawerHeader>
        {currentJourney && currentJourney.uiConfig?.ui?.emsCompanyLogo ?
            <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(currentJourney.uiConfig.ui.emsCompanyLogo )}`}
                style={{
                    maxHeight: 40
                }}/> :
            <HeaderLogo
                style={{
                    minWidth: 30,
                    minHeight: 30,
                    padding: "2px 10px 2px 2px",
                    borderRight: "1px solid #fff",
                    borderRadius: "0px",
                }}
             />
        }
        {isOpen && (
          <p style={{ marginLeft: 10, fontSize: 16, fontWeight: 400 }}>
            EMS
          </p>
        )}
      </DrawerHeader>
      <Grid
        container
        direction={"column"}
        justifyContent={"space-between"}
        sx={{ height: "100%", flexFlow: 'column' }}
      >
        <Grid container item>
          <List>
            <ListItem
              key={"Dashboard"}
              disablePadding
              sx={{ display: "block" }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <HomeOutlined sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Dashboard"}
                    sx={{
                      color: "#ffffff",
                      fontSize: 14,
                      fontWeight: 400,
                      opacity: isOpen ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <DrawerListHeader label={isI9 ? "I-9 TOOLKIT" : "CASE STATUS"} visible={isOpen} />
            {(currentJourney?.uiConfig?.sidebar ? currentJourney.uiConfig.sidebar : []).map(({ label, filter, icon }, index) => (
              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                <Link
                  to={'/cases'}
                  onClick={() => {
                    if (filter) {
                      setFilterStatuses(filter as string[]);
                      setFilterText("");
                      setFilterDate("");
                      setFilterDateEnd("");
                    }
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: isOpen ? "initial" : "center",
                      px: 2.5,
                      textDecoration: "none",
                    }}
                  >
                    <img
                      style={{
                        minWidth: 0,
                        marginRight: isOpen ? '1em' : "auto",
                        justifyContent: "center",
                      }}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
                    />
                    <ListItemText
                      primary={label}
                      sx={{
                        color: "#ffffff",
                        fontSize: 14,
                        fontWeight: 400,
                        opacity: isOpen ? 1 : 0,
                        textDecoration: "none",
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            ))}
            {isAdminUser && (
              <DrawerListHeader label={"AUDIT"} visible={isOpen} />
            )}
            {isAdminUser &&
              AuditPageList.filter(item => isI9 || item.link !== "/audit/ice" && item.link !== "/audit/internal").map(({ title, link, icon }, index) => (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <Link to={link} style={{ textDecoration: "none" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: isOpen ? "initial" : "center",
                        px: 2.5,
                        textDecoration: "none",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isOpen ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={title}
                        sx={{
                          color: "#ffffff",
                          fontSize: 14,
                          fontWeight: 400,
                          opacity: isOpen ? 1 : 0,
                          textDecoration: "none",
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            {features.isI9v2 && (
              <>
                <DrawerListHeader label={"SUPPORT"} visible={isOpen} />
                <ListItem
                  key={"FAQs"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <Link to="/faq" style={{ textDecoration: "none" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: isOpen ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isOpen ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <HelpOutline sx={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={"FAQs"}
                        sx={{
                          color: "#ffffff",
                          fontSize: 14,
                          fontWeight: 400,
                          opacity: isOpen ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </>
            )}
          </List>
        </Grid>
        <Grid
          container
          item
          justifyContent={"center"}
          spacing={5}
          sx={{ marginBottom: 3 }}
        >
          <Grid
            container
            item
            flexDirection={"row"}
            alignItems={"center"}
            spacing={1}
            sx={{ marginLeft: 2, marginRight: 2 }}
          >
            {isOpen ? (
              <CreateCaseButton href={"/cases/new"}>
                Create Case
              </CreateCaseButton>
            ) : (
              <IconButton
                href="/cases/new"
                sx={{
                  background: theme.palette.secondary.main,
                  borderRadius: 2,
                }}
              >
                <Add sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            )}
          </Grid>
          <UserAccount
            isSidebarOpen={isOpen}
            isPopoverOpen={isPopoverOpen}
            onClose={() => setIsPopoverOpen(!isPopoverOpen)}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Sidebar;
