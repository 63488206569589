import {CaseSpecifications} from "../../../models/responses/Cases/CaseSpecifications/CaseSpecifications";
import {Box, Grid, Typography} from "@mui/material";
import {DetailsBlock} from "../../CaseDetailsForm/common";
import {Country} from "../../../models/Country";
import { useContext } from "react";
import JourneyContext from "../../../context/JourneyContext";
import { PrimaryButton } from "../../../pages/CreateCase/CreateCaseForm/styles";

const SubmissionGuidelines = ({caseSpecifications, country, locationNames} : {caseSpecifications : CaseSpecifications | undefined, country: Country, locationNames: string[]}) => {

    const { currentJourney } = useContext(JourneyContext);
    const isPrescient = currentJourney?.journeyType === "PRESCIENT";
    const isI9HiringManager = currentJourney?.flowType && ['I9_ALT', 'I9_HIRING_MANAGER'].includes(currentJourney.flowType);

    const handleDownloadFile = async () => {

        const fileUrl = () => {
            switch (currentJourney?.flowType) {
              case 'I9_ALT':
                return 'https://us-i9.s3.amazonaws.com/myI9_Batch_upload_Template.csv';
              case 'I9_HIRING_MANAGER':
                return 'https://us-i9.s3.amazonaws.com/myI9_HM_Batch_upload_Template.csv';
              case 'UK_RTW':
              case 'IRL_RTW':
                return 'https://uk-rtw.s3.eu-west-2.amazonaws.com/myRTW_Batch_upload_Template.csv';
              default:
               return 'https://uk-rtw.s3.eu-west-2.amazonaws.com/myRTW_Batch_upload_Template.csv';
            }
          }
    
        try {
            const response = await fetch(fileUrl());
            if (!response.ok) {
                throw new Error("Failed to download file.");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', currentJourney?.flowType + '_batch_upload_template.csv');
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };

    return <Box sx={{width: 400, maxWidth: "40vw"}}>
        <DetailsBlock xs={12} title={"Submission Guidelines"}>
            <Grid item xs={12}>Ensure that the CSV file follows our Submission Guidelines below: </Grid>
            <Grid item xs={12}>
                <ol>
                    <li>Your CSV file size must be under 10MB.</li>
                    <li>The ‘Header’ of your CSV should contain the format below:</li>
                    <ul><li style={{overflowWrap: "break-word"}}>
                        candidateReference,givenName,familyName,email,mobileNumber,country,location,startDate
                        {
                            caseSpecifications?.additionalFields.map(
                                (field) => "," + field.name
                            )
                        }
                        {isI9HiringManager && ',hiringManagerName,hiringManagerLastName,hiringManagerEmail,hiringManagerPhone,hiringManagerTitle'}
                    </li></ul>
                    <li>Each Case on your CSV should contain all the following mandatory fields:</li>
                    <p><strong>Candidate Details</strong></p>
                    <ul>
                        <li>candidateReference</li>
                        <li>givenName</li>
                        <li>familyName</li>
                        <li>email (i.e. example@domain.com)</li>
                        <li>mobileNumber (i.e. Country Code + Number)</li>
                        <li>country (Acceptable value: { country })</li>
                        <li>location { locationNames.length > 1 ? `(Acceptable values: ${ locationNames.join(", ") })` : `(Acceptable value: ${locationNames?.[0] || ''})` }</li>
                        {
                            !isPrescient ? country === Country.UNITED_STATES ?
                                <li>startDate (i.e. Employment start date in format: 12/31/2023)</li>
                                :
                                <li>startDate (i.e. Employment start date in format: 2023-12-31)</li> : <></>
                        }
                        {
                            caseSpecifications?.additionalFields.map(
                                (field, idx) => {
                                    return (
                                        <li key={`additionalField-${idx}`}> {field.name} </li>
                                    )
                                }
                            )
                        }
                    </ul>
                    {isI9HiringManager && 
                        <>
                            <p><strong>Hiring Manager Details</strong></p>
                            <ul>
                                <li>hiringManagerName (i.e. Given Name)</li>
                                <li>hiringManagerLastName (i.e. Last Name)</li>
                                <li>hiringManagerEmail (i.e. example@domain.com)</li>
                                <li>hiringManagerPhone (i.e. Country Code + Number)</li>
                                <li>hiringManagerTitle (e.g. Lead, US HR Services)</li>
                            </ul>
                        </>
                    }
                    <li>The following optional column headers can be added. If added to your CSV ‘Header’, each Case on your CSV should contain valid data for them:</li>
                    <ul>
                        {
                            isPrescient && <li>startDate (i.e. Employment start date in format: 2023-12-31)</li>
                        }
                        {
                            country === Country.UNITED_STATES ?
                                <><li>endDate (i.e. Employment end date in format: 12/31/2023)</li>
                                </>
                                :
                                <li>endDate (i.e. Employment end date in format: 2023-12-31)</li>
                       }
                        
                    </ul>
                    <li>The CSV has been exported from a trusted application.</li>
                </ol>
            </Grid>
        </DetailsBlock>
        <DetailsBlock xs={12}>
            <Grid item xs={12} sx={{marginBottom: '20px'}}>
                <Typography variant="h5" sx={{fontWeight: 'bold', marginBottom: '10px'}}>Need help?</Typography>
                If you need a template to get started, click ‘Download’ to get the CSV file.</Grid>
            <Grid item xs={12}>
                <PrimaryButton
                    onClick={handleDownloadFile}
                >
                    Download
                </PrimaryButton>
            </Grid>
        </DetailsBlock>
    </Box>
}

export default SubmissionGuidelines;
