import { useContext, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  CalendarTodayOutlined,
  Close,
  LockOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { SecondaryButton } from "../../../pages/CreateCase/CreateCaseForm/styles";
import { DetailsBlock } from "../../CaseDetailsForm/common";
import { useOAuthStatus } from "../../../hooks/Meetings/useOAuthStatus";
import { getProviderDisplayName } from "../../../models/responses/Meetings/OAuthStatus";
import ConfigService from "../../../services/ConfigService/ConfigService";
import { AccountSettingsTab, AccountSettingsTabs } from "./styles";
import AuthenticationContext from "../../../context/AuthenticationContext";
import {useRevokeOAuthToken} from "../../../hooks/Meetings/useSaveOAuthToken";
//import {ReactComponent as GoogleCalendarIcon} from "../../../img/google_calendar.svg";
import {ReactComponent as MicrosoftOutlookIcon} from "../../../img/microsoft_outlook.svg";
import JourneyContext from "../../../context/JourneyContext";

type AccountSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  userData: {
    firstname: string;
    familyname: string;
    email: string;
  };
  onRequestPasswordChange: () => void;
};

const AccountSettingsModal = ({
  isOpen,
  onClose,
  userData,
  onRequestPasswordChange,
}: AccountSettingsModalProps) => {
  const { data: oauthStatus, isSuccess } = useOAuthStatus();
  const { mutate: revokeOAuthToken } = useRevokeOAuthToken();

  const { isSSOUser } = useContext(AuthenticationContext);

  const { currentJourney } = useContext(JourneyContext);
  const isPrescient = currentJourney?.journeyType === "PRESCIENT";

  // const OAUTH_GOOGLE_CLIENT_ID: string = ConfigService.getConfigValue(
  //   "OAUTH_GOOGLE_CLIENT_ID"
  // );
  // const OAUTH_GOOGLE_REDIRECT: string =
  //   encodeURIComponent(window.location.origin) + "/oauth/google";
  // const OAUTH_GOOGLE_AUTHORISE_URL = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/calendar.freebusy%20https%3A//www.googleapis.com/auth/calendar.events.owned&access_type=offline&response_type=code&redirect_uri=${OAUTH_GOOGLE_REDIRECT}&client_id=${OAUTH_GOOGLE_CLIENT_ID}`;

  const OAUTH_MICROSOFT_CLIENT_ID: string = ConfigService.getConfigValue(
      "OAUTH_MICROSOFT_CLIENT_ID"
  );
  const OAUTH_MICROSOFT_REDIRECT: string =
      encodeURIComponent(window.location.origin) + "/oauth/microsoft";
  const OAUTH_MICROSOFT_AUTHORISE_URL = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${OAUTH_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${OAUTH_MICROSOFT_REDIRECT}&response_mode=query&scope=offline_access%20calendars.readwrite%20onlinemeetings.readwrite&prompt=consent`;

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ paddingBottom: "40px" }}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle
        sx={{ fontWeight: "bold", borderBottom: "1px solid #D7D7D7" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <p style={{ margin: 0 }}>Account settings</p>
          <p style={{ margin: 0 }} onClick={onClose}>
            <Close fontSize="small" />
          </p>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Grid container>
          <Grid
            item
            xs={3}
            sx={{ background: "#f4f4f4", borderRight: "1px solid #D7D7D7" }}
          >
            <AccountSettingsTabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <AccountSettingsTab
                icon={<PersonOutlineOutlined fontSize="small" />}
                iconPosition="start"
                label="Profile"
                disabled={isSSOUser}
                {...a11yProps(0)}
              />
              <AccountSettingsTab
                icon={<LockOutlined fontSize="small" />}
                iconPosition="start"
                label="Change Password"
                disabled={isSSOUser}
                {...a11yProps(1)}
              />
              {!isPrescient && <AccountSettingsTab
                icon={<CalendarTodayOutlined fontSize="small" />}
                iconPosition="start"
                label="Authentication"
                {...a11yProps(2)}
              />}
            </AccountSettingsTabs>
          </Grid>
          <Grid item xs={9}>
            <TabPanel value={value} index={0}>
              <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
                Profile
              </Typography>
              <Grid container>
                <DetailsBlock xs={12}>
                  <Grid
                    container
                    alignItems={"center"}
                    sx={{ marginBottom: "20px" }}
                  >
                    <Grid item xs={3}>
                      Given name
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size={"small"}
                        value={userData.firstname}
                        disabled
                        InputProps={{
                          readOnly: true,
                          style: { paddingRight: "35px" },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems={"center"}
                    sx={{ marginBottom: "20px" }}
                  >
                    <Grid item xs={3}>
                      Family name
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size={"small"}
                        value={userData.familyname}
                        disabled
                        InputProps={{
                          readOnly: true,
                          style: { paddingRight: "35px" },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={3}>
                      E-mail
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        size={"small"}
                        value={userData.email}
                        disabled
                        InputProps={{
                          readOnly: true,
                          style: { paddingRight: "35px" },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DetailsBlock>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
                Change Password
              </Typography>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "20px",
                }}
              >
                To change your password{" "}
                <Link
                  onClick={onRequestPasswordChange}
                  style={{
                    textDecoration: "none",
                    color: "#2253FF",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  click here
                </Link>{" "}
                and a message containing a reset link with be sent to the email
                inbox associated with the account.
              </p>
            </TabPanel>
            {!isPrescient && <TabPanel value={value} index={2}>
              <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
                Authentication
              </Typography>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "20px",
                }}
              >
                HR Management have identified you will be conducting  live video calls with the employees.
                <br/>Appointments for the live video calls must be linked to your email.
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "20px",
                }}
              >
                Click on the relevant provider to authenticate your email address.
              </p>
              <Grid item xs={12}>
                {!isSuccess ? (
                  <LinearProgress />
                ) : oauthStatus?.authorised ? (
                    <Stack direction="column" spacing={3}>
                      <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                      >
                        <p style={{ fontWeight: "bold" }}> {getProviderDisplayName(oauthStatus?.provider)} </p>
                        <SecondaryButton
                            onClick={() => { revokeOAuthToken() }}
                            sx={{ textTransform: "none" }}
                        >
                          {"Disconnect"}
                        </SecondaryButton>
                      </Stack>
                    </Stack>
                ) : (
                  <Stack direction="column" spacing={3}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                      <p style={{ fontWeight: "bold" }}>Microsoft</p>
                      <SecondaryButton
                          href={OAUTH_MICROSOFT_AUTHORISE_URL}
                          sx={{ textTransform: "none", width: 300  }}
                          startIcon={<MicrosoftOutlookIcon style={{width: 20, height: 20}}/>}
                      >
                        {"Connect with " + getProviderDisplayName("MICROSOFT")}
                      </SecondaryButton>
                    </Stack>
                    {/* <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <p style={{ fontWeight: "bold" }}>Google</p>
                      <SecondaryButton
                        href={OAUTH_GOOGLE_AUTHORISE_URL}
                        sx={{ textTransform: "none", width: 300 }}
                        startIcon={<GoogleCalendarIcon style={{width: 20, height: 20}}/>}
                      >
                        {"Connect with " + getProviderDisplayName("GOOGLE")}
                      </SecondaryButton>
                    </Stack> */}
                  </Stack>
                )}
              </Grid>
            </TabPanel>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #D7D7D7", padding: 2 }}>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default AccountSettingsModal;
