import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {useAxiosParams} from "../common/useAxiosParams";

type useOptOutProps = {
    brandUid?: string,
    caseUid: string;
    onSuccess: () => void;
    onError: () => void;
}

export const useApproveOptOut = ({caseUid, brandUid, onSuccess, onError}: useOptOutProps) => {
    const {basePath, config} = useAxiosParams();

    const cache = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            await axios.post(basePath + `/${caseUid}/opt-out`, {}, config);
            await cache.invalidateQueries(["cases", brandUid]);
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid]);
            onSuccess();
        },
        onError: (error) => {
            console.error(error);
            onError();
        }
    });
}
