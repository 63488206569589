import { styled } from "@mui/material/styles";
import {Paper } from "@mui/material";

export const CaseDetailsBox = styled(Paper)(() => ({
    background: "#fff",
    borderRadius: "20px",
    padding: "10px 20px",
    border: "1px solid #D7D7D7",
    p: {
      margin: 0,
      color: "#666666",
      span: {
        color: "#1A1A1A",
      },
    },
  }));