import { Paper } from "@mui/material";
import { styled, CSSObject } from "@mui/material/styles";

export const DateNameWidgetBox = styled(Paper)(
  ({ theme }): CSSObject => ({
    background: `${theme.palette.secondary.main} url('../../../img/date-name-widget-bg.svg') no-repeat center bottom`,
    padding: "20px 20px 150px 20px",
    color: theme.palette.primary.main,
    borderRadius: "16px",
    position: "relative",
    svg: {
      position: "absolute",
      right: 0,
      bottom: 0,
    },
    ".date": {
      background: "rgba(255, 255, 255, 0.8)",
      border: "1px solid #FFFFFF",
      borderRadius: "4px",
      color: "#1A1A1A",
      fontSize: "14px",
      padding: "2px 10px",
      display: "inline-block",
      marginBottom: "10px",
    },
    h3: {
      fontSize: "34px",
      lineHeight: "37px",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    p: {
      margin: 0,
    },
  })
);
