import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SectionRTWDataWidgetBox = styled(Grid)(() => ({
  marginBottom: "20px",
  "&:last-child": {
    marginBottom: '0'
  },
  "& .MuiPaper-root": {
    h3: {
      fontSize: "18px",
      color: "#1A1A1A",
      fontWeight: "bold",
      marginBottom: "15px",
    },
  },
}));
