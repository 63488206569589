import { Button, Stack } from "@mui/material";
import { styled, CSSObject } from "@mui/material/styles";

export const InstructionsBottomBarToggle = styled(Button)(
  ({ theme }): CSSObject => ({
    background: theme.palette.secondary.light,
    borderRadius: 0,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.secondary.light,
    },
  })
);

export const InstructionsBottomBarBox = styled(Button)(() => ({
  position: "fixed",
  bottom: "75px",
  right: "5vw",
  width: "75%",
  margin: "0px auto",
  display: "block",
  background: "none",
  transition: "all ease 0.2s",
  zIndex: "90",
  ".MuiContainer-root": {
    padding: "10px 0px",
    border: "1px solid #D7D7D7",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    boxShadow: "0px 0px 24px 0px #00000014",
    background: "#fff",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "flex-start",
  },
  "&:hover": {
    background: "none",
    cursor: "auto",
  },
}));

export const InstructionBottomBarItem = styled(Stack)(() => ({
  borderLeft: "1px solid #D7D7D7",
  padding: "10px 20px",
  display: "grid",
  gridTemplateColumns: "1fr 2fr 5fr",
  alignItems: "flex-start",
  "&:first-child": {
    border: 0,
  },
  svg: {
    width: "72px",
    height: "72px",
  },
  p: {
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "22px",
    textTransform: "none",
  },
}));
