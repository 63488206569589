import HeaderBar from "../../components/HeaderBar";
import CaseList from "./CaseList";
import CaseFilterBar from "./CaseFilterBar";
import { LinearProgress } from "@mui/material";
import { useContext, useMemo } from "react";
import { useCaseList } from "../../hooks/Cases/useCaseList";
import { CaseResponse } from "../../models/responses/Cases/CaseResponse";
import {CaseListFilterContext} from "../../context/CaseListFilters";
import JourneyContext from "../../context/JourneyContext";

const Cases = () => {
  const { currentJourney } = useContext(JourneyContext);
  const { allCases, isSuccess } = currentJourney? useCaseList(currentJourney.brandId) : ({allCases: [], isSuccess: false});
  const { filterCases } = useContext(CaseListFilterContext);

  const filteredCases = useMemo<CaseResponse[]>(() => filterCases(allCases || [], currentJourney),
      [filterCases, allCases, currentJourney]);
  const isI9: boolean = currentJourney?.journeyType.startsWith("I9") || false;
  const isPrescient: boolean = currentJourney?.journeyType === "PRESCIENT";

  return (
    <HeaderBar
      breadcrumbs={[
        {
            label: isI9 ? "I-9 Cases" : (isPrescient? "myKYC Cases" : "myRTW Cases"),
        },
      ]}
    >
      {isSuccess ? (
        <>
          <CaseFilterBar cases={ filteredCases } />
          <CaseList cases={ filteredCases } />
        </>
      ) : (
        <LinearProgress />
      )}
    </HeaderBar>
  );
};

export default Cases;
