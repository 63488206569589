export const formatDate = (
  date: Date | string,
  options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
  }
) => {
  try {
      return new Intl.DateTimeFormat("en-US", {
          ...(options || {}),
      }).format(date instanceof Date ? date : new Date(date));
  }
  catch (e) {
      return String(date);
  }
}

export const formatNonUSDate = (
    date: Date | string,
    options: Intl.DateTimeFormatOptions = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }
  ) => {
    try {
        return new Intl.DateTimeFormat("zh", {
            ...(options || {}),
        }).format(date instanceof Date ? date : new Date(date));
    }
    catch (e) {
        return String(date);
    }
  }

export const formatFullDate = (
    date: Date | string
) => formatDate(date, {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
})

export const formatNonUSFullDate = (
    date: Date | string
) => formatNonUSDate(date, {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
})

export const formatDateTime = (
    date: Date | string
) => date === "" ? date : formatDate(date, {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
})

export const formatNonUSDateTime = (
    date: Date | string
) => date === "" ? date : formatNonUSDate(date, {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
})

export const formatTime = (
    date: Date | string
) => date === "" ? date : formatDate(date, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
})

export const isBetweenDate = (date : string | undefined, startDate : string | undefined, endDate : string | undefined) => {
    if (!date || date.length === 0) {
        return false;
    }
    const dateString = new Date(date).toLocaleString("en-CA").substring(0, 10);
    const startDateString = startDate && startDate.length && new Date(startDate).toLocaleString("en-CA").substring(0,10);
    const endDateString = endDate && endDate.length && new Date(endDate).toLocaleString("en-CA").substring(0,10);

    return (!startDateString || dateString >= startDateString) && (!endDateString || dateString <= endDateString);
}

export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;
