import { Button, Grid } from "@mui/material";
import { useCaseNotes, useCreateCaseNote } from "../../../hooks/Cases/useCaseNotes";
import { CaseNoteResponse } from "../../../models/responses/Cases/CaseNoteResponse";
import { formatDateTime, formatNonUSDateTime } from "../../../utils/DateUtils/dateUtils";
import { CaseNoteInput } from "./styles";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useContext, useEffect, useState } from "react";
import JourneyContext from "../../../context/JourneyContext";

const CaseNote = ({note} : {note: CaseNoteResponse}) => {
    const { currentJourney } = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");
    
    return <Grid container item style={{marginTop: 15}}>
        <Grid item xs={12}><span style={{fontWeight: 600}}>{note.title}</span> <span dangerouslySetInnerHTML={{__html: note.description.replaceAll("\n", "<br/>")}} /> </Grid>
        <Grid item xs={8} style={{fontSize:12, color:'#555'}}> {isI9 ? formatDateTime(note.createdAt) : formatNonUSDateTime(note.createdAt)} </Grid>
        <Grid item xs={4} style={{fontSize:12, color:'#555'}}> {note.createdByFullname} </Grid>
    </Grid>
}

const CaseNoteForm = ({caseId} : {caseId: string}) => {
    const {mutate, isLoading} = useCreateCaseNote(caseId);

    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const submitHandler = () => {
        mutate({title, description});
    }

    useEffect(()=>{
        if (!isLoading) {
            setTitle("");
            setDescription("");
        }
    },[isLoading])

    return <div style={{ width: '100%', padding: 10, marginTop: 15,}}>
        <div style={{
            width: '100%',
            padding: "8px 12px 8px 12px",
            borderRadius: "10px 10px 0px 0px",
            background: '#000',
            color: '#fff'
        }}>
            <span>Add note to case</span>
            <DriveFileRenameOutlineOutlinedIcon sx={{float: 'right'}}/>
        </div>
        <div style={{
            width: '100%',
            height: 335,
            border: '2px solid #000',
            borderRadius: "0px 0px 10px 10px",
        }}>
            <CaseNoteInput
                fullWidth
                variant="standard"
                placeholder="Add title"
                value={title}
                sx={{paddingLeft: 1, paddingRight: 1}}
                onChange={evt => setTitle(evt.target.value)}
            />
            <CaseNoteInput
                fullWidth
                variant="standard"
                multiline
                rows={10}
                placeholder="Enter notes..."
                InputProps={{ disableUnderline: true }}
                sx={{paddingLeft: 1, paddingRight: 1}}
                value={description}
                onChange={evt => setDescription(evt.target.value)}
            />
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                padding: 8
            }}>
                <Button
                    variant="contained"
                    sx={{
                        color: '#fff',
                        background: '#000',
                        maxHeight: 30,
                    }}
                    disabled={title==="" || description==="" || isLoading}
                    onClick={submitHandler}
                >Save</Button>
            </div>
        </div>
    </div>
}

const CaseNotesView = ({caseId} : {caseId: string}) => {
    const {data} = useCaseNotes(caseId);

    return <Grid container alignContent={"flex-start"} item padding={1}>
        <Grid item xs={9} style={{fontWeight: 600, marginBottom: 5,}}>Case Notes</Grid>
        <div style={{maxHeight: 400, overflow: "auto"}}>
        {
            data && data.sort((a, b) => (b.id - a.id)).map(note => <CaseNote key={`note-${note.id}`} note={note}/>)
        }
        </div>
        <CaseNoteForm caseId={caseId}/>
    </Grid>
}

export default CaseNotesView;