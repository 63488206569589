import {useQuery} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {useAxiosParams} from "../common/useAxiosParams";
import { useBrand } from "../Brand/useBrand";
import { UserResponse } from "../../models/responses/UserResponse";

export const useUsers = () => {
    const {orchestratorPath, config} = useAxiosParams()
    const { data: brand } = useBrand();

    return useQuery<unknown, AxiosError, UserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const response = await axios.get<UserResponse[]>(orchestratorPath + `/brands/${brand?.id}/users`, config);
            return response.data;
        },
        staleTime: 5 * 60 * 1000, // the users available to a user wouldn't change on a high frequency, and shouldn't require frequent re-loading
    })
}
