import {getDisplayDocumentListType} from "./documentListType";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";

type documentNameProps = {
    text: string;
};

const documentTypes: Record<string, documentNameProps> = {
    FOREIGN_PASSPORT: {
        text: 'Passport'
    },
    US_PASSPORT_CARD: {
        text: 'US Passport Card'
    },
    US_PASSPORT: {
        text: 'US Passport'
    },
    FORM_I551_STAMP: {
        text: 'Form I-551 Stamp'
    },
    FORM_I551_MRIV: {
        text: 'Form I-551 MRIV'
    },
    FORM_I551: {
        text: 'Form I-551'
    },
    FORM_I766: {
        text: 'Form I-766'
    },
    FORM_I94: {
        text: 'Form I-94'
    },
    FORM_I20: {
        text: 'Form I-20'
    },
    FORM_DS_2019: {
        text: 'Form DS-2019'
    },
    US_DRIVERS_LICENSE: {
        text: 'Drivers License'
    },
    CANADA_DRIVERS_LICENSE: {
        text: 'Drivers License, Canada'
    },
    ID_CARD: {
        text: 'ID Card'
    },
    SCHOOL_ID: {
        text: 'School ID'
    },
    VOTER_CARD: {
        text: 'Voter Registration Card'
    },
    NATIVE_DOCUMENT: {
        text: 'Native American ID'
    },
    MILITARY_CARD: {
        text: 'Uniformed Services ID'
    },
    MILITARY_DEPENDENTS_CARD: {
        text: 'Uniformed Services ID'
    },
    MARINER_ID: {
        text: 'Uniformed Services ID'
    },
    SCHOOL_OTHER: {
        text: 'School Record'
    },
    MEDICAL_RECORD: {
        text: 'Healthcare Record'
    },
    NURSERY_RECORD: {
        text: 'School Record'
    },
    SOCIAL_SECURITY: {
        text: 'Social Security Card'
    },
    FORM_FS_240: {
        text: 'Form FS-240'
    },
    FORM_FS_545: {
        text: 'Form FS-545'
    },
    FORM_DS_1350: {
        text: 'Form DS-1350'
    },
    US_BIRTH_CERTIFICATE: {
        text: 'Birth Certificate'
    },
    FORM_I197: {
        text: 'Form I-197'
    },
    FORM_I179: {
        text: 'Form I-179'
    },
    US_EMPLOYMENT_AUTHORIZATION: {
        text: 'DHS Employment Authorization'
    },
    EAD: {
        text: 'EAD issued by the DHS'
    },
    OTHER: {
        text: 'Other'
    }
}

export const getDisplayDocumentTitle = (documentType: string): string => {
    const defaultName = {text: 'Document'};
    const key = Object.keys(documentTypes).find(key => documentType && documentType === key);
    const {text} = key && documentTypes[key] ? documentTypes[key] : defaultName;
    return text;
}

export const getDisplayDocumentTitleAboveImage = (documents: { [key: string]: string }[] | undefined, documentType: string): string => {
    if (!documents) {
        return getDisplayDocumentTitle(documentType);
    }
    const document = documents.find(document => documentType && documentType === document.document_type);
    return getDisplayDocumentTitle(documentType) + (document ? ` (${getDisplayDocumentListType(document.list_type)})` : '');
}

export const longestCommonPrefix = (sortedArray: string[]) => {
    const size = sortedArray.length;
    if (size == 0) return "";
    if (size == 1) return sortedArray[0];
    /* find the minimum length from first and last string */
    const end = Math.min(sortedArray[0].length, sortedArray[size - 1].length);
    /* find the common prefix between the first and last string */
    let i = 0;
    while (i < end && sortedArray[0][i] == sortedArray[size - 1][i]) i++;
    return sortedArray[0].substring(0, i);
}

export const getDocumentImageMap = (documents: { [key: string]: string }[], standaloneTransactions: GenericJsonObject[]): Map<string, string[]> => {
    const documentImageMap = new Map<string, string[]>();
    documents && documents.forEach(document => {
        for (let index = standaloneTransactions.length - 1; index >= 0; index--) {
            const transactionDocuments = standaloneTransactions[index].documents as GenericJsonObject;
            const transactionDocumentNames = Object.keys(transactionDocuments).sort();
            const documentType = longestCommonPrefix(transactionDocumentNames);
            if (!documentImageMap.has(documentType) && document.document_type === documentType) {
                documentImageMap.set(documentType,
                    transactionDocumentNames.map(docName => (transactionDocuments[docName] as GenericJsonObject).image as string));
            }
        }
    });
    return documentImageMap;
}

export const getDocumentImages = (documents: { [key: string]: string }[], standaloneTransactions: GenericJsonObject[]): { title: string, image: string }[] => {
    const documentImageMap = getDocumentImageMap(documents, standaloneTransactions);
    const documentImages: { title: string, image: string }[] = [];
    documentImageMap.forEach((value: string[], key: string) => {
        value.forEach(image => documentImages.push({title: key, image: image}));
    });
    return documentImages;
}