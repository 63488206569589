import {CircularProgress, SvgIcon} from "@mui/material";
import {ReactComponent as CsvFileSvg} from "../../../../img/csvFile.svg";
import {ReactComponent as FolderSvg} from "../../../../img/folder.svg";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import {DropzoneInputProps} from "react-dropzone";

const dropzoneStyle = {normal: {}, hover: {}};
dropzoneStyle.normal = {
    marginTop: 20,
    marginBottom: 20,
    width: "50vw",
    height: 230,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: "1px dashed #6F6F6F",
    boxSizing: "border-box",
    borderRadius: 4,
    color: '#333',
    fontSize: 18,
    fontWeight: 50,
    padding: '10%',
    textAlign: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
};
dropzoneStyle.hover = {
    ...dropzoneStyle.normal,
    border: "1px dashed #2253FF",
    background: "#275FA60C"
}

export type CsvDropzoneProps = {
    getRootProps: <T extends DropzoneInputProps>(props?: T) => T,
    getInputProps: <T extends DropzoneInputProps>(props?: T) => T,
    isDragActive: boolean,
    selectedFile: File | undefined | false,
    isLoading: boolean,
    fileTypeLabel?: string;
}

const CsvDropzone = ({getRootProps, getInputProps, isDragActive, selectedFile, isLoading, fileTypeLabel} : CsvDropzoneProps)  => {
    return <div {...getRootProps()} style={isDragActive? dropzoneStyle.hover : dropzoneStyle.normal }>
        <input data-testid="dropzone" {...getInputProps()} />
        {
            isLoading ? <CircularProgress style={{color: "#002554", marginBottom: 10,}}/>
            : selectedFile ? <SvgIcon style={{fontSize: 60, marginBottom: 10,}}><CsvFileSvg/></SvgIcon>
                : isDragActive ? <SvgIcon style={{fontSize: 60, marginBottom: 10,}}><FolderSvg/></SvgIcon>
                    : <NoteAddOutlinedIcon style={{fontSize: 60, marginBottom: 10,}}/>
        }

        {
            isLoading ? <div>Submitting file...</div>
            : selectedFile ? <div>Document selected. Ready to submit.</div>
                : isDragActive ? <div>Drag the file here</div>
                    : <div>Drag your {fileTypeLabel || "CSV"} file here or <span style={{color: "#2253FF"}}>browse</span></div>
        }

        <div style={{fontSize: 16, color: "#666"}}>{
            selectedFile ? `${selectedFile.name} - ${selectedFile.size} bytes`
                : `(Maximum file size: 10mb)`
        }</div>
    </div>;
}

export default CsvDropzone;
