import jwt_decode from "jwt-decode";
import ConfigService from "../ConfigService/ConfigService";
import { CognitoJwtVerifier } from "aws-jwt-verify";

class AuthenticationService {

  COGNITO_URL = ConfigService.getConfigValue("REACT_APP_COGNITO_URL");
  CLIENT_ID = ConfigService.getConfigValue("REACT_APP_CLIENT_ID");
  USER_POOL_ID = ConfigService.getConfigValue("REACT_APP_USER_POOL_ID");
  CLIENT_SECRET = ConfigService.getConfigValue("REACT_APP_CLIENT_SECRET");

  AUDITOR_COGNITO_URL = ConfigService.getConfigValue("AUDITOR_FQDN_COGNITO");
  AUDITOR_CLIENT_ID = ConfigService.getConfigValue("AUDITOR_CLIENT_ID");
  AUDITOR_USER_POOL_ID = ConfigService.getConfigValue("AUDITOR_USER_POOL_ID");
  AUDITOR_CLIENT_SECRET = ConfigService.getConfigValue("AUDITOR_CLIENT_SECRET");

  REDIRECT_URL = ConfigService.getConfigValue(
      "REACT_APP_EMS_CASE_MANAGEMENT_UI_URL"
  );

  changePassword() {
    
    sessionStorage.clear();

    const state = this.getRandomString();
    const code_verifier = this.getRandomString();

    // Create code challenge
    this.encryptStringWithSHA256(code_verifier).then((arrayHash) => {
      const code_challenge = this.hashToBase64url(arrayHash);

      window.location.href = `${this.COGNITO_URL}/forgotPassword?response_type=code&state=${state}&client_id=${this.CLIENT_ID}&redirect_uri=${this.REDIRECT_URL}/login&scope=email+openid+profile&code_challenge_method=S256&code_challenge=${code_challenge}`
    });
    
  }

  logout(auditRole?: string) {
    sessionStorage.clear();
    if (auditRole) {
      window.location.href = `${this.AUDITOR_COGNITO_URL}/logout?client_id=${this.AUDITOR_CLIENT_ID}&logout_uri=${this.REDIRECT_URL}/audit-logout`;
    } else {
      window.location.href = `${this.COGNITO_URL}/logout?client_id=${this.CLIENT_ID}&logout_uri=${this.REDIRECT_URL}`;
    }
  }

  // utility functions for PKCE below:

  login() {
    if (window.location.href.includes("/cases/")) {
      sessionStorage.setItem("caseUrl", window.location.href);
    }

    // Create random "state"
    const state = this.getRandomString();
    sessionStorage.setItem("pkce_state", state);

    // Create PKCE code verifier
    const code_verifier = this.getRandomString();
    sessionStorage.setItem("code_verifier", code_verifier);

    // Create code challenge
    this.encryptStringWithSHA256(code_verifier).then((arrayHash) => {
      const code_challenge = this.hashToBase64url(arrayHash);

      // Redirect user-agent to /authorize endpoint
      if (["audit-login", "audit-logout"].includes(window.location.pathname.replace("/", ""))) {
        window.location.href = `${this.AUDITOR_COGNITO_URL}/oauth2/authorize?response_type=code&state=${state}&client_id=${this.AUDITOR_CLIENT_ID}&redirect_uri=${this.REDIRECT_URL}/audit-login&scope=email+openid+profile&code_challenge_method=S256&code_challenge=${code_challenge}`;
      } else {
        window.location.href = `${this.COGNITO_URL}/oauth2/authorize?response_type=code&state=${state}&client_id=${this.CLIENT_ID}&redirect_uri=${this.REDIRECT_URL}/login&scope=email+openid+profile&code_challenge_method=S256&code_challenge=${code_challenge}`;
      }
    });
  }

  async authorise(code: string, state: string): Promise<{ token: string | null; exp: number | null }> {
    const isAuditorLogin = window.location.pathname.replace("/", "") === "audit-login";
    const cognitoUrl = isAuditorLogin? this.AUDITOR_COGNITO_URL : this.COGNITO_URL;
    const userPoolId = isAuditorLogin? this.AUDITOR_USER_POOL_ID : this.USER_POOL_ID;
    const clientId = isAuditorLogin? this.AUDITOR_CLIENT_ID : this.CLIENT_ID;
    const clientSecret = isAuditorLogin? this.AUDITOR_CLIENT_SECRET : this.CLIENT_SECRET;

    // Verify state matches
    if (sessionStorage.getItem("pkce_state") !== state) {
      console.error(`Invalid PKCE state: ${state}`);
      return {
        token: null,
        exp: null,
      };
    } else {
      // Fetch OAuth2 tokens from Cognito
      const code_verifier = sessionStorage.getItem("code_verifier");
      const response = await fetch(`${cognitoUrl}/oauth2/token`, {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: Object.entries({
          grant_type: "authorization_code",
          client_id: clientId,
          client_secret: clientSecret,
          code: code,
          code_verifier: code_verifier,
          redirect_uri: isAuditorLogin ? `${this.REDIRECT_URL}/audit-login` : `${this.REDIRECT_URL}/login`,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join("&"),
      });
      const data = await response.json();

      sessionStorage.setItem("tokens", JSON.stringify(data));
      // Verify id_token
      const idVerified = await this.verifyToken(data.id_token, userPoolId, clientId);
      if (idVerified.localeCompare("verified")) {
        console.error("Invalid ID Token - " + idVerified);
      }

      const idToken = data.id_token;
      const token = jwt_decode<{
        exp : number;
      }>(idToken);

      return { token: idToken, exp: token.exp };
    }
  }

  //Generate a Random String
  getRandomString(): string {
    const randomItems = new Uint32Array(28);
    crypto.getRandomValues(randomItems);
    const binaryStringItems : string[] = Array.from(randomItems).map(
      (dec : number) => `0${dec.toString(16).substring(-2)}`
    );

    return binaryStringItems.reduce((acc, item) => `${acc}${item}`, "");
  }

  //Encrypt a String with SHA256
  async encryptStringWithSHA256(str: string): Promise<ArrayBuffer> {
    const PROTOCOL = "SHA-256";
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return crypto.subtle.digest(PROTOCOL, encodedData);
  }

  //Convert Hash to Base64-URL
  hashToBase64url(arrayBuffer: ArrayBuffer): string {
    const items = new Uint8Array(arrayBuffer);
    const stringifiedArrayHash = items.reduce(
      (acc, i) => `${acc}${String.fromCharCode(i)}`,
      ""
    );
    const decodedHash = btoa(stringifiedArrayHash);

    const base64URL = decodedHash
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
    return base64URL;
  }

  //verify token
  async verifyToken(token: string, userPoolId: string, clientId: string): Promise<string> {
    if (userPoolId && clientId) {
      const verifier = CognitoJwtVerifier.create({
        userPoolId: userPoolId,
        tokenUse: "id",
        clientId: clientId,
      });

      try {
        const tokenPayload = await verifier.verify(token, {
          tokenUse: "id",
          clientId: clientId,
        });
        if (Date.now() >= tokenPayload.exp * 1000) {
          return "Token expired";
        }
        if (tokenPayload.aud.localeCompare(clientId)) {
          return "Token was not issued for this audience";
        }
        return "verified";
      } catch (e) {
        return "Signature verification failed";
      }
    } else {
      return "Cognito configuration not available";
    }
  }
}

const AuthenticationServiceSingleton = new AuthenticationService();
export default AuthenticationServiceSingleton;
