export const AuditCredentialFormFields = [
  {
    name: "givenName",
    label: "Auditor’s Given Name*",
    placeholder: "Auditor’s Given Name*",
  },
  {
    name: "familyName",
    label: "Auditor’s Family Name*",
    placeholder: "Auditor’s Family Name*",
  },
  {
    name: "email",
    label: "Auditor’s Email / Username*",
    placeholder: "Auditor’s Email / Username*",
  },
  {
    name: "communicationEmail",
    label: "Auditor’s Communication Email*",
    placeholder: "Auditor’s Communication Email*",
  },
];
