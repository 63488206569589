import { Button, CSSObject, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AuditCredentialFormInput = styled(TextField)(() => ({
  background: "#F4FDFC",
  marginBottom: "0px",
  width: "100%",
  ".MuiOutlinedInput-root": {
    paddingLeft: "8px",
    ".MuiSvgIcon-root": {
      fontSize: "16px",
    },
    input: {
      padding: "10px 10px",
      fontSize: "16px",
      borderRadius: "8px",
    },
  },
}));

export const AddAuditorButton = styled(Button)(({theme}): CSSObject => ({
  color: theme.palette.primary.main,
  borderRadius: "8px",
  border: "0px",
  textTransform: "none",
  padding: "0px 0px",
  margin: "20px 0px",
  fontSize: "16px",
  fontWeight: "bold",
  "& svg": {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: "10px",
    borderRadius: "8px",
    width: "40px",
    height: "40px",
  },
}));

export const ConfirmationModal = styled(Grid)(() => ({
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.08)",
  position: "fixed",
  bottom: 0,
  right: 0,
  maxWidth: "40vw",
  width: "400px",
  background: "#fff",
  "& .textinfo": {
    background: "#F4F4F4",
    borderBottom: "1px solid #cecece",
    h3: {
      fontSize: "18px",
      marginBottom: "10px",
      fontWeight: "bold",
      padding: "0px 20px",
    },
    p: {
      fontSize: "14px",
      lineHeight: "24px",
      padding: "0px 20px",
    },
  },
}));
