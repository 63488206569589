import {useState, useRef, useContext} from "react";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  AccountCircleOutlined,
  Add,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { DrawerHeader } from "../../../components/HeaderBar/styles";
import { StartEndDateField } from "../../../components/CaseDetailsForm/BasicDetailsPanel/styles";
import {
  AuditCredentialFormInput,
  AddAuditorButton,
  ConfirmationModal,
} from "./styles";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../CreateCase/CreateCaseForm/styles";
import ConfirmationModalBG from "../../../img/conformation-modal-bg.png";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCreateAuditor } from "../../../hooks/Audit/useCreateAuditor";
import { formatDate } from "../../../utils/DateUtils/dateUtils";
import { Controller, useForm } from "react-hook-form";
import { AuditCredentialFormFields } from "./AuditCredentialFormFields";
import { AuditCriteriaProps } from "..";
import JourneyContext from "../../../context/JourneyContext";

type AuditCredentialFormProps = {
  auditType: string;
  auditCriteria: AuditCriteriaProps;
  auditCasesLength: number;
  isOpen: boolean;
  onClose: () => void;
};

type AuditorDetailsProps = {
  givenName: string;
  familyName: string;
  email: string;
  communicationEmail: string;
};

const initialFormValues = {
  givenName: "",
  familyName: "",
  email: "",
  communicationEmail: "",
  access: "",
};

const AuditCredentialForm = ({
  auditType,
  auditCriteria,
  auditCasesLength,
  isOpen,
  onClose,
}: AuditCredentialFormProps) => {
  const [auditors, setAuditors] = useState<AuditorDetailsProps[]>([]);
  const [givenName, setAuditorGivenName] = useState("");
  const [familyName, setAuditorFamilyName] = useState("");
  const [email, setAuditorUsername] = useState("");
  const [communicationEmail, setAuditorContactEmail] = useState("");
  const [expiry, setExpiry] = useState("");
  const [confirmSent, setConfirmSent] = useState(false);

  const { control } = useForm({
    defaultValues: initialFormValues,
  });

  const { currentJourney } = useContext(JourneyContext);

  const { mutate, isLoading } = useCreateAuditor({
    onSuccess: () => {
      toast.success("Auditor invite(s) sent!");
      onClose();
      setConfirmSent(false);
    },
    onError: () => toast.error("Could not create Auditor"),
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "givenName") {
      setAuditorGivenName(value);
    } else if (name === "familyName") {
      setAuditorFamilyName(value);
    } else if (name === "email") {
      setAuditorUsername(value);
    } else if (name === "communicationEmail") {
      setAuditorContactEmail(value);
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  const handleAddAuditor = () => {
    const newAuditor: AuditorDetailsProps = {
      givenName: givenName,
      familyName: familyName,
      email: email,
      communicationEmail: communicationEmail,
    };

    setAuditors((prevAuditors) => [...prevAuditors, newAuditor]);
    setAuditorGivenName("");
    setAuditorFamilyName("");
    setAuditorUsername("");
    setAuditorContactEmail("");
    formRef.current?.reset();
  };

  const handleRemoveAuditor = (index: number) => {
    setAuditors((prevAuditors) => {
      const updatedAuditors = [...prevAuditors];
      updatedAuditors.splice(index, 1);
      return updatedAuditors;
    });
  };

  const handleCreateAuditor = () => {
    mutate({
      auditorType:
        auditType === "internal" ? "INTERNAL_AUDITOR" : "ICE_AUDITOR",
      expiry: expiry,
      auditors,
      auditCriteria,
    });
  };

  const closeDrawer = () => {
    onClose();
    setConfirmSent(false);
  };

  const todayDate = dayjs();
  const maxAuditorAccessDate = todayDate.add(90, "day");

  return (
    <Drawer anchor={"right"} open={isOpen} onClose={closeDrawer}>
      <DrawerHeader />
      <Box sx={{ width: 400, maxWidth: "40vw" }}>
        <Grid container item p={2}>
          <Grid item xs={9} style={{ fontWeight: 600, fontSize: "24px" }}>
            Credentials Creation
          </Grid>
          <Grid item xs={12}>
            <p>
              Complete all the requirements below to set-up credentials for an{" "}
              {auditType === "internal" ? "Internal" : "ICE"} auditor.
            </p>
            <p>
              A Communication will be sent to the auditor’s email with the
              Credentials provided.
            </p>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontWeight: 600 }}>Audit Criteria</p>
            <p style={{ fontSize: "14px" }}>
              Audit Reference: {auditCriteria?.auditReference}
              <br />
              Date Range: {formatDate(auditCriteria?.dateFrom || "")} -{" "}
              {formatDate(auditCriteria?.dateTo || "")}
              <br />
              Location(s):
              <br />
              {auditCriteria.locationIds.map((id) => {
                const locationInfo = currentJourney?.locations.find(
                  (obj) => obj.id === Number(id)
                );
                return (
                  <span key={id}>
                    {locationInfo?.street}, {locationInfo?.cityTown} -{" "}
                    {locationInfo?.state}
                    <br />
                  </span>
                );
              })}
              <br />
              Total I-9 Cases: {auditCasesLength}
              <br />
            </p>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <form ref={formRef}>
              <p style={{ fontWeight: 600, marginBottom: 0 }}>
                Auditor(s) Details
              </p>
              {auditors.map((item, index) => {
                return (
                  <Grid
                    container
                    alignItems={"center"}
                    item
                    xs={12}
                    key={index}
                  >
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <AuditCredentialFormInput
                        value={`${item.givenName} ${item.familyName}`}
                        disabled
                        fullWidth
                        label={`Auditor #${index + 1}`}
                        size={"small"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <p
                        onClick={() => handleRemoveAuditor(index)}
                        style={{
                          cursor: "pointer",
                          fontSize: "12px",
                          margin: "5px 0 0 0",
                          textAlign: "right",
                          textDecoration: "underline",
                        }}
                      >
                        Remove
                      </p>
                    </Grid>
                  </Grid>
                );
              })}
              {AuditCredentialFormFields.map((field, index) => {
                return (
                  <Grid
                    container
                    alignItems={"center"}
                    item
                    xs={12}
                    key={index}
                  >
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <AuditCredentialFormInput
                        name={field.name}
                        onChange={handleInputChange}
                        fullWidth
                        size={"small"}
                        placeholder={field.placeholder}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonOutlineOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              <AddAuditorButton onClick={handleAddAuditor} startIcon={<Add />}>
                Add auditor
              </AddAuditorButton>
              <Divider />
            </form>
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontWeight: 600 }}>Access</p>
            <Controller
              name={"access"}
              control={control}
              rules={{ required: true }}
              render={() => (
                <>
                  <StartEndDateField
                    // eslint-disable-next-line
                    onChange={(expiryDate: any) =>
                      setExpiry(expiryDate.toISOString())
                    }
                    maxDate={maxAuditorAccessDate}
                    slotProps={{
                      textField: {
                        placeholder: "Access Termination Date*",
                      },
                      actionBar: {
                        actions: ["clear"],
                        sx: {
                          background: "#002554",
                        },
                      },
                    }}
                  />
                </>
              )}
            />
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            flexGrow: 1,
            padding: 20,
            borderTop: 1,
            borderColor: "divider",
          }}
        >
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <SecondaryButton variant="contained" onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={() => setConfirmSent(!confirmSent)}
              disabled={auditors.length === 0 || !expiry}
            >
              Continue
            </PrimaryButton>
          </Grid>
        </Grid>
        <ConfirmationModal
          container
          item
          xs={12}
          style={{
            flexGrow: 1,
            borderTop: 1,
            borderColor: "divider",
            display: confirmSent ? "block" : "none",
          }}
        >
          <Grid item xs={12} className="textinfo">
            <img src={ConfirmationModalBG} alt="Sending Credentials" />
            <Typography variant="h3">Sending Credentials</Typography>
            <p>You are about to authorize credentials for an external party.</p>
            <p>
              Before clicking ‘Send’, please review and verify that all
              information provided is accurate.
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{
              padding: 2,
            }}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => setConfirmSent(!confirmSent)}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={handleCreateAuditor}
              disabled={auditors.length === 0 || isLoading}
            >
              Send
            </PrimaryButton>
          </Grid>
        </ConfirmationModal>
      </Box>
    </Drawer>
  );
};

export default AuditCredentialForm;
