import { useState } from "react";

import { Container, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { InstructionBottomBarItem, InstructionsBottomBarBox, InstructionsBottomBarToggle } from "./styles";

import { InteractionScreen } from "../../models/responses/Cases/CaseSpecifications/CaseInteractiveEvents/InteractionScreen";

type InstructionsBottomBarProps = {
  content?: {
    interactionType: string;
    buttonLabel: string;
    screens: InteractionScreen[];
    caseEventType: string;
    payload: string[];
  }
}

const InstructionsBottomBar = ( { content }: InstructionsBottomBarProps ) => {
  const [isInstructionsOpen, setInstructionsOpen] = useState(false);

  return (
    <div>
      <InstructionsBottomBarBox disableRipple>
        <InstructionsBottomBarToggle
          onClick={() => setInstructionsOpen(!isInstructionsOpen)}
        >
          {content?.buttonLabel}
          {isInstructionsOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </InstructionsBottomBarToggle>
        <Container
          sx={{ display: isInstructionsOpen ? "block" : "none !important" }}
        >
          {content?.screens.map((item, index) => (
            <InstructionBottomBarItem direction="row" spacing={1} alignItems="center" key={index}>
              <Typography
                variant="h3"
                sx={{ fontSize: "34px", fontWeight: "bold" }}
              >
                {item.title}
              </Typography>
              <img src={`data:image/svg+xml;utf8,${encodeURIComponent(item.icon)}`} />
              <p>{item.description}</p>
            </InstructionBottomBarItem>
          ))}
        </Container>
      </InstructionsBottomBarBox>
    </div>
  );
}

export default InstructionsBottomBar