import { useContext } from "react";
import { Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";

import AuthenticationContext from "../../context/AuthenticationContext";
import { useCaseList } from "../../hooks/Cases/useCaseList";
import { formatDate } from "../../utils/DateUtils/dateUtils";

import AuditorHeaderBar from "../../components/AuditorHeaderBar";
import AuditorCaseList from "./AuditorCaseList";
import JourneyContext from "../../context/JourneyContext";

const Auditor = () => {
  const { auditRole, defaultBrandId, dateFrom, dateTo, locationIds } =
    useContext(AuthenticationContext);
  const { currentJourney } = useContext(JourneyContext);
  const { allCases } = useCaseList(currentJourney?.brandId || defaultBrandId);

  return (
    <AuditorHeaderBar>
      {allCases?.length != 0 ?
      <Grid container spacing={2} sx={{ p: 3, marginTop: "30px" }}>
        <Grid item xs={12}>
          <Grid container sx={{ p: 5 }}>
            <Grid item xs={5}>
              <Grid container alignItems={"top"} item xs={12}>
                <Grid item xs={6}>
                  Date Range
                </Grid>
                <Grid item xs={6} sx={{ marginBottom: 2 }}>
                  <TextField
                    fullWidth
                    size={"small"}
                    value={`${formatDate(dateFrom || "")} - ${formatDate(
                      dateTo || ""
                    )}`}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarToday />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"top"} item xs={12}>
                <Grid item xs={6}>
                  Total Case Count
                </Grid>
                <Grid item xs={6} sx={{ marginBottom: 2 }}>
                  <TextField
                    fullWidth
                    size={"small"}
                    value={allCases?.length}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Divider orientation="vertical" variant="middle" flexItem />
            </Grid>
            <Grid item xs={5}>
              <Grid container alignItems={"top"} item xs={12}>
                <Grid item xs={4}>
                  Locations
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size={"small"}
                    value={
                      locationIds
                        ? locationIds.map((id) => {
                            const locationInfo =
                              currentJourney?.locations.find(
                                (obj) => obj.id === Number(id)
                              );
                            return `${locationInfo?.street}, ${locationInfo?.cityTown} - ${locationInfo?.state}\n`;
                          })
                        : ""
                    }
                    multiline
                    rows={4}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ margin: "0px 0px 20px 0px" }} />
        </Grid>
        <Grid item xs={12}>
          {allCases && (
            <AuditorCaseList cases={allCases} auditorType={auditRole} />
          )}
        </Grid>
      </Grid>
      : 
      <Grid container spacing={2} sx={{ padding: '5em 3em 3em 3em', marginTop: "100px" }}>
        <Grid item xs={12}>
          <Typography color={"error"} padding={1}>No cases have been shared with this account.</Typography>
        </Grid>
      </Grid>
      }
    </AuditorHeaderBar>
  );
};

export default Auditor;
