import {GenericJsonObject} from "../../../models/responses/Cases/CaseResponse";
import {AppBar, Grid, Typography} from "@mui/material";
import {FC, useState} from "react";
import {DetailsBlock, DetailsFields, StatusLabel} from "../common";
import {formatFullDate} from "../../../utils/DateUtils/dateUtils";
import {WORK_AUTH_STATUS_DICTIONARY} from "../../../constants/dictionaries"
import {PrimaryButton, SecondaryButton} from "../../../pages/CreateCase/CreateCaseForm/styles";
import axios from "axios";
import {toast} from "react-toastify";
import {useAxiosParams} from "../../../hooks/common/useAxiosParams";
import {CaseTabContext, TabMode} from "../../../pages/CaseDetails/useCaseControl.hook";
import NotificationModal from "../../NotificationModal";
import {ReactComponent as LargeCorrectionIcon} from "../../../img/large-correction-icon.svg";
import {useNavigate} from "react-router-dom";
import {ConfirmationModelContainer, useConfirmationModal} from "../../ConfirmationModal";
import I9Form from "../I9Form";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const SectionOnePanel: FC<{
    data: GenericJsonObject, prevData?: GenericJsonObject,
    i9PdfFile?: Blob | undefined,
    context?: CaseTabContext, handleRefresh?: () => void
}>
    = ({data, prevData, i9PdfFile, context, handleRefresh}) => {
    // eslint-disable-next-line
    const employeeData: any = data.employee;
    if (!employeeData.work_auth.foreign_passport) {
        employeeData.work_auth.foreign_passport = {number: "", country: ""};
    }
    // eslint-disable-next-line
    const preparerData: any = data.preparer || {
        "last_name": "",
        "first_name": "",
        "middle_initial": "",
        "address": {
            "street": "",
            "city": "",
            "state": "",
            "zip": ""
        },
        "signature": {
            "image": "",
            "date": ""
        }
    };
    // eslint-disable-next-line
    const prevEmployeeData: any = prevData?.employee;
    if (prevEmployeeData && !prevEmployeeData.work_auth.foreign_passport) {
        prevEmployeeData.work_auth.foreign_passport = {number: "", country: ""};
    }
    // eslint-disable-next-line
    const prevPreparerData: any = (!prevData) ? null : (prevData?.preparer || {
        "last_name": "",
        "first_name": "",
        "middle_initial": "",
        "address": {
            "street": "",
            "city": "",
            "state": "",
            "zip": ""
        },
        "signature": {
            "image": "",
            "date": ""
        }
    });

    const caseId = context?.caseId ? context.caseId : "";

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [correctionFields, setCorrectionFields] = useState<string[]>([]);
    const [submitSuccessModalOpen, setSubmitSuccessModalOpen] = useState<boolean>(false);
    const [validateModalOpen, setValidateModalOpen] = useState<boolean>(false);


    const modal = useConfirmationModal();

    const {basePath, config} = useAxiosParams();
    const navigate = useNavigate();

    const handleItemSelected = (field: string) => {
        if (correctionFields.includes(field)) {
            // eslint-disable-next-line
            setCorrectionFields(correctionFields.filter((value, index, arr) => value !== field));
        } else {
            setCorrectionFields([...correctionFields, field]);
        }
    }

    const isCorrectionMode = (): boolean => {
        return context?.mode === TabMode.CORRECTION;
    }

    const isWaitingForCorrectionStatus = (): boolean => {
        return context?.caseStatus === "SECTION_1_CORRECTION";
    }

    const isCorrectingField = (field: string): boolean => {
        return context?.correctedFields?.includes(field) || correctionFields.includes(field);
    }

    const showLipstick = (field: string): boolean => {
        return isWaitingForCorrectionStatus() && isCorrectingField(field);
    }

    const decorate = (prevValue: string | undefined, currentValue: string) => {
        return prevValue && prevValue !== currentValue ?
            `<span style="text-decoration: line-through;">${prevValue}</span>&nbsp&nbsp${currentValue}`
            : `${currentValue}`;
    }

    const getWorkAuthStatusValue = (key: string) => {
        return WORK_AUTH_STATUS_DICTIONARY[key] || `Unknown status: ${key}`;
    }

    const handleCancel = () => {
        setCorrectionFields([]);
        handleRefresh && handleRefresh();
    }

    const handleCancelCorrectionRequest = () => {
        modal.openConfirmationModal({
            message: "Are you sure you want to leave? You will lose your unsaved progress.",
            confirmButtonLabel: "Confirm",
            onConfirm: handleCancel,
            onCancel: () => undefined
        })
    }

    const onRequestCorrection = () => {
        if (correctionFields.length <= 0) {
            setValidateModalOpen(true);
        } else {
            setIsDisabled(!isDisabled);
            axios
                .post(
                    basePath + `/${caseId}/events`,
                    {
                        caseEventType: "MANUAL_REJECT",
                        payload: {
                            'reviewReasons': correctionFields
                        }
                    },
                    config
                )
                .then(() => {
                    setIsDisabled(false);
                    setSubmitSuccessModalOpen(true);
                    setTimeout(function () {
                        setSubmitSuccessModalOpen(false)
                        navigate(0);
                    }, 1000);
                })
                // eslint-disable-next-line
                .catch((error: any) => {
                    setIsDisabled(false);
                    toast.error("Could not  send correction to candidate");
                    console.log(error);
                });
        }
    };

    try {
        return <><Grid container p={2} alignItems={"flex-start"}>
            <Grid container flexDirection={"row"} item xs={12} style={{marginBottom: 60}}>
                <DetailsBlock xs={12} borderBottom>
                    <Grid container item xs={12} style={{fontWeight: 600, marginBottom: 10}}>
                        <Grid item xs={8} style={{fontWeight: 600}}>Candidate Details</Grid>
                        <Grid container item xs={4} alignItems={"center"} justifyContent={"flex-end"}>
                            <StatusLabel
                                visible={!isCorrectionMode()}
                                backgroundColor={"rgba(144,235,191, 0.6)"}
                                borderColor={"#85E88B"}
                                label={isWaitingForCorrectionStatus() ? "1: Correction Pending" : "1: Completed"}
                            />
                        </Grid>
                    </Grid>

                    <DetailsFields
                        keyPrefix={"candidate-"}
                        handleItemSelected={handleItemSelected}
                        showLipstick={showLipstick}
                        isCorrectingField={isCorrectingField}
                        selectedFields={correctionFields}
                        items={[
                            {
                                label: "Last name (Family name)",
                                value: decorate(prevEmployeeData?.last_name, employeeData.last_name),
                                field: "employee_last_name",
                                isCheckBoxVisible: isCorrectionMode()
                            },
                            {
                                label: "First name (Given name)",
                                value: decorate(prevEmployeeData?.first_name, employeeData.first_name),
                                field: "employee_first_name",
                                isCheckBoxVisible: isCorrectionMode()
                            },
                            {
                                label: "Middle Initial",
                                value: decorate(prevEmployeeData?.middle_initial, employeeData.middle_initial),
                                field: "employee_middle_initial",
                                isCheckBoxVisible: isCorrectionMode(),
                                xs: 3
                            },
                            {
                                label: "Other last name used (if any)",
                                field: "employee_other_last_name_used",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.other_last_names, employeeData.other_last_names),
                            }
                        ]}
                    />
                </DetailsBlock>

                <DetailsBlock xs={12} title={"Contact Details"} borderBottom>
                    <DetailsFields
                        keyPrefix={"candidate-"}
                        handleItemSelected={handleItemSelected}
                        showLipstick={showLipstick}
                        isCorrectingField={isCorrectingField}
                        selectedFields={correctionFields}
                        items={[
                            {
                                label: "Address: Street number & name",
                                field: "employee_address",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.address.street, employeeData.address.street),
                            },
                            {
                                label: "Apartment number",
                                field: "employee_apt_number",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.address.apt_number, employeeData.address.apt_number),
                            },
                            {
                                label: "City / Town",
                                field: "employee_city_or_town",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.address.city, employeeData.address.city),
                            },
                            {
                                label: "State",
                                field: "employee_state",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.address.state, employeeData.address.state),
                                xs: 3
                            },
                            {
                                label: "ZIP code",
                                field: "employee_zip_code",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.address.zip, employeeData.address.zip),
                                xs: 3
                            },
                            {
                                label: "Date of birth",
                                field: "employee_date_of_birth",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate((prevEmployeeData ? formatFullDate(prevEmployeeData.date_birth) : undefined),
                                    formatFullDate(employeeData.date_birth)),
                                xs: 3
                            },
                            {
                                label: "Social Security Number",
                                field: "employee_ssn",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.ssn, employeeData.ssn),
                                xs: 3
                            },
                            {
                                label: "Email",
                                field: "employee_email",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.email, employeeData.email),
                            },
                            {
                                label: "Cell / Mobile Number",
                                field: "employee_telephone_number",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.telephone, employeeData.telephone),
                                xs: 3
                            }
                        ]}
                    />
                </DetailsBlock>

                <DetailsBlock xs={12} borderBottom>
                    <DetailsFields
                        keyPrefix={"work_auth-"}
                        handleItemSelected={handleItemSelected}
                        showLipstick={showLipstick}
                        isCorrectingField={isCorrectingField}
                        selectedFields={correctionFields}
                        items={[
                            {
                                label: "Citizen immigration status",
                                field: "employee_citizenship",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate((prevEmployeeData ? getWorkAuthStatusValue(prevEmployeeData.work_auth.status) : undefined),
                                    getWorkAuthStatusValue(employeeData.work_auth.status))

                            },
                            {
                                label: "Expiration date",
                                field: "employee_expiration_date",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate((prevEmployeeData ? (prevEmployeeData.work_auth.expiration_date !== '' ? dayjs.utc(prevEmployeeData.work_auth.expiration_date).format('MM/DD/YYYY') : '') : undefined),
                                    dayjs.utc(employeeData.work_auth.expiration_date).format('MM/DD/YYYY')),
                                xs: 3
                            },
                            {
                                label: "USCIS A-Number",
                                field: "employee_alien_reg_number_or_uscis_number",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.work_auth.alien_registration_or_uscis_number,
                                    employeeData.work_auth.alien_registration_or_uscis_number),
                                xs: 3
                            },
                            {
                                label: "Form I-94 admission number",
                                field: "employee_form_i94_admission_number",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.work_auth.i94_number, employeeData.work_auth.i94_number),
                            },
                            {
                                label: "Foreign passport number",
                                field: "employee_passport_number",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.work_auth.foreign_passport.number,
                                    employeeData.work_auth.foreign_passport.number),
                                xs: 3
                            },
                            {
                                label: "Country of issuance",
                                field: "employee_country_of_issue",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevEmployeeData?.work_auth.foreign_passport.country,
                                    employeeData.work_auth.foreign_passport.country),
                                xs: 3
                            },
                            {
                                label: "Signature",
                                value: employeeData.signature ? `${employeeData.signature.image}` : "",
                                image: employeeData.signature ? employeeData.signature.imageQuery : undefined,
                                xs: 3
                            },
                            {
                                label: "Date",
                                value: employeeData.signature.date && employeeData.signature.date !== '' ? dayjs.utc(`${employeeData.signature.date}`).format('MM/DD/YYYY') : "",
                                xs: 3
                            }
                        ]}
                    />
                </DetailsBlock>

                <DetailsBlock xs={12} borderBottom>
                    <DetailsFields
                        keyPrefix={"preparer-"}
                        handleItemSelected={handleItemSelected}
                        showLipstick={showLipstick}
                        isCorrectingField={isCorrectingField}
                        selectedFields={correctionFields}
                        items={[
                            {
                                label: "Preparer and/or translator certification",
                                field: "employee_has_preparer_or_translator",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: (preparerData && preparerData.first_name && preparerData.last_name && (preparerData.first_name !== "" || preparerData.last_name !== "")) ?
                                    "A preparer(s) and/or translator(s) assisted me in completing Section 1."
                                    : "I did not use a preparer or translator."
                            },
                            {
                                label: "Signature",
                                value: preparerData.signature? `${preparerData.signature.image}` : "",
                                image: preparerData.signature? preparerData.signature.imageQuery : undefined,
                                xs: 3
                            },
                            {
                                label: "Date",
                                value: preparerData.signature ? dayjs.utc(`${preparerData.signature.date}`).format('MM/DD/YYYY') : "",
                                xs: 3
                            },
                            {
                                label: "Last Name",
                                field: "preparer_or_translator_last_name",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevPreparerData?.last_name, preparerData.last_name),
                            },
                            {
                                label: "First Name",
                                field: "preparer_or_translator_first_name",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevPreparerData?.first_name, preparerData.first_name),
                            },
                            {
                                label: "Middle Initial",
                                value: decorate(prevPreparerData?.middle_initial, preparerData.middle_initial),
                                field: "preparer_or_translator_middle_initial",
                                isCheckBoxVisible: isCorrectionMode(),
                                xs: 3
                            },
                            {
                                label: "Address: Street number & name",
                                field: "preparer_or_translator_home_address",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevPreparerData?.address?.street, preparerData.address?.street),
                            },
                            {
                                label: "State",
                                field: "preparer_or_translator_state",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevPreparerData?.address?.state, preparerData.address?.state),
                                xs: 3
                            },
                            {
                                label: "ZIP code",
                                field: "preparer_or_translator_zip_code",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevPreparerData?.address?.zip, preparerData.address?.zip),
                                xs: 3
                            },
                            {
                                label: "City / Town",
                                field: "preparer_or_translator_city_or_town",
                                isCheckBoxVisible: isCorrectionMode(),
                                value: decorate(prevPreparerData?.address?.city, preparerData.address?.city),
                            }
                        ]}
                    />
                </DetailsBlock>
                <DetailsBlock xs={12} borderBottom title="Documents">
                    <Grid item container xs={7}>
                        <I9Form i9PdfFile={i9PdfFile}/>
                    </Grid>
                </DetailsBlock>
                <ConfirmationModelContainer {...modal}/>
            </Grid>
            <AppBar
                position="fixed"
                sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    top: "auto",
                    bottom: 0,
                    background: "white",
                }}
                style={{display: isCorrectionMode() ? "" : "none"}}
            >
                <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                    <Grid item xs={8}></Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <SecondaryButton variant="contained"
                                         onClick={handleCancelCorrectionRequest}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={() => onRequestCorrection()}
                            disabled={isDisabled}
                        >
                            Submit
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </AppBar>
        </Grid>
            <NotificationModal
                isOpen={submitSuccessModalOpen}
                text="Correction sent to candidate"
                icon={<LargeCorrectionIcon/>}
            />
            <NotificationModal
                isOpen={validateModalOpen}
                handleOk={() => setValidateModalOpen(false)}
                text="Please select a field for correction"
            />
        </>
    } catch (e) {
        console.error(e)
        return <Typography color={"error"} padding={1}> Unexpected data received for Section 1 </Typography>
    }
}
