import {useReferCase} from "../../../hooks/Cases/useMutateCase";
import {Dialog, DialogContent, DialogTitle, Grid, Radio, Stack, TextField} from "@mui/material";
import {Close} from "@mui/icons-material";
import {PrimaryButton, SecondaryButton} from "../../CreateCase/CreateCaseForm/styles";
import {useCallback, useState} from "react";

type ReferProps = {
    onClose: () => void;
    caseId: string;
};

const reasons = [
    "Candidate missed call",
    "Candidate didn't have documentation with them"
]

const StyledRadio = ({checked, onClick} : {checked: boolean, onClick: ()=>void}) => {
    return <Radio onClick={onClick} checked={checked} sx={{color: checked ? "#1CDBBC!important" : "#666!important"}}/>
}

const ReferModal = ({
                                  onClose,
                                  caseId,
                              }: ReferProps) => {
    const { mutate, isLoading } = useReferCase(caseId);

    const [selectedReason, setSelectedReason] = useState<string | null>(reasons[0]);
    const [otherReason, setOtherReason] = useState<string>("");
    const [isValid, setIsValid] = useState(true);

    const selectReasonHandler = (val: string | null) => {
        setSelectedReason(val);
        if (val) {
            setOtherReason("");
        }
    }

    const referHandler = useCallback(()=>{
        const reason = selectedReason || otherReason;
        if (selectedReason == null && otherReason.length == 0) {
            setIsValid(false);
            return;
        }
        mutate(reason);
    }, [selectedReason, otherReason])

    return (
        <Dialog open={true} onClose={onClose} maxWidth={"sm"} fullWidth>
            <DialogTitle sx={{ fontWeight: "bold" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <p style={{ margin: 0 }}>Refer</p>
                    <p style={{ margin: 0 }} onClick={onClose}>
                        <Close fontSize="small" />
                    </p>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <p
                    style={{
                        fontSize: "16px",
                        padding: "0",
                        marginBottom: "10px",
                        color: "#505050",
                    }}
                >
                    Please provide your reason below.
                </p>
                <Grid container sx={{
                    fontSize: "16px",
                    color: "#1A1A1A",
                }}>
                    {
                        reasons.map((reason, idx) => <Grid container item alignItems={"center"} key={"reason-" + idx}>
                            <Grid item xs={8}>
                                {reason}
                            </Grid>
                            <Grid item xs={4}>
                                <StyledRadio checked={selectedReason === reason} onClick={()=>{selectReasonHandler(reason)}}/>
                            </Grid>
                        </Grid>)
                    }

                    <Grid container item alignItems={"center"}>
                        <Grid item xs={8}>Other reason</Grid>
                        <Grid item xs={4}><StyledRadio checked={selectedReason === null} onClick={()=>{selectReasonHandler(null)}}/></Grid>
                    </Grid>
                    <TextField
                        value={otherReason}
                        onFocus={() => setSelectedReason(null)}
                        onChange={(evt) => setOtherReason(evt.target.value)}
                        fullWidth
                        multiline={true}
                        rows={4}
                        placeholder="Other reason not listed above:"
                        error={!isValid}
                        helperText={!isValid ? "This field should not be empty" : ""}
                    />
                </Grid>
                <Grid container>
                    <Grid
                        container
                        item
                        xs={12}
                        style={{ flexGrow: 1, padding: 1, marginTop: "30px" }}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <SecondaryButton variant="contained" onClick={onClose}>
                                Return to Verification
                            </SecondaryButton>
                            <PrimaryButton disabled={isLoading} onClick={referHandler}>
                                Refer
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ReferModal;
