import {useAxiosParams} from "../common/useAxiosParams";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {OAuthStatus} from "../../models/responses/Meetings/OAuthStatus";

export const useSaveOAuthToken = () => {
    const {orchestratorPath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async ({code, provider}: { code: string, provider: string }) => {
            const response = await axios.post<OAuthStatus>(orchestratorPath + `/meetings/${provider}/token?code=${code}`, {}, config);
            return response.data;
        },
        onSuccess: (data) => {
            cache.setQueryData(["oauth-status/me"], data);
            setTimeout(() => {window.location.href = window.location.origin;}, 1000);
        }
    });
}

export const useRevokeOAuthToken = () => {
    const {orchestratorPath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await axios.get<OAuthStatus>(orchestratorPath + `/meetings/revoke`, config);
            return response.data;
        },
        onSuccess: (data) => {
            cache.setQueryData(["oauth-status/me"], data);
            setTimeout(() => {window.location.href = window.location.origin;}, 1000);
        }
    });
}
