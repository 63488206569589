import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface Address {
  id: number;
  name: string;
  street: string;
  cityTown: string;
  state: string;
  zip: string;
  timezone: string;
}

export interface Coordinates {
  lat: number;
  lon: number;
  name?: string;
}

async function getLocation(addresses?: Address[]): Promise<Coordinates[]> {
  const baseUrl = "https://nominatim.openstreetmap.org/search";
  if (!addresses) return [];
  const promises = addresses.map(async (address) => {
    try {
      const response = await axios.get(baseUrl, {
        params: {
          q: `${address.street}, ${address.cityTown}, ${address.state}, ${address.zip}`,
          format: "json",
        },
      });

      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        return {
          lat: parseFloat(lat),
          lon: parseFloat(lon),
          name: `${address.street}, ${address.cityTown}, ${address.state}`,
        };
      } else {
        return { lat: 0, lon: 0, name: "" };
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.error(
        `Error fetching coordinates for address with ID ${address.id}: ${error.message}`
      );
      return { lat: 0, lon: 0, name: "" };
    }
  });

  const results = await Promise.all(promises);
  return results;
}

export const useLocation = (addresses?: Address[]) => {
  const locationCoordinates = useQuery<Coordinates[], Error>({
    queryKey: ["locations", addresses],
    queryFn: () => getLocation(addresses),
    refetchInterval: 2 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
  });

  const getMiddleCoordinate = (
    coordinates: Coordinates[]
  ): Coordinates | null => {
    if (coordinates.length === 0) {
      return null;
    }

    const totalCoordinates = coordinates.length;
    let totalLat = 0;
    let totalLon = 0;

    for (const coordinate of coordinates) {
      totalLat += coordinate.lat;
      totalLon += coordinate.lon;
    }

    const middleLat = totalLat / totalCoordinates;
    const middleLon = totalLon / totalCoordinates;

    return {
      lat: middleLat,
      lon: middleLon,
    };
  };

  return {
    locationCoordinates: locationCoordinates.data,
    middleCoordinate: getMiddleCoordinate(locationCoordinates.data || []),
  };
};
