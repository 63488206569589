import { Button, TextField } from "@mui/material";
import { styled, CSSObject } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const CaseFinderDateInput = styled(DatePicker)(
  ({ theme }): CSSObject => ({
    background: theme.palette.secondary.light,
    marginBottom: "20px",
    width: "100%",
    ".MuiOutlinedInput-root": {
      paddingLeft: "8px",
      flexDirection: "row-reverse",
      ".MuiButtonBase-root": {
        padding: 0,
        margin: "0px 7px 0px -7px",
      },
      ".MuiSvgIcon-root": {
        fontSize: "16px",
      },
      input: {
        padding: "5px 10px",
        fontSize: "14px",
        borderRadius: "8px",
      },
    },
  })
);

export const CaseFinderInput = styled(TextField)(
  ({ theme }): CSSObject => ({
    background: theme.palette.secondary.light,
    marginBottom: "20px",
    width: "100%",
    ".MuiFormLabel-root":{
      fontSize: '14px',
      marginTop: '-9px'
    },
    ".MuiOutlinedInput-root": {
      paddingLeft: "8px",
      ".MuiSvgIcon-root": {
        fontSize: "16px",
      },
      ".MuiSelect-select": {
        padding: "5px 10px",
        fontSize: "14px",
        borderRadius: "8px",
      },
      input: {
        padding: "5px 10px",
        fontSize: "14px",
        borderRadius: "8px",
      },
    },
  })
);

export const SearchButton = styled(Button)(
  ({ theme }): CSSObject => ({
    color: theme.palette.primary.main,
    borderRadius: "8px",
    background: theme.palette.secondary.light,
    border: "1px solid #000",
    textTransform: "none",
    padding: "0px 20px",
    fontSize: "14px",
    marginTop: "10px",
  })
);
