import { Stack } from "@mui/material";
import { Flag, FlagOutlined, FlagTwoTone } from "@mui/icons-material";

type CaseListEverifyIdProps = {
  status: string;
  id?: string;
};

type everifyBadgeIconProps = {
  icon: React.ReactNode;
};

const everifyBadgeIcon: Record<string, everifyBadgeIconProps> = {
  FINAL_NONCONFIRMATION: {
    icon: <Flag fontSize="small" />,
  },
  EMPLOYMENT_AUTHORIZED: {
    icon: <FlagOutlined fontSize="small" />,
  },
};

const CaseListEverifyId = ({ status, id }: CaseListEverifyIdProps) => {
  const defaultBadge = { icon: <FlagTwoTone /> };
  const { icon } = everifyBadgeIcon[status] ? everifyBadgeIcon[status] : defaultBadge;

  if(!id) return null;

  return (
    <Stack direction="row" useFlexGap flexWrap="wrap">
      {icon} <p style={{padding: 0, margin: 0, color: '#2253FF'}}>{id}</p>
    </Stack>
  );
};

export default CaseListEverifyId;
