import * as yup from "yup";
import {matchIsValidTel} from "mui-tel-input";

export const CreateCaseFormFields = [
  {
    name: "candidateReference",
    label: "Candidate reference*",
    placeholder: "ABCD1234ABCD",
  },
  {
    name: "lastName",
    label: "Last name (Family name)*",
    placeholder: "Doe",
  },
  {
    name: "firstName",
    label: "First name (Given name)*",
    placeholder: "Sally",
  },
  {
    name: "divider",
    type: "divider",
  },
  {
    name: "email",
    label: "Email Address*",
  },
  {
    name: "mobileNumber",
    label: "Cell / Mobile Number*",
    sx: 3,
  },
  {
    name: "divider",
    type: "divider",
  },
  {
    name: "startDate",
    label: "Start Date",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "endDate",
    label: "End Date",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "locationId",
    label: "Location*",
  }
];

export const createCaseSchema = yup.object().shape({
  candidateReference: yup
      .string()
      .required("Please fill the required field to continue."),
  lastName: yup
      .string()
      .required("Please fill the required field to continue."),
  firstName: yup
      .string()
      .required("Please fill the required field to continue."),
  email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please fill the required field to continue."),
  mobileNumber: yup
      .string()
      .required("Please fill the required field to continue."),
  locationId: yup
      .string()
      .required("Please fill the required field to continue."),
  hiringManagerName: yup.string().nullable().optional(),
  hiringManagerEmail: yup.string().nullable().optional(),
  hiringManagerPhone: yup.string().nullable().optional(),
  hiringManagerTitle: yup.string().nullable().optional(),
});

export const CreateI9AltCaseFormFields = [
  {
    name: "candidateDetails",
    type: "subtitle",
    label: "Candidate’s Details"
  },
  {
    name: "candidateReference",
    label: "Candidate reference*",
    placeholder: "ABCD1234ABCD",
  },
  {
    name: "firstName",
    label: "First name (Given Name)*",
    placeholder: "Sally",
  },
  {
    name: "lastName",
    label: "Last name (Family Name)*",
    placeholder: "Doe",
  },
  {
    name: "email",
    label: "Email Address*",
  },
  {
    name: "mobileNumber",
    label: "Cell / Mobile Number*",
    sx: 3,
  },
  {
    name: "startDate",
    label: "Start Date*",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "endDate",
    label: "End Date",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "locationId",
    label: "Location / Company Account*",
  }
];

export const createI9AltCaseSchema = yup.object().shape({
  candidateReference: yup
    .string()
    .required("Please fill the required field to continue."),
  lastName: yup
    .string()
    .required("Please fill the required field to continue."),
  firstName: yup
    .string()
    .required("Please fill the required field to continue."),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please fill the required field to continue."),
  mobileNumber: yup.string()
      .required()
      .test('is-valid-tel', 'Cell / Mobile Number is invalid', function (value) {
        return matchIsValidTel(value);
      }),
  startDate: yup
    .date()
    .min(new Date(1900, 1, 1), "Please enter a valid date")
    .max(new Date(3000, 1, 1), "Please enter a valid date")
    .required("Please fill the required field to continue."),
  locationId: yup
    .string()
    .required("Please fill the required field to continue."),
    hiringManagerName: yup.string().nullable().optional(),
    hiringManagerLastName: yup.string().nullable().optional(),
    hiringManagerEmail: yup.string().nullable().optional(),
    hiringManagerPhone: yup.string().nullable().optional(),
    hiringManagerTitle: yup.string().nullable().optional(),
});

export const CreateI9CaseFormFields = [
  {
    name: "candidateDetails",
    type: "subtitle",
    label: "Candidate’s Details"
  },
  {
    name: "candidateReference",
    label: "Candidate reference*",
    placeholder: "ABCD1234ABCD",
  },
  {
    name: "firstName",
    label: "First name (Given Name)*",
    placeholder: "Sally",
  },
  {
    name: "lastName",
    label: "Last name (Family Name)*",
    placeholder: "Doe",
  },
  {
    name: "email",
    label: "Email Address*",
  },
  {
    name: "mobileNumber",
    label: "Cell / Mobile Number*",
    sx: 3,
  },
  {
    name: "startDate",
    label: "Start Date*",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "endDate",
    label: "End Date",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "locationId",
    label: "Location / Company Account*",
  },
  {
    name: "divider",
    type: "divider",
  },
  {
    name: "hiringManagerDetails",
    type: "subtitle",
    label: "Hiring Manager’s Details"
  },
  {
    name: "hiringManagerLastName",
    label: "Last Name",
    placeholder: "Smith"
  },
  {
    name: "hiringManagerName",
    label: "Given Name",
    placeholder: "John"
  },
  {
    name: "hiringManagerEmail",
    label: "Email Address",
    placeholder: "john.smith@companyname.com"
  },
  {
    name: "hiringManagerPhone",
    label: "Cell / Mobile Number",
    placeholder: "+1 467 567 7854"
  },
  {
    name: "hiringManagerTitle",
    label: "Title",
    placeholder: "Lead, US HR Services"
  },
];

export const createI9CaseSchema = yup.object().shape({
  candidateReference: yup
    .string()
    .required("Please fill the required field to continue."),
  lastName: yup
    .string()
    .required("Please fill the required field to continue."),
  firstName: yup
    .string()
    .required("Please fill the required field to continue."),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please fill the required field to continue."),
  mobileNumber: yup.string()
      .required()
      .test('is-valid-tel', 'Cell / Mobile Number is invalid', function (value) {
        return matchIsValidTel(value);
      }),
  startDate: yup
    .date()
    .min(new Date(1900, 1, 1), "Please enter a valid date")
    .max(new Date(3000, 1, 1), "Please enter a valid date")
    .required("Please fill the required field to continue."),
  locationId: yup
    .string()
    .required("Please fill the required field to continue."),
    hiringManagerName: yup.string().nullable().optional(),
    hiringManagerLastName: yup.string().nullable().optional(),
    hiringManagerEmail: yup.string().nullable().optional(),
    hiringManagerPhone: yup.string().nullable().optional(),
    hiringManagerTitle: yup.string().nullable().optional(),
});

export const CreateRtwCaseFormFields = [
  {
    name: "candidateReference",
    label: "Candidate reference*",
    placeholder: "ABCD1234ABCD",
  },
  {
    name: "lastName",
    label: "Last name (Family name)*",
    placeholder: "Doe",
  },
  {
    name: "firstName",
    label: "First name (Given name)*",
    placeholder: "Sally",
  },
  {
    name: "divider",
    type: "divider",
  },
  {
    name: "email",
    label: "Email Address*",
  },
  {
    name: "mobileNumber",
    label: "Cell / Mobile Number*",
    sx: 3,
  },
  {
    name: "divider",
    type: "divider",
  },
  {
    name: "startDate",
    label: "Start Date*",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "endDate",
    label: "End Date",
    placeholder: "01/01/2021",
    sx: 3,
  },
  {
    name: "locationId",
    label: "Location*",
  },
];

export const createRtwCaseSchema = yup.object().shape({
  candidateReference: yup
      .string()
      .required("Please fill the required field to continue."),
  lastName: yup
      .string()
      .required("Please fill the required field to continue."),
  firstName: yup
      .string()
      .required("Please fill the required field to continue."),
  email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please fill the required field to continue."),
  mobileNumber: yup
      .string()
      .required("Please fill the required field to continue."),
  startDate: yup
      .date()
      .min(new Date(1900, 1, 1), "Please enter a valid date")
      .max(new Date(3000, 1, 1), "Please enter a valid date")
      .required("Please fill the required field to continue."),
  locationId: yup
      .string()
      .required("Please fill the required field to continue."),
  hiringManagerName: yup.string().nullable().optional(),
  hiringManagerEmail: yup.string().nullable().optional(),
  hiringManagerPhone: yup.string().nullable().optional(),
  hiringManagerTitle: yup.string().nullable().optional(),
});

