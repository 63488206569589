import {useParams} from "react-router-dom";
import ConfigService from "../../services/ConfigService/ConfigService";
import {useEffect, useState} from "react";
import './index.css';

const RequestOptOut = () => {
    const {token}: { token: string } = useParams();
    const [success, setSuccess] = useState<boolean>();
    const basePath: string = ConfigService.getConfigValue('ORCHESTRATOR_ENDPOINT');

    const fetchOptOut = async () => {
        try {
            const response = await fetch(basePath + "/request-opting-out", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Opt-Out-Token': token,
                },
                body: JSON.stringify({})
            });
            setSuccess(response.ok);
        } catch (error) {
            setSuccess(false);
        }
    }

    useEffect(() => {
        fetchOptOut();
    }, []);

    return (<div className="content">
        {success === true &&
            <div>
                <h1><b>Thank you for sending your request.</b></h1>
                <p>You will shortly receive a message confirming your opt-out from the alternative procedure,
                    along with instructions for completing your Form I-9.</p><br/>
                <p><b>Note:</b> For your safety and security, the link to access the app in your SMS and email
                    has now been
                    deactivated.</p><br/>
                <p>If you have the myI-9 app, please delete it.</p>
            </div>
        }
        {success === false && <div><h1><b>This link is no longer active.</b></h1></div>
        }
    </div>);
};

export default RequestOptOut;
