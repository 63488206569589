import { Box, Button, Card, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DashboardActionButton = styled(Button)(() => ({
  color: "#1A1A1A",
  width: "100%",
  borderRadius: "8px",
  border: "1px solid #002554",
  padding: "12px 0px",
  marginBottom: "20px",
  textTransform: "none",
}));

export const LocationCard = styled(Card)(() => ({
  border: "1px solid #D7D7D7",
  borderRadius: "16px",
  minHeight: "315px",
  h3: {
    fontSize: "18px",
    color: "#1A1A1A",
    fontWeight: "bold",
    margin: 0,
  },
  p: {
    fontSize: "14px",
    margin: 0,
  },
}));

export const BlankDashboardCard = styled(Grid)(() => ({
  "& .MuiPaper-root": {
    padding: "20px 20px 20px 20px",
    background: "#fff",
    border: "1px solid #D7D7D7",
    borderRadius: "16px",
    height: "100%",
    h3: {
      fontSize: "18px",
      color: "#1A1A1A",
      fontWeight: "bold",
      marginBottom: "15px",
    },
  },
}));

export const RTWTilesGrid = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: '16px'
}))
