import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import {CaseNoteResponse} from "../../models/responses/Cases/CaseNoteResponse";
import {useAxiosParams} from "../common/useAxiosParams";
import { CreateCaseNoteRequest } from "../../models/requests/CreateCaseNoteRequest";

export const useCaseNotes = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()

    return useQuery<unknown, GenericJsonObject, CaseNoteResponse[]>({
        queryKey: ["cases-notes", caseUid],
        queryFn: async () => {
            try {
                const response = await axios.get<CaseNoteResponse[]>(basePath + `/${caseUid}/note`, config);
                return response.data;
            }
            catch (error) {
                const axiosError = error as AxiosError;
                throw axiosError.response?.data || {"message": axiosError.message};
            }
        }
    })
}

export const useCreateCaseNote = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (CreateCaseNoteRequest : CreateCaseNoteRequest) => {
            const response = await axios.post<CaseNoteResponse>(basePath + `/${caseUid}/note`, CreateCaseNoteRequest, config);
            return response.data;
        },
        onSuccess: data => {
            cache.setQueryData<CaseNoteResponse[]>(["cases-notes", caseUid], notes => [...(notes || []), data]);
        },
        onError: (error) => {
            console.error(error)
            alert(error)
        }
    });
}
