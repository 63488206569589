import { styled, CSSObject } from "@mui/material/styles";
import { Paper, Chip, TextField, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const FilterBar = styled(Paper)(
  ({ theme }): CSSObject => ({
    background: theme.palette.secondary.light,
    padding: 10,
    width: "100%",
    p: {
      fontSize: 14,
    },
  })
);

export const FilterBarChip = styled(Chip)(() => ({
  background: "#E9EFF6",
  borderRadius: 8,
  border: "1px solid #A4A4A4",
  svg: {
    fontSize: 14,
    display: "block",
    color: "#333333",
  },
}));

export const FilterSelect = styled(Select)(
  ({ theme }): CSSObject => ({
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "8px",
    fontSize: 0,
    height: "32px",
    "& .MuiSelect-icon": {
      color: "#333",
      fontSize: 18,
      transform: "translateX(-8px)",
    },
    "& .MuiSelect-iconOpen": { transform: "translateX(-8px), rotate(0deg)" },
  })
);

export const FilterBarTextChip = styled(Chip)(() => ({
  background: "#E9EFF6",
  borderRadius: 8,
  "& .MuiChip-deleteIcon": {
    fontSize: 16,
    display: "none",
    color: "#333333",
  },
  "&:hover": {
    ".MuiChip-deleteIcon": {
      display: "block",
    },
  },
}));

export const FilterBarTextField = styled(TextField)(() => ({
  background: "#ffffff",
  borderRadius: 8,
  padding: 0,
  input: {
    padding: "5px 0",
  },
  svg: {
    fontSize: 18,
  },
}));

export const FilterDateInput = styled(DatePicker)(() => ({
  background: "#F6FDFC",
  width: "160px",
  ".MuiOutlinedInput-root": {
    paddingLeft: "8px",
    flexDirection: "row-reverse",
    ".MuiButtonBase-root": {
      padding: 0,
      margin: "0px 7px 0px -7px",
    },
    ".MuiSvgIcon-root": {
      fontSize: "16px",
    },
    input: {
      padding: "5px 10px",
      fontSize: "14px",
      borderRadius: "8px",
    },
  },
}));

export const FilterDateSelect = styled(Select)(() => ({
  backgroundColor: "transparent",
  height: "31px",
  width: "120px",
  fontSize: 14,
  transform: "translateX(17px)",
}));
