import React, {useState} from "react";
import {Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {
    PrimaryButton,
    SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";
import {formatDate, formatNonUSFullDate} from "../../../utils/DateUtils/dateUtils";
import {useScheduleAppointment} from "../../../hooks/Cases/useScheduleAppointment";
import {
    StyledTimePicker,
    StyledToggleButton,
    StyledToggleButtonGroup,
} from "./styles";
import NotificationModal from "../../NotificationModal";

type ScheduleRtwAppointmentModalProps = {
    isOpen: boolean;
    onClose: () => void;
    caseId: string;
    caseStatus: string;
    startDate: string;
    isReschedule?: boolean;
};

const ScheduleRtwAppointmentModal = ({
                                         isOpen,
                                         onClose,
                                         caseId,
                                         caseStatus,
                                         startDate,
                                         isReschedule
                                     }: ScheduleRtwAppointmentModalProps) => {
    const {mutate, isLoading} = useScheduleAppointment(caseId, isReschedule);
    // eslint-disable-next-line
    const formatToString = (date: any) => {
        return new Date(date).toLocaleDateString("sv-SE");
    };
    const [appointmentDate, setAppointmentDate] = useState<string | null>(
        formatToString(startDate)
    );
    const [appointmentTime, setAppointmentTime] = useState<Date | null>();
    const isOnlyPersonAllowed = caseStatus.includes("IN_PERSON");
    const isInPersonMeetingScheduled = caseStatus === "IN_PERSON_MEETING_SCHEDULED";
    const isRemoteMeetingScheduled = caseStatus === "MEETING_SCHEDULED";

    const disableMeetingTypeSelection = isInPersonMeetingScheduled || isRemoteMeetingScheduled || isOnlyPersonAllowed;

    const [inPerson, setInPerson] = useState<boolean>(isInPersonMeetingScheduled || isOnlyPersonAllowed);


    const [validateModalOpen, setValidateModalOpen] = useState<boolean>(false);
    const [validationtText, setValidationText] = useState<string>("");
    const dialogTitle = isReschedule ? "Reschedule Appointment" : "Schedule Appointment";

    const handleScheduleAppointment = () => {
        if (!appointmentDate || !appointmentTime) {

            const validationText =
                !appointmentDate && !appointmentTime
                    ? "Please select date and time"
                    : !appointmentDate
                        ? "Please select a date"
                        : "Please select a time";
            setValidationText(validationText);
            setValidateModalOpen(true);
        } else {
            const startAppointmentDateTimeStr = appointmentDate + `T${new Date(appointmentTime).toLocaleTimeString("sv-SE")}.000Z`;
            const endAppointmentDateTime = new Date(startAppointmentDateTimeStr);
            endAppointmentDateTime.setMinutes(endAppointmentDateTime.getMinutes() + 10);
            const endAppointmentDateTimeStr = endAppointmentDateTime.toISOString();
            mutate({
                startTime: startAppointmentDateTimeStr,
                endTime: endAppointmentDateTimeStr,
                isInPerson: inPerson
            });
        }
    };

    const formatDateToOptionLabel = (date: Date | string) =>
        date === ""
            ? date
            : formatDate(date, {
                weekday: "long",
                day: "2-digit",
                month: "long",
                year: "numeric",
            });

    const handleAppointmentDateChange = (
        event: React.MouseEvent<HTMLElement>,
        newAppointmentDate: string
    ) => {
        if (newAppointmentDate != null) {
            setAppointmentDate(newAppointmentDate);
        }
    };

    // eslint-disable-next-line
    const handleAppointmentTimeChange = (newAppointmentTime: any) => {
        setAppointmentTime(newAppointmentTime);
    };

    const selectableDates = [];
    const date = new Date(startDate);
    selectableDates.push(date);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={"md"} fullWidth>
            <DialogTitle sx={{fontWeight: "bold"}}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{marginBottom: "25px"}}>
                            The available dates identified below provide the extent of the timeframe to complete the
                            remote examination of the employee’s documentation. You can select any one of the available
                            days and a time that is convenient for you and the employee. To schedule the appointment
                            click on the
                            day, input the time and confirm by clicking on the ‘{dialogTitle}’ button. The employee will
                            receive a
                            calendar invite via email.
                        </div>
                    </Grid>
                    <Grid container alignItems={"start"} spacing={10}>
                        <Grid item xs={6}>
                            <div style={{marginBottom: "25px"}}>
                                Candidate start date:
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{marginBottom: "25px"}}>
                                {formatNonUSFullDate(startDate)}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{margin: "0px 0px 20px 0px"}}/>
                    </Grid>
                    <Grid container alignItems={"start"} spacing={10}>
                        <Grid item xs={6}>
                            <div style={{marginBottom: "25px"}}>
                                First Day Check Location
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <RadioGroup
                                row
                                style={{marginBottom: "25px"}}
                                aria-labelledby="schedule-meeting-location"
                                name="schedule-meeting-location"
                                defaultValue={isRemoteMeetingScheduled || !inPerson}
                                onChange={(e) =>
                                    setInPerson(/false/.test(e.target.value))
                                }
                            >
                                <FormControlLabel
                                    value={true}
                                    disabled={disableMeetingTypeSelection}
                                    control={<Radio/>}
                                    label="Remote"
                                />
                                <FormControlLabel
                                    value={false}
                                    disabled={disableMeetingTypeSelection}
                                    control={<Radio/>}
                                    label="In-Person"
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{margin: "0px 0px 20px 0px"}}/>
                    </Grid>
                    <Grid container alignItems={"start"} spacing={10}>
                        <Grid item xs={6}>
                            <div style={{marginBottom: "10px"}}>
                                Select available date
                            </div>
                            <StyledToggleButtonGroup
                                orientation="vertical"
                                color="secondary"
                                aria-label="Appointment date"
                                value={appointmentDate}
                                exclusive
                                onChange={handleAppointmentDateChange}
                            >
                                {selectableDates.map((date, key) => (
                                    <StyledToggleButton value={formatToString(date)} key={key}>
                                        {formatDateToOptionLabel(date)}
                                    </StyledToggleButton>
                                ))}
                            </StyledToggleButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{marginBottom: "10px"}}>Select time slot</div>
                            <StyledTimePicker
                                label="Select time"
                                value={appointmentTime}
                                onChange={handleAppointmentTimeChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        style={{flexGrow: 1, padding: 1, marginTop: "30px"}}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <SecondaryButton variant="contained" onClick={onClose}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                disabled={isLoading || !appointmentTime}
                                onClick={handleScheduleAppointment}
                            >
                                {dialogTitle}
                            </PrimaryButton>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <NotificationModal
                isOpen={validateModalOpen}
                handleOk={() => setValidateModalOpen(false)}
                text={validationtText}
            />
        </Dialog>
    )
        ;
};

export default ScheduleRtwAppointmentModal;
