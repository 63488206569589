import React, { createContext, useCallback, useState } from "react";
import AuthenticationService from "../services/AuthenticationService/AuthenticationService";
import jwt_decode from "jwt-decode";

interface AuthenticationContextInterface {
  token: string | undefined;
  username: string;
  firstname: string;
  familyname: string;
  initials: string;
  email: string;
  brandIds: string[];
  defaultBrandId: string;
  auditRole?: string;
  auditReference?: string;
  dateFrom?: string;
  dateTo?: string;
  locationIds?: string[];
  isGeneralUser: boolean;
  isAdminUser: boolean;
  isSSOUser: boolean;
}

const AuthenticationContext = createContext<AuthenticationContextInterface>({
  token: undefined,
  username: "",
  firstname: "",
  familyname: "",
  initials: "",
  email: "",
  brandIds: [],
  defaultBrandId: "",
  auditRole: undefined,
  auditReference: "",
  dateFrom: "",
  dateTo: "",
  locationIds: [],
  isGeneralUser: false,
  isAdminUser: false,
  isSSOUser: false,
});

export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [token, setToken] = useState<string | undefined>(
    sessionStorage.getItem("auth_token") || undefined
  );
  const [expiry, setExpiry] = useState<number>(
    Number(sessionStorage.getItem("auth_expiry") || new Date().getTime())
  );

  const handleSetToken = useCallback(
    (jwt: string | undefined) => {
      if (jwt) {
        sessionStorage.setItem("auth_token", jwt);
      } else {
        sessionStorage.removeItem("auth_token");
      }
      setToken(jwt);
    },
    [setToken]
  );

  const handleSetExpiry = useCallback(
    (exp: number) => {
      sessionStorage.setItem("auth_expiry", String(exp));
      setExpiry(exp);
    },
    [setExpiry]
  );

  let username = "";
  let firstname = "";
  let familyname = "";
  let initials = "";
  let email = "";
  let brandIds: string[] = [];
  let defaultBrandId = "";
  let auditRole = undefined;
  let auditReference = undefined;
  let dateFrom = undefined;
  let dateTo = undefined;
  let locationIds: string[] | undefined = undefined;
  let isGeneralUser = false;
  let isAdminUser = false;
  let isSSOUser = false;

  if (token) {
    const jwt = jwt_decode<{
      email: string;
      family_name: string;
      given_name: string;
      "custom:brandIds": string;
      "custom:auditRole"?: string;
      "custom:auditReference"?: string;
      "custom:dateFrom"?: string;
      "custom:dateTo"?: string;
      "custom:locationIds"?: string;
      "cognito:groups"?: string[];
      identities?: string[];
    }>(token);
    username = jwt.given_name + " " + jwt.family_name;
    firstname = jwt.given_name;
    familyname = jwt.family_name;
    initials = (jwt.given_name[0] + jwt.family_name[0]).toUpperCase();
    email = jwt.email;
    brandIds = jwt["custom:brandIds"].split(",");
    defaultBrandId = brandIds[0];
    auditRole = jwt["custom:auditRole"];
    auditReference = jwt["custom:auditReference"];
    dateFrom = jwt["custom:dateFrom"];
    dateTo = jwt["custom:dateTo"];
    locationIds = jwt["custom:locationIds"]?.split(",");
    isGeneralUser = !!jwt["cognito:groups"]?.includes("general");
    isAdminUser = !!jwt["cognito:groups"]?.includes("admin");
    isSSOUser = jwt.identities != undefined;
  }

  if (!token) {
    let queryString = window.location.search || window.location.hash;
    if (queryString.charAt(0) === "?" || queryString.charAt(0) === "#") {
      queryString = queryString.substring(1);
    }
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state) {
      // received query strings from Cognito login redirect for authentication
      AuthenticationService.authorise(code, state).then(({ token, exp }) => {
        if (token && exp) {
          handleSetToken(token);
          handleSetExpiry(exp * 1000);
          const caseUrl = sessionStorage.getItem("caseUrl");
          sessionStorage.removeItem("caseUrl");
          if (caseUrl) {
            window.location.href = caseUrl;
          } else {
            window.location.href = window.location.origin;
          }
        } else {
          AuthenticationService.login();
        }
      });
    } else {
      // no token and not redirected from Cognito login, redirect to cognito for authentication
      AuthenticationService.login();
    }
  } else {
    // token exists, check for expiry and redirect to cognito login page if token expired
    if (expiry <= new Date().getTime()) {
      handleSetToken(undefined);
      AuthenticationService.login();
    }
  }

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        username,
        firstname,
        familyname,
        initials,
        email,
        brandIds,
        defaultBrandId,
        auditRole,
        auditReference,
        dateFrom,
        dateTo,
        locationIds,
        isGeneralUser,
        isAdminUser,
        isSSOUser,
      }}
    >
      {token ? children : <div>Authenticating...</div>}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;
