import {styled} from "@mui/material/styles";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: "100%",
    '& .MuiToggleButtonGroup-grouped': {
        marginBottom: theme.spacing(1),
        border: "0.5px solid !important",
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export const StyledToggleButton = styled(ToggleButton)(() => ({
    textTransform: "unset",
    fontWeight: "bold",
    "&.Mui-selected": {
        color: "#000",
        backgroundColor: "#1CDBBC",
        "&:hover": {
            color: "#000",
            backgroundColor: "#1CDBBC",
        },
    },
    "&:hover": {
        color: "#000",
        backgroundColor: "#1CDBBC60",
    },
}));

export const StyledTimePicker = styled(TimePicker)(() => ({
    "& .MuiOutlinedInput-root": {
        "& .MuiInputBase-input": {
            padding: "12px 14px",
        },
    },
}));
