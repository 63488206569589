import React, {FC} from "react";
import {GenericJsonObject} from "../../../models/responses/Cases/CaseResponse";
import AuditLogList from "../../../pages/AuditLogList";

export const AuditLogPanel: FC<{ data: GenericJsonObject }> =
    ({data}) => {
        // eslint-disable-next-line
        const caseId: any = data.uid;
        return <AuditLogList caseId={caseId}></AuditLogList>
    }
