import { ListItemButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const EverifyDoughnutChart = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: "bold",
  display: "block",
  width: "max-content",
  border: "7px solid #98DBFA",
  borderRadius: "100px",
  padding: "30px",
  margin: "10px auto 20px auto",
  textAlign: "center",
  minWidth: "100px",
}));

export const EverifyListButton = styled(ListItemButton)(() => ({
  width: "100%",
  display: "block",
  padding: "0px",
  borderRadius: "8px",
}));

export const EverifyStack = styled(Stack)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #D7D7D7",
  padding: "7px 5px",
  "&:last-child": {
    borderBottom: "none",
  },
  p: {
    fontSize: "12px",
    margin: 0,
  },
  ".MuiSvgIcon-fontSizeSmall": {
    fontSize: "12px",
    margin: "4px 0px 0px 10px",
  },
  ".MuiSvgIcon-fontSizeMedium": {
    fontSize: "14px",
    margin: "2px 5px 0px 0px",
  },
  ".MuiChip-outlined": {
    height: "auto",
    background: "#D7D7D7",
    border: "1px solid rgba(0,0,0,0.1)",
    span: {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "&.nonconfirmation": {
      background: "rgba(137, 214, 247, 0.3)",
    },
    "&.tentativeconfirmation": {
      background: "rgba(137, 214, 247, 0.6)",
    },
    "&.authorized": {
      background: "rgba(137, 214, 247, 1)",
    },
    "&.creationfailed": {
      background: "rgba(255, 0, 0, 0.5)",
    },
  },
}));
