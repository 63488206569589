import React, {useState} from 'react';
import DocumentImageModal from "../CaseDetailsForm/DocumentImageModal";
import {ReactComponent as EnlargeIcon} from "../../img/enlarge.svg";
import {Box, CircularProgress} from "@mui/material";

const DocumentImage = ({imageName, imageScr, showImageName}:
                           {
                               imageName?: string,
                               imageScr: string,
                               showImageName: boolean
                           }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    const handleEnlarge = () => {
        setOpen(true);
    }
    return (
        <div>
            <div style={{display: loading ? "block" : "none"}}>
                {showImageName && <p>{imageName}</p>}
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
            </div>
            <div style={{display: loading ? "none" : "block"}}>
                {showImageName && <p>{imageName}</p>}
                <div style={{
                    padding: "16.5px 14px",
                    borderRadius: 4,
                    position: "relative",
                    background: "#F4F4F4",
                    width: "100%",
                }}>
                    <EnlargeIcon
                        style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            margin: "23px",
                            zIndex: "1000"
                        }}
                        onClick={() => handleEnlarge()}
                    />


                    <img
                        src={imageScr}
                        style={{display: "block", width: "100%", height: "auto"}}
                        aria-label={imageName}
                        alt={imageName}
                        onLoad={() => setLoading(false)}
                    />
                </div>
            </div>
            <DocumentImageModal
                open={open}
                setOpen={setOpen}
                imageScr={imageScr}
                imageName={imageName}
            />
        </div>
    );
}

DocumentImage.defaultProps = {
    showImageName: true
};

export default DocumentImage;
