import React, {useCallback, useContext, useMemo, useState} from "react";
import {AppBar, Grid} from "@mui/material";
import {CaseResponse, getDueDate, getRawDueDate, MeetingMap} from "../../../models/responses/Cases/CaseResponse";
import {formatDate, formatDateTime, formatNonUSDate, formatNonUSDateTime} from "../../../utils/DateUtils/dateUtils";
import CaseListStatusBadge from "../CaseListStatusBadge";
import {Location} from "../../../models/responses/Location";
import VirtualizedTable, {VirtualizedTableSort} from "../../../components/common/VirtualizedTable";
import JourneyContext from "../../../context/JourneyContext";
import CaseListEverifyId from "../CaseListEverifyId";
import {useBrand} from "../../../hooks/Brand/useBrand";
import {PrimaryButton} from "../../CreateCase/CreateCaseForm/styles";
import {CaseListFilterContext} from "../../../context/CaseListFilters";
import {usePurge} from "../../../hooks/Cases/useMutateCase";
import {toast} from "react-toastify";
import {useConfirmationModal, ConfirmationModelContainer} from "../../../components/ConfirmationModal";
import AuthenticationContext from "../../../context/AuthenticationContext";
import {useNavigate} from "react-router-dom";

type CaseListProps = {
    cases: CaseResponse[];
};

const CaseList = ({cases}: CaseListProps) => {
    const {currentJourney} = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");
    const {isAdminUser} = useContext(AuthenticationContext);
    const {
        filterStatuses
    } = useContext(CaseListFilterContext);
    const modal = useConfirmationModal();
    const [sort, setSort] = useState<VirtualizedTableSort>({
        key: "createdAt",
        direction: "DESC"
    })

    const [headerCheckboxChecked, setHeaderCheckboxChecked] = useState<boolean>(false);
    const [checkedCaseItems, setCheckedCaseItems] = useState<CaseResponse[]>([]);

    const getLocationForId = (locationId: number): Location | null => {
        if (locationId === null || locationId === undefined) {
            return null;
        }
        return (currentJourney?.locations || []).find(location => location.id === locationId) || null;
    }

    const handleSort = useCallback((header: string) => {
        if (header === sort.key) {
            setSort({
                key: header,
                direction: sort.direction === "ASC" ? "DESC" : "ASC"
            })
        } else {
            setSort({
                key: header,
                direction: "ASC"
            })
        }
    }, [sort]);

    const casesSorted = useMemo(() => {
        const direction = (sort.direction === "ASC") ? 1 : -1;

        if (sort.key === "dueDate") {
            return cases.sort((a, b) => (getRawDueDate(a) > getRawDueDate(b) ? 1 : -1) * direction)
        }

        if (sort.key === "location") {
            return cases.sort((a, b) => (a.locationId > b.locationId ? 1 : -1) * direction)
        }

        if (sort.key === "appointmentDate") {
            return cases.sort((a, b) => {
                if (isI9) {
                    return (formatDateTime((a?.additionalData?.meeting as MeetingMap)?.[a.status]?.startTime as string) > formatDateTime((b?.additionalData?.meeting as MeetingMap)?.[b.status]?.startTime as string) ? 1 : -1) * direction;
                }
                return (formatDateTime(a?.additionalData?.meeting?.startTime as string) > formatDateTime(b?.additionalData?.meeting?.startTime as string) ? 1 : -1) * direction;
            });
        }

        return cases.sort(
            (a, b) => {
                // eslint-disable-next-line
                // @ts-ignore
                return (a[sort.key] > b[sort.key] ? 1 : -1) * direction
            }
        );
    }, [cases, sort]);

    const isPrescient = currentJourney?.journeyType === "PRESCIENT";
    const {data: brand} = useBrand();
    const isEverifyEnabled = isI9 && brand?.i9Config?.everifyEnabled;
    const purgeApplicableStatuses: string[] | undefined = currentJourney && Object.entries(currentJourney?.uiConfig.filter)
        .map(([key, value]) => ({key: key, statuses: value.statuses}))
        .find(item => (item.key === "Purge_Applicable"))?.statuses;
    const purgableCases = casesSorted.filter(caseItem => purgeApplicableStatuses?.includes(caseItem.status));
    const filterStatusNames = filterStatuses?.flatMap(filter => currentJourney?.uiConfig.filter[filter].statuses);
    const isPurgableFilterStatusSelected = purgeApplicableStatuses && !!purgeApplicableStatuses.find(purgableStatus => filterStatusNames.includes(purgableStatus));
    const isAnyCaseSelected = !!(checkedCaseItems?.length && checkedCaseItems?.length > 0);

    const navigate = useNavigate();
    const {mutate: deleteCases} = usePurge(
        () => {
            toast.success("Cases are deleted");
            setTimeout(() => navigate("/cases"), 3000);
        },
        () => {
            toast.error("Cases deletion is failed");
        },
    );

    const handleDeleteCases = () => {
        const caseIds = checkedCaseItems?.map(caseItem => caseItem.uid);
        if (caseIds) {
            deleteCases({purgeCaseIds: caseIds});
        }
    }

    const handleDeleteCasesRequest = () => {
        const deletionCasesCount = checkedCaseItems?.length;
        modal.openConfirmationModal({
            message: `By clicking ‘Yes’, you are authorizing the deletion of ${deletionCasesCount} case records.`,
            confirmButtonLabel: "Yes",
            cancelButtonLabel: "No",
            onConfirm: handleDeleteCases,
            onCancel: () => undefined
        })
    }

    return (<Grid sx={{height: "calc(100% - 110px)", padding: 3}}>
        <VirtualizedTable
            handleSort={handleSort}
            sorting={sort}
            rowHeight={34}
            rowCount={casesSorted.length}
            rowGetter={({index}) => casesSorted[index]}
            headerChecked={headerCheckboxChecked}
            onChangeHeaderCheckbox={() => {
                setHeaderCheckboxChecked(!headerCheckboxChecked);
                const selectedCaseItems: CaseResponse[] = [];
                if (!headerCheckboxChecked) {
                    casesSorted.forEach(caseItem => {
                        if (purgeApplicableStatuses?.includes(caseItem.status)) {
                            selectedCaseItems.push(caseItem);
                        }
                    });
                }
                setCheckedCaseItems(selectedCaseItems);
            }}
            columns={[
                ...(isAdminUser && isI9 && isPurgableFilterStatusSelected ? [{
                    dataKey: 'checkbox',
                    // eslint-disable-next-line
                    // @ts-ignore
                    checked: ({rowData}) => {
                        return !!checkedCaseItems?.includes(rowData);
                    },
                    // eslint-disable-next-line
                    // @ts-ignore
                    onChangeRowCheckbox: ({rowData}) => {
                        if (checkedCaseItems?.includes(rowData)) {
                            const selectedCaseItems: CaseResponse[] = [];
                            checkedCaseItems?.forEach(caseItem => {
                                if (caseItem !== rowData) {
                                    selectedCaseItems.push(caseItem);
                                }
                            });
                            setCheckedCaseItems(selectedCaseItems);
                            setHeaderCheckboxChecked(purgableCases.length === selectedCaseItems?.length);
                        } else {
                            const selectedCaseItems: CaseResponse[] = [...checkedCaseItems];
                            selectedCaseItems.push(rowData);
                            setCheckedCaseItems(selectedCaseItems);
                            setHeaderCheckboxChecked(purgableCases.length === selectedCaseItems?.length);
                        }
                    },
                    // eslint-disable-next-line
                    // @ts-ignore
                    checkboxVisible: ({rowData}) => {
                        return rowData && !!purgeApplicableStatuses?.includes(rowData.status)
                    },
                }] : []),
                {
                    label: 'Candidate Ref',
                    dataKey: 'consumerReference',
                    urlGetter: ({rowData}) => {
                        return {label: rowData.consumerReference, url: `/cases/${rowData.uid}`}
                    }
                },
                {
                    label: 'First Name',
                    dataKey: 'givenName',
                },
                {
                    label: 'Last Name',
                    dataKey: 'familyName',
                },
                {
                    label: 'Location',
                    dataKey: 'location',
                    dataGetter: ({rowData: caseItem}) => getLocationForId(caseItem.locationId)?.name || caseItem.country
                },
                {
                    label: 'Date Created',
                    dataKey: 'createdAt',
                    dataGetter: ({rowData: caseItem}) => (isI9 ? formatDateTime : formatNonUSDateTime)(caseItem.createdAt)
                },
                {
                    label: 'Start Date',
                    dataKey: 'startDate',
                    dataGetter: ({rowData: caseItem}) => (isI9 ? formatDate : formatNonUSDate)(caseItem.startDate || "")
                },
                ...(isAdminUser && isI9 && isPurgableFilterStatusSelected  ? [{
                    label: 'End Date',
                    dataKey: 'endtDate',
                    // eslint-disable-next-line
                    // @ts-ignore
                    dataGetter: ({rowData: caseItem}) => (isI9 ? formatDate : formatNonUSDate)(caseItem.endDate || "")
                }] : []),
                ...(!isPrescient ? [{
                    label: 'Appointment',
                    dataKey: 'appointmentDate',
                    // eslint-disable-next-line
                    // @ts-ignore
                    dataGetter: ({rowData: caseItem}) => (isI9 ? ((caseItem?.additionalData?.meeting as MeetingMap)?.[caseItem.status] ? formatDateTime((caseItem?.additionalData?.meeting as MeetingMap)?.[caseItem.status]?.startTime) : "")
                        : (caseItem?.additionalData?.meeting? formatNonUSDateTime(caseItem.additionalData?.meeting.startTime) : ""))
                }] : []),
                {
                    label: isI9 ? 'Section Status' : 'Status',
                    dataKey: 'status',
                    dataGetter: ({rowData: caseItem}) => {
                        const isWorkAuthExpirable =
                            caseItem.additionalData.Section_1 &&
                            caseItem.additionalData.Section_1.length > 0 &&
                            caseItem.additionalData.Section_1[0].employee.work_auth.expiration_date !== "N/A" &&
                            caseItem.additionalData.Section_1[0].employee.work_auth.expiration_date !== "";
                      
                        return (<CaseListStatusBadge status={caseItem.status}
                                            section2Invited={caseItem.additionalData.i9_section_2_invite_timestamp !== undefined}
                                            isWorkAuthExpirable={isWorkAuthExpirable}
                        />)
                    }
                },
                {
                    label: 'Due Date',
                    dataKey: 'dueDate',
                    hide: !isI9,
                    dataGetter: ({rowData: caseItem}) => getDueDate(caseItem)
                },
                ...(isEverifyEnabled ? [{
                    label: 'E-Verify Case No.',
                    dataKey: 'everify_case_number',
                    // eslint-disable-next-line
                    // @ts-ignore
                    dataGetter: ({rowData: caseItem}) => <CaseListEverifyId
                        id={caseItem.additionalData.everify_case_number}
                        status={caseItem.additionalData.everify_case_eligibility_statement === "EMPLOYMENT_AUTHORIZED" ? "EMPLOYMENT_AUTHORIZED" : ["CLOSED", "FINAL_NONCONFIRMATION"].includes(caseItem.additionalData.everify_case_status || "") ? "FINAL_NONCONFIRMATION" : "TENTATIVE_NONCONFIRMATION"}/>,
                }] : []),
            ]}
        />
        <ConfirmationModelContainer {...modal}/>
        {isAdminUser && isI9 && isPurgableFilterStatusSelected && isAnyCaseSelected &&
            <AppBar
                position="fixed"
                sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    top: "auto",
                    bottom: 0,
                    background: "white",
                }}
            >
                <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                    <Grid item xs={8}></Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <PrimaryButton onClick={handleDeleteCasesRequest}>
                            Delete Case(s)
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </AppBar>
        }
    </Grid>);
};

export default CaseList;
