import {CaseResponse, GenericJsonObject, MeetingMap} from "../../models/responses/Cases/CaseResponse";
import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import {BasicDetailsPanel} from "../../components/CaseDetailsForm/BasicDetailsPanel";
import {SectionOnePanel} from "../../components/CaseDetailsForm/SectionOnePanel";
import {SectionTwoPanel} from "../../components/CaseDetailsForm/SectionTwoPanel";
import {SectionThreePanel} from "../../components/CaseDetailsForm/SectionThreePanel";
import ComparePanel from "../../components/CaseDetailsForm/ComparePanel";
import {AuditLogPanel} from "../../components/CaseDetailsForm/AuditLogPanel";
import AuthenticationContext from "../../context/AuthenticationContext";
import JourneyContext from "../../context/JourneyContext";
import {BasicDetailsGenericPanel} from "../../components/CaseDetailsForm/BasicDetailsGenericPanel";
import {TemplatingUtils} from "../../utils/TemplatingUtils";
import {GenericDocumentPanel} from "../../components/CaseDetailsForm/GenericDocumentPanel";
import {FaceImagesPanel} from "../../components/CaseDetailsForm/FaceImagesPanel";
import {InteractiveEvent} from "../../models/responses/Cases/CaseSpecifications/CaseInteractiveEvents/InteractiveEvent";
import {ShareCodePanel} from "../../components/CaseDetailsForm/ShareCodePanel";
import {JourneyInfo} from "../../models/responses/JourneyInfo";
import ImportedFormPanel from "../../components/CaseDetailsForm/ImportedFormPanel";

export enum CaseTabType {
    CASE_DETAILS = "caseDetails",
    SECTION_1 = "section_1",
    SECTION_2 = "section_2",
    SECTION_3 = "section_3",
    COMPARE = "compare",
    AUDIT_LOG = "auditLog",
    CASE_DETAILS_GENERIC = "caseDetailsGeneric",
    GENERIC_DOCUMENT = "genericDocument",
    FACE_IMAGES = "faceImages",
    SHARE_CODE = "shareCode",
    IMPORTED = "imported"
}

export enum TabMode {
    CORRECTION = "CORRECTION",
    READ = "READ",
    COMPARE_SECTION_2 = 'COMPARE_SECTION_2',
    COMPARE_SECTION_3 = 'COMPARE_SECTION_3'
}

type CaseTab = {
    key: string;
    label: string;
    selected: boolean;
    approved: boolean;
    type: CaseTabType;
    data: GenericJsonObject;
    images?: GenericJsonObject;
    prevData?: GenericJsonObject;
    context: CaseTabContext;
    handleRefresh?: () => void;
}

export type CaseTabContext = {
    caseId: string;
    caseStatus?: string;
    mode?: string;
    correctedFields?: string[];
    compareSection?: string;
    selectTab?: (key: string) => void;
    hasNoDocument?: boolean;
}

type CaseControlInterface = {
    tabs: CaseTab[];
    tabSelected: CaseTab | undefined;
    selectTab: (key: string) => void;
    TabPanel: FC<{
            data: GenericJsonObject,
            prevData?: GenericJsonObject,
            context?: CaseTabContext,
            i9PdfFile?: Blob | undefined,
            shareCodePdfFile?: Blob | undefined,
            canReplaceShareCodePdf?: boolean,
            handleRefresh?: () => void,
            images?: GenericJsonObject,
            handleCompare?: (e?: string) => void;
        }>
        | undefined;
    interactions: InteractiveEvent[],
    handleCorrectionSectionOne: () => void;
    handleCorrectionSectionTwo: () => void;
    handleCompareSectionTwo: () => void;
    handleCompareSectionThree: () => void;
}

type StatusDetails = {
    invited: string;
    status: string;
    completed: string;
    due: string;
}

export type StatusSummary = {
    Section_1: StatusDetails;
    Section_2: StatusDetails;
    Section_3: StatusDetails;
    E_Verify: StatusDetails;
}

const isDocumentsSubmitted = (emsCase: CaseResponse, statusSummary: StatusSummary) => {
    if (statusSummary.Section_3.status === 'in_progress') {
        return emsCase.additionalData.standaloneTransactions?.Section_3_Documents &&
            emsCase.additionalData.standaloneTransactions?.Section_3_Documents?.length != 0
            && emsCase.additionalData.Section_3_Documents && emsCase.additionalData.Section_3_Documents?.length != 0;
    } else if (statusSummary.Section_2.status === 'in_progress') {
        return emsCase.additionalData.standaloneTransactions?.Section_2_Documents &&
            emsCase.additionalData.standaloneTransactions?.Section_2_Documents?.length != 0
            && emsCase.additionalData.Section_2_Documents && emsCase.additionalData.Section_2_Documents?.length != 0;
    } else {
        return false;
    }
}

const extractStatusSummary = (emsCase: CaseResponse): StatusSummary => {
    const  isRequestOptOutSection2 = "SECTION_2_OPT_OUT_REQUESTED" === (emsCase.status as string);
    const  isRequestOptOutSection3 = (["SECTION_3_OPT_OUT_REQUESTED", "SECTION_3_REHIRE_OPT_OUT_REQUESTED",
        "SECTION_3_RENAME_OPT_OUT_REQUESTED"].includes(emsCase.status as string));
    return {
        Section_1: {
            invited: emsCase.createdAt,
            status: (emsCase.additionalData.Section_1 && emsCase.status !== "SECTION_1_CORRECTION") ? "completed" : "in_progress",
            completed: (emsCase.additionalData.Section_1?.slice(-1)[0].createdAt || "") as string,
            due: emsCase.additionalData.i9_section_1_deadline || "",
        },
        Section_2: {
            invited: emsCase.additionalData.i9_section_2_invite_timestamp || "",
            status: isRequestOptOutSection2 ? emsCase.status : (!emsCase.additionalData.Section_1 ? "" : emsCase.additionalData.Section_2 ? "completed" : "in_progress"),
            completed: (emsCase.additionalData.Section_2?.slice(-1)[0].createdAt || "") as string,
            due: emsCase.additionalData.i9_section_2_deadline || "",
        },
        Section_3: {
            invited: emsCase.additionalData.i9_section_3_invite_timestamp || "",
            status: isRequestOptOutSection3 ? emsCase.status : (["SECTION_3_INVITED", "SECTION_3_REHIRE", "SECTION_3_RENAME", "SECTION_3_SUBMITTED", "SECTION_3_RENAME_SUBMITTED", "SECTION_3_REHIRE_SUBMITTED"].includes(emsCase.status) ? "in_progress" : emsCase.status === "SECTION_3_COMPLETE" ? "completed" : ""),
            completed: (emsCase.additionalData.Section_3?.slice(-1)[0].createdAt || "") as string,
            due: emsCase.status === "SECTION_3_REHIRE" ? emsCase.startDate || "" : emsCase.additionalData.i9_section_3_deadline || "",
        },
        E_Verify: {
            invited: "",
            status: "",
            completed: "",
            due: "",
        },
    }
}

export const createUseImageQuery = (path: string) => {
    const comp = new Image();
    let loading = true;
    comp.src = path;
    comp.onload = () => {
        loading = false;
    }

    return () => ({
        data: comp.src,
        // keep previous properties so existing codes will continue to work
        isSuccess: true,
        isError: false,
        isLoading: false,
        // provide a load status checker function, so that a hook can later be built for the Component that consume this object
        checkLoading: () => {
            return loading;
        }
    });
}

const handleDocumentsImages = (obj: GenericJsonObject) => {
    return obj && Object.fromEntries(Object.entries(obj).map(([key, value]) =>
        ([key === 'Section_2_Documents' ? 'Section_2' : (key === 'Section_3_Documents' ? 'Section_3' : key), value])));
}

const initialiseTabsI9 = (isAdminUser: boolean, auditRole: string | undefined, currentJourney?: JourneyInfo,
                          emsCase?: CaseResponse, previousTabs?: CaseTab[]): CaseTab[] => {
    if (!emsCase) {
        return [];
    }
    const caseStatusSummary = extractStatusSummary(emsCase);
    const status = emsCase?.status || "" as string;
    const statusDisplay = emsCase?.specifications?.stateDisplayValues?.[status] || status;
    const txtStatusDisplay = currentJourney?.uiConfig.statusBadge[statusDisplay]?.label
        || currentJourney?.uiConfig.statusBadge[status]?.label;
    const tabs: CaseTab[] = [
        {
            key: CaseTabType.CASE_DETAILS,
            label: "Case Details",
            selected: true,
            approved: false,
            type: CaseTabType.CASE_DETAILS,
            data: {
                uid: emsCase.uid,
                consumerReference: emsCase.consumerReference,
                createdBy: emsCase.createdBy,
                givenName: emsCase.givenName,
                familyName: emsCase.familyName,
                emailAddress: emsCase.emailAddress,
                mobileNumber: emsCase.mobileNumber,
                clientId: emsCase.clientId,
                brandId: emsCase.brandId,
                createdAt: emsCase.createdAt,
                status: emsCase.status,
                country: emsCase.country,
                locationId: emsCase.locationId,
                displayStatus: emsCase.displayStatus,
                startDate: emsCase.startDate,
                endDate: emsCase.endDate,
                ...emsCase.additionalData.caseDetails,
                statusSummary: caseStatusSummary,
                everify_case_number: emsCase.additionalData.everify_case_number,
                everify_case_status: emsCase.additionalData.everify_case_status,
                everify_case_status_display: emsCase.additionalData.everify_case_status_display,
                everify_case_eligibility_statement: emsCase.additionalData.everify_case_eligibility_statement,
                everify_case_creation_failed: emsCase.additionalData.everify_case_creation_failed,
                i9_section_2_deadline: emsCase.additionalData.i9_section_2_deadline,
                i9_section_3_deadline: emsCase.status === "SECTION_3_REHIRE" ? emsCase.startDate || "" : emsCase.additionalData.i9_section_3_deadline || "",
                isDocumentSubmitted: isDocumentsSubmitted(emsCase, caseStatusSummary),
                meeting: (emsCase.additionalData.meeting as MeetingMap)?.[emsCase.status],
                selectedReasons: emsCase.additionalData.selectedReasons,
                hiringManagerEmail: emsCase.hiringManagerEmail,
                hiringManagerLastName: emsCase.hiringManagerLastName,
                hiringManagerName: emsCase.hiringManagerName,
                hiringManagerPhone: emsCase.hiringManagerPhone,
                hiringManagerTitle: emsCase.hiringManagerTitle,
                statusDisplayText: txtStatusDisplay,
                referredReason: emsCase.additionalData.selectedReasons
            },
            context: {
                caseId: emsCase.uid
            }
        }
    ];

    if (emsCase.additionalData.Section_1) {
        emsCase.additionalData.Section_1.sort(function (a, b) {
            const dateA = new Date(String(a.createdAt));
            const dateB = new Date(String(b.createdAt));
            return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        });
        // eslint-disable-next-line
        const sectionOneData: any = JSON.parse(JSON.stringify(emsCase.additionalData.Section_1[emsCase.additionalData.Section_1.length - 1]));
        if (previousTabs) {
            // eslint-disable-next-line
            // @ts-ignore
            sectionOneData.employee.signature.imageQuery = previousTabs.find(t => t.key === CaseTabType.SECTION_1)?.data?.employee?.signature?.imageQuery;
            // eslint-disable-next-line
            // @ts-ignore
            sectionOneData.preparer && sectionOneData.preparer?.signature && (sectionOneData.preparer.signature.imageQuery = previousTabs.find(t => t.key === CaseTabType.SECTION_1)?.data?.preparer?.signature?.imageQuery);
        } else {
            if (sectionOneData.employee.signature.image !== "") {
                sectionOneData.employee.signature.imageQuery = createUseImageQuery(sectionOneData.employee.signature.image);
            }

            if (sectionOneData.preparer?.signature?.image && sectionOneData.preparer?.signature?.image !== "") {
                sectionOneData.preparer.signature.imageQuery = createUseImageQuery(sectionOneData.preparer.signature.image);
            }
        }


        // eslint-disable-next-line
        let prevSectionOneData: any;
        if (emsCase.additionalData.Section_1.length >= 2) {
            prevSectionOneData = JSON.parse(JSON.stringify(emsCase.additionalData.Section_1[0]));
        }

        tabs.push({
            key: CaseTabType.SECTION_1,
            label: "Section 1",
            selected: false,
            approved: false,
            type: CaseTabType.SECTION_1,
            data: sectionOneData,
            prevData: prevSectionOneData,
            context: {
                caseId: emsCase.uid,
                caseStatus: emsCase.status,
                mode: TabMode.READ,
                correctedFields: emsCase.additionalData.selectedReasons?.split(', ')
            }
        })
    }

    if (emsCase.additionalData.Section_2) {
        // eslint-disable-next-line
        const sectionTwoData: any = JSON.parse(JSON.stringify({
            ...emsCase.additionalData.Section_2[emsCase.additionalData.Section_2.length - 1],
            employee: emsCase.additionalData.Section_1 && (emsCase.additionalData.Section_1[emsCase.additionalData.Section_1.length - 1] as GenericJsonObject).employee,
            employer: (emsCase.additionalData.brand_i9_config as GenericJsonObject)?.employer,
        }));
        if (previousTabs) {
            // eslint-disable-next-line
            // @ts-ignore
            sectionTwoData.employer_or_authorized_representatives?.signature && (sectionTwoData.employer_or_authorized_representatives.signature.imageQuery = previousTabs.find(t => t.key === CaseTabType.SECTION_2)?.data?.employer_or_authorized_representatives?.signature?.imageQuery);
        } else {
            if (sectionTwoData.employer_or_authorized_representatives?.signature?.image && sectionTwoData.employer_or_authorized_representatives?.signature?.image !== "") {
                sectionTwoData.employer_or_authorized_representatives.signature.imageQuery = createUseImageQuery(sectionTwoData.employer_or_authorized_representatives.signature.image);
            }
            if (emsCase.additionalData.standaloneTransactions?.Section_2?.length != 0) {
                // eslint-disable-next-line
                emsCase.additionalData.standaloneTransactions?.Section_2?.forEach((transaction: any) => {
                    // eslint-disable-next-line
                    const {documents}: any = transaction;
                    Object.keys(documents).forEach((key) => {
                        const preloadImage = new Image();
                        preloadImage.src = documents[key].image;
                    });
                })
            }
        }

        // eslint-disable-next-line
        let prevSectionTwoData: any = undefined;
        if (emsCase.additionalData.Section_2.length >= 2) {
            prevSectionTwoData = JSON.parse(JSON.stringify(emsCase.additionalData.Section_2[0]));
        }

        tabs.push({
            key: CaseTabType.SECTION_2,
            label: "Section 2",
            selected: false,
            approved: false,
            type: CaseTabType.SECTION_2,
            data: sectionTwoData,
            images: {
                standaloneDocuments: handleDocumentsImages(emsCase.additionalData.standaloneDocuments as GenericJsonObject),
                standaloneTransactions: handleDocumentsImages(emsCase.additionalData.standaloneTransactions as GenericJsonObject),
            },
            prevData: prevSectionTwoData,
            context: {
                caseId: emsCase.uid,
                compareSection: TabMode.COMPARE_SECTION_2
            },
        });
    }

    if (emsCase.additionalData.Section_3) {
        // eslint-disable-next-line
        const sectionThreeData: any = JSON.parse(JSON.stringify(emsCase.additionalData.Section_3[emsCase.additionalData.Section_3.length - 1]));
        if (previousTabs) {
            // eslint-disable-next-line
            // @ts-ignore
            sectionThreeData.reverification.reviewer.signature.imageQuery = previousTabs.find(t => t.key === CaseTabType.SECTION_3)?.data?.reverification?.reviewer?.signature?.imageQuery;
        } else {
            if (sectionThreeData.reverification?.reviewer?.signature?.image !== "") {
                sectionThreeData.reverification.reviewer.signature.imageQuery = createUseImageQuery(sectionThreeData.reverification.reviewer.signature.image);
            }
            if (emsCase.additionalData.standaloneTransactions?.Section_3?.length != 0) {
                // eslint-disable-next-line
                emsCase.additionalData.standaloneTransactions?.Section_3?.forEach((transaction: any) => {
                    // eslint-disable-next-line
                    const {documents}: any = transaction;
                    Object.keys(documents).forEach((key) => {
                        const preloadImage = new Image();
                        preloadImage.src = documents[key].image;
                    });
                })
            }
        }

        tabs.push({
            key: CaseTabType.SECTION_3,
            label: "Supplement B",
            selected: false,
            approved: false,
            type: CaseTabType.SECTION_3,
            data: sectionThreeData,
            images: {
                standaloneDocuments: handleDocumentsImages(emsCase.additionalData.standaloneDocuments as GenericJsonObject),
                standaloneTransactions: handleDocumentsImages(emsCase.additionalData.standaloneTransactions as GenericJsonObject),
            },
            context: {
                caseId: emsCase.uid,
                compareSection: TabMode.COMPARE_SECTION_3
            }
        })
    }

    if (emsCase.additionalData.Section_2 && emsCase.additionalData.standaloneDocuments && emsCase.additionalData.standaloneTransactions) {

        // eslint-disable-next-line
        const sectionTwoData: any = JSON.parse(JSON.stringify({
            ...emsCase.additionalData.Section_2[emsCase.additionalData.Section_2.length - 1],
            employee: emsCase.additionalData.Section_1 && (emsCase.additionalData.Section_1[emsCase.additionalData.Section_1.length - 1] as GenericJsonObject).employee
        }));

        // eslint-disable-next-line
        const sectionThreeData: any = emsCase.additionalData.Section_3 && JSON.parse(JSON.stringify(emsCase.additionalData.Section_3[emsCase.additionalData.Section_3.length - 1]));

        tabs.push({
            key: CaseTabType.COMPARE,
            label: "Compare",
            selected: false,
            approved: false,
            type: CaseTabType.COMPARE,
            data: {
                uid: emsCase.uid,
                standaloneDocuments: handleDocumentsImages(emsCase.additionalData.standaloneDocuments as GenericJsonObject),
                standaloneTransactions: handleDocumentsImages(emsCase.additionalData.standaloneTransactions as GenericJsonObject),
                sectionTwoData,
                sectionThreeData,
            },
            context: {
                caseId: emsCase.uid,
                compareSection: sectionThreeData ? TabMode.COMPARE_SECTION_3 : TabMode.COMPARE_SECTION_2
            },
        });
    }

    if (emsCase.importedPdfUrl) {
        tabs.push({
            key: CaseTabType.IMPORTED,
            label: "Form I-9",
            selected: false,
            approved: false,
            type: CaseTabType.IMPORTED,
            data: {
                importedUrl: emsCase.importedPdfUrl
            },
            context: {
                caseId: emsCase.uid,
                caseStatus: emsCase.status,
                mode: TabMode.READ,
            }
        })
    }

    if (auditRole || isAdminUser) {
        tabs.push({
            key: CaseTabType.AUDIT_LOG,
            label: "Audit Log",
            selected: false,
            approved: false,
            type: CaseTabType.AUDIT_LOG,
            data: {
                uid: emsCase.uid,
            },
            context: {
                caseId: emsCase.uid
            },
        });
    }

    return tabs;
}

const initialiseTabsGeneric = (isAdminUser: boolean, auditRole: string | undefined, currentJourney?: JourneyInfo,
                               emsCase?: CaseResponse, previousTabs?: CaseTab[]): CaseTab[] => {
    if (!emsCase) {
        return [];
    }

    const status = emsCase?.status || "" as string;
    const statusDisplay = emsCase?.specifications?.stateDisplayValues?.[status] || status;

    let statusExplanation = emsCase?.specifications?.statusExplanations?.[status] || emsCase?.specifications?.statusExplanations?.[statusDisplay];
    if (statusExplanation) {
        statusExplanation = TemplatingUtils.templateSelectedDocuments(statusExplanation, (emsCase?.additionalData?.selectedDocuments || []));
        statusExplanation = TemplatingUtils.templateShareCodeUploadMessage(statusExplanation, emsCase?.additionalData?.shareCode);
        statusExplanation = TemplatingUtils.templateShareCodeCheckMessage(statusExplanation, emsCase?.additionalData?.shareCode);
    }
    const txtStatusDisplay = currentJourney?.uiConfig.statusBadge[statusDisplay]?.label
        || currentJourney?.uiConfig.statusBadge[status]?.label;
    const txtStatusExplanationTitle = emsCase?.specifications?.statusDisplayValues?.[statusDisplay]
        || emsCase?.specifications?.statusDisplayValues?.[status]
        || txtStatusDisplay;
    const tabs: CaseTab[] = [
        {
            key: CaseTabType.CASE_DETAILS_GENERIC,
            label: "Case Details",
            selected: true,
            approved: false,
            type: CaseTabType.CASE_DETAILS_GENERIC,
            data: {
                uid: emsCase.uid,
                consumerReference: emsCase.consumerReference,
                createdBy: emsCase.createdBy,
                givenName: emsCase.givenName,
                familyName: emsCase.familyName,
                emailAddress: emsCase.emailAddress,
                mobileNumber: emsCase.mobileNumber,
                clientId: emsCase.clientId,
                brandId: emsCase.brandId,
                createdAt: emsCase.createdAt,
                status: status,
                country: emsCase.country,
                locationId: emsCase.locationId,
                displayStatus: emsCase.displayStatus,
                startDate: emsCase.startDate,
                endDate: emsCase.endDate,
                ...emsCase.additionalData.caseDetails,
                meeting: emsCase.additionalData.meeting,
                selectedReasons: emsCase.additionalData.selectedReasons,
                statusDisplayText: txtStatusDisplay,
                statusExplanationTitle: txtStatusExplanationTitle,
                statusExplanationText: statusExplanation,
                specifications: emsCase.specifications,
                shareCode: emsCase.additionalData.shareCode,
                additionalData: emsCase.additionalData,
                selectedDocuments: emsCase.additionalData.selectedDocuments
            },
            context: {
                caseId: emsCase.uid
            }
        }
    ];

    if (emsCase.additionalData.shareCode) {
        tabs.push({
            key: CaseTabType.SHARE_CODE,
            label: `Share Code`,
            selected: false,
            approved: false,
            type: CaseTabType.SHARE_CODE,
            data: {
                uid: emsCase.uid,
                shareCode: emsCase.additionalData.shareCode,
                shareCodePdfPresent: !!emsCase.additionalData["shareCodePdf"],
            },
            context: {
                caseId: emsCase.uid
            }
        })
    }

    for (const doc_key in (emsCase?.additionalData.documents || {})) {
        const doc = emsCase?.additionalData.documents?.[doc_key] as GenericJsonObject;
        const tabKey = `DOCUMENT_${doc_key}`;
        const isPdf = (doc.image as string).includes(".pdf")

        let imageQuery;

        if (previousTabs) {
            // eslint-disable-next-line
            // @ts-ignore
            const previousImageQuery = previousTabs.find(t => t.key === tabKey)?.data?.imageQuery as (() => { isSuccess });
            if (previousImageQuery && previousImageQuery().isSuccess) {
                imageQuery = previousImageQuery;
            }
        }
        if (!imageQuery && doc.image && doc.image !== "" && !isPdf) {
            imageQuery = createUseImageQuery(doc.image as string);
        }

        tabs.push({
            key: tabKey,
            label: `${doc_key}`,
            selected: false,
            approved: false,
            type: CaseTabType.GENERIC_DOCUMENT,
            data: {
                documentName: doc_key,
                image: doc.image,
                imageQuery: imageQuery,
                documentParticulars: doc.documentParticulars,
                echip: doc.echip,
                mrz: doc.mrz,
                ocr: doc.ocr,
            },
            context: {
                caseId: emsCase.uid
            }
        })

        const fixedLabel = "Case Details"
        const fixedItemIndex = tabs.findIndex(obj => obj.label === fixedLabel);
        const fixedItem = tabs[fixedItemIndex];
        
        const rest = tabs.filter((obj, index) => obj.label !== fixedLabel || index !== fixedItemIndex);
        rest.sort((a, b) => a.label.localeCompare(b.label));

        tabs.length = 0;
        tabs.push(fixedItem, ...rest);
    }

    // eslint-disable-next-line
    const faces: any = {};

    if (previousTabs) {
        const previousFaceTabData = previousTabs.find(t => t.key === CaseTabType.FACE_IMAGES)?.data || {};
        for (const faceKey in previousFaceTabData) {
            const prevFace = previousFaceTabData[faceKey] as ({ image: string, imageQuery: (() => { isSuccess: boolean }) });
            if (prevFace.imageQuery && prevFace.imageQuery().isSuccess) {
                faces[faceKey] = prevFace;
            }
        }
    }

    if (emsCase?.additionalData.face) {
        const faceData = emsCase?.additionalData.face as GenericJsonObject
        for (const faceKey in faceData || {}) {
            if (!faces[faceKey] && faceData[faceKey] !== "" && (typeof faceData[faceKey] === "string") && (faceData[faceKey] as string).startsWith("https://")) {
                faces[faceKey] = {
                    image: faceData[faceKey],
                    imageQuery: createUseImageQuery(faceData[faceKey] as string),
                }
            }
        }
    }

    if (emsCase?.additionalData.faceCheck) {
        const faceData = emsCase?.additionalData.faceCheck as GenericJsonObject
        for (const faceKey in faceData || {}) {
            if (!faces[faceKey] && faceData[faceKey] !== "" && (typeof faceData[faceKey] === "string") && (faceData[faceKey] as string).startsWith("https://")) {
                faces[faceKey] = {
                    image: faceData[faceKey],
                    imageQuery: createUseImageQuery(faceData[faceKey] as string),
                }
            }
        }
    }

    if (Object.keys(faces).length) {
        tabs.push({
            key: CaseTabType.FACE_IMAGES,
            label: "Face",
            selected: false,
            approved: false,
            type: CaseTabType.FACE_IMAGES,
            data: faces,
            context: {
                caseId: emsCase.uid
            }
        })
    }

    if (auditRole || isAdminUser) {
        tabs.push({
            key: CaseTabType.AUDIT_LOG,
            label: "Audit Log",
            selected: false,
            approved: false,
            type: CaseTabType.AUDIT_LOG,
            data: {
                uid: emsCase.uid,
            },
            context: {
                caseId: emsCase.uid
            },
        });
    }

    return tabs;
}

export const useCaseControl = (emsCase?: CaseResponse): CaseControlInterface => {
    const {isAdminUser, auditRole} = useContext(AuthenticationContext);
    const {journeys, currentJourney, selectJourney} = useContext(JourneyContext);

    const initialiseTabs = useMemo(() => {
        const caseJourney = journeys?.find(journey => journey.uid === emsCase?.journeyId);
        if (caseJourney && caseJourney.uid !== currentJourney?.uid) {
            selectJourney(caseJourney.uid);
        }
        const isI9 = caseJourney?.journeyType.startsWith("I9");
        return isI9 ? initialiseTabsI9 : initialiseTabsGeneric;
    }, [journeys, emsCase?.journeyId, currentJourney?.uid, selectJourney])

    const [tabs, setTabs] = useState<CaseTab[]>(initialiseTabs(isAdminUser, auditRole, currentJourney, emsCase));
    const tabSelected = tabs.find(tab => tab.selected);
    const isInitialMount = useRef(true);

    const interactions = emsCase?.specifications?.interactiveEvents?.find(eventGroup => eventGroup.entryState === emsCase.status)?.interactions || [];

    const selectTab = (key: string, mode?: TabMode, compareSection?: TabMode) => {
        if (mode !== undefined) {
            setTabs(tabs.map(
                tab => (tab.key === key ?
                    {
                        ...tab,
                        selected: true,
                        context: {
                            ...tab.context,
                            mode: mode,
                            compareSection: compareSection
                        },
                        handleRefresh: () => {
                            selectTab(key, TabMode.READ)
                        }
                    } : {
                        ...tab,
                        selected: false
                    })
            ))
        } else {
            if (key === CaseTabType.SECTION_1) {
                selectTab(CaseTabType.SECTION_1, TabMode.READ)
            } else {
                setTabs(tabs.map(
                    tab => ({
                        ...tab,
                        selected: tab.key === key
                    }))
                )
            }
        }
    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (emsCase) {
            const newTabs = initialiseTabs(isAdminUser, auditRole, currentJourney, emsCase, tabs);

            setTabs(tabs.map(tab => {
                const newTab = newTabs.find(itm => itm.key === tab.key);
                if (newTab) {
                    return {
                        ...newTab,
                        selected: tab.selected,
                        approved: tab.approved,
                        context: tab.context && {
                            ...tab.context,
                            mode: TabMode.READ,
                        }
                    }
                } else {
                    return tab;
                }
            }))
        }
    }, [emsCase])

    const handleCorrectionSectionOne = () => {
        selectTab(CaseTabType.SECTION_1, TabMode.CORRECTION);
    };

    const handleCorrectionSectionTwo = () => {
        selectTab(CaseTabType.SECTION_2, TabMode.CORRECTION);
    };

    const handleCompareSectionTwo = () => {
        selectTab(CaseTabType.COMPARE, TabMode.READ, TabMode.COMPARE_SECTION_2);
    };

    const handleCompareSectionThree = () => {
        selectTab(CaseTabType.COMPARE, TabMode.READ, TabMode.COMPARE_SECTION_3);
    };


    let TabPanel = undefined;
    switch (tabSelected?.type) {
        case CaseTabType.CASE_DETAILS:
            TabPanel = BasicDetailsPanel;
            break;
        case CaseTabType.SECTION_1:
            TabPanel = SectionOnePanel;
            break;
        case CaseTabType.SECTION_2:
            TabPanel = SectionTwoPanel;
            break;
        case CaseTabType.SECTION_3:
            TabPanel = SectionThreePanel;
            break;
        case CaseTabType.COMPARE:
            TabPanel = ComparePanel;
            break;
        case CaseTabType.AUDIT_LOG:
            TabPanel = AuditLogPanel;
            break;
        case CaseTabType.CASE_DETAILS_GENERIC:
            TabPanel = BasicDetailsGenericPanel;
            break;
        case CaseTabType.GENERIC_DOCUMENT:
            TabPanel = GenericDocumentPanel;
            break;
        case CaseTabType.FACE_IMAGES:
            TabPanel = FaceImagesPanel;
            break;
        case CaseTabType.SHARE_CODE:
            TabPanel = ShareCodePanel;
            break;
        case CaseTabType.IMPORTED:
            TabPanel = ImportedFormPanel;
            break;
    }

    return {
        tabs,
        tabSelected: tabSelected && ({
            ...tabSelected,
            context: tabSelected.context && {
                ...tabSelected.context,
                selectTab: selectTab,
                hasNoDocument: tabs.find(tab => [CaseTabType.GENERIC_DOCUMENT, CaseTabType.FACE_IMAGES, CaseTabType.SHARE_CODE].includes(tab.type)) === undefined
            }
        }),
        selectTab,
        TabPanel,
        interactions,
        handleCorrectionSectionOne,
        handleCorrectionSectionTwo,
        handleCompareSectionTwo,
        handleCompareSectionThree
    }
}
