import {Button, Grid, SvgIcon} from "@mui/material";
import {ReactComponent as UploadSuccessIcon} from "../../../img/upload-success.svg";
import {CenteredTextGrid} from "../common";

type SuccessScreenProps = {
    caseCount: number;
    handleCloseSuccess : () => void;
}

const SuccessScreen = ({caseCount, handleCloseSuccess} : SuccessScreenProps) => {
    return <Grid container item alignContent={"flex-start"}>
        <Grid container item xs={12} justifyContent={"center"}>
            <SvgIcon style={{fontSize: 88, marginTop: 80, marginBottom: 20,}}><UploadSuccessIcon/></SvgIcon>
        </Grid>
        <CenteredTextGrid sx={{fontSize: 26, fontWeight: 500, color: "#333"}}>
            File upload successful
        </CenteredTextGrid>
        <CenteredTextGrid sx={{fontSize: 16, fontWeight: 400, color: "#1A1A1A", margin: 2.5}}>
            You have successfully uploaded {`${caseCount}`} Cases.<br/>
            Press ‘See cases’ to view uploaded cases on the ‘Case Management’ Screen.
        </CenteredTextGrid>
        <Grid container item xs={12} justifyContent={"center"} spacing={2}>
            <Grid item>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleCloseSuccess}
                > Upload another </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    href={"/cases"}
                > See cases </Button>
            </Grid>
        </Grid>
    </Grid>
}

export default SuccessScreen;
