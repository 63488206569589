import { AppBar, Button, Checkbox, FormHelperText, Grid, Stack, TextField, Typography } from "@mui/material";
import HeaderBar from "../../components/HeaderBar";
import { PrimaryButton, SecondaryButton } from "../CreateCase/CreateCaseForm/styles";
import { useContext, useRef, useState } from "react";
import JourneyContext from "../../context/JourneyContext";
import {useNavigate, useParams} from "react-router-dom";
import { useCaseDetails } from "../../hooks/Cases/useCaseDetails";
import RejectCaseModal from "../../components/CaseDetailsForm/RejectCaseModal";
import VideoCallData from "../VerifyDocuments/VideoCallData";
import { DetailsBlock } from "../../components/CaseDetailsForm/common";
import { Controller, useForm } from "react-hook-form";
import { ConsentCheckbox } from "../CompleteSectionTwo/styles";
import FirstDayCheckImageBG from "../../../src/img/first-day-live-face-bg.png";
import {useCompleteFirstDayCheck, useResendNotifications} from "../../hooks/Cases/useMutateCase";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import NotificationModal from "../../components/NotificationModal";
import {ReactComponent as ConfirmationIcon} from "../../img/confirmation-icon.svg";
import { useQueryClient } from "@tanstack/react-query";
import InstructionsBottomBar from "../../components/InstructionsBottomBar";
import { CaseSpecifications } from "../../models/responses/Cases/CaseSpecifications/CaseSpecifications";
import {Meeting} from "../../models/responses/Cases/Meeting";

const FirstDayCheck = () => {

  const {caseId}: { caseId: string } = useParams();
  const {data} = useCaseDetails(caseId);
  const cache = useQueryClient();

  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [submitSuccessModalOpen, setSubmitSuccessModalOpen] = useState<boolean>(false);
  const signatureRef = useRef(null);

  const { currentJourney } = useContext(JourneyContext);
  const isI9 = currentJourney?.journeyType.startsWith("I9");
  const isPrescient = currentJourney?.journeyType === "PRESCIENT";

  const {handleSubmit, control, watch} = useForm({
    mode: 'all',
    defaultValues: {
        full_name: "",
        signature: {
            image: "",
            date: ""
        },
        consent: undefined
    }
  });

  const navigate = useNavigate();
  const onSubmitSuccess = () => {
    setSubmitSuccessModalOpen(true);
    setTimeout(function () {
        setSubmitSuccessModalOpen(false);
        cache.invalidateQueries(["cases-details", caseId])
            .then(() => {
                navigate(`/cases/${caseId}`);
            });
    }, 5000);
  }
  const onSubmitError = () => {
      toast.error("Could not complete first day check");
  }

  const { mutate, isLoading } = useCompleteFirstDayCheck(caseId, onSubmitSuccess, onSubmitError);

  const generateBase64Image = () => {
    if (signatureRef && signatureRef.current) {
        return html2canvas(signatureRef.current, {scale: 3}).then((canvas) => {
            return canvas.toDataURL("image/jpeg").split(';base64,')[1];
        });
    }
  }

  const  faceCheck = data?.additionalData?.faceCheck;
     // eslint-disable-next-line
    // @ts-ignore
  const  faceCheckImage = faceCheck && faceCheck[Object.keys(faceCheck)[0]]

  // eslint-disable-next-line
  const onSubmit = async (data: any) => {
    const imageBase64 = await generateBase64Image();

    const firstDayCheckReviewer = {
      reviewer: {
        full_name: data.signature.image,
        consent: data.consent,
        signature: {
            image: imageBase64 ? imageBase64 : data.signature.image,
            date: new Date().toLocaleDateString("sv-SE") + "T00:00:00.000Z"
        }
      },
    };

    mutate(firstDayCheckReviewer);
  }

  const specs = data?.specifications as CaseSpecifications;
  const interactiveEvents = specs?.interactiveEvents?.find(evtGroup => evtGroup.entryState === data?.status);
  const firstDayCheackAction = interactiveEvents && interactiveEvents.interactions.find(evt => evt.interactionType === "INSTRUCTIONS");
    const {mutate: sendNotifications} = useResendNotifications(
        data?.uid as string,
        () => { toast.success("Notification is re-sent"); },
        () => { toast.error("Notification re-sending is failed"); },
    );

  const  isInPerson = data?.status.includes("IN_PERSON");
  return(
    <HeaderBar
        breadcrumbs={[
            {
                label: isI9 ? "I-9 Cases" : (isPrescient? "myKYC Cases" : "myRTW Cases"),
                path: "/cases",
            },
            ...(data ? [{label: `${data.givenName} ${data.familyName}`}] : []),
            {
                label: caseId,
            },
        ]}
    >
        <Grid container sx={{paddingBottom: "100px"}}>
            <Grid
                alignItems={"center"}
                item
                xs={12}
                sx={{borderBottom: 1, borderColor: "divider", padding: 2}}
            >
                {(data?.additionalData?.meeting as Meeting)?.hostUrl &&
                    <VideoCallData meetingUrl={(data?.additionalData?.meeting as Meeting)?.hostUrl}/>
                }
            </Grid>
            <Grid
                alignItems={"center"}
                item
                xs={12}
                sx={{borderBottom: 1, borderColor: "divider", padding: 2}}
            >
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  {data?.additionalData?.face?.extracted &&
                    <Stack direction="column" spacing={2}>
                      <p>RTW CHECK: DOCUMENT FACE</p>
                      <img
                        src={data?.additionalData?.face?.extracted}
                        style={{
                          display: "block",
                          width: "257px",
                          height: '279px',
                          objectFit: 'cover',
                          borderRadius: "8px",
                          padding: "8px",
                          border: "1px solid #D7D7D7"
                        }}
                      />
                    </Stack>
                  }
                  {data?.additionalData?.face?.echip &&
                    <Stack direction="column" spacing={2}>
                      <p>RTW CHECK: eCHIP FACE</p>
                      <img
                        src={data?.additionalData?.face?.echip}
                        style={{
                          display: "block",
                          width: "257px",
                          height: '279px',
                          objectFit: 'cover',
                          borderRadius: "8px",
                          padding: "8px",
                          border: "1px solid #D7D7D7"
                        }}
                      />
                    </Stack>
                  }
                  {data?.additionalData?.face?.face &&
                    <Stack direction="column" spacing={2}>
                      <p>RTW CHECK: LIVE FACE</p>
                      <img
                        src={data?.additionalData?.face?.face}
                        style={{
                          display: "block",
                          width: "257px",
                          height: '279px',
                          objectFit: 'cover',
                          borderRadius: "8px",
                          padding: "8px",
                          border: "1px solid #D7D7D7"
                        }}
                      />
                    </Stack>
                  }
                    <Stack direction="column" spacing={2}>
                      <p>FIRST DAY CHECK: LIVE FACE</p>
                      {faceCheckImage ? <img
                        src={faceCheckImage}
                        style={{
                          display: "block",
                          width: "257px",
                          height: '279px',
                          objectFit: 'cover',
                          borderRadius: "8px",
                          padding: "8px",
                          border: "1px solid #D7D7D7"
                        }}
                      /> :
                      <div style={{
                        background: `url(${FirstDayCheckImageBG}) no-repeat center center`,
                        backgroundSize: 'cover',
                        display: "flex",
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignContent: 'center',
                        width: "257px",
                        height: '279px',
                        objectFit: 'cover',
                        borderRadius: "8px",
                        padding: "8px",
                        border: "1px solid #D7D7D7",
                      }}>
                        <Typography align="center" variant="h6"><strong>Awaiting Capture...</strong></Typography>
                        <p style={{textAlign: "center"}}>Once capture has been completed, please refresh to review the image.</p>
                        <PrimaryButton onClick={() => location.reload()}>Refresh</PrimaryButton>
                      </div>
                      }
                    </Stack>
                </Stack>
                  { !faceCheckImage && (isInPerson ?
                      <p><strong>NOTE:</strong> If there is an issue with the ‘FIRST DAY CHECK: LIVE FACE’ notification and you are unable to verify the employee, click on <a style={{color: "blue"}} onClick={() => sendNotifications()}>this link</a>. You will receive a notification shortly, it will guide you through the capture of their live face. Once the live face image is loaded, you can approve this check.</p>
                      : <p><strong>NOTE:</strong> If there is an issue with the ‘FIRST DAY CHECK: LIVE FACE’ notification and you are unable to verify the employee, click on <a style={{color: "blue"}}  onClick={() => sendNotifications()}>this link</a>. Let the employee know they will receive a notification shortly, it will guide them through the capture of their live face. Once the live face image is loaded, you can approve this check.</p>
                  )}
              </Grid>
              <DetailsBlock xs={10} title={"Approved by"}>
                  <Controller
                      // eslint-disable-next-line
                      //@ts-ignore
                      name="signature.image"
                      control={control}
                      rules={{required: true}}
                      render={({
                                    field,
                                    fieldState: {error},
                                }) => (
                          <>
                              <TextField
                                  {...field}
                                  sx={{width: "350px"}}
                                  size={"small"}
                                  error={!!error}
                                  helperText={error?.message}
                              />
                              <Grid item xs={10}>
                                  <div
                                      aria-label="signature"
                                      style={{
                                          display: "inline-block",
                                          marginTop: "15px",
                                          borderRadius: "4px",
                                          border: "1px solid #D7D7D7",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden"
                                      }}
                                  >
                                      <div
                                          ref={signatureRef}
                                          style={{
                                              display: "inline-block",
                                              fontFamily: "TuesdayNight",
                                              fontSize: "52px",
                                              marginTop: "15px",
                                              width: "350px",
                                              height: "100px",
                                              textAlign: "center",
                                              lineHeight: "100px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden"
                                          }}
                                      > {field.value}
                                      </div>
                                  </div>
                              </Grid></>
                      )}
                  />
                  <Controller
                      // eslint-disable-next-line
                      //@ts-ignore
                      name="consent"
                      control={control}
                      rules={{required: true}}
                      render={({
                                    field,
                                    fieldState: {error},
                                }) => (
                          <ConsentCheckbox
                              control={<><Checkbox {...field}/>{!!error &&
                                  <FormHelperText error>{error.message}</FormHelperText>}</>}
                              label="I agree to sign electronically and acknowledge and agree that your electronic signature is legally equivalent to a handwritten signature and that it binds you to the same extent as a handwritten signature would."/>
                      )}
                  />
              </DetailsBlock>
            </Grid>

            <AppBar
                position="fixed"
                sx={{
                    borderTop: 1,
                    borderColor: "divider",
                    top: "auto",
                    bottom: 0,
                    background: "white",
                }}
            >
                <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                    <Grid item xs={8}></Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Button sx={{textDecoration: "underline", textTransform: "none"}}
                                    href={`/cases/${caseId}`}>
                            Exit, no decision
                        </Button>
                        <SecondaryButton onClick={() => setRejectModalOpen(!isRejectModalOpen)}>
                            Reject
                        </SecondaryButton>
                        <PrimaryButton
                          disabled={!watch().consent || !faceCheckImage || isLoading}
                          onClick={handleSubmit(onSubmit)}
                        >
                            Confirm
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </AppBar>
        </Grid>
        <InstructionsBottomBar content={firstDayCheackAction} />
        <NotificationModal
            isOpen={submitSuccessModalOpen}
            text="Employee’s Live Face First Day Check has been approved"
            subtext="Case moved to 'Approved' status."
            icon={<ConfirmationIcon/>}
        />
        <RejectCaseModal caseId={caseId} isOpen={isRejectModalOpen} onClose={() => setRejectModalOpen(!isRejectModalOpen)} />
    </HeaderBar>
  )
}

export default FirstDayCheck;