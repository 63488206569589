import { Button, ButtonProps, Popover } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  background: theme.palette.primary.main,
  color: "#ffffff",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: "#000000",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const sidebarTopDetailMixin = (): CSSObject => ({
  content: "''",
  display: "block",
  position: "fixed",
  backgroundColor: "transparent",
  top: "0px",
  height: "50px",
  width: "25px",
  borderTopLeftRadius: "25px",
  boxShadow: "0 -25px 0 0 #000000",
  zIndex: 9999,
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 0, 0, 2.4),
  overflow: "hidden",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "&:after": {
    ...sidebarTopDetailMixin(),
    left: drawerWidth,
    transition: "all cubic-bezier(0.4, 0, 0.6, 1) 225ms",
  },
  ...(!open && {
    "&:after": {
      ...sidebarTopDetailMixin(),
      left: `calc(${theme.spacing(7)} + 9px)`,
      transition: "all ease 0.1s",
    },
  }),
}));

export const CreateCaseButton = styled(Button)<ButtonProps>(({theme}): CSSObject => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.main,
  width: "100%",
  textTransform: "none",
  "&:hover": {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  },
}));

export const AccountPopover = styled(Popover)(({theme}): CSSObject => ({
  ".MuiPopover-paper": {
    border: "1px solid #D7D7D7",
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.08)",
    backdropFilter: "blur(8px)",
    borderRadius: "10px",
    top: "auto !important",
    bottom: "90px",
    width: drawerWidth - 33,
    ".MuiList-root": {
      paddingTop: 0,
      paddingBottom: 0,
      ".MuiListItem-root": {
        alignItens: "flex-end",
        "&:hover": {
          background: theme.palette.secondary.light,
          cursor: "pointer",
        },
        ".MuiListItemAvatar-root": {
          minWidth: "36px",
          svg: {
            fontSize: "18px",
          },
        },
        ".MuiListItemText-root": {
          span: {
            fontSize: "12px",
          },
        },
        ".MuiListItemSecondaryAction-root": {
          button: {
            svg: {
              fontSize: "18px",
            },
          },
        },
      },
    },
  },
}));
