import {Button, Checkbox, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import { ConfirmationModalDialog } from "./styles";
import React, {useCallback, useState} from "react";

type OpenConfirmationModalProps = {
  message: string,
  content?: string,
  checkList?: string[],
  confirmButtonLabel: string,
  cancelButtonLabel?: string,
  confirmIsSecondaryButton?: boolean,
  onConfirm: ()=>void,
  onCancel: ()=>void,
}

interface ConfirmationModalInterface {
  openConfirmationModal: (props: OpenConfirmationModalProps) => void,
  open: boolean,
  title: string,
  body?: string,
  checkList?: string[],
  confirmBtnLabel: string,
  cancelBtnLabel: string,
  confirmIsSecondaryButton?: boolean,
  onClose: ()=>void,
  onSubmit: ()=>void,
}

export const useConfirmationModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string | undefined>(undefined);
  const [checkList, setCheckList] = useState<string[] | undefined>(undefined);
  const [confirmBtnLabel, setConfirmBtnLabel] = useState<string>("");
  const [cancelBtnLabel, setCancelBtnLabel] = useState<string>("");
  const [cancelFunc, setCancelFunc] = useState<()=>void>(()=>undefined);
  const [confirmFunc, setConfirmFunc] = useState<()=>void>(()=>undefined);
  const [confirmIsSecondaryButton, setConfirmIsSecondaryButton] = useState<boolean>(false);


  const openConfirmationModal = ({message, content, checkList, confirmButtonLabel, cancelButtonLabel = 'Cancel', confirmIsSecondaryButton = false, onConfirm, onCancel}: OpenConfirmationModalProps) => {
    setTitle(message);
    setConfirmBtnLabel(confirmButtonLabel);
    setCancelBtnLabel(cancelButtonLabel);
    setConfirmFunc(() => onConfirm);
    setCancelFunc(() => onCancel);
    setOpen(true);
    setBody(content);
    setCheckList(checkList);
    setConfirmIsSecondaryButton(confirmIsSecondaryButton);
  }

  const onClose = useCallback(() => {
    setOpen(false);
    cancelFunc();
  }, [cancelFunc]);

  const onSubmit = useCallback(() => {
    setOpen(false);
    confirmFunc();
  }, [confirmFunc]);

  return {openConfirmationModal, open, title, body, checkList, confirmBtnLabel, cancelBtnLabel, confirmIsSecondaryButton, onClose, onSubmit}
}

export const ConfirmationModelContainer = ({open, title, body, checkList, confirmBtnLabel, cancelBtnLabel, confirmIsSecondaryButton, onClose, onSubmit} : ConfirmationModalInterface) => {

  const [checked, setChecked] = useState<number[]>([]);

  const handleToggleCheckbox = (idx: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChecked([...checked, idx]);
    } else {
      setChecked(checked.filter(itm => itm !== idx));
    }
  }

  const disableConfirmButton = checkList && checkList.length !== checked.length;

  return <ConfirmationModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    { body && <DialogContent>{body}</DialogContent> }
    {
      checkList && checkList.map(
        (checkItem, idx) => <Grid container spacing={1} justifyContent={"space-around"} key={`checkItem-${idx}`}>
          <Grid item xs={1}><Checkbox
            checked={checked.includes(idx)}
            onChange={handleToggleCheckbox(idx)}
          /></Grid>
          <Grid item xs={10}>{checkItem}</Grid>
        </Grid>
      )
    }
    <DialogActions>
      <Button onClick={confirmIsSecondaryButton? onSubmit: onClose}
              variant="outlined"
              disabled={confirmIsSecondaryButton && disableConfirmButton}
              style={confirmIsSecondaryButton && disableConfirmButton ? {background: "grey"} : undefined}>
        {confirmIsSecondaryButton? confirmBtnLabel : cancelBtnLabel}
      </Button>
      <Button disabled={!confirmIsSecondaryButton && disableConfirmButton}
              onClick={confirmIsSecondaryButton? onClose: onSubmit}
              variant="contained"
              style={!confirmIsSecondaryButton && disableConfirmButton ? {background: "grey"} : undefined}>
        {confirmIsSecondaryButton? cancelBtnLabel : confirmBtnLabel}
      </Button>
    </DialogActions>
  </ConfirmationModalDialog>
}
