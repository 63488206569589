import {CSSObject, styled} from "@mui/material/styles";
import {ButtonProps, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: "100%",
    '& .MuiToggleButtonGroup-grouped': {
        marginBottom: theme.spacing(1),
        border: "0.5px solid !important",
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export const StyledToggleButton = styled(ToggleButton)<ButtonProps>(({theme}): CSSObject => ({
    textTransform: "unset",
    fontWeight: "bold",
    "&.Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
    },
    "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
    },
}));

export const StyledTimePicker = styled(TimePicker)(() => ({
    "& .MuiOutlinedInput-root": {
        "& .MuiInputBase-input": {
            padding: "12px 14px",
        },
    },
}));
