import {useQuery} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {CaseResponse, GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import {useAxiosParams} from "../common/useAxiosParams";

export const useCaseDetails = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()

    return useQuery<unknown, GenericJsonObject, CaseResponse>({
        queryKey: ["cases-details", caseUid],
        queryFn: async () => {
            try {
                const response = await axios.get<CaseResponse>(basePath + `/${caseUid}/interactiveEvents`, config);
                return response.data;
            }
            catch (error) {
                const axiosError = error as AxiosError;
                throw axiosError.response?.data || {"message": axiosError.message};
            }
        },
        cacheTime: 0, staleTime: 0, // always re-fetch the latest data
    })
}
