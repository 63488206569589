import {useQuery} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {useAxiosParams} from "../common/useAxiosParams";
import {JourneyInfo} from "../../models/responses/JourneyInfo";

export const useJourneys = () => {
    const {basePath, config} = useAxiosParams()

    return useQuery<unknown, AxiosError, JourneyInfo[]>({
        queryKey: ["journeys"],
        queryFn: async () => {
            const response = await axios.get<JourneyInfo[]>(basePath + `/journeyInfo`, config);
            return response.data;
        },
        staleTime: 5 * 60 * 1000, // the journeys available to a user wouldn't change on a high frequency, and shouldn't require frequent re-loading
    })
}
