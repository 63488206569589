import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { DetailsBlock } from "../common";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";
import { formatDate, formatTime } from "../../../utils/DateUtils/dateUtils";
import { useScheduleAppointment } from "../../../hooks/Cases/useScheduleAppointment";
import {
  StyledTimePicker,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "./styles";
import NotificationModal from "../../NotificationModal";

type ScheduleAppointmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
  startDate: string;
  section2Deadline: string;
  isAuthenticated?: boolean;
  isReschedule?: boolean;
  candidatename?: string;
};

const ScheduleAppointmentModal = ({
  isOpen,
  onClose,
  caseId,
  startDate,
  section2Deadline,
  isAuthenticated,
  isReschedule,
  candidatename
}: ScheduleAppointmentModalProps) => {
  const { mutate, isLoading, isSuccess } = useScheduleAppointment(caseId, isReschedule);
  // eslint-disable-next-line
  const formatToString = (date: any) => {
    return new Date(date).toLocaleDateString("en-US");
  };
  const [appointmentDate, setAppointmentDate] = useState<string | null>(
    startDate
  );
  const [appointmentTime, setAppointmentTime] = useState<Date | null>();
  const [validateModalOpen, setValidateModalOpen] = useState<boolean>(false);
  const [validationtText, setValidationText] = useState<string>("");
  const dialogTitle = isReschedule ? "Reschedule appointment" : "Schedule appointment";

  const handleScheduleAppointment = () => {
    if (!appointmentDate || !appointmentTime) {

      const validationText =
        !appointmentDate && !appointmentTime
          ? "Please select date and time"
          : !appointmentDate
          ? "Please select a date"
          : "Please select a time";
      setValidationText(validationText);
      setValidateModalOpen(true);
    } else {
      const caseTimeString = formatTime(appointmentTime)
      const startAppointmentDateTime = `${appointmentDate} ${caseTimeString}`;
      const startAppointmentDateTimeStr = new Date(startAppointmentDateTime).toISOString();
      const [hours, minutes] = caseTimeString.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      date.setMinutes(date.getMinutes() + 10);
      const endAppointmentTime = date.toTimeString().slice(0, 5);
      const endAppointmentDateTime = `${appointmentDate} ${endAppointmentTime}`;
      const endAppointmentDateTimeStr = new Date(endAppointmentDateTime).toISOString();
      mutate({
        startTime: startAppointmentDateTimeStr,
        endTime: endAppointmentDateTimeStr,
      });
    }
  };

  const formatDateToOptionLabel = (date: Date | string) =>
    date === ""
      ? date
      : formatDate(date, {
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        });

  const handleAppointmentDateChange = (
    event: React.MouseEvent<HTMLElement>,
    newAppointmentDate: string
  ) => {
    setAppointmentDate(newAppointmentDate);
  };

  // eslint-disable-next-line
  const handleAppointmentTimeChange = (newAppointmentTime: any) => {
    setAppointmentTime(newAppointmentTime);
  };

  const selectableDates = [];
  const date = new Date(startDate);
  while (date < new Date(section2Deadline)) {
    selectableDates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  const handleConfirmationModalClose = () => (
    onClose(), window.location.reload()
  );

  if (isAuthenticated === false && isSuccess)
    return (
      <Dialog
        open={isOpen}
        onClose={handleConfirmationModalClose}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: "14px",
              padding: "0",
              marginBottom: "0",
            }}
          >
            You should now schedule a video call with{" "}
            <strong>{candidatename}</strong> for{" "}
            <strong>{appointmentTime?.toString()}</strong> to verify the
            employees documents to complete Section 2.
          </p>
          <p
            style={{
              fontSize: "14px",
              padding: "0",
              marginBottom: "0",
            }}
          >
            When the video call takes place you can quickly access the case
            details for {candidatename} with the following{" "}
            {window.location.origin + "/cases/" + caseId}.
          </p>

          <p
            style={{
              fontSize: "14px",
              padding: "0",
              marginBottom: "0",
            }}
          >
            Remember, do not share this link with the employee.
          </p>
        </DialogContent>
      </Dialog>
    );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle sx={{fontWeight: "bold"}}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <p
            style={{
              fontSize: "14px",
              borderBottom: "1px solid #cecece",
              padding: "0 0 20px 0",
              marginBottom: "20px",
            }}
        >
          The available dates identified below provide the extent of the timeframe to complete the remote examination of
          the employee’s documentation. You can select any one of the available days and a time that is convenient for
          you and the employee. To schedule the appointment click on the day, input the time and confirm by clicking on
          the ‘{dialogTitle}’ button. Both you and the employee
          will receive a calendar invite via email.
          <br/>
          <br/>
          Candidate start date: {formatDate(startDate)}
        </p>
        <Grid container>
          <DetailsBlock xs={12}>
            <Grid container alignItems={"start"} spacing={10}>
              <Grid item xs={6}>
                <div style={{marginBottom: "10px"}}>
                  Select available date
                </div>
                <StyledToggleButtonGroup
                    orientation="vertical"
                    color="secondary"
                    aria-label="Appointment date"
                    value={appointmentDate}
                    exclusive
                    onChange={handleAppointmentDateChange}
                >
                  {selectableDates.map((date, key) => (
                      <StyledToggleButton value={formatToString(date)} key={key}>
                        {formatDateToOptionLabel(date)}
                      </StyledToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              </Grid>
              <Grid item xs={6}>
                <div style={{marginBottom: "10px"}}>Select time slot</div>
                <StyledTimePicker
                    label="Select time"
                    value={appointmentTime}
                    onChange={handleAppointmentTimeChange}
                />
              </Grid>
            </Grid>
            <Grid
                container
                item
                xs={12}
                style={{flexGrow: 1, padding: 1, marginTop: "30px"}}
            >
              <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
              >
                <SecondaryButton variant="contained" onClick={onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                    disabled={isLoading || !appointmentTime}
                    onClick={handleScheduleAppointment}
                >
                  {dialogTitle}
                </PrimaryButton>
              </Grid>
            </Grid>
          </DetailsBlock>
        </Grid>
      </DialogContent>
      <NotificationModal
          isOpen={validateModalOpen}
          handleOk={() => setValidateModalOpen(false)}
          text={validationtText}
      />
    </Dialog>
  );
};

export default ScheduleAppointmentModal;
