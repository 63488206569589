import {useContext, useState} from "react";
import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { AuditCriteriaProps } from "..";
import { AuditFormDateInput } from "./styles";
import dayjs from "dayjs";
import JourneyContext from "../../../context/JourneyContext";

type AuditQueryFormFieldProps = {
  name: string;
  label?: string;
  placeholder?: string;
  sx?: number;
  type?: string;
};

const AuditQueryForm = ({
  fields,
  setAuditCriteria,
}: {
  fields: AuditQueryFormFieldProps[];
  // eslint-disable-next-line
  setAuditCriteria: (setAuditCriteria: any) => void;
}) => {
  const { currentJourney } = useContext(JourneyContext);

  const updateAuditCriteriaState = (
    key: keyof AuditCriteriaProps,
    value: string | string[]
  ) => {
    if (["locationIds"].includes(key)) {
      setAuditCriteria((prevState: AuditCriteriaProps) => ({
        ...prevState,
        [key]: [value],
      }));
    } else {
      setAuditCriteria((prevState: AuditCriteriaProps) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");

  function findLocationIdByName(
    // eslint-disable-next-line
    arr: any,
    // eslint-disable-next-line
    key: any,
    names: string | string[]
  ): number[] {
    const ids: number[] = [];

    for (let i = 0; i < arr.length; i++) {
      if (names.includes(arr[i][key])) {
        ids.push(arr[i].id.toString());
      }
    }

    return ids;
  }

  const handleSelectLocations = (
    event: SelectChangeEvent<typeof selectedLocations>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedLocations(typeof value === "string" ? value.split(",") : value);
    setAuditCriteria((prevState: AuditCriteriaProps) => ({
      ...prevState,
      locationIds: findLocationIdByName(
        currentJourney?.locations,
        "name",
        value
      ),
    }));
  };

  return (
    <>
      <form style={{ width: "100%" }}>
        <Grid
          container
          alignItems={"center"}
          item
          xs={12}
          sx={{ marginTop: 2 }}
        >
          {fields.map((field, index) => {
            return (
              <Grid
                container
                alignItems={"center"}
                item
                xs={12}
                key={index}
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={4}>
                  {field.label}
                </Grid>
                <Grid item xs={field.sx || 8}>
                  {field.name === "auditReference" && (
                    <TextField
                      id={field.name}
                      name={field.name}
                      fullWidth
                      size={"small"}
                      placeholder="Unique ID"
                      onChange={(event) => {
                        updateAuditCriteriaState(
                          field.name as keyof AuditCriteriaProps,
                          event.target.value
                        );
                      }}
                    />
                  )}
                  {["dateFrom"].includes(field.name) && (
                    <AuditFormDateInput
                      // eslint-disable-next-line
                      onChange={(event: any) => {
                        updateAuditCriteriaState(
                          field.name as keyof AuditCriteriaProps,
                          event.toISOString()
                        );
                        setSelectedStartDate(event.toISOString());
                      }}
                    />
                  )}
                  {["dateTo"].includes(field.name) && (
                    <AuditFormDateInput
                      // eslint-disable-next-line
                      onChange={(event: any) => {
                        updateAuditCriteriaState(
                          field.name as keyof AuditCriteriaProps,
                          event.toISOString()
                        );
                      }}
                      minDate={dayjs(selectedStartDate) || ""}
                    />
                  )}
                  {field.name === "locationIds" && (
                    <Select
                      multiple
                      value={selectedLocations}
                      onChange={handleSelectLocations}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      fullWidth
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "10px",
                        },
                      }}
                    >
                      {currentJourney?.locations.map((location) => (
                        <MenuItem key={location.id} value={location.name}>
                          <Checkbox
                            checked={
                              selectedLocations.indexOf(location.name) > -1
                            }
                          />
                          <ListItemText primary={location.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </form>
    </>
  );
};

export default AuditQueryForm;
