import { styled, CSSObject } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";

export const AccountSettingsTabs = styled(Tabs)(() => ({
  borderRight: "0 !important",
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

export const AccountSettingsTab = styled(Tab)(
  ({ theme }): CSSObject => ({
    textAlign: "left",
    justifyContent: "flex-start",
    textTransform: "none",
    color: theme.palette.primary.main,
    fontSize: "14px",
    "&.Mui-selected": {
      fontWeight: "bold",
    },
  })
);
