import { FC, useEffect, useState } from "react"
import { GenericJsonObject } from "../../../models/responses/Cases/CaseResponse"
import { Grid, Paper, Typography } from "@mui/material"
import { DetailsBlock, ImageBlock, ImageQuery } from "../common"
import { CaseDetailsBox } from "./styles"
import { StringUtils } from "../../../utils/StringUtils"
import { CompareField } from "../ComparePanel/styles"
import PdfForm from "../../PdfForm"

export const GenericDocumentPanel: FC<{
    data: GenericJsonObject
}>
    = ({data}) => {

        const [secondaryDocumentPdfFile, setSecondaryDocumentPdfFile] = useState<Blob | undefined>(undefined);

        function isDocumentParticularsEmpty(obj: GenericJsonObject) {
            for (const key in obj) {
            // eslint-disable-next-line
              if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (value !== '' && value !== null && value !== undefined) {
                  return false;
                }
              }
            }
            return true;
          }

        useEffect(() => {
            if ((data?.image as string).includes(".pdf")) {
                fetch(data?.image as string, {
                    headers: {'Accept': 'application/pdf'}
                })
                    .then(response => {
                        if (response.ok) {
                            return response.arrayBuffer();
                        }
                    })
                    .then(arrayBuffer => {
                        if (arrayBuffer) {
                            setSecondaryDocumentPdfFile(new Blob([arrayBuffer], {type: 'application/pdf'}));
                        }
                    })
            } else {
                setSecondaryDocumentPdfFile(undefined);
            }
        }, [data]);

        const today = new Date();
        
        try {
            return <Grid container p={0} spacing={2} alignItems={"flex-start"}>
                <Grid item xs={6}>
                    <p>ORIGINAL SUBMISSION</p>
                    <CaseDetailsBox>
                        <DetailsBlock xs={12}>
                        {
                            (data?.image as string).includes(".pdf") 
                            ? <PdfForm pdfFile={secondaryDocumentPdfFile} /> : data.imageQuery ?
                                <ImageBlock
                                    useImageQuery={data.imageQuery as (()=>ImageQuery)}
                                    path={data.image as string}
                                    alt={"document image for " + data.documentName as string}
                                /> : <></>
                        }
                        </DetailsBlock>
                    </CaseDetailsBox>
                </Grid>
                {
                    data.documentParticulars && !isDocumentParticularsEmpty(data.documentParticulars as GenericJsonObject) && !(data?.image as string).includes(".pdf") && <Grid item xs={6}>
                        <p>Document Particulars</p>
                        <Paper>
                            {
                                Object.entries(data.documentParticulars as GenericJsonObject)
                                .map(([key, val]) => val ? <CompareField
                                        label={StringUtils.keyToLabel(key)}
                                        defaultValue={val as string}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="filled"
                                        key={`particulars_-${key}`}
                                        fullWidth
                                        error={key as string === "dateOfExpiry" && new Date(val as string).getTime() < today.setHours(0,0,0,0)}
                                    /> : <></>
                                )
                            }
                        </Paper>
                    </Grid>
                }

            </Grid>
        } catch (e) {
            console.error(e)
            return <Typography color={"error"} padding={1}> Unexpected data received for document: {data?.documentName as string} </Typography>
        }
}