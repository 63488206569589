import {styled, CSSObject} from "@mui/material/styles";
import {FormControlLabel, Paper} from "@mui/material";

export const ConsentCheckbox = styled(FormControlLabel)(() => ({
    "&.MuiFormControlLabel-root": {
        ">.MuiTypography-root": {
            fontSize: "0.8rem !important"
        }
    },
}));

export const PrePopulateDataBlock = styled(Paper)(
    ({theme}): CSSObject => ({
        background: theme.palette.secondary.light,
        padding: 10,
        width: "100%",
        borderBottom: "1px solid #D7D7D7",
        paddingRight: "25px",
        paddingLeft: "25px"
    })
);