import {useState} from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from "@mui/material";
import { DetailsBlock } from "../common";
import { StartEndDateField } from "../BasicDetailsPanel/styles";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";
import {useReactivate} from "../../../hooks/Cases/useMutateCase";

type ReactivateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
};

type ReactivateDataProps = {
  startDate: string;
};

const ReactivateModal = ({ isOpen, onClose, caseId }: ReactivateModalProps) => {
  const { mutate, isLoading } = useReactivate(caseId);

  const [reactivateData, setReactivateData] = useState<ReactivateDataProps>({
    startDate: ""
  });

  // eslint-disable-next-line
  const handleStartDateChange = (date: any) => {
    setReactivateData({
      ...reactivateData,
      startDate: new Date(date).toLocaleDateString("sv-SE") + "T00:00:00.000Z",
    });
  };

  const handleReactivate = () => {
    mutate(reactivateData);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>Reactivate</DialogTitle>
      <DialogContent>
        <p
          style={{
            fontSize: "14px",
            borderBottom: "1px solid #cecece",
            padding: "0 0 20px 0",
            marginBottom: "20px",
          }}
        >
          By clicking ‘Reactivate Case,’ you are authorizing the reactivation of this case with a new Start Date.
          <br/><br/>
          <b>Note:</b> A notification will be sent to the user, informing them of their new Start Date and instructions to complete their Form I-9.
        </p>
        <Grid container>
          <DetailsBlock xs={12}>
            <Grid container alignItems={"center"}>
              <Grid item xs={5}>
                Start Date:
              </Grid>
              <Grid item xs={7}>
                <StartEndDateField onAccept={handleStartDateChange} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ flexGrow: 1, padding: 1, marginTop: "30px" }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <SecondaryButton variant="contained" onClick={onClose}>
                  Exit, no decision
                </SecondaryButton>
                <PrimaryButton
                  disabled={!reactivateData.startDate || isLoading}
                  onClick={handleReactivate}
                >
                  Reactivate Case
                </PrimaryButton>
              </Grid>
            </Grid>
          </DetailsBlock>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReactivateModal;
