import { Dialog, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../CreateCase/CreateCaseForm/styles";
import { Close } from "@mui/icons-material";
import { useRejectCaseDocuments } from "../../../hooks/Cases/useMutateCase";

type RejectDocumentsModalProps = {
  onClose: () => void;
  caseId: string;
};

const RejectDocumentsModal = ({
  onClose,
  caseId,
}: RejectDocumentsModalProps) => {
  const { mutate, isLoading } = useRejectCaseDocuments(caseId);

  const rejectCaseDocuments = mutate as () => void;

  return (
    <Dialog open={true} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <p style={{ margin: 0 }}>Rejecting documentation</p>
          <p style={{ margin: 0 }} onClick={onClose}>
            <Close fontSize="small" />
          </p>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <p
          style={{
            fontSize: "14px",
            padding: "0",
            marginBottom: "0px",
            color: "#505050",
          }}
        >
          Rejecting documentation is the decision if the employees documentation
          does not appear genuine or does not relate to the employee on the
          call.
        </p>
        <p
          style={{
            fontSize: "14px",
            padding: "0",
            marginBottom: "0px",
            color: "#505050",
          }}
        >
          For any other reason go back and use the{" "}
          <strong>Exit, no decision</strong> tab and identify your reason.
        </p>
        <p
          style={{
            fontSize: "14px",
            padding: "0",
            marginBottom: "0px",
            color: "#505050",
          }}
        >
          Click <strong>Reject</strong> to confirm
        </p>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            style={{ flexGrow: 1, padding: 1, marginTop: "30px" }}
          >
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <SecondaryButton variant="contained" onClick={onClose}>
                Return to Verification
              </SecondaryButton>
              <PrimaryButton disabled={isLoading} onClick={rejectCaseDocuments}>
                Reject
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RejectDocumentsModal;
