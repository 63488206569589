import { Location } from "../../../models/responses/Location";
import { formatDate, formatDateTime } from "../../../utils/DateUtils/dateUtils";
import {
  CaseResponse,
  getDueDate,
} from "../../../models/responses/Cases/CaseResponse";
import CaseListStatusBadge from "../../Cases/CaseListStatusBadge";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AuditHeadTableCell } from "./styles";
import {useContext} from "react";
import JourneyContext from "../../../context/JourneyContext";

type AuditCaseListProps = {
  cases: CaseResponse[];
};

const AuditCaseList = ({ cases }: AuditCaseListProps) => {
  const { currentJourney } = useContext(JourneyContext);

  const getLocationForId = (locationId: number): Location | null => {
    if (locationId === null || locationId === undefined) {
      return null;
    }
    return (
      (currentJourney?.locations || []).find(
        (location) => location.id === locationId
      ) || null
    );
  };

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="Audit Case List">
          <TableHead>
            <TableRow>
              <AuditHeadTableCell>Candidate Ref</AuditHeadTableCell>
              <AuditHeadTableCell>First Name</AuditHeadTableCell>
              <AuditHeadTableCell>Last Name</AuditHeadTableCell>
              <AuditHeadTableCell>Location</AuditHeadTableCell>
              <AuditHeadTableCell>Start Date</AuditHeadTableCell>
              <AuditHeadTableCell>Section Status</AuditHeadTableCell>
              <AuditHeadTableCell>Due Date</AuditHeadTableCell>
              <AuditHeadTableCell>Create Date</AuditHeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cases.map((caseItem) => (
              <TableRow
                key={caseItem.uid}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="caseItem">
                  {caseItem.consumerReference}
                </TableCell>
                <TableCell>{caseItem.givenName}</TableCell>
                <TableCell>{caseItem.familyName}</TableCell>
                <TableCell>
                  {getLocationForId(caseItem.locationId)?.name ||
                    caseItem.country}
                </TableCell>
                <TableCell>{formatDate(caseItem.startDate || "")}</TableCell>
                <TableCell>
                  {<CaseListStatusBadge status={caseItem.status} />}
                </TableCell>
                <TableCell>{getDueDate(caseItem)}</TableCell>
                <TableCell>{formatDateTime(caseItem.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AuditCaseList;
