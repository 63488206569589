import {ActivityBox, ActivityLine} from "./styles";

import {ReactComponent as UserEdit} from "../../../img/activity-user-edit-icon.svg";
import {ReactComponent as SystemEdit} from "../../../img/activity-system-icon.svg";
import {ReactComponent as UserMinus} from "../../../img/activity-user-minus-icon.svg";
import {ReactComponent as UserPlus} from "../../../img/activity-user-plus-icon.svg";
import {CaseActivityLogResponse} from "../../../models/responses/CaseActivityLogResponse";

const activityTypeMap = new Map(
    Object.entries({
        ["useredit"]: <UserEdit/>,
        ["system"]: <SystemEdit/>,
        ["userminus"]: <UserMinus/>,
        ["userplus"]: <UserPlus/>,
    })
);

const AllActivityWidget = ({activityLogs}:
                               {
                                   activityLogs: CaseActivityLogResponse[] | undefined
                               }) => {
    return (
        <ActivityBox>
            {activityLogs?.map((item, index) => (
                <ActivityLine key={index} direction="row">
                    {activityTypeMap.get(item.type)}
                    <div>
                        <p>{item.text}</p>
                        <p>
                            <span>{item.displayTime}</span>
                        </p>
                    </div>
                </ActivityLine>
            ))}
        </ActivityBox>
    );
};

export default AllActivityWidget;
