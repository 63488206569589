export class StringUtils {
  static keyToLabel(key: string): string {
    return key
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
    // space before last upper in a sequence followed by lower
            .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
            .replace(/_/g, ' ')
            .toUpperCase();
  }

  static camelCaseToLabel(key: string): string {
    const label = key
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        .replace(/_/g, ' ');
    // capitalize First Letter
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  static replaceWindowsForbiddenFileNameCharacters(str: string, replacement: string): string {
    return str.replaceAll(/[<>:"\\/|?*.]/g, replacement);
  }
}
