import { CaseSpecifications } from "./CaseSpecifications/CaseSpecifications";
import {formatDate} from "../../../utils/DateUtils/dateUtils";
import {Meeting} from "./Meeting";

export type CaseResponse = {
  uid: string;
  consumerReference: string;
  createdBy: string;
  givenName: string;
  familyName: string;
  emailAddress: string;
  mobileNumber: string;
  clientId: string;
  brandId: string;
  journeyId: string;
  createdAt: string;
  status: string;
  prevStatus?: string;
  country: string;
  locationId: number;
  displayStatus?: string;
  startDate?: string;
  endDate?: string;
  additionalData: AdditionalData;
  reportDownloaded: boolean;
  importedPdfUrl?: string;
  specifications?: CaseSpecifications;
  [key: string]: string | AdditionalData | CaseSpecifications | number | undefined | boolean;
};

export type AdditionalData = {
  documents? : GenericJsonObject;
  geolocation? : {
    latitude?: string,
    longitude?: string
  };
  caseDetails? : GenericJsonObject;
  meeting?: MeetingMap | Meeting;
  Section_1? : GenericJsonObject[];
  Section_2? : GenericJsonObject[];
  Section_3? : GenericJsonObject[];
  Section_2_Documents? : Section_2_Document[];
  Section_3_Documents?: Section_3_Document[]
  standaloneTransactions?: {
    Section_2?: GenericJsonObject[];
    Section_3?: GenericJsonObject[];
    Section_2_Documents?: GenericJsonObject[];
    Section_3_Documents?: GenericJsonObject[];
  }
  selectedDocuments? : string[];
  selectedReasons? : string;
  shareCode? : string;
  i9_section_1_deadline? : string;
  i9_section_2_deadline? : string;
  i9_section_3_deadline? : string;
  i9_section_2_invite_timestamp?: string;
  i9_section_3_invite_timestamp?: string;
  everify_case_status?: string;
  everify_case_creation_failed?: boolean;
  face?: {
    face?: string;
    echip?: string;
    extracted?: string;
    firstday?: string;
  }
  [key: string]: GenericJsonObject[] | GenericJsonObject | string[] | string | undefined | boolean;
}

export type Section_2_Document = {
  documents: { [key: string]: string }[]
  createdAt: string;
}

export type Section_3_Document = {
  documents: { [key: string]: string }[]
  createdAt: string;
}

export type GenericJsonObject = {
  [key: string]: GenericJsonObject[] | string[] | string | number | undefined | GenericJsonObject | boolean | (()=>void)
}

export type MeetingMap = {
  [key: string]: Meeting
}

type CaseStatusDictionaryProps = {
  [key: string]: string[] | undefined
}

export const CaseStatusDictionary : CaseStatusDictionaryProps = {
  "ALL_CASES" : [],
  "Section_1" : ["SECTION_1_INVITED", "SECTION_1_CORRECTION", "SECTION_1_COMPLETE"],
  "Section_2" : ["SECTION_2_INVITED", "SECTION_2_COMPLETE", "DOCUMENT_SUBMITTED"],
  "Section_3" : ["SECTION_3_INVITED","SECTION_3_RENAME", "SECTION_3_REHIRE", "SECTION_3_SUBMITTED", "SECTION_3_RENAME_SUBMITTED", "SECTION_3_REHIRE_SUBMITTED", "SECTION_3_COMPLETE"],
  "E_Verify": ["E_VERIFY"],
  "Archived": ["ARCHIVED"],
  "Invited": ["INVITED", "DOCUMENTS_SUBMITTED", "PENDING_FOR_REINVITE"],
  "Re_invited": ["REINVITED", "DOCUMENTS_RESUBMITTED"],
  "Approved": ["APPROVED", "APPROVED_REFERRED"],
  "Approve": ["APPROVAL_REQUIRED", "APPROVAL_REQUIRED_EXPIRED_PASSPORT", "APPROVAL_REQUIRED_WITH_AUTORETRIEVED_SHARECODE", "APPROVAL_REQUIRED_WITH_UPLOADED_SHARECODE", "EXCEPTION_HANDLING_FOR_SHARECODE"],
  "Verify": ["VERIFICATION_REQUIRED", "VERIFICATION_REQUIRED_EXPIRED_PASSPORT"],
  "Reverify": ["REVERIFICATION_REQUIRED"],
  "Failed": ["UNABLE_TO_VALIDATE_EVIDENCE", "FAILED"],
  "Referred": ["REFERRED"],
  "Cancelled": ["CANCELLED"]
}

export const getDueDate = (caseItem: CaseResponse) : string => {
  if (["SECTION_1_INVITED", "INVITED"].includes(caseItem.status)) {
    return caseItem.additionalData.i9_section_1_deadline ? formatDate(caseItem.additionalData.i9_section_1_deadline) : "";
  }
  if (["SECTION_1_COMPLETE", "SECTION_1_CORRECTION", "SECTION_2_COMPLETE"].includes(caseItem.status)) {
    return caseItem.additionalData.i9_section_2_deadline ? formatDate(caseItem.additionalData.i9_section_2_deadline) : "";
  }
  if (["SECTION_3_INVITED", "SECTION_3_COMPLETE"].includes(caseItem.status)) {
    return caseItem.additionalData.i9_section_3_deadline ? formatDate(caseItem.additionalData.i9_section_3_deadline) : "";
  }
  return "";
}

export const getRawDueDate = (caseItem: CaseResponse) : string => {
  if (["SECTION_1_INVITED", "INVITED"].includes(caseItem.status)) {
    return caseItem.additionalData.i9_section_1_deadline || "";
  }
  if (["SECTION_1_COMPLETE", "SECTION_1_CORRECTION", "SECTION_2_COMPLETE"].includes(caseItem.status)) {
    return caseItem.additionalData.i9_section_2_deadline || "";
  }
  if (["SECTION_3_INVITED", "SECTION_3_COMPLETE"].includes(caseItem.status)) {
    return caseItem.additionalData.i9_section_3_deadline || "";
  }
  return "";
}

export const getStatus = (emsCase: CaseResponse): string  => {
  return  emsCase.status === "SECTION_1_COMPLETE" && emsCase.additionalData.i9_section_2_invite_timestamp !== undefined
      ? "SECTION_2_INVITED" : emsCase.status;
}