import {useAxiosParams} from "../common/useAxiosParams";
import {useQuery} from "@tanstack/react-query";
import {GenericJsonObject} from "../../models/responses/Cases/CaseResponse";
import axios, {AxiosError} from "axios";
import {CaseActivityLogResponse, sortCaseActivityLogResponse} from "../../models/responses/CaseActivityLogResponse";

export const useActivityLogList = (journeyId: string) => {
    const {basePath, config} = useAxiosParams()

    return useQuery<unknown, GenericJsonObject, CaseActivityLogResponse[]>({
        queryKey: ["all-activity-logs", journeyId],
        queryFn: async () => {
            try {
                const response = await axios.get<CaseActivityLogResponse[]>(basePath + `/journeys/${journeyId}/activityLogs`, config);
                return response.data?.sort(sortCaseActivityLogResponse);
            } catch (error) {
                const axiosError = error as AxiosError;
                throw axiosError.response?.data || {"message": axiosError.message};
            }
        }
    })
}