import {Grid, SvgIcon, SxProps} from "@mui/material";
import {FC} from "react";
import {Theme} from "@mui/material/styles";
import {ReactComponent as RedErrorIcon} from "../../img/red-error.svg";

type CenteredTextGridProps = {
    xs?: number;
    children: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[];
    sx?: SxProps<Theme>
}

export const CenteredTextGrid : FC<CenteredTextGridProps> = ({children, xs= 12, sx}) =>{
    return <Grid item xs={xs} sx={{textAlign: "center", ...sx}}>{children}</Grid>
}

type UploadCsvErrorGridProps = {
    message: string;
}

export const UploadCsvErrorGrid : FC<UploadCsvErrorGridProps> = ({message}) => {
    return <Grid container item xs={12}>
        <Grid container item xs={1.2} alignContent={"center"}>
            <SvgIcon><RedErrorIcon/></SvgIcon>
        </Grid>
        <Grid container item xs={10.8} sx={{color: "#666", fontSize: 14, fontWeight: 500}} alignContent={"center"}>
            <div>{message}</div>
        </Grid>
    </Grid>
}

export const renderCsvValidationErrorRows = (rows : number[]) => {
    switch (rows.length) {
        case 0: throw new Error("Validation error returned without row number");
        case 1: return `line ${rows[0]}`;
        case 2: return `lines ${rows[0]} and ${rows[1]}`;
        case 3: return `lines ${rows[0]}, ${rows[1]} and ${rows[2]}`;
        default: return `lines ${rows[0]} and ${rows[1]}, and in ${rows.length - 2} other case(s)`;
    }
};
