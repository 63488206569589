import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SectionTabs = styled(Tabs)(() => ({
  minHeight: 0,
  marginTop: "68px",
  "& .MuiTabs-indicator": {
    backgroundColor: "#000",
  },
}));

export const SectionTab = styled(Tab)(() => ({
  opacity: 0.4,
  textTransform: "none",
  padding: "4px",
  minHeight: 0,
  minWidth: 0,
  margin: "0px 5px",
  "&.Mui-selected": {
    color: "#000",
    opacity: 1,
  },
}));
