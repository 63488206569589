import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {useAxiosParams} from "../common/useAxiosParams";

export type ScheduleAppointmentRequest = {
    startTime: string;
    endTime: string;
    isInPerson?: boolean | undefined;
}

export const useScheduleAppointment = (caseUid: string, isReschedule: boolean | undefined) => {
    const {orchestratorPath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async ({startTime, endTime, isInPerson}: ScheduleAppointmentRequest) => {
            const response = await axios[isReschedule ? 'put' : 'post'](orchestratorPath + `/meetings`, {
                "caseId": caseUid,
                "startTime": startTime,
                "endTime": endTime,
                "inPerson": isInPerson
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(()=>window.location.href = window.location.origin + "/cases/" + caseUid);
        },
        onError: (error) => {
            console.error(error);
            alert("Could not schedule an appointment");
        }
    });
}
