import { useState } from "react";
import { DownloadAuditLogRequest } from "../../models/requests/DownloadAuditLogRequest";

export const useAuditLogForm = (defaultValue: DownloadAuditLogRequest) => {

    const [data, setData] = useState<DownloadAuditLogRequest>(defaultValue);

    const handleChange = (key: string) => (value: string | number[]) => {
        setData({
            ...data,
            [key]: value,
        });
    }

    return {
        data,
        handleChange,
    }
}