import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ActivityBox = styled(Box)(() => ({
  position: "relative",
  "&:after": {
    content: '""',
    border: "1px dashed #D7D7D7",
    width: 1,
    height: "100%",
    position: "absolute",
    left: "13px",
    top: "10px",
    zIndex: 1,
  },
}));

export const ActivityLine = styled(Stack)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  alignItems: "center",
  marginBottom: "24px",
  position: "relative",
  zIndex: 2,
  p: {
    fontSize: "12px",
    lineHeight: "14px",
    margin: 0,
    span: {
      color: "#666666",
    },
  },
  svg: {
    background: "#fff"
  },
}));
