import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import I9Form from "../../../components/CaseDetailsForm/I9Form";
import DocumentImage from "../../../components/DocumentImage";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useCaseDetails} from "../../../hooks/Cases/useCaseDetails";
import Text from "../../../components/common/Text";
import {formatFullDate} from "../../../utils/DateUtils/dateUtils";
import {PrimaryButton, SecondaryButton} from "../../CreateCase/CreateCaseForm/styles";
import {CaseResponse} from "../../../models/responses/Cases/CaseResponse";
import AuditLogList from "../../AuditLogList";
import JourneyContext from "../../../context/JourneyContext";
import {getDisplayDocumentTitleAboveImage, getDocumentImages} from "../../../utils/CaseUtils/documentsDisplayUtil";

const CaseDetailsIceAuditorModal = ({index, setIndex, getCase, casesCount, open, setOpen}:
                                        {
                                            index: number,
                                            casesCount: number,
                                            getCase: (index: number) => CaseResponse
                                            open: boolean,
                                            setOpen: Dispatch<SetStateAction<boolean>>,
                                            setIndex: Dispatch<SetStateAction<number>>
                                        }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(index);
    const [currentCaseId, setCurrentCaseId] = useState<string>(getCase(index).uid);

    const [prevCaseId, setPrevCaseId] = useState<string | undefined>();
    const [nextCaseId, setNextCaseId] = useState<string | undefined>();
    const [showAuditLogs, setShowAuditLogs] = useState<boolean>(false);

    const getNextCaseId = (index: number) => {
        return index + 1 < casesCount ? getCase(index + 1).uid : undefined;
    }
    const getPrevCaseId = (index: number) => {
        return index - 1 >= 0 ? getCase(index - 1).uid : undefined;
    }

    useEffect(() => {
        setCurrentIndex(index);
    }, [index])

    useEffect(() => {
        setCurrentCaseId(getCase(index).uid);
        setNextCaseId(getNextCaseId(currentIndex));
        setPrevCaseId(getPrevCaseId(currentIndex));
    }, [currentIndex])

    const {data: emsCase} = useCaseDetails(currentCaseId);

    const {currentJourney} = useContext(JourneyContext);
    const [i9PdfFile, setI9PdfFile] = useState<Blob | undefined>(undefined);

    useEffect(() => {
        if (emsCase?.additionalData["i9Pdf"]) {
            fetch(emsCase?.additionalData["i9Pdf"] as string, {
                headers: {'Accept': 'application/pdf'}
            })
                .then(response => {
                    if (response.ok) {
                        return response.arrayBuffer();
                    }
                })
                .then(arrayBuffer => {
                    if (arrayBuffer) {
                        setI9PdfFile(new Blob([arrayBuffer], {type: 'application/pdf'}));
                    }
                })
        } else {
            setI9PdfFile(undefined);
        }
    }, [emsCase])

    const closeButtonHandler = () => {
        setShowAuditLogs(false);
        setOpen(false);
    }

    const section2Data = emsCase?.additionalData?.Section_2;
    // eslint-disable-next-line
    const section2Documents: { [key: string]: string }[] = section2Data && (section2Data[section2Data.length - 1]?.employer_or_authorized_representatives as any)?.documents;
    const section3Data = emsCase?.additionalData?.Section_3;
    // eslint-disable-next-line
    const section3Documents: { [key: string]: string }[] = section3Data && (section3Data[section3Data.length - 1]?.reverification as any)?.documents;
    // eslint-disable-next-line
    const standaloneDocuments: any = emsCase?.additionalData.standaloneDocuments;
    // eslint-disable-next-line
    const standaloneTransactions: any = emsCase?.additionalData.standaloneTransactions;
    // eslint-disable-next-line
    const standaloneTransactionsSectionTwo: any = standaloneTransactions?.Section_2 || [];
    // eslint-disable-next-line
    const standaloneTransactionsSectionThree: any = standaloneTransactions?.Section_3 || [];

    const sectionThreeDocumentImages: { title: string, image: string }[]  = getDocumentImages(section3Documents, standaloneTransactionsSectionThree);
    const sectionTwoDocumentImages: { title: string, image: string }[]  = getDocumentImages(section2Documents, standaloneTransactionsSectionTwo);

    const handleAuditLogClick = () => {
        setShowAuditLogs(true);
    };

    const backButtonHandler = () => {
        setShowAuditLogs(false);
    };

    return (
        <Dialog maxWidth={'lg'}
                open={open}
                fullWidth>
            <DialogTitle>
                {showAuditLogs &&
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <div style={{
                            height: 0
                        }}>
                            <IconButton
                                aria-label="back"
                                onClick={backButtonHandler}
                                sx={{
                                    height: 35,
                                    width: 35,
                                }}
                            >
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </div>
                    </div>
                }
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{
                        height: 0,
                        paddingRight: 15,
                        paddingTop: 5
                    }}>
                        <IconButton
                            aria-label="close"
                            onClick={closeButtonHandler}
                            sx={{
                                height: 35,
                                width: 35,
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <div style={{
                        marginBottom: 15,
                        marginLeft: 30,
                        fontSize: 16,
                        textTransform: 'uppercase'
                    }}>
                        {emsCase?.givenName && emsCase?.familyName
                            && <strong>{`${emsCase?.givenName} ${emsCase?.familyName}`}</strong>}
                        {emsCase?.consumerReference
                            && <span style={{color: "#666666"}}>{` (${emsCase?.consumerReference})`}</span>}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                {!showAuditLogs &&
                    <div>
                        <Grid container
                              flexDirection="row"
                              justifyContent="space-between"
                              marginTop="5px"
                              style={{
                                  backgroundColor: 'rgba(28,217,185,0.05)',
                              }}>
                            <Grid item container xs={4}
                                  marginLeft="30px"
                                  marginTop="10px"
                                  marginBottom="10px">
                                {emsCase?.startDate && <Text
                                    label="Start Date"
                                    value={formatFullDate(emsCase.startDate)}/>
                                }
                                {emsCase?.locationId && <Text
                                    label="Location"
                                    value={currentJourney?.locations.find(l => l.id === emsCase?.locationId)?.name}/>
                                }
                            </Grid>
                            <Grid item container xs={4}
                                  marginTop="10px"
                                  marginBottom="10px">
                                {emsCase?.additionalData.everify_case_number
                                    && (typeof emsCase?.additionalData.everify_case_number === "string")
                                    && <Text
                                        label="E-Verify Case Number"
                                        value={emsCase.additionalData.everify_case_number}/>
                                }
                                <br/>
                                {emsCase?.additionalData.everify_case_creation_failed == true ?
                                    <Text label="E-Verify Case Status" value="Case Creation Failed"/>
                                    : (emsCase?.additionalData.everify_case_eligibility_statement ?

                                        typeof emsCase?.additionalData.everify_case_eligibility_statement === "string"
                                        && <Text
                                            label="E-Verify Case Status"
                                            value={emsCase.additionalData.everify_case_eligibility_statement}/>
                                        :
                                        emsCase?.additionalData.everify_case_status_display
                                        && typeof emsCase?.additionalData.everify_case_status_display === "string"
                                        && <Text
                                            label="E-Verify Case Status"
                                            value={emsCase.additionalData.everify_case_status_display}/>)
                                }
                            </Grid>
                            <Grid item container xs="auto"
                                  marginTop="15px"
                                  marginRight="30px"
                                  justifyContent="flex-end"
                                  alignItems="flex-start">
                                <SecondaryButton variant="contained"
                                                 onClick={handleAuditLogClick}
                                                 style={{height: "30px"}}>
                                    Audit Log
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                        <Grid container
                              flexDirection="row"
                              marginTop="15px"
                              paddingRight="30px"
                              paddingLeft="30px"
                              justifyContent="space-between">
                            <Grid item container xs={5.8}
                                  alignItems="flex-start"
                                  alignContent="flex-start"
                                  justifyContent="flex-start"
                                  maxHeight="60vh">
                                {emsCase?.additionalData["i9Pdf"] &&
                                    <I9Form i9PdfFile={i9PdfFile}/>
                                }
                            </Grid>
                            <Grid item container xs={5.8} overflow="scroll"
                                  alignItems="flex-start"
                                  alignContent="flex-start"
                                  justifyContent="flex-start"
                                  maxHeight="58.7vh">
                                {sectionTwoDocumentImages.length > 0 &&
                                    sectionTwoDocumentImages.map((item: { title: string, image: string }, index: number) => (
                                            <DocumentImage key={index}
                                                           imageName={getDisplayDocumentTitleAboveImage(section2Documents, item.title)}
                                                           imageScr={item.image}/>
                                        )
                                    )
                                }
                                {sectionThreeDocumentImages.length > 0 &&
                                    sectionThreeDocumentImages.map((item: { title: string, image: string }, index: number) => (
                                            <DocumentImage key={index}
                                                           imageName={getDisplayDocumentTitleAboveImage(section3Documents, item.title)}
                                                           imageScr={item.image}/>
                                        )
                                    )
                                }
                            </Grid>
                        </Grid>
                    </div>}
                {showAuditLogs &&
                    <div style={{height: "600px", width: "100%"}}><AuditLogList caseId={currentCaseId}/></div>}
            </DialogContent>
            <DialogActions>
                <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                    <Grid item xs={8}></Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        alignItems={"center"}
                        justifyContent={"flex-end"}>
                        <SecondaryButton variant="contained"
                                         disabled={!prevCaseId}
                                         onClick={() => {
                                             setIndex(index - 1);
                                         }}>
                            PREVIOUS
                        </SecondaryButton>
                        <PrimaryButton
                            disabled={!nextCaseId}
                            onClick={() => {
                                setIndex(index + 1);
                            }}>
                            NEXT
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default CaseDetailsIceAuditorModal;
