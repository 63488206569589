import { Typography } from "@mui/material";
import { DateNameWidgetBox } from "./styles";
import dayjs from "dayjs";

import { ReactComponent as DateNameWidgetBG } from "../../../img/date-name-widget-bg.svg";

type DateNameWidgetProps = {
  name: string;
};

const DateNameWidget = ({ name }: DateNameWidgetProps) => {
  const today = dayjs();
  return (
    <DateNameWidgetBox>
      <p className="date">{today.format("MMM D, YYYY")}</p>
      <Typography variant="h3">
        Good day,
        <br />
        {name}
      </Typography>
      <p>Let’s review some candidates</p>
      <DateNameWidgetBG />
    </DateNameWidgetBox>
  );
};

export default DateNameWidget;
